import React from 'react';

interface Props {
  color: string;
}

export default ({ color, ...props }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 358 358"
    fill={color || '#000'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M112.8 187.579l109.584-31.422 5.513 19.225-109.584 31.423-5.513-19.226zM144.223 297.163l109.584-31.422 5.513 19.225-109.584 31.423-5.513-19.226zM138.854 140.577l109.584-31.422 5.513 19.225-109.584 31.423-5.513-19.226zM188.541 244.924l109.584-31.423 5.512 19.226-109.584 31.422-5.512-19.225zM128.38 104.049l109.584-31.422 5.512 19.225-109.583 31.423-5.513-19.226zM178.066 208.396l109.584-31.423 5.513 19.226-109.584 31.422-5.513-19.225zM81.378 77.996l109.584-31.423 5.512 19.225L86.89 97.221l-5.512-19.225z" />
    <path d="M351.066 129.661c27.249 95.029-27.698 194.156-122.727 221.405C133.31 378.315 34.183 323.368 6.934 228.339-20.316 133.31 34.632 34.183 129.661 6.934c95.029-27.249 194.156 27.698 221.405 122.727zm-128.24 202.18c84.412-24.205 133.219-112.256 109.015-196.667C307.636 50.762 219.585 1.954 135.174 26.159 50.762 50.364 1.954 138.415 26.159 222.826c24.205 84.412 112.256 133.219 196.667 109.015z" />
  </svg>
);
