import React from 'react';
import { Main, Left, Right } from './ButtonWrapper.styled';

export interface ButtonWrapperProps {
  numberOnRight?: number;
  children: React.ReactNode;
}

/**
 * Lays out groups of buttons
 */
const ButtonWrapper = ({ numberOnRight = 1, children }: ButtonWrapperProps) => {
  const childrenArrey = React.Children.toArray(children);
  let left = childrenArrey.slice(0, -numberOnRight);
  let right = childrenArrey.slice(-numberOnRight);

  if (numberOnRight === 0) {
    left = childrenArrey;
    right = [];
  }

  const autoColumns = childrenArrey.length === 2 || numberOnRight === 0;

  return (
    <Main autoColumns={autoColumns}>
      {left.length ? <Left autoColumns={autoColumns}>{left}</Left> : null}
      {right.length ? <Right autoColumns={autoColumns}>{right}</Right> : null}
    </Main>
  );
};

export default ButtonWrapper;
