import React from 'react';
import { useTheme } from 'styled-components';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import {
  setHours,
  setMinutes,
  getHours,
  getMinutes,
  addYears,
  format,
} from 'date-fns';
import { HeadingLabel, HeadingSmall } from '@ui/components/Typography';
import Button from '@ui/components/Button';
import { formatDate } from '@helpers/formatDate';
import { EquallySpacedColumn } from 'components/common/EquallySpacedColumn';
import { InfoPair } from 'components/common/InfoPair/InfoPair.styled';
import CustomHeader from './CustomHeader/CustomHeader';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Wrapper,
  Children,
  DatePickerWrapper,
  PresetsWrapper,
} from './DatePicker.styled';

interface Props extends ReactDatePickerProps {
  onChange: (value: any) => void;
  label?: string;
  selectedColor?: string;
  rowAligned?: boolean;
  presets?: { text: string; dateScheduled: Date; isDark: boolean }[];
}

/**
 * Styles and configures third-party date picker form input 'react-datepicker'
 */
const DatePicker = (props: Props) => {
  const theme = useTheme();
  // console.log('selected', props.selected);

  const handleChange = (date: Date) => {
    if (!date) return;
    if (props.showTimeSelect) {
      props.onChange(date);
    } else {
      const hours = getHours(props.selected as Date);
      const minutes = getMinutes(props.selected as Date);
      const dateScheduled = setHours(setMinutes(date, minutes), hours);
      props.onChange(dateScheduled);
    }
  };

  return (
    <Wrapper
      data-testid="date-picker"
      selectedColor={theme[props.selectedColor || 'primary']}
      inline={props.inline}
      disabled={props.disabled}
      rowAligned={props.rowAligned}
    >
      {!props.inline && props.label && (
        <HeadingLabel>{props.label}</HeadingLabel>
      )}
      {props.inline && (
        <InfoPair>
          {props.label && <HeadingLabel>{props.label}</HeadingLabel>}
          <HeadingSmall>{formatDate(props.selected as Date)}</HeadingSmall>
          {props.showTimeSelect && (
            <HeadingSmall>
              {format(props.selected as Date, 'HH:mm a')}
            </HeadingSmall>
          )}
        </InfoPair>
      )}
      <DatePickerWrapper>
        {props.presets && (
          <PresetsWrapper>
            <EquallySpacedColumn>
              {props.presets.map(button => (
                <Button
                  key={button.text}
                  text={button.text}
                  onPress={() => handleChange(button.dateScheduled)}
                  dark={button.isDark}
                  small
                />
              ))}
            </EquallySpacedColumn>
          </PresetsWrapper>
        )}
        <ReactDatePicker
          dateFormat="yyyy/MM/dd"
          popperPlacement="bottom-start"
          showPopperArrow={false}
          {...props}
          onChange={props.selectsRange ? props.onChange : handleChange}
          minDate={props.minDate || new Date()}
          maxDate={props.maxDate || addYears(new Date(), 5)}
          placeholderText="yyyy/mm/dd"
          disabledKeyboardNavigation
          disabled={props.disabled}
          renderCustomHeader={params => (
            <CustomHeader
              {...params}
              maxDate={props.maxDate as Date}
              minDate={props.minDate || new Date()}
            />
          )}
        >
          {props.children && <Children>{props.children}</Children>}
        </ReactDatePicker>
      </DatePickerWrapper>
    </Wrapper>
  );
};

export default DatePicker;
