import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ajax from '@helpers/ajax';
import { getUserDevice } from '@helpers/getUserDevice';
import useUser from '@hooks/useUser';
import { useNavigate, useSearchParams } from '@hooks/useRouting';
import Downgrade from 'components/account/Downgrade';
import Page from 'components/app/Page';
import FeaturePage from 'components/app/FeaturePage';

const CancelSubscription = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const redirectTo = search.get('redirect_to') || '/welcome';
  const [loading, setLoading] = useState(false);
  const { canCancel } = useUser();

  useEffect(() => {
    if (!canCancel) {
      navigate(redirectTo);
    }
  }, [canCancel]);

  const onReturn = () => {
    navigate(redirectTo);
  };

  const onCancel = async (params: any) => {
    setLoading(true);
    try {
      const locationData = await ajax('public/location', {}, 'GET', false);

      const res = await ajax(
        'payments/stripe/cancel-subscription',
        {
          redirectTo,
          device: getUserDevice(),
          country: locationData?.country?.toLowerCase?.(),
          feedBack: `Reason: '${params.title || 'N/A'}'. Feedback: '${
            params.feedback?.message || 'N/A'
          }'`,
        },
        'POST'
      );
      window.location = res.redirectTo;
    } catch (e) {
      console.error('Failed to cancel', e);
      toast.error(
        "There's been an issue cancelling your subscription, please contact support"
      );
      setLoading(false);
    }
  };

  return (
    <Page bgColour="info">
      <FeaturePage isScrollable>
        <Downgrade
          handleReturn={onReturn}
          onConfirm={onCancel}
          loading={loading}
        />
      </FeaturePage>
    </Page>
  );
};

export default CancelSubscription;
