import React from 'react';

interface Props {
  color: string;
}

export default ({ color, ...props }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 882 206.91"
    fill={color || '#000'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M241.94,56.63h31.65c19.51,0,34.54,5.34,34.54,23.41,0,8.39-5.06,17.48-16.18,20.38V101c13.44,2.46,20.52,9.54,20.52,22.11,0,19.22-15.75,27.75-36.13,27.75h-34.4Zm30.64,37.86c10.41,0,14.74-4.33,14.74-11.56,0-6.94-4.62-9.68-14.59-9.68h-9.54V94.49Zm2,39.75c11.57,0,17.06-4.2,17.06-12.72,0-8.24-5.35-11.71-17.06-11.71H263.19v24.43Z" />
    <path d="M317.28,115c0-23.56,18.07-37.57,37.29-37.57,22.11,0,33.82,14.88,33.82,34.39a57.73,57.73,0,0,1-1,9.55H338.53c2,10.4,9.68,15,21.38,15,6.51,0,12.15-1.73,18.5-5.06l7.09,12.86A55.86,55.86,0,0,1,357,152.59C334.62,152.59,317.28,138.87,317.28,115Zm21.53-7.94h30.64c-1-8.82-5.92-13.44-14.16-13.44S341,98.1,338.81,107.07Z" />
    <path d="M390.45,130.91c0-16,13.29-24.14,47.26-26.16-.73-6.5-5.64-10.4-15.32-10.4-6.94,0-13.73,2.6-21.83,6.65l-7.51-13.88c9.83-5.64,21.24-9.68,33.53-9.68,20.09,0,32.37,10.4,32.37,32.37v41.05H441.61l-1.59-8.1h-.58c-7.22,5.5-16.19,9.83-25.44,9.83C399.55,152.59,390.45,143.05,390.45,130.91Zm47.26-1.59V117.47c-21.1,1.15-26.89,5.64-26.89,11.85,0,4.63,3.91,6.79,10,6.79C427.16,136.11,432.65,133.23,437.71,129.32Z" />
    <path d="M482,121.08V95.79H463.91V80L483,79.18l2.75-22h17.49v22h30.35V95.79H503.22v25.15c0,10.84,4.62,15,15.17,15a43.25,43.25,0,0,0,14.17-2.6l3.75,15.32c-6.36,1.87-13.87,3.9-23.41,3.9C490.07,152.59,482,139.88,482,121.08Z" />
    <path d="M538.81,115c0-23.84,19.37-37.57,41.48-37.57,11.27,0,20.81,4,26.88,9.68l-9.83,13.16c-5.49-3.91-10.55-5.64-15.9-5.64-12.28,0-20.81,7.94-20.81,20.37s8.24,20.38,20.09,20.38c7.52,0,13.73-3.17,18.93-6.79L608.18,142a46.71,46.71,0,0,1-29.48,10.55C556.15,152.59,538.81,138.87,538.81,115Z" />
    <path d="M614.72,49.55H636V74.4l-1.16,13.73h.44c5.34-5.49,13-10.69,23.84-10.69,16,0,23.27,11.13,23.27,29.19v44.23H661.12V109.37c0-9.53-2.75-13.87-10.55-13.87-5.78,0-9.54,2.6-14.6,7.52v47.84H614.72Z" />
    <path d="M688.33,130.91c0-16,13.29-24.14,47.26-26.16-.73-6.5-5.64-10.4-15.32-10.4-6.94,0-13.73,2.6-21.83,6.65l-7.51-13.88c9.83-5.64,21.24-9.68,33.53-9.68,20.09,0,32.37,10.4,32.37,32.37v41.05H739.49l-1.59-8.1h-.58c-7.23,5.5-16.19,9.83-25.44,9.83C697.43,152.59,688.33,143.05,688.33,130.91Zm47.26-1.59V117.47c-21.1,1.15-26.88,5.64-26.88,11.85,0,4.63,3.9,6.79,10,6.79C725,136.11,730.53,133.23,735.59,129.32Z" />
    <path d="M776.44,95.79H763.23V79.18h34.45v71.68H776.44ZM772,55c0-7.81,5.93-13.3,14.17-13.3s14.16,5.49,14.16,13.3S794.35,67.9,786.12,67.9,772,62.84,772,55Z" />
    <path d="M810.29,79.18h17.34l1.59,9.53h.58c6.21-6.07,14-11.27,24.71-11.27,16.19,0,23.41,11.13,23.41,29.19v44.23H856.68V109.37c0-9.53-2.75-13.87-10.55-13.87-5.78,0-9.54,2.6-14.6,7.52v47.84H810.29Z" />
    <path d="M205.25,75.81A102.5,102.5,0,0,0,4.9,90.51,102.5,102.5,0,0,0,208.25,116.4,101.84,101.84,0,0,0,205.25,75.81ZM168.07,103,171,113.42l-62.32,17.46-2.91-10.39ZM149,74.78,86.63,92.24,83.72,81.85,146,64.4Zm-8.83-31.13L143,54,80.71,71.5,77.8,61.11ZM51.31,46.13l62.32-17.46,2.91,10.39L54.22,56.51Zm17.35,62.35L131,91l2.91,10.38L71.57,118.86Zm20.48,72.67-2.91-10.38,62.32-17.46,2.9,10.38Zm25.34-29.5-2.91-10.39,62.32-17.45,2.91,10.38Z" />
  </svg>
);
