import React from 'react';
import useActiveArtist from '@hooks/useActiveArtist';
import { permissionDisplay } from '@helpers/permissionDisplay';
import { ModalInjectedProps } from '@ui/types';
import { Heading, BodyText } from '@ui/components/Typography';
import Button from '@ui/components/Button';
import ContentWithFooter from 'components/common/ContentWithFooter';
import { Box, BoxInBox } from 'components/common/Box/Box.styled';
import { Wrapper } from './UnauthorizedModal.styled';

interface Props {
  data: { permissions: string[] };
}

const UnauthorizedModal = (props: Props & ModalInjectedProps) => {
  const { actName, ownerEmail } = useActiveArtist();
  const accessTo =
    props.data.permissions?.length > 0
      ? props.data.permissions
          .map(el => `"${permissionDisplay(el.split('.')[0])}"`)
          .join(', ')
      : actName;

  return (
    <Wrapper>
      <ContentWithFooter>
        <Box isLightMode>
          <Heading>UNAUTHORIZED ACCESS</Heading>
          <BoxInBox isLightMode>
            <BodyText>
              {`Unfortunately, you don't have access to perform this action on the
              artist ${actName}. You will need to request access to 
              ${accessTo} from the artist owner `}
              <a href={`mailto:${ownerEmail}`}>{ownerEmail || ''}</a>.
            </BodyText>
          </BoxInBox>
        </Box>
        <Button text="Close" dark onPress={props.closeClickHandler} />
      </ContentWithFooter>
    </Wrapper>
  );
};

export default UnauthorizedModal;
