import Cookies from 'universal-cookie';
import { addDays } from 'date-fns';

/**
 * Sets cookie
 */
export const setCookie = (name: string, days = 1) => {
  const cookies = new Cookies();
  const expires = addDays(new Date(), days);
  cookies.set(name, 'dismissed', { expires });
};

/**
 * Checks if cookie exists
 */
export const cookieExists = (name: string) => {
  const cookies = new Cookies();
  return !!cookies.get(name);
};

export const cookieNames = {
  reconnectSocials: 'socialCheckDismissed',
  adblock: 'adBlockDismissed',
};
