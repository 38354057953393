import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#FFF' : 'transparent'}
      d="M349.7 162.4a132.07 132.07 0 00-80.09-38.33c-5.57-.6-19.51-.83-24.54-.37-10.46.91-24.63 4.02-34.04 7.45a130.95 130.95 0 00-47.88 30.34c-22.21 21.8-35.6 49.07-39.12 79.78-.91 7.86-.96 21.75-.04 29.47 6.94 60.04 52.18 107.24 111.94 116.8 8.5 1.36 25.55 1.68 34.23.68a130.9 130.9 0 0046.24-13.94 133.05 133.05 0 0067.03-81.38c13.39-46.56.6-96.1-33.72-130.5z"
    />
    <path
      fill={isColour ? '#000' : 'currentColor'}
      d="M252.84 219.2a42.55 42.55 0 00-18.37 6.58c-7.63 5.25-13.66 14.99-15.17 24.63-3.02 18.69 8.73 36.7 27.14 41.63 4.9 1.32 13.62 1.41 18.28.22a38.17 38.17 0 0025.22-21.2c2.2-4.98 3.02-9.73 2.79-16.31-.18-5.12-.41-6.53-1.7-10.24-4.97-14.7-17.54-24.4-32.94-25.26a41.17 41.17 0 00-5.25-.05z"
    />
    <path
      fill={isColour ? '#000' : 'currentColor'}
      d="M238.68.51C153.46 6.13 76.42 54.34 33.74 128.64 10.48 169.2-.98 214.4.07 261.6c.4 17.55 2 32.03 5.39 47.98 11.79 55.93 42.58 106.92 86.72 143.66a256.23 256.23 0 00123.65 56.02c14.53 2.28 21.2 2.74 39.98 2.74 17.91 0 22.16-.23 35.64-2.1a254.96 254.96 0 00106.47-40.58c3.83-2.56 6.07-3.79 6.85-3.74.64.04 17.5 3.15 37.47 6.85 19.97 3.75 36.37 6.76 36.46 6.67.1-.05-2.97-16.95-6.76-37.51l-6.9-37.43 4.52-6.85c24.04-36.23 38.25-77.95 41.72-122.23.64-8.59.64-29.42-.05-38.15-4.25-54.7-24.76-104.73-60.31-146.9-6.49-7.68-21.25-22.49-28.79-28.88a250.47 250.47 0 00-52.77-34.64C346.06 15 324.44 7.91 299.45 3.66A271.37 271.37 0 00238.68.51zm30.93 123.56a132.07 132.07 0 0180.1 38.33c34.31 34.4 47.1 83.94 33.72 130.5a133.05 133.05 0 01-67.03 81.38 130.88 130.88 0 01-46.24 13.94c-8.68 1-25.73.68-34.23-.69-59.76-9.55-105-56.75-111.94-116.79-.92-7.72-.87-21.61.04-29.47 3.52-30.7 16.9-57.99 39.12-79.78a130.96 130.96 0 0147.88-30.34c9.41-3.43 23.58-6.54 34.04-7.45 5.03-.46 18.97-.23 24.54.37z"
    />
  </svg>
);
