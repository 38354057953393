import { useEffect } from 'react';

/**
 * Uses web otp to verify phone/mfa
 */
const useWebOtpApi = onCodeFound => {
  const canCheckOtp =
    typeof window !== 'undefined' &&
    'OTPCredential' in window &&
    typeof AbortController !== 'undefined' &&
    typeof navigator !== 'undefined';

  useEffect(() => {
    const ac = new AbortController();
    const checkOTP = () => {
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        } as any)
        .then(otp => {
          onCodeFound(otp);
        })
        .catch(err => {
          console.error(err);
        });
    };

    if (canCheckOtp) {
      window.addEventListener('DOMContentLoaded', checkOTP);
    }

    return () => window.removeEventListener('DOMContentLoaded', checkOTP);
  }, [canCheckOtp]);
};

export default useWebOtpApi;
