import styled from 'styled-components';
export var FooterWrapper = styled.div.withConfig({
  displayName: "Footerstyled__FooterWrapper",
  componentId: "sc-hchpdm-0"
})(["width:100%;display:flex;justify-content:center;"]);
export var FooterMain = styled.div.withConfig({
  displayName: "Footerstyled__FooterMain",
  componentId: "sc-hchpdm-1"
})(["display:grid;grid-gap:", "px;padding:", "px 0;"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.largeSpacer;
});
export var Links = styled.div.withConfig({
  displayName: "Footerstyled__Links",
  componentId: "sc-hchpdm-2"
})(["display:flex;justify-content:space-around;width:auto;a,a:hover,a:active,a:visited{color:", ";}"], function (props) {
  return props.theme.primary;
});
export var Copyright = styled.div.withConfig({
  displayName: "Footerstyled__Copyright",
  componentId: "sc-hchpdm-3"
})(["display:flex;justify-content:center;"]);