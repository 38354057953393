import React, { PropsWithChildren } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { isWindowExists } from '@helpers/isWindowExists';
import GlobalState from '../../../context/GlobalState';

const globalStateValueFromCloudFront = isWindowExists
  ? {
      // eslint-disable-next-line no-underscore-dangle
      isMobile: window.__DEVICE_TYPE === 'mobile',
      // eslint-disable-next-line no-underscore-dangle
      isTablet: window.__DEVICE_TYPE === 'tablet',
      isWebView:
        window.localStorage.getItem('beatchain-webview') === 'true' ||
        !!window.ReactNativeWebView,
      currency: window.beatchain_currency?.toUpperCase?.(),
      width: window.innerWidth,
    }
  : {};

/**
 * Handles device detection and resize observer
 */
const GlobalStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const target = React.useRef<HTMLDivElement>(null);
  const [size, setSize] = React.useState<DOMRect>();

  React.useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, entry => setSize(entry.contentRect));

  const dynamicState = isWindowExists
    ? { ...globalStateValueFromCloudFront, width: size?.width }
    : {};

  const dynamicStateMemo = React.useMemo(() => dynamicState, [dynamicState]);

  return (
    <div ref={target}>
      <GlobalState.Provider value={dynamicStateMemo}>
        {children}
      </GlobalState.Provider>
    </div>
  );
};

export default GlobalStateProvider;
