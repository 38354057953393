import React, { InputHTMLAttributes } from 'react';
import Tooltip from '@ui/components/Tooltip';
import {
  Wrapper,
  Label,
  CodeInput,
  Message,
  WithTooltip,
  HintWrapper,
  CustomIcon,
  HintMessage,
} from './InputCode.styled';

export interface InputProps
  // eslint-disable-next-line no-undef
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  label?: string;
  small?: boolean;
  error?: boolean;
  message?: string;
  isCorrect?: boolean;
  isIncorrect?: boolean;
  icon?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  isLocked?: boolean;
  children?: React.ReactNode;
  tooltip?: string;
  avoidDisabledOpacity?: boolean;
  hideNumberToggles?: boolean;
  dataTestId?: string;
  hint?: {
    message: string;
    iconColor?: string;
    messageColor?: string;
  };
}

/**
 * Composes a 6 digit code input
 */
const InputCode = (props: InputProps) => (
  <Wrapper className={props.className}>
    {props.label && !props.tooltip && <Label>{props.label}</Label>}
    {props.label && props.tooltip && (
      <WithTooltip>
        <Tooltip
          text={props.tooltip}
          id={props.tooltip}
          place="right"
          effect="solid"
        />
        <Label hasTooltip>{props.label}</Label>
      </WithTooltip>
    )}

    <>
      <CodeInput
        type="number"
        fields={6}
        onChange={props.onChange}
        name="inviteCode"
        inputMode="numeric"
        isCorrect={props.isCorrect}
        isIncorrect={props.isIncorrect}
        disabled={props.isLocked}
        data-testid="invite-code"
      />
      {props.hint?.message && !props.message && (
        <HintWrapper>
          <CustomIcon
            name="exclamation-circle"
            size="xs"
            color={props.hint?.iconColor}
          />
          <HintMessage color={props.hint?.messageColor}>
            {props.hint?.message}
          </HintMessage>
        </HintWrapper>
      )}
      {props.message && (
        <Message error={props.error} data-testid="message">
          {props.message}
        </Message>
      )}
    </>
  </Wrapper>
);

export default InputCode;
