import RoyaltyPayouts from 'components/account/RoyaltyPayouts';
import useModal from '@hooks/useModal';

/**
 * Opens royalty wallet modal
 */
const useOpenWallet = () => {
  const openModal = useModal();
  return () => {
    openModal({
      Component: RoyaltyPayouts,
      modalTitle: 'Royalty Wallet',
      modalSize: 'medium',
    });
  };
};

export default useOpenWallet;
