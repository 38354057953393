import React, { ComponentType } from 'react';
import { BoxWrapper, BoxHeading, NavBarBoxWrapper } from './NavbarBox.styled';

type Link = {
  name: string;
};

interface Props {
  isSmall?: boolean;
  isBlocked?: boolean;
  links?: Link[];
  isCustomWithProps?: boolean;
  label?: string;
  linkComponent?: ComponentType<any>;
  collapseNav?: () => void;
  activeItem?: string;
}

const NavbarBox = (props: Props) => {
  const LinkComponent = props.linkComponent;

  if (
    !props.isBlocked &&
    (props.links?.length || props.isCustomWithProps) &&
    LinkComponent
  ) {
    return (
      <NavBarBoxWrapper>
        {props.label && <BoxHeading>{props.label}</BoxHeading>}
        <BoxWrapper isSmall={props.isSmall}>
          {props.isCustomWithProps ? (
            <LinkComponent {...props} collapseNav={props.collapseNav} />
          ) : (
            props.links?.map(link => (
              <LinkComponent
                key={link.name}
                {...link}
                activeItem={props.activeItem}
                collapseNav={props.collapseNav}
              />
            ))
          )}
        </BoxWrapper>
      </NavBarBoxWrapper>
    );
  }

  return null;
};

export default NavbarBox;
