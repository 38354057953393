import { Auth } from '@aws-amplify/auth';
import { take, put, select } from 'redux-saga/effects';
import Cookies from 'js-cookie';

import { apiBase } from 'config/backend.config';
import { browserHistory } from 'browserHistory';
import api from '@helpers/api';
import trackEvent, {
  trackPixelEvent,
  trackGoogleConversion,
} from '@helpers/tracking';
import transformUserData from '@helpers/transformUserData';
import { getUserDevice } from '@helpers/getUserDevice';
import { getLoginRedirect } from '@helpers/loginRedirect';
import VerifyPhone from 'components/account/UserProfile/PhoneNumber/VerifyPhone/VerifyPhone';
import { setModalContent } from 'store/ui';
import { fetchUserMetadata } from 'store/user';
import {
  forceChangePassword,
  forceChangePasswordError,
  forceChangePasswordSuccess,
  login,
  loginError,
  loginSuccess,
  logout,
  logoutSuccess,
  mfaChallenge,
  mustChangePassword,
  requestResetPassword,
  requestResetPasswordError,
  requestResetPasswordSuccess,
  resetPassword,
  resetPasswordError,
  resetPasswordSuccess,
  signup,
  signupError,
} from './reducer';

import { LoginAction, LoginSuccessAction, SignupAction } from './types';

export function* loginSuccessSaga() {
  while (true) {
    const { payload }: LoginSuccessAction = yield take(loginSuccess.type);
    const redirectTo = payload.redirectTo || getLoginRedirect() || '/welcome';
    trackEvent('Login', {
      email:
        payload.signInUserSession &&
        payload.signInUserSession.getIdToken &&
        payload.signInUserSession.getIdToken().payload.email,
      mode: payload.mode || 'user_password',
    });
    if (payload.callback) payload.callback();
    browserHistory.push(
      payload.signup
        ? `/invite-code?redirect_to=${encodeURIComponent(
            redirectTo
          )}&pref=signup&io=true`
        : redirectTo
    );
  }
}

export function* forceChangePasswordSuccessSaga() {
  while (true) {
    yield take(forceChangePasswordSuccess.type);
    browserHistory.push('/welcome');
  }
}

export function* logoutSaga() {
  while (true) {
    yield take(logout.type);

    const actId = yield select(state => state.user.activeArtistId);

    yield Auth.signOut();
    window?.analytics?.reset();

    window?.postMessage?.('signOut', '*');

    window?.zE?.('webWidget', 'logout');

    yield put(logoutSuccess());

    trackEvent('Logout');
    window.localStorage.clear();
    window.sessionStorage.clear();
    Cookies.remove('beatchain_auth');
    window.localStorage.setItem('beatchain_last_act_id', actId);

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        method: 'reactNativeLogOut',
      })
    );
  }
}

export function* loginSaga() {
  while (true) {
    let signInAttempt;
    try {
      const { payload }: LoginAction = yield take(login.type);
      const { email = '', password, redirectTo, mfaCode, mfaType } = payload;
      const signInAttemptPayload = yield select(
        state => state.auth.signInAttemptPayload
      );
      const isMfaCheck = !!(mfaCode && mfaType && signInAttemptPayload);

      try {
        if (isMfaCheck) {
          signInAttempt = {
            result: 'success',
            payload: yield Auth.confirmSignIn(
              signInAttemptPayload,
              mfaCode as string,
              mfaType
            ),
          };
        } else {
          signInAttempt = {
            result: 'success',
            payload: yield Auth.signIn(email.toLowerCase(), password),
          };
        }

        Cookies.set('beatchain_auth', true, { expires: 1 });
        window.localStorage.setItem('impersonation', 'false');
      } catch (e) {
        console.error(e);
        signInAttempt = {
          result: 'error',
          payload: isMfaCheck ? new Error('Invalid or expired code') : e,
        };
      }

      const { challengeName, attributes } = signInAttempt.payload;
      if (signInAttempt.result === 'success') {
        if (challengeName === 'NEW_PASSWORD_REQUIRED') {
          signInAttempt.payload.betaSignUpCode = password;
          // Must change password bc the pw was autogenerated
          yield put(mustChangePassword(signInAttempt.payload));
        } else if (
          !isMfaCheck &&
          (challengeName === 'SMS_MFA' ||
            challengeName === 'SOFTWARE_TOKEN_MFA')
        ) {
          yield put(mfaChallenge(signInAttempt.payload));
        } else if (attributes?.['custom:must_change_password'] === '1') {
          // Must change password bc we manually force them
          yield put(
            mustChangePassword({
              ...signInAttempt.payload,
              forcedByUs: true,
              invalidatedPassword: password,
            })
          );
        } else {
          const actId =
            window.localStorage.getItem('beatchain_last_act_id') || '';

          yield put(
            loginSuccess({
              ...signInAttempt.payload,
              redirectTo,
            })
          );
          yield put(fetchUserMetadata({ actId }));
        }
      } else {
        yield put(loginError(signInAttempt.payload));
      }
    } catch (e) {
      console.error(e);
      yield put(loginError(e));
    }
  }
}

export function* forceChangePasswordSaga() {
  while (true) {
    let changePasswordAttempt;
    try {
      const { payload } = yield take(forceChangePassword.type);
      const { password, signInAttemptPayload, name } = payload;
      const { forcedByUs, invalidatedPassword } = signInAttemptPayload;

      if (forcedByUs) {
        const user = yield Auth.currentAuthenticatedUser();
        const result = yield Auth.changePassword(
          user,
          invalidatedPassword,
          password
        );
        if (result !== 'SUCCESS') {
          yield put(forceChangePasswordError());
        } else {
          yield api({
            endpoint: `${apiBase}/user/force_change_password_finish`,
            method: 'POST',
          });
          const session = yield Auth.currentSession();
          yield put(forceChangePasswordSuccess(session));
          yield put(fetchUserMetadata());
        }
      } else {
        const { userAttributes } = signInAttemptPayload.challengeParam;
        if (name) userAttributes.name = name;
        delete userAttributes.email_verified;

        changePasswordAttempt = yield new Promise(resolve => {
          signInAttemptPayload.completeNewPasswordChallenge(
            password,
            userAttributes,
            {
              onSuccess: result => {
                resolve({ result: 'success', payload: result });
              },
              onFailure: error => {
                console.error(error);
                resolve({ result: 'error', payload: error });
              },
            }
          );
        });
        if (changePasswordAttempt.result === 'success') {
          yield put(forceChangePasswordSuccess(changePasswordAttempt.payload));
          yield put(fetchUserMetadata());
        } else {
          yield put(forceChangePasswordError());
        }
      }
    } catch (e) {
      console.error(e);
      yield put(forceChangePasswordError());
    }
  }
}

export function* requestResetPasswordSaga() {
  while (true) {
    let result;
    try {
      const { payload: email } = yield take(requestResetPassword.type);
      result = yield Auth.forgotPassword(email.toLowerCase());
      yield put(requestResetPasswordSuccess({ email, result }));
    } catch (e) {
      console.error(e);
      yield put(requestResetPasswordError(e));
    }
  }
}

export function* resetPasswordSaga() {
  while (true) {
    try {
      const { payload } = yield take(resetPassword.type);
      const { email, code, newPassword } = payload;
      const result = yield Auth.forgotPasswordSubmit(
        email.toLowerCase(),
        code,
        newPassword
      );
      yield put(resetPasswordSuccess(result));
    } catch (e) {
      console.error(e);
      yield put(resetPasswordError(e));
    }
  }
}

export function* signupSaga() {
  while (true) {
    let result;
    try {
      const { payload }: SignupAction = yield take(signup.type);

      const { password, redirectTo } = payload;

      const email = payload.email.toLowerCase();
      const queryParams = new URLSearchParams(window?.location.search);
      const trialCode =
        payload.trialCode || queryParams.get('trial_code') || null;
      const cohortId = payload.cohortId || queryParams.get('cohort_id') || null;

      const userDevice = getUserDevice();

      const locationData = yield api({
        endpoint: `${apiBase}/public/location`,
        auth: false,
      });

      const parser = new window.UAParser();
      const os = parser.getOS()?.name;
      const browser = parser.getBrowser()?.name;

      const captchaScore = window.localStorage.getItem('captcha_score');

      const { getReferralCode } = transformUserData();

      const referralCode = getReferralCode();

      let phoneNumber;
      if (payload.phoneNumber) {
        const phoneVerification = yield api({
          endpoint: `${apiBase}/public/phone-number/verify`,
          method: 'POST',
          params: {
            phoneNumber: payload.phoneNumber,
            // Passing a country code would help with validation...
            countryCode: undefined,
          },
          auth: false,
        });

        if (phoneVerification.error) {
          throw new Error(phoneVerification.error);
        }
        phoneNumber = phoneVerification.phoneNumber;
      }

      result = yield Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          phone_number: phoneNumber || '',
        },
        // CAREFUL, validationData doesn't get send on federated sigins...
        validationData: {
          trialCode,
          cohortId,
          referralCode,
          captchaScore,
          os,
          browser,
          country: locationData?.country?.toLowerCase?.(),
          device: userDevice,
        },
      });

      if (result) {
        trackPixelEvent(
          'CompleteRegistration',
          { content_name: 'user_password' },
          {
            eventID: `registration.${email}`,
          }
        );

        trackEvent('CompleteRegistration', {
          mode: 'user_password',
          email,
        });

        trackGoogleConversion('sign_up', {
          method: 'user_password',
        });

        const user = yield Auth.signIn(email, password);
        Cookies.set('beatchain_auth', true, { expires: 1 });

        // Disable autoverify
        const autoverified = false;
        // const autoverified =
        //   process.env.REACT_APP_API_BASE !== 'prod' &&
        //   /@beatchain\.com$/.test(email);
        if (phoneNumber && !autoverified) {
          yield Auth.verifyCurrentUserAttribute('phone_number');
          yield put(
            setModalContent({
              Component: VerifyPhone,
              modalSize: 'dynamic',
              isVisible: true,
              modalTitle: 'Verify Your Phone Number',
            })
          );
        }

        yield put(
          loginSuccess({
            ...user,
            redirectTo,
            signup: true,
          })
        );
        yield put(fetchUserMetadata());
      } else {
        console.error('Empty result', { result });
        throw new Error('There was an error signin up, please try again later');
      }
    } catch (e) {
      console.error('Thrown error', e);
      yield put(signupError(e));
    }
  }
}
