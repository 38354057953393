import React from 'react';
import { useTheme } from 'styled-components';
import { BodyText } from '../Typography';
import { Label, Input, Check, ReadOnlyIcon } from './Checkbox.styled';

export interface CheckboxProps {
  name: string;
  isTicked?: boolean;
  text?: string;
  handleChange?: (name: string, isTicked: boolean) => void;
  isSuccess?: boolean;
  color?: string;
  isReadOnly?: boolean;
}

/**
 * Styled checkbox
 */
const Checkbox = ({
  name,
  handleChange,
  isSuccess = false,
  text,
  color,
  isReadOnly,
  ...props
}: CheckboxProps) => {
  const onChange = e => {
    handleChange?.(e.target.name, e.target.checked);
  };

  const theme = useTheme();

  const readOnlyIconMarkup = () => {
    if (props.isTicked === false)
      return <ReadOnlyIcon name="cross" color={theme.warning} />;
    if (props.isTicked === true)
      return <ReadOnlyIcon name="tick" color={theme.success} />;
    return <ReadOnlyIcon name="minus" color={color || theme.white} />;
  };

  return (
    <Label isReadOnly={isReadOnly}>
      {isReadOnly ? (
        readOnlyIconMarkup()
      ) : (
        <>
          <Input
            name={name}
            defaultChecked={props.isTicked}
            onChange={onChange}
          />
          <Check
            isSuccess={isSuccess}
            isTicked={props.isTicked}
            color={color}
          />
        </>
      )}
      {text && <BodyText>{text}</BodyText>}
    </Label>
  );
};

export default Checkbox;
