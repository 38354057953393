import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "MetaItemstyled__Wrapper",
  componentId: "sc-1dmilol-0"
})(["display:grid;grid-auto-flow:column;grid-gap:", "px;align-items:center;box-sizing:border-box;padding:0 ", "px;flex-shrink:0;box-shadow:", ";background-color:", ";color:", ";min-height:", "px;"], function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.solidShadow;
}, function (props) {
  return props.theme.secondary;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.iconCanvasSize;
});