import styled from 'styled-components';
import { Form } from 'formik';
/**
 * Styles and configures Form element of third-party form module 'formik'
 */

export var FormWrapper = styled(Form).withConfig({
  displayName: "FormWrapperstyled__FormWrapper",
  componentId: "sc-13zs79l-0"
})(["display:grid;grid-gap:", "px;color:", ";"], function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.white;
});