import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg viewBox="0 0 160 50" fill="none" {...props}>
    <path
      fill={isColour ? '#FF004F' : 'none'}
      d="M30.576 18.83a18.31 18.31 0 0010.681 3.415v-7.66c-.754 0-1.505-.079-2.243-.235v6.03c-3.983 0-7.67-1.265-10.68-3.416v15.633c0 7.82-6.343 14.159-14.167 14.159-2.92 0-5.633-.882-7.887-2.395a14.127 14.127 0 0010.13 4.26c7.824 0 14.167-6.34 14.167-14.16V18.83zm2.767-7.73a10.674 10.674 0 01-2.767-6.25v-.985h-2.125a10.724 10.724 0 004.892 7.236zM11.23 38.36a6.479 6.479 0 017.124-10.094v-7.832a14.284 14.284 0 00-2.242-.129v6.096a6.487 6.487 0 00-1.967-.304A6.478 6.478 0 0011.23 38.36z"
    />
    <path
      fill={isColour ? '#000' : 'currentColor'}
      stroke={isColour ? undefined : 'currentColor'}
      strokeWidth={isColour ? undefined : 0.5}
      d="M28.333 16.964a18.314 18.314 0 0010.681 3.416v-6.03a10.722 10.722 0 01-5.67-3.25 10.725 10.725 0 01-4.893-7.235h-5.584V34.46a6.48 6.48 0 01-11.639 3.899 6.476 6.476 0 012.916-12.262c.686 0 1.347.106 1.967.303v-6.096c-7.687.159-13.868 6.436-13.868 14.156 0 3.854 1.54 7.347 4.038 9.9a14.105 14.105 0 007.886 2.395c7.824 0 14.167-6.34 14.167-14.16V16.965z"
    />
    <path
      fill={isColour ? '#00F2EA' : 'currentColor'}
      stroke={isColour ? undefined : 'currentColor'}
      strokeWidth={isColour ? undefined : 0.5}
      d="M39.014 14.35v-1.63a10.681 10.681 0 01-5.67-1.62 10.711 10.711 0 005.67 3.25zM28.451 3.865c-.051-.292-.09-.585-.118-.88V2h-7.709v30.596a6.479 6.479 0 01-6.48 6.455 6.457 6.457 0 01-2.915-.69 6.472 6.472 0 005.158 2.555 6.48 6.48 0 006.48-6.455V3.865h5.584zm-12.34 16.44V18.57a14.295 14.295 0 00-1.944-.132C6.343 18.438 0 24.777 0 32.597c0 4.902 2.493 9.222 6.28 11.764a14.107 14.107 0 01-4.037-9.9c0-7.72 6.181-13.997 13.868-14.156z"
    />
    <path
      fill={isColour ? '#FF004F' : 'none'}
      d="M128.34 40.345c5.598 0 10.137-4.501 10.137-10.054s-4.539-10.055-10.137-10.055h-1.527c5.598 0 10.137 4.502 10.137 10.055s-4.539 10.054-10.137 10.054h1.527z"
    />
    <path
      fill={isColour ? '#00F2EA' : 'none'}
      d="M126.675 20.236h-1.527c-5.599 0-10.138 4.502-10.138 10.055s4.539 10.054 10.138 10.054h1.527c-5.599 0-10.138-4.501-10.138-10.054s4.539-10.055 10.138-10.055z"
    />
    <path
      fill={isColour ? '#000' : 'currentColor'}
      d="M49.61 15.691v5.097h5.97v19.42h5.972V20.926h4.86l1.666-5.234H49.61zm48.877 0v5.097h5.97v19.42h5.972V20.926h4.859l1.666-5.234H98.487zm-29.298 2.893a2.902 2.902 0 012.914-2.893c1.612 0 2.916 1.296 2.916 2.893 0 1.597-1.303 2.892-2.916 2.892-1.61 0-2.914-1.295-2.914-2.892zm0 4.959h5.83v16.665h-5.83V23.543zm8.33-7.851v24.516h5.833v-6.335l1.805-1.655 5.692 8.127h6.248L88.904 28.5l7.36-7.162h-7.082l-5.83 5.784v-11.43h-5.833zm62.901 0v24.516h5.833v-6.335l1.804-1.655 5.694 8.127H160L151.807 28.5l7.359-7.162h-7.081l-5.832 5.784v-11.43h-5.833zM126.812 40.345c5.599 0 10.138-4.501 10.138-10.054s-4.539-10.055-10.138-10.055h-.138c-5.598 0-10.137 4.502-10.137 10.055s4.539 10.054 10.137 10.054h.138zm-4.998-10.054c0-2.701 2.208-4.889 4.93-4.889 2.721 0 4.929 2.188 4.929 4.889s-2.208 4.89-4.929 4.89c-2.722 0-4.93-2.189-4.93-4.89z"
    />
  </svg>
);
