import styled from 'styled-components';
export var WrapperSimple = styled.div.withConfig({
  displayName: "RoyaltyPayoutsstyled__WrapperSimple",
  componentId: "sc-1a8eg11-0"
})(["justify-content:center;width:100%;height:100%;"]);
export var AccountWrapper = styled.div.withConfig({
  displayName: "RoyaltyPayoutsstyled__AccountWrapper",
  componentId: "sc-1a8eg11-1"
})(["display:grid;grid-template-columns:1fr 1fr;grid-gap:", "px;align-items:start;width:100%;padding:", "px;box-sizing:border-box;", ""], function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.isMobile && "grid-template-columns: unset;\n    grid-gap: ".concat(props.theme.largeSpacer, "px;\n    padding: ").concat(props.theme.largeSpacer, "px;");
});