import styled from 'styled-components';
import { HeadingSmall } from '@ui/components/Typography';
import { rgba } from 'polished';
export var Wrapper = styled.div.withConfig({
  displayName: "Dropzonestyled__Wrapper",
  componentId: "sc-1hw8zve-0"
})(["display:flex;justify-content:flex-end;", ""], function (props) {
  return !props.showButton && "\n    position: relative;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  ";
});
export var DropzoneOverlay = styled.div.withConfig({
  displayName: "Dropzonestyled__DropzoneOverlay",
  componentId: "sc-1hw8zve-1"
})(["position:absolute;top:0;left:0;z-index:1;width:100%;height:100%;display:flex;justify-content:center;align-items:center;background-color:", ";border:dashed ", " ", "px;"], function (props) {
  return rgba(props.theme.white, 0.8);
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.xSmallSpacer;
});
export var Text = styled(HeadingSmall).withConfig({
  displayName: "Dropzonestyled__Text",
  componentId: "sc-1hw8zve-2"
})(["color:", ";"], function (props) {
  return props.theme.grey;
});