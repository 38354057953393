import React from 'react';

const Blog = () => {
  React.useEffect(() => {
    window.location.replace('https://www.beatchain.com/blog');
  }, []);
  return null;
};

export default Blog;
