import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';

import { useSelector, useDispatch } from '@hooks/useStore';
import useDevice from '@hooks/useDevice';
import { MediaLibraryModalContent, ModalContent, OpenAlertFn } from '@ui/types';
import * as uiStore from 'store/ui';
import Dialogue from '@ui/components/Dialogue';
import Alert from 'components/common/Alert';
import MediaLibrary from 'components/mediaLibrary/MediaLibrary';
import BackButton from 'components/common/BackButton';
import {
  Wrapper,
  CloseHitArea,
  Modal,
  Header,
  ModalTitle,
  CloseButton,
  Content,
} from './ModalWrapper.styled';

/**
 * Wrapper for all modals, handles styling and navigation
 */
const ModalWrapper = () => {
  const dispatch = useDispatch();
  const { modalContent, mediaLibraryModalContent } = useSelector(
    state => state.ui
  );

  const height = use100vh() as number;
  const { isMobile } = useDevice();

  function setModalContent(newModalContent: Partial<ModalContent>) {
    dispatch(uiStore.setModalContent(newModalContent));
  }

  function setMediaLibraryModalContent(
    newModalContent: Partial<MediaLibraryModalContent>
  ) {
    dispatch(uiStore.setMediaLibraryModalContent(newModalContent));
  }

  function updateModalContent(newContent: Partial<ModalContent>) {
    setModalContent({ ...modalContent, ...newContent });
  }

  function updateMediaLibraryModalContent(
    newContent: Partial<MediaLibraryModalContent>
  ) {
    setMediaLibraryModalContent({ ...mediaLibraryModalContent, ...newContent });
  }

  function clearModal() {
    dispatch(uiStore.resetModalContent());
  }

  function clearMediaLibraryModal() {
    dispatch(uiStore.resetMediaLibraryModalContent());
  }

  const openAlert: OpenAlertFn = (
    title,
    text,
    AlertComponent,
    componentProps,
    onClose
  ) => {
    setModalContent({
      isAlertVisible: true,
      alertTitle: title,
      alertText: text,
      AlertComponent,
      alertComponentProps: componentProps,
      onCloseAlert: onClose,
    });
  };

  function closeModal() {
    clearModal();
    if (modalContent.onClose) modalContent.onClose();
  }

  function closeButtonHandler() {
    if (modalContent.alertBeforeClose) {
      openAlert(
        modalContent.alertBeforeClose.title,
        modalContent.alertBeforeClose.text,
        Dialogue,
        {
          actionButton: {
            ...modalContent.alertBeforeClose.actionButton,
            clickHandler: clearModal,
          },
        }
      );
    } else {
      closeModal();
    }
  }

  function closeMediaLibraryModal() {
    clearMediaLibraryModal();
  }

  const openMediaLibraryAlert: OpenAlertFn = (
    title,
    text,
    AlertComponent,
    componentProps,
    onClose
  ) => {
    console.log(title, text, AlertComponent, componentProps, onClose);
    setMediaLibraryModalContent({
      isAlertVisible: true,
      alertTitle: title,
      alertText: text,
      AlertComponent,
      alertComponentProps: componentProps,
      onCloseAlert: onClose,
    });
  };

  function goBack() {
    if (modalContent.onGoBack) modalContent.onGoBack();
  }

  function mediaLibraryGoBack() {
    if (mediaLibraryModalContent.onGoBack) {
      mediaLibraryModalContent.onGoBack();
    } else {
      closeMediaLibraryModal();
    }
  }

  function closeAlert() {
    setModalContent({
      isAlertVisible: false,
      alertTitle: '',
      alertText: '',
    });
    if (modalContent.onCloseAlert) modalContent.onCloseAlert();
  }

  function closeMediaLibraryAlert() {
    setMediaLibraryModalContent({
      isAlertVisible: false,
      alertTitle: '',
      alertText: '',
    });
    if (mediaLibraryModalContent.onCloseAlert)
      mediaLibraryModalContent.onCloseAlert();
  }

  const ModalComponent = modalContent.Component;

  const sharedProps = {
    closeClickHandler: closeModal,
    updateModalContent,
    openAlert,
    closeAlert,
    modalText: modalContent.modalText,
    ...modalContent.componentProps,
  };

  return (
    <>
      {modalContent.isVisible && (
        <Wrapper height={height}>
          {!modalContent.alertBeforeClose && (
            <CloseHitArea onClick={closeModal} />
          )}
          <Modal
            size={modalContent.modalSize || 'medium'}
            isMobile={isMobile}
            showOverflow={modalContent.showOverflow}
          >
            <Header
              hasGoBackButton={modalContent.hasGoBackButton}
              isSuccess={modalContent.isSuccess}
              isWarning={modalContent.isWarning}
              isPayment={modalContent.isPayment}
            >
              {modalContent.hasGoBackButton && <BackButton onClick={goBack} />}
              <ModalTitle>{modalContent.modalTitle}</ModalTitle>
              <CloseButton clickHandler={closeButtonHandler} />
            </Header>
            <Content
              isAlert={modalContent.modalSize === 'alert'}
              showOverflow={modalContent.showOverflow}
            >
              {modalContent.isAlertVisible && modalContent.AlertComponent && (
                <Alert
                  title={modalContent.alertTitle}
                  closeHandler={closeAlert}
                >
                  <modalContent.AlertComponent
                    modalText={modalContent.alertText}
                    closeClickHandler={closeAlert}
                    {...modalContent.alertComponentProps}
                  />
                </Alert>
              )}
              {modalContent.isAlertVisible && !modalContent.AlertComponent && (
                <Alert
                  {...modalContent.alertComponentProps}
                  title={modalContent.alertTitle}
                  text={modalContent.alertText}
                  closeHandler={closeAlert}
                />
              )}
              {/* could have routes for all modals and not use modalContent.Component */}
              <Routes>
                <Route path="*" element={<ModalComponent {...sharedProps} />} />
                <Route
                  path="/launchpad/:launchPadId/*"
                  element={<ModalComponent {...sharedProps} />} // can import and pass LaunchpadPost
                />
                <Route
                  path="/distribution/:listItem/create/tracks/:trackId/*"
                  element={<ModalComponent {...sharedProps} />} // can import and pass TrackModal
                />
              </Routes>
            </Content>
          </Modal>
        </Wrapper>
      )}
      {mediaLibraryModalContent.isVisible && (
        <Wrapper height={height}>
          <CloseHitArea onClick={closeMediaLibraryModal} />
          <Modal size="large" isMobile={isMobile}>
            <Header hasGoBackButton={mediaLibraryModalContent.hasGoBackButton}>
              {mediaLibraryModalContent.hasGoBackButton && (
                <BackButton onClick={mediaLibraryGoBack} />
              )}
              <ModalTitle>{mediaLibraryModalContent.modalTitle}</ModalTitle>
              <CloseButton clickHandler={closeMediaLibraryModal} />
            </Header>
            <Content>
              {mediaLibraryModalContent.isAlertVisible &&
                !mediaLibraryModalContent.AlertComponent && (
                  <Alert
                    title={mediaLibraryModalContent.alertTitle}
                    text={mediaLibraryModalContent.alertText}
                    closeHandler={closeMediaLibraryAlert}
                  />
                )}
              {mediaLibraryModalContent.isAlertVisible &&
                mediaLibraryModalContent.AlertComponent && (
                  <Alert
                    title={mediaLibraryModalContent.alertTitle}
                    closeHandler={closeMediaLibraryAlert}
                  >
                    <mediaLibraryModalContent.AlertComponent
                      modalText={mediaLibraryModalContent.alertText}
                      closeClickHandler={closeMediaLibraryAlert}
                      {...mediaLibraryModalContent.alertComponentProps}
                    />
                  </Alert>
                )}
              <MediaLibrary
                closeClickHandler={closeMediaLibraryModal}
                updateModalContent={updateMediaLibraryModalContent}
                openAlert={openMediaLibraryAlert}
                closeAlert={closeMediaLibraryAlert}
                {...mediaLibraryModalContent.componentProps}
              />
            </Content>
          </Modal>
        </Wrapper>
      )}
    </>
  );
};

export default React.memo(ModalWrapper);
