import React, { useState } from 'react';
import { ModalInjectedProps } from '@ui/types';
import MarkDown from '@ui/components/Markdown';
import ButtonWrapper from '@ui/components/ButtonWrapper';
import Button from '@ui/components/Button';

import { DialogueMain } from './Dialogue.styled';

interface Props {
  buttons?: any;
  noPadding?: boolean;
  actionButton?: any;
  cancelButtonText?: string;
  preCloseHandler?: (booleanValue?: boolean) => void;
  awaitClickHandler?: () => void;
  closeClickHandler?: () => void;
  autoClose?: boolean;
}

/**
 * Composes messaging and buttons alerts requiring user response
 */
const Dialogue = (props: Props & ModalInjectedProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <DialogueMain noPadding={props.noPadding}>
      {props.modalText && <MarkDown>{props.modalText}</MarkDown>}
      {props.buttons ? (
        <ButtonWrapper numberOnRight={props.buttons.length}>
          {props.buttons.map(button => (
            <Button key={button.text} {...button} />
          ))}
        </ButtonWrapper>
      ) : (
        props.actionButton && (
          <ButtonWrapper numberOnRight={2}>
            <Button
              dark
              squishy
              text={props.cancelButtonText || 'Cancel'}
              onPress={() => {
                if (props.preCloseHandler) {
                  props.preCloseHandler();
                }
                props.closeClickHandler();
              }}
              disabled={loading}
            />
            <Button
              {...props.actionButton}
              squishy
              text={props.actionButton.text}
              onPress={async () => {
                if (
                  props.awaitClickHandler ||
                  props.actionButton.clickHandler
                ) {
                  setLoading(true);
                }
                await props.actionButton.clickHandler();
                if (props.awaitClickHandler) {
                  setLoading(false);
                }
                if (props.autoClose) {
                  if (props.preCloseHandler) {
                    props.preCloseHandler(true);
                  }
                  props.closeClickHandler();
                }
              }}
              loading={loading}
            />
          </ButtonWrapper>
        )
      )}
    </DialogueMain>
  );
};

Dialogue.defaultProps = {
  autoClose: true,
};

export default Dialogue;
