import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '@ui/components/Icon';
import useDevice from '@hooks/useDevice';

import { StyledToastContainer } from './Toast.styled';

/**
 * Styles and configures third-party "toast" notification UI module 'react-toastify'
 */
const Toast = () => {
  const { isMobile } = useDevice();
  return (
    <StyledToastContainer
      isMobile={isMobile}
      closeButton={({ closeToast }) => (
        <Icon name="cross" clickHandler={closeToast} />
      )}
    />
  );
};

export default Toast;
