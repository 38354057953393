import styled from 'styled-components';
import { readableColor, rgba } from 'polished';
import { BodyText, Heading } from '@ui/components/Typography';
export var AlertWrapper = styled.div.withConfig({
  displayName: "Alertstyled__AlertWrapper",
  componentId: "sc-5de3dv-0"
})(["display:flex;justify-content:center;align-items:center;position:absolute;width:100%;height:100%;padding:", "px;box-sizing:border-box;z-index:", ";overflow-y:auto;background-color:", ";"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.zIndexShading;
}, function (props) {
  return rgba(props.theme.grey, 0.4);
});
export var AlertMain = styled.div.withConfig({
  displayName: "Alertstyled__AlertMain",
  componentId: "sc-5de3dv-1"
})(["width:", "px;color:", ";box-shadow:1px 2px 4px 1px ", ";"], function (props) {
  return props.theme.alertWidth;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return rgba(props.theme.lgrey, 0.5);
});
export var AlertHeader = styled.div.withConfig({
  displayName: "Alertstyled__AlertHeader",
  componentId: "sc-5de3dv-2"
})(["padding:", "px;display:grid;grid-template-columns:1fr ", "px;grid-gap:", "px;grid-template-areas:'title close';background:", ";color:", ";"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.iconCanvasSize;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.warning;
}, function (props) {
  return readableColor(props.theme.warning);
});
export var AlertTitle = styled(Heading).withConfig({
  displayName: "Alertstyled__AlertTitle",
  componentId: "sc-5de3dv-3"
})(["grid-area:title;padding:", "px;"], function (props) {
  return props.theme.spacer;
});
export var AlertClose = styled.div.withConfig({
  displayName: "Alertstyled__AlertClose",
  componentId: "sc-5de3dv-4"
})(["grid-area:close;display:flex;justify-content:center;align-items:center;font-size:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var AlertContent = styled.div.withConfig({
  displayName: "Alertstyled__AlertContent",
  componentId: "sc-5de3dv-5"
})(["display:flex;flex-direction:column;padding:", "px;background-color:", ";color:", ";"], function (props) {
  return props.noPadding ? 0 : props.theme.largeSpacer;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.black;
});
export var AlertText = styled(BodyText).withConfig({
  displayName: "Alertstyled__AlertText",
  componentId: "sc-5de3dv-6"
})(["&:not(:only-child){margin-bottom:", "px;}"], function (props) {
  return props.theme.largeSpacer;
});