import styled from 'styled-components';
export var PageMain = styled.div.withConfig({
  displayName: "Pagestyles__PageMain",
  componentId: "sc-f5abqv-0"
})(["width:100vw;display:flex;background-color:", ";color:", ";overflow-y:", ";overflow-x:hidden;position:relative;flex-direction:column;", " height:", ";", ""], function (props) {
  return props.isLightBg ? props.theme.white : props.theme.black;
}, function (props) {
  return props.isLightBg ? props.theme.black : props.theme.white;
}, function (props) {
  return props.hasScroll ? 'scroll' : 'hidden';
}, function (props) {
  return props.bgColour && "background-color: ".concat(props.theme[props.bgColour] || props.bgColour, ";");
}, function (props) {
  return props.isMobile ? "calc(100vh - ".concat(props.theme.topbarHeightMobile + props.theme.largeSpacer, "px)") : "calc(100vh - ".concat(props.theme.topbarHeightDesktop, "px)");
}, function (props) {
  return props.isFullHeight && "\n  height: 100vh;\n  ";
});