import React from 'react';

import { IconProps } from './index';

export default ({ isColour, tiled, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    {isColour ? (
      <>
        <path
          fill="#F4481E"
          d="M467.38 227.22L79.15 6.63c-16.59-9.3-31.43-8.43-40.45.3-6.4 6.11-9.9 16-9.9 28.81v440.32c0 12.8 3.79 22.7 9.9 28.81 9.31 9.02 23.86 9.9 40.45.3l388.23-220.6c27.94-15.72 27.94-41.33 0-57.34z"
        />
        <path
          fill="#FFD119"
          d="M428.96 306.68c4.66-16.01 6.99-33.18 6.99-50.64 0-102.74-83.53-186.26-186.26-186.26-102.73 0-186.26 83.53-186.26 186.26 0 86.14 58.5 158.6 137.95 179.85l227.58-129.21z"
        />
        <path
          fill="#FF8C00"
          d="M249.7 372.44c64.28 0 116.4-52.11 116.4-116.4 0-64.3-52.12-116.42-116.4-116.42-64.3 0-116.42 52.12-116.42 116.42 0 64.29 52.12 116.4 116.41 116.4z"
        />
        <path
          fill="#FAFAFA"
          d="M249.7 194.92v71.6a27.57 27.57 0 0 0-13.69-3.79 27.01 27.01 0 0 0-27.06 27.07A27.01 27.01 0 0 0 236 316.86c15.14 0 28.23-12.22 28.23-27.06v-65.78h26.2v-29.1h-40.75z"
        />
      </>
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d={
          tiled
            ? 'M255.97 66v223.1c-12.7-7.25-27.2-11.79-42.63-11.79A84.18 84.18 0 0 0 129 361.66 84.18 84.18 0 0 0 213.34 446c47.16 0 87.97-38.1 87.97-84.34V156.69h81.63V66H255.97z'
            : 'M512 256c0 141.38-114.62 256-256 256S0 397.38 0 256 114.61 0 256 0c141.38 0 256 114.61 256 256zm-256 23.05V121.6h89.6v64H288v144.63c0 32.64-28.8 59.52-62.08 59.52a59.4 59.4 0 0 1-59.52-59.51 59.4 59.4 0 0 1 59.52-59.52c10.88 0 21.12 3.2 30.08 8.31z'
        }
        clipRule="evenodd"
      />
    )}
  </svg>
);
