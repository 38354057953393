import styled from 'styled-components';
import { HeadingLabel } from '@ui/components/Typography';
export var BoxHeading = styled(HeadingLabel).withConfig({
  displayName: "NavbarBoxstyled__BoxHeading",
  componentId: "sc-7d87nk-0"
})(["color:", ";font-size:10px;padding:0 0 ", "px 0;display:flex;align-items:center;justify-content:flex-start;"], function (props) {
  return props.theme.primary;
}, function (props) {
  return props.theme.largeSpacer;
});
export var NavBarBoxWrapper = styled.div.withConfig({
  displayName: "NavbarBoxstyled__NavBarBoxWrapper",
  componentId: "sc-7d87nk-1"
})(["display:flex;flex-direction:column;"]);
export var BoxWrapper = styled.div.withConfig({
  displayName: "NavbarBoxstyled__BoxWrapper",
  componentId: "sc-7d87nk-2"
})(["min-width:240px;", ";"], function (props) {
  return props.isSmall && 'min-width: 124px;';
});