import React, { useState } from 'react';
import ajax from '@helpers/ajax';
import { ModalInjectedProps } from '@ui/types';
import ButtonWrapper from '@ui/components/ButtonWrapper';
import Button from '@ui/components/Button';
import { BodyText } from '@ui/components/Typography';
import BulletList from 'components/common/BulletList';
import ContentWithFooter from 'components/common/ContentWithFooter';
import { Box } from 'components/common/Box/Box.styled';

interface Props {
  lockedOutArtists: { act_name: string }[];
}

const LockedActsNotice = (props: Props & ModalInjectedProps) => {
  const [loading, setLoading] = useState(false);
  const dismiss = async () => {
    setLoading(true);
    await ajax('user/locked-out-acts', {}, 'DELETE');
    setLoading(false);
    props.closeClickHandler();
  };

  return (
    <ContentWithFooter>
      <Box isLightMode>
        <BodyText>
          Another user has claimed following artists which you were previously
          linked to:
        </BodyText>
        <BulletList
          items={props.lockedOutArtists.map(el => ({
            slug: el.act_name,
            label: el.act_name,
          }))}
        />
        <BodyText>
          You will no longer have access to those artists. If you think there
          has been a mistake please contact Beatchain.
        </BodyText>
      </Box>
      <ButtonWrapper numberOnRight={1}>
        <Button dark onPress={dismiss} text="Dismiss" loading={loading} />
      </ButtonWrapper>
    </ContentWithFooter>
  );
};

export default LockedActsNotice;
