import styled from 'styled-components';
import { BodyText } from '../Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "Tooltipstyled__Wrapper",
  componentId: "sc-1cqxlzo-0"
})(["display:flex;flex:1;.__react_component_tooltip{padding:", "px ", "px;max-width:300px;min-width:150px;text-align:center;}"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.largeSpacer;
});
export var DataTip = styled.div.withConfig({
  displayName: "Tooltipstyled__DataTip",
  componentId: "sc-1cqxlzo-1"
})(["display:flex;flex:1;"]);
export var PopupText = styled(BodyText).withConfig({
  displayName: "Tooltipstyled__PopupText",
  componentId: "sc-1cqxlzo-2"
})([""]);
export var Story = styled.div.withConfig({
  displayName: "Tooltipstyled__Story",
  componentId: "sc-1cqxlzo-3"
})(["display:flex;align-items:center;padding:", "px;"], function (props) {
  return props.theme.largeSpacer;
});