import styled from 'styled-components';
import { darken, transparentize } from 'polished';
export var Wrapper = styled.div.withConfig({
  displayName: "DatePickerstyled__Wrapper",
  componentId: "sc-1ifsp1e-0"
})(["display:flex;flex-direction:", ";gap:", "px;", " ", " .react-datepicker{border-radius:0;", " display:flex;&__input-container input{width:100%;background:", ";border:none;min-height:", "px;padding:0 ", "px;box-sizing:border-box;&:focus{outline:", " solid 1px;}}&__triangle,&__triangle:before{border-bottom-color:", " !important;}&__header{background:", ";border-bottom:none;border-top-left-radius:0;border-top-right-radius:0;padding-top:0;&--time{background:", ";padding:", "px;}}&__day-name{font-family:", ";letter-spacing:", "px;text-transform:", ";font-size:10px;width:1.4rem;line-height:1.4rem;}&__month{margin:0.3rem;}&__day{font-family:", ";font-size:10px;line-height:1.4rem;width:1.4rem;outline:none;&:hover{border-radius:0;}&--selected,&--in-selecting-range,&--in-range{border-radius:50%;background-color:", ";color:", ";&:hover{background-color:", ";}}&--keyboard-selected{border-radius:0;background-color:", ";color:", ";&:hover{background-color:", ";}}&--in-selecting-range:not(&--in-range){background-color:", ";}}&-time__header{color:", ";font-family:", ";letter-spacing:", "px;text-transform:", ";font-size:", "px;line-height:24px;}&__time-list-item{height:24px !important;padding:unset !important;font-family:", ";font-size:12px;line-height:24px;&--selected{background-color:", " !important;color:", " !important;}}}"], function (props) {
  return props.rowAligned ? 'row' : 'column';
}, function (props) {
  return props.inline ? props.theme.largeSpacer : props.theme.spacer;
}, function (props) {
  return props.inline && 'align-items: flex-start;';
}, function (props) {
  return props.disabled && "opacity: 0.5;\n    pointer-events: none;";
}, function (props) {
  return props.inline && 'border: 0;';
}, function (props) {
  return props.theme.vvlgrey;
}, function (props) {
  return props.theme.buttonHeight;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.headingLabelFont;
}, function (props) {
  return props.theme.headingLabelLetterSpacing;
}, function (props) {
  return props.theme.headingLabelTextTransform;
}, function (props) {
  return props.theme.bodyTextFont;
}, function (props) {
  return props.selectedColor;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return darken(0.1, props.selectedColor);
}, function (props) {
  return darken(0.1, props.selectedColor);
}, function (props) {
  return props.theme.black;
}, function (props) {
  return darken(0.1, props.selectedColor);
}, function (props) {
  return transparentize(0.5, props.selectedColor);
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.headingLabelFont;
}, function (props) {
  return props.theme.headingLabelLetterSpacing;
}, function (props) {
  return props.theme.headingLabelTextTransform;
}, function (props) {
  return props.theme.headingLabelFontSize;
}, function (props) {
  return props.theme.bodyTextFont;
}, function (props) {
  return props.theme.primary;
}, function (props) {
  return props.theme.black;
});
export var DatePickerWrapper = styled.div.withConfig({
  displayName: "DatePickerstyled__DatePickerWrapper",
  componentId: "sc-1ifsp1e-1"
})(["display:flex;"]);
export var PresetsWrapper = styled.div.withConfig({
  displayName: "DatePickerstyled__PresetsWrapper",
  componentId: "sc-1ifsp1e-2"
})(["background-color:white;padding:", "px;"], function (props) {
  return props.theme.spacer;
});
export var Children = styled.div.withConfig({
  displayName: "DatePickerstyled__Children",
  componentId: "sc-1ifsp1e-3"
})(["order:-1;padding:", "px;"], function (props) {
  return props.theme.spacer;
});