import styled from 'styled-components';
import { SmallText } from '@ui/components/Typography';
export var SubWrapper = styled.div.withConfig({
  displayName: "NewsFeedCardstyled__SubWrapper",
  componentId: "sc-a2bolu-0"
})(["background-color:", ";padding:", "px;box-sizing:border-box;transition:background-color 0.3s;cursor:", ";&:hover{background-color:", ";}position:relative;"], function (props) {
  return props.theme.vvdgrey;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.isFeatured ? 'default' : 'pointer';
}, function (props) {
  return props.isFeatured ? props.theme.vvdgrey : props.theme.vdgrey;
});
export var NewsFeedCardMain = styled.div.withConfig({
  displayName: "NewsFeedCardstyled__NewsFeedCardMain",
  componentId: "sc-a2bolu-1"
})(["display:grid;width:100%;grid-template-columns:auto 1fr;grid-gap:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var CtaRow = styled.div.withConfig({
  displayName: "NewsFeedCardstyled__CtaRow",
  componentId: "sc-a2bolu-2"
})(["width:100%;display:flex;justify-content:flex-start;align-items:center;"]);
export var NewsCardSubText = styled(SmallText).withConfig({
  displayName: "NewsFeedCardstyled__NewsCardSubText",
  componentId: "sc-a2bolu-3"
})(["color:", ";font-weight:400;text-transform:uppercase;", ""], function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.isMultipleArtists && !props.isWelcomeCard && "&:first-of-type {\n    &::after {\n      content: '|';\n      padding: 0 ".concat(props.theme.smallSpacer, "px 0 ").concat(props.theme.smallSpacer, "px;\n    }\n  }");
});
export var ContentWrapper = styled.div.withConfig({
  displayName: "NewsFeedCardstyled__ContentWrapper",
  componentId: "sc-a2bolu-4"
})(["display:grid;grid-gap:", "px;font-family:", ";font-size:", "px;line-height:", "px;"], function (props) {
  return props.theme.spacer * 1.5;
}, function (props) {
  return props.theme.bodyTextFont;
}, function (props) {
  return props.theme.bodyTextFontSize;
}, function (props) {
  return props.theme.bodyTextLineHeight;
});
export var Content = styled.div.withConfig({
  displayName: "NewsFeedCardstyled__Content",
  componentId: "sc-a2bolu-5"
})(["display:grid;grid-auto-flow:row;grid-gap:", "px;font-family:", ";font-size:", "px;line-height:", "px;width:100%;h3{font-family:", ";font-weight:bold;font-size:", "px;line-height:", "px;display:inline;margin:0;&::after{content:':';padding-right:4px;}}p{display:inline;margin:", ";}strong{font-weight:700;}"], function (props) {
  return props.theme.spacer * 1.5;
}, function (props) {
  return props.theme.bodyTextFont;
}, function (props) {
  return props.theme.bodyTextFontSize;
}, function (props) {
  return props.theme.bodyTextLineHeight;
}, function (props) {
  return props.theme.bodyFont;
}, function (props) {
  return props.theme.headingSmallFontSize;
}, function (props) {
  return props.theme.headingLineHeight;
}, function (props) {
  return "0 0 ".concat(props.theme.smallSpacer, "px 0");
});