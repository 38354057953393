import styled from 'styled-components';
export var SingleColumnMain = styled.div.withConfig({
  displayName: "SingleColumnstyled__SingleColumnMain",
  componentId: "sc-6ig7lm-0"
})(["height:", ";display:grid;grid-template-columns:", ";justify-content:center;padding:", "px;background-color:", ";color:", ";box-sizing:border-box;"], function (props) {
  return props.isMobile ? "calc(100vh - ".concat(props.theme.topbarHeightMobile, "px)") : "calc(100vh - ".concat(props.theme.topbarHeightDesktop, "px)");
}, function (props) {
  return props.isMobile ? '1fr' : "".concat(props.theme.centeredDesktopColumnWidth, "px");
}, function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.white;
});