import { getCDNUrl } from '@helpers/getCDNUrl';
import { getMediaItemThumbnail } from '@helpers/getMediaItemThumbnail';
import { Artist } from '@ui/types';
import { useSelector } from './useStore';

/**
 * Gets the active artist and information on socials and feature access
 */
const useActiveArtist = () => {
  const activeArtist = useSelector(
    state => state.user.activeArtist || {}
  ) as Artist;

  const hasSocialProfileForPosting = !!(
    activeArtist.facebookProfile || activeArtist.twitterProfile
  );

  return {
    ...activeArtist,
    appProfileImage:
      getMediaItemThumbnail(activeArtist.profile_image, 'square') ||
      getCDNUrl(activeArtist.appProfileImage),
    facebookPixel: activeArtist.facebookPixel || activeArtist.facebook_pixel,
    hasFacebook: !!activeArtist.facebookProfile,
    hasInstagram: !!activeArtist.instagramProfile,
    hasStripe: !!activeArtist.stripeProfile,
    hasTwitter: !!activeArtist.twitterProfile,
    hasYoutube: !!activeArtist.youtubeProfile,
    socials: activeArtist.scrapedPlatforms,
    spotifyProfileUrl: activeArtist.scrapedPlatforms?.spotify?.url,
    hasSocialProfileForPosting,
    hasWebsiteBuilderAccess: !!activeArtist.hasWebsiteBuilderAccess,
  };
};

export default useActiveArtist;
