import React from 'react';
import { MediaLibraryItem, MediaLibraryProps } from '@ui/types';
import useDevice from '@hooks/useDevice';
import Tooltip from '@ui/components/Tooltip';
import ConditionalWrapper from '@ui/components/ConditionalWrapper';

import { formatNumberToContainBytes } from '@helpers/formatNumberToContainBytes';
import { getUserFileName } from '@helpers/getUserFileNameUrl';
import { getMediaItemThumbnail } from '@helpers/getMediaItemThumbnail';
import {
  Wrapper,
  Status,
  Preview,
  StyledLoader,
  Text,
  FileIcon,
  Image,
  Video,
  VideoIcon,
} from './MediaItem.styled';

interface Props extends MediaLibraryItem {
  onItemClick: () => void;
  // selectedItems: string[];
  // setSelectedItems: (ids: string[]) => void;
  // addToSelectedItems: (id: string) => void;
  // removeFromSelectedItems: (id: string) => void;
}

const MediaItem = (props: Props & MediaLibraryProps) => {
  // const isItemSelected = props.selectedItems.includes(props.media_id);

  const { isMobile } = useDevice();

  /*  const handleSelect = selected => {
    if (selected) {
      if (!props.isMultiSelect) {
        props.setSelectedItems([props.media_id]);
      } else {
        props.addToSelectedItems(props.media_id);
      }
    } else {
      props.removeFromSelectedItems(props.media_id);
    }
  }; */

  const thumbnail = getMediaItemThumbnail(props, 'small');
  const renderPreview = () => {
    switch (props.meta?.fileType) {
      case 'audio':
        return <FileIcon name="music" />;
      case 'image':
        return <Image src={thumbnail || props.url} alt={props.name} />;
      case 'video':
        return (
          <>
            {thumbnail ? (
              <Image src={thumbnail} alt={props.name} />
            ) : (
              <Video src={props.url} />
            )}
            <VideoIcon />
          </>
        );
      default:
        return <FileIcon name="file" />;
    }
  };

  // const isListItem = isMobile && props.meta.fileType === 'audio';
  const hasNoDefaultImage = props.meta.fileType !== 'image';

  const { isDisabled, tooltip } = props.isItemDisabled?.(props) || {};

  return (
    <ConditionalWrapper
      condition={!!isDisabled && !!tooltip}
      wrapper={children => (
        <Tooltip id={`${props.media_id}-media-item`} text={tooltip}>
          {children}
        </Tooltip>
      )}
    >
      <Wrapper
        isMobile={isMobile}
        onClick={props.onItemClick}
        isDisabled={isDisabled}
        data-testid={props.name}
      >
        {props.isUsed && <Status>used</Status>}

        <Preview
          onClick={props.onItemClick}
          isMobile={isMobile}
          hasNoDefaultImage={hasNoDefaultImage}
        >
          {props.url ? renderPreview() : <StyledLoader />}
        </Preview>

        <Text>
          {getUserFileName(props.url)}
          {/* {props.meta.fileExtension && `.${props.meta.fileExtension}`}{' '} */}
          {props.meta.fileSize &&
            ` (${formatNumberToContainBytes(props.meta.fileSize)})`}
        </Text>
      </Wrapper>
    </ConditionalWrapper>
  );
};

export default MediaItem;
