import { PLATFORM } from '../../consts';

import {
  Logo7digital,
  LogoAcast,
  LogoAmazon,
  LogoAmazonMusic,
  LogoAppleMusic,
  LogoApplePodcasts,
  LogoBandcamp,
  LogoBandsintown,
  LogoBeatchain,
  LogoBeatport,
  LogoDatpiff,
  LogoDeezer,
  LogoDice,
  LogoDreamstage,
  LogoEventbrite,
  LogoFacebook,
  LogoGoogle,
  LogoGoogleMusic,
  LogoGooglePlay,
  LogoGooglePodcasts,
  LogoInstagram,
  LogoItunes,
  LogoJoox,
  LogoLinkedin,
  LogoMixcloud,
  LogoNapster,
  LogoPandora,
  LogoPaypal,
  LogoReddit,
  LogoSeeTickets,
  LogoShazam,
  LogoSongkick,
  LogoSoundcloud,
  LogoSpinrilla,
  LogoSpotify,
  LogoTicketmaster,
  LogoTidal,
  LogoTiktok,
  LogoTunein,
  LogoTwitch,
  LogoTwitter,
  LogoYandex,
  LogoYoutube,
  LogoYoutubeMusic,
  LogoMailchimp,
  LogoSpotifyForArtists,
  LogoStripe,
  LogoFuga,
  LogoDrip,
} from './logos';

const icons = {
  [PLATFORM.ACAST]: LogoAcast,
  [PLATFORM.AMAZON_MUSIC]: LogoAmazonMusic,
  [PLATFORM.AMAZON]: LogoAmazon,
  [PLATFORM.APPLE_MUSIC]: LogoAppleMusic,
  [PLATFORM.APPLE_PODCASTS]: LogoApplePodcasts,
  [PLATFORM.BANDCAMP]: LogoBandcamp,
  [PLATFORM.BANDSINTOWN]: LogoBandsintown,
  [PLATFORM.BEATCHAIN]: LogoBeatchain,
  [PLATFORM.BEATPORT]: LogoBeatport,
  [PLATFORM.DATPIFF]: LogoDatpiff,
  [PLATFORM.DEEZER]: LogoDeezer,
  [PLATFORM.DICE]: LogoDice,
  [PLATFORM.DREAMSTAGE]: LogoDreamstage,
  [PLATFORM.EVENTBRITE]: LogoEventbrite,
  [PLATFORM.FACEBOOK]: LogoFacebook,
  [PLATFORM.GOOGLE_MUSIC]: LogoGoogleMusic,
  [PLATFORM.GOOGLE_PLAY]: LogoGooglePlay,
  [PLATFORM.GOOGLE_PODCASTS]: LogoGooglePodcasts,
  [PLATFORM.GOOGLE]: LogoGoogle,
  [PLATFORM.INSTAGRAM]: LogoInstagram,
  [PLATFORM.ITUNES_STORE]: LogoItunes,
  [PLATFORM.JOOX]: LogoJoox,
  [PLATFORM.LINKEDIN]: LogoLinkedin,
  [PLATFORM.MIXCLOUD]: LogoMixcloud,
  [PLATFORM.NAPSTER]: LogoNapster,
  [PLATFORM.PANDORA]: LogoPandora,
  [PLATFORM.PAYPAL]: LogoPaypal,
  [PLATFORM.REDDIT]: LogoReddit,
  [PLATFORM.SEE_TICKETS]: LogoSeeTickets,
  [PLATFORM.SEVEN_DIGITAL]: Logo7digital,
  [PLATFORM.SHAZAM]: LogoShazam,
  [PLATFORM.SONGKICK]: LogoSongkick,
  [PLATFORM.SOUNDCLOUD]: LogoSoundcloud,
  [PLATFORM.SPINRILLA]: LogoSpinrilla,
  [PLATFORM.SPOTIFY]: LogoSpotify,
  [PLATFORM.TICKETMASTER]: LogoTicketmaster,
  [PLATFORM.TIDAL]: LogoTidal,
  [PLATFORM.TIKTOK]: LogoTiktok,
  [PLATFORM.TUNEIN]: LogoTunein,
  [PLATFORM.TWITCH]: LogoTwitch,
  [PLATFORM.TWITTER]: LogoTwitter,
  [PLATFORM.YANDEX]: LogoYandex,
  [PLATFORM.YOUTUBE]: LogoYoutube,
  [PLATFORM.YOUTUBE_MUSIC]: LogoYoutubeMusic,
  [PLATFORM.MAILCHIMP]: LogoMailchimp,
  [PLATFORM.SPOTIFY_FOR_ARTISTS]: LogoSpotifyForArtists,
  [PLATFORM.STRIPE]: LogoStripe,
  [PLATFORM.FUGA]: LogoFuga,
  [PLATFORM.DRIP]: LogoDrip,
};

export default icons;
