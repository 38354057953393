import styled from 'styled-components';
import { rgba } from 'polished';
import { BodyText } from '@ui/components/Typography';
import Checkbox from '@ui/components/Checkbox';
import Loader from '@ui/components/Loader';
import Icon from '@ui/components/Icon';
export var Wrapper = styled.div.withConfig({
  displayName: "MediaItemstyled__Wrapper",
  componentId: "sc-1qi0wd7-0"
})(["display:grid;grid-auto-rows:max-content;grid-gap:", "px;justify-items:center;align-self:flex-start;padding:", "px 0;position:relative;", " ", ""], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.isListItem && "box-shadow: ".concat(props.theme.shadow, ";\n    justify-items: start;\n    grid-template-columns: auto 1fr ").concat(props.theme.largeSpacer, "px;\n    padding: ").concat(props.theme.spacer, "px;");
}, function (props) {
  return props.isDisabled && "pointer-events: none;\n    opacity: 50%;";
});
export var Status = styled.div.withConfig({
  displayName: "MediaItemstyled__Status",
  componentId: "sc-1qi0wd7-1"
})(["position:absolute;top:", "px;left:", "px;z-index:2;background-color:", ";padding:0 ", "px;color:", ";font-weight:700;font-size:12px;"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return rgba(props.theme.black, 0.6);
}, function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.white;
});
export var StyledCheckBox = styled(Checkbox).withConfig({
  displayName: "MediaItemstyled__StyledCheckBox",
  componentId: "sc-1qi0wd7-2"
})(["", " ", ""], function (props) {
  return !props.isListItem && "position: absolute;\n    top: ".concat(props.isMobile ? 2 : 14, "px;\n    right: -").concat(props.theme.smallSpacer, "px;\n    z-index: 1;");
}, function (props) {
  return props.isMobile && 'pointer-events: none;';
});
export var Preview = styled.div.withConfig({
  displayName: "MediaItemstyled__Preview",
  componentId: "sc-1qi0wd7-3"
})(["background:", ";", " width:96px;position:relative;display:flex;justify-content:center;align-items:center;&:hover{box-shadow:1.5px 3px 6px 1.5px ", ";transition:box-shadow 0.4s;cursor:pointer;}", ""], function (props) {
  return props.theme.vvlgrey;
}, function (props) {
  return props.hasNoDefaultImage && 'height: 96px;';
}, function (props) {
  return rgba(props.theme.vvdgrey, 0.5);
}, function (props) {
  return props.isListItem && "box-shadow: unset;\n    background: 0;\n    padding-top: 0;";
});
export var StyledLoader = styled(Loader).withConfig({
  displayName: "MediaItemstyled__StyledLoader",
  componentId: "sc-1qi0wd7-4"
})(["margin-top:28px;"]);
export var FileIcon = styled(Icon).attrs({
  size: 'lg'
}).withConfig({
  displayName: "MediaItemstyled__FileIcon",
  componentId: "sc-1qi0wd7-5"
})(["background-color:", ";color:", ";"], function (props) {
  return props.theme.vvlgrey;
}, function (props) {
  return props.theme.vlgrey;
});
export var Image = styled.img.withConfig({
  displayName: "MediaItemstyled__Image",
  componentId: "sc-1qi0wd7-6"
})(["height:100%;width:100%;background-size:contain;"]);
export var Video = styled.video.withConfig({
  displayName: "MediaItemstyled__Video",
  componentId: "sc-1qi0wd7-7"
})(["height:100%;width:100%;object-fit:cover;border-radius:1px;"]);
export var VideoIcon = styled(Icon).attrs({
  name: 'video'
}).withConfig({
  displayName: "MediaItemstyled__VideoIcon",
  componentId: "sc-1qi0wd7-8"
})(["position:absolute;top:0;right:0;background:radial-gradient( 36px at 112% -16%,#000000 0%,rgba(0,0,0,0) 100% );padding:", "px;color:", ";"], function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.white;
});
export var Text = styled(BodyText).withConfig({
  displayName: "MediaItemstyled__Text",
  componentId: "sc-1qi0wd7-9"
})(["text-align:center;word-break:break-word;"]);