import React from 'react';
import { getDate, addMonths, format, setDate } from 'date-fns';

import useActiveArtist from '@hooks/useActiveArtist';
import { formatCurrency } from '@helpers/formatCurrency';
import Tooltip from '@ui/components/Tooltip';
import Icon from '@ui/components/Icon';
import PlatformLogo from '@ui/components/PlatformLogo';
import { HeadingLabel, BodyText } from '@ui/components/Typography';
import DashboardRow from 'components/infographics/DashboardRow';
import { Data } from '../types';
import {
  Wrapper,
  Balance,
  BalanceLarge,
  BalancesWrapper,
  BalancesBox,
  BalanceBox,
  PendingText,
  PaymentMethod,
  NoPaymentMethod,
  WarningText,
  CustomButton,
} from './AccountInfo.styled';

interface Props extends Data {
  openPaymentRails: () => void;
}

const AccountInfo = (props: Props) => {
  const { federated, actName, ownerEmail, permissions } = useActiveArtist();
  const disabled = !!federated && !permissions?.revenue_withdrawals?.write;
  const { payout_method_descriptor: descriptor, payout_method_type: type } =
    props.recipient || {};
  const hasPaymentMethod = !!type;
  const balanceCleanUp = (balance: number) =>
    formatCurrency(balance || 0, props.currency || 'EUR');

  const today = new Date();
  const nextPayoutDate = format(
    setDate(addMonths(today, getDate(today) < 20 ? 0 : 1), 20),
    'dd MMM yyyy'
  );

  return (
    <Wrapper>
      <DashboardRow
        heading="Account Balance"
        isLightMode
        datatest-id="account-balance-container"
      >
        <BalancesBox>
          <BalanceBox>
            <HeadingLabel>available:</HeadingLabel>
            <BalanceLarge>{balanceCleanUp(props.balance)}</BalanceLarge>
          </BalanceBox>
          <BalancesWrapper>
            <BalanceBox>
              <HeadingLabel>Total</HeadingLabel>
              <Balance>{balanceCleanUp(props.total)}</Balance>
            </BalanceBox>
            <BalanceBox>
              <PendingText>
                <HeadingLabel>Pending</HeadingLabel>
                {` (funds will be available on ${nextPayoutDate})`}
              </PendingText>
              <Balance>{balanceCleanUp(props.pending)}</Balance>
            </BalanceBox>
          </BalancesWrapper>
        </BalancesBox>
        {props.splits && (
          <BodyText>
            {`Total revenue generated from royalty splits for your contributors is ${balanceCleanUp(
              props.splits.total
            )} (${balanceCleanUp(props.splits.available)} available)`}
          </BodyText>
        )}
        <BodyText>
          *Funds become available up to 30 days after streaming platforms post
          revenue report
        </BodyText>
      </DashboardRow>
      <DashboardRow isLightMode heading="Payout Method">
        {hasPaymentMethod ? (
          <PaymentMethod>
            {type === 'paypal' ? (
              <PlatformLogo name="paypal" size={80} isColour />
            ) : (
              <Icon name="bank" size="2x" />
            )}
            <BodyText>Transfer to: {descriptor}</BodyText>
          </PaymentMethod>
        ) : (
          <NoPaymentMethod>
            <WarningText>You do not have a payout method setup.</WarningText>
            <BodyText>
              If you request a payment to an account that does not exist, the
              full amount may not be returned to you due to F/X and transaction
              fees. Please check your account details are correct.
            </BodyText>
          </NoPaymentMethod>
        )}
        <Tooltip
          id="manage-payout-method-btn-tooltip"
          text={
            disabled
              ? `You don't have access to perform this action on the
          artist ${actName}. You will need to request access to "Revenue Withdrawals"
          from the artist owner "${ownerEmail}"`
              : undefined
          }
        >
          <CustomButton
            onPress={props.openPaymentRails}
            text={type ? 'Manage Payout Method' : 'Add Payout Method'}
            disabled={disabled}
          />
        </Tooltip>
      </DashboardRow>
    </Wrapper>
  );
};

export default AccountInfo;
