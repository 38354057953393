import { take, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';
import { Auth } from '@aws-amplify/auth';
import { apiBase } from 'config/backend.config';
import { User } from '@ui/types';
import { cookieExists, cookieNames } from '@helpers/checkAndSetCookie';
import trackEvent, { cleanUrl, trackGoogleConversion } from '@helpers/tracking';
import { getUserDevice } from '@helpers/getUserDevice';
import { isWindowExists } from '@helpers/isWindowExists';
import transformUserData from '@helpers/transformUserData';
import api from '@helpers/api';
import ReconnectSocials from 'components/account/ReconnectSocials';
import LockedActsNotice from 'components/account/LockedActsNotice';
import { showErrorPage } from 'store/error';
import { setModalContent } from 'store/ui';
import {
  dismissFeedItem,
  fetchUserFeed,
  fetchUserMetadata,
  fetchUserMetadataError,
  fetchUserMetadataSuccess,
  getUser,
  liveUserFeed,
  markFeedItemAsSeen,
  setActiveArtist,
} from './reducer';
import { FeedDataAction, FetchUserMetadataAction, UserState } from './types';

const requestSocialReconnect = artist =>
  artist &&
  artist.invalidSocialTokens &&
  artist.invalidSocialTokens.length > 0 &&
  !cookieExists(`${cookieNames.reconnectSocials}-${artist.actId}`);

const modalContent = {
  isVisible: true,
  modalSize: 'dialogue',
  modalTitle: 'Please reconnect socials',
  Component: ReconnectSocials,
};

function* dismissFeedItemApi({ payload }: FeedDataAction) {
  try {
    const { activeArtistId }: UserState = yield select(getUser);

    yield api({
      endpoint: `${apiBase}/feed/${activeArtistId}/cards`,
      method: 'POST',
      params: {
        act_id: payload.act_id,
        news_feed_id: payload.news_feed_id,
        dismissed: true,
      },
    });
  } catch (e) {
    console.error('dismissFeedItemSaga', e);
  }
}

export function* dismissFeedItemSaga() {
  yield takeEvery(dismissFeedItem, dismissFeedItemApi);
}

function* markFeedItemAsDismissedApi({ payload }: FeedDataAction) {
  try {
    const { activeArtistId }: UserState = yield select(getUser);

    yield api({
      endpoint: `${apiBase}/feed/${activeArtistId}/cards`,
      method: 'POST',
      params: {
        act_id: payload.act_id,
        news_feed_id: payload.news_feed_id,
        seen: true,
      },
    });
  } catch (e) {
    console.error('dismissFeedItemSaga', e);
  }
}

export function* markFeedItemAsDismissedSaga() {
  yield takeEvery(markFeedItemAsSeen, markFeedItemAsDismissedApi);
}

export function* fetchUserFeedSaga() {
  while (true) {
    try {
      const { payload } = yield take(fetchUserFeed.type);
      const { feed, activeArtistId }: UserState = yield select(getUser);
      let after;
      let before;
      switch (payload.type) {
        case 'infiniteList':
          if (feed?.data?.length) {
            before = feed.data[feed.data.length - 1]?.date_ts;
          }
          break;
        case 'refresh':
          if (feed?.data?.length) {
            after = feed.data[0]?.date_ts;
          }
          break;
        case 'initial':
        default:
          break;
      }

      const result = yield api({
        endpoint: `${apiBase}/feed/${activeArtistId}/cards/paginated?after=${
          after || ''
        }&before=${before || ''}`,
      });

      yield put(liveUserFeed(result));
    } catch (e) {
      console.error('fetchUserFeedSaga', e);
    }
  }
}

function* fetchUserMetadataApi({ payload }: FetchUserMetadataAction) {
  try {
    const result: User = yield api({ endpoint: `${apiBase}/user/me` });

    window.Beatchain = {};

    const { activeArtistId }: UserState = yield select(getUser);
    const actId = activeArtistId || payload?.actId;

    if (result) {
      window.Beatchain.App = {
        Features: result.features,
        User: {
          email: result.email,
        },
      };

      const activeArtist =
        result.artists.find(artist => artist.actId === actId) ||
        result.artists[0];

      yield put(
        fetchUserMetadataSuccess({
          ...result,
          activeArtist,
          activeArtistId: get(activeArtist, 'actId'),
        })
      );

      const { getReferralCode } = transformUserData();

      const referralCode = getReferralCode();

      const needsTracking =
        window.localStorage.getItem('needs_registration_tracking') ===
        result.email;
      // Hacky way for setting referral code and other stuff for federated signups...
      if (needsTracking && Date.now() - result.createdTs < 2 * 60 * 1000) {
        try {
          window.localStorage.removeItem('needs_registration_tracking');

          const user = yield Auth.currentAuthenticatedUser();
          const splitted = user.username.split('_');
          const mode = splitted.length > 1 ? splitted[0] : 'user_password';

          trackEvent('CompleteRegistration', {
            mode,
            email: result.email,
          });

          trackGoogleConversion('sign_up', {
            method: mode,
          });

          const locationData = yield api({
            endpoint: `${apiBase}/public/location`,
            auth: false,
          });

          const userDevice = getUserDevice();

          yield api({
            endpoint: `${apiBase}/user/completeRegistration`,
            method: 'POST',
            params: {
              device: userDevice,
              country: locationData?.country?.toLowerCase?.(),
            },
          });

          if (referralCode) {
            yield api({
              endpoint: `${apiBase}/user/referral_code`,
              method: 'POST',
              params: { referralCode },
            });
          }
        } catch (e) {
          console.error(e);
        }
      }

      if (requestSocialReconnect(activeArtist)) {
        yield put(
          setModalContent({
            ...modalContent,
            componentProps: {
              socials: activeArtist?.invalidSocialTokens,
              actId: activeArtist.actId,
            },
          })
        );
      }

      if (result?.lockedOutArtists?.length > 0) {
        yield put(
          setModalContent({
            isVisible: true,
            modalSize: 'tall',
            modalTitle: 'Claimed Artists',
            Component: LockedActsNotice,
            componentProps: {
              lockedOutArtists: result.lockedOutArtists,
            },
          })
        );
      }

      if (isWindowExists) {
        const name = result.name || result.email;
        const artistsNames = (result.artists || []).map(el => el.actName);

        const trackingArgs = {
          name,
          email: result.email,
          'User ID': result.userId,
          userId: result.userId,
          Onboarded: result.onboarded,
          bc_cohort: get(result, 'cohorts[0].name'),
          Artist: artistsNames.length === 1 ? artistsNames[0] : '',
          Artists: artistsNames.length > 1 ? artistsNames.join(', ') : '',
          buildTag: window.BeatchainBuildTag,
        };

        cleanUrl();

        const isWebView = window.location.search
          .toLowerCase()
          .includes('webview=true');

        window.analytics?.identify(result.userId, trackingArgs, {
          Intercom: {
            hideDefaultLauncher: isWebView,
          },
        });
      }
    } else {
      yield put(fetchUserMetadataError('API returned an empty response'));
    }
  } catch (err: any) {
    window.Raven?.captureException(err);
    yield put(fetchUserMetadataError(err.toString()));
  }
}

export function* fetchUserMetadataSaga() {
  yield takeLatest(fetchUserMetadata, fetchUserMetadataApi);
}

export function* setActiveArtistSaga() {
  while (true) {
    const { payload } = yield take(setActiveArtist.type);

    const { artists }: UserState = yield select(getUser);
    const artist = artists.find(el => el.actId === payload.actId)!;

    if (requestSocialReconnect(artist)) {
      yield put(
        setModalContent({
          ...modalContent,
          componentProps: {
            socials: artist.invalidSocialTokens,
            actId: artist.actId,
          },
        })
      );
    }
  }
}

export function* fetchMetadataErrorSaga() {
  while (true) {
    const { payload } = yield take(fetchUserMetadataError.type);
    yield put(
      showErrorPage({
        errorCode: 500,
        errorMessage: `API error: ${payload}`,
        errorData: {
          request: {
            endpoint: 'user/me',
          },
        },
      })
    );
  }
}
