import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    fill={isColour ? '#FF7700' : 'currentColor'}
    {...props}
  >
    <path d="M197.369 180.252c-7.524 0-11.414 8.456-11.414 13.557 0 0-5.684 83.648-5.684 108.107 0 37.317 3.494 61.053 3.494 61.053 0 5.172 6.08 9.341 13.604 9.341 7.477 0 12.089-4.169 12.089-9.341 0 0 9.271-24.179 9.271-61.472 0-42.791-3.564-96.134-6.615-106.966-2.609-9.201-7.291-14.279-14.745-14.279zm-46.495 6.243c-7.524 0-11.414 8.455-11.414 13.557 0 0-5.684 77.382-5.684 101.841 0 37.316 2.772 61.053 2.772 61.053 0 5.171 6.057 9.341 13.604 9.341 7.477 0 13.534-4.17 13.534-9.341 0 0 1.886-23.457 1.886-60.751 0-42.79-4.798-102.167-4.798-102.167 0-5.078-2.423-13.533-9.9-13.533zm-47.077 40.275c-7.547 0-11.414 8.432-11.414 13.557 0 0-6.336 37.13-6.336 61.589 0 37.317 4.17 59.609 4.17 59.609 0 5.171 5.334 10.785 12.858 10.785 7.477 0 12.089-5.614 12.089-10.785 0 0 4.426-21.989 4.426-59.306 0-42.791-5.893-61.892-5.893-61.892 0-5.125-2.423-13.557-9.9-13.557zm-47.66-8.339c-7.523 0-11.413 8.455-11.413 13.557 0 0-3.774 44.724-3.774 69.183 0 37.316 1.607 58.141 1.607 58.141 0 5.171 6.057 12.975 13.604 12.975 7.477 0 13.534-7.082 13.534-12.253 0 0 3.33-21.803 3.33-59.12 0-42.791-4.798-68.95-4.798-68.95 0-5.078-4.612-13.533-12.09-13.533zM5.614 252.952c-6.336 0-5.404 5.078-5.404 5.078v92.757s-.932 7.803 5.404 7.803c8.176 0 16.679-19.381 16.679-54.694 0-29.001-10.157-50.944-16.679-50.944zm435.946-22.735c-6.732 0-13.208.979-19.381 2.702C412.464 180.135 356.815 140 299.582 140c-26.252 0-48.801 16.422-68.274 30.655v190.078s0 11.274 11.647 11.274h198.604c38.551 0 69.881-36.105 69.881-73.492 0-37.433-31.353-68.298-69.881-68.298z" />
  </svg>
);
