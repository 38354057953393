import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSearchParams, useLocation } from '@hooks/useRouting';
import { isWindowExists } from '@helpers/isWindowExists';
import AppUtilities from 'AppUtilities';
import Confirmation from 'pages/confirmation';
import Signup from 'pages/signup';
import Signin from 'pages/signin';
import DistributionPreview from 'pages/releases';
import Unsubscribe from 'pages/unsubscribe';
import Partners from 'pages/partners';
import Forgot from 'pages/forgot';
import ScrollToTop from 'components/app/ScrollToTop';

const RouteWrapper = props => {
  if (isWindowExists) {
    return (
      <>
        <ScrollToTop />
        {props.children}
      </>
    );
  }
  return <>{props.children}</>;
};

const Placeholder = () => (
  <div
    style={{
      backgroundColor: 'black',
      width: '100%',
      height: '100%',
      position: 'fixed',
    }}
  />
);

const UnauthenticatedApp = () => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const redirect =
    searchParams.get('signup') === 'true' ? '/signup' : '/signin';

  return (
    <AppUtilities>
      <RouteWrapper>
        <Routes>
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/login" element={<Navigate to="/signin" />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route
            path="/releases/:userId/:releaseId"
            element={<DistributionPreview isUnauthenticated />}
          />
          <Route
            path="/partners/:partner"
            element={<Partners isUnauthenticated />}
          />
          <Route path="/placeholder" element={<Placeholder />} />
          <Route
            path="*"
            element={<Navigate to={{ pathname: redirect, search }} replace />}
          />
        </Routes>
      </RouteWrapper>
    </AppUtilities>
  );
};

export default UnauthenticatedApp;
