import styled from 'styled-components';
export var ErrorPageMain = styled.div.withConfig({
  displayName: "AppErrorPagestyled__ErrorPageMain",
  componentId: "sc-1svmncu-0"
})(["color:", ";width:100%;height:100%;position:fixed;overflow:auto;a{color:", ";}"], function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.white;
});
export var InnerContainer = styled.div.withConfig({
  displayName: "AppErrorPagestyled__InnerContainer",
  componentId: "sc-1svmncu-1"
})(["display:grid;grid-gap:", "px;padding:", "px;"], function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.xlargeSpacer;
});