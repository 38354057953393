import transformUserData from '@helpers/transformUserData';
import { getFormattedAmountWithCurrency } from './getFormattedAmountWithCurrency';

/**
 * Formats number to a currency/ the users default currency
 */
export const formatCurrency = (
  value: number,
  currency?: string,
  exact: boolean = false,
  use3DecimalPlaces: boolean = false
) => {
  const user = transformUserData();
  return getFormattedAmountWithCurrency(
    value,
    currency || user.currency,
    exact,
    use3DecimalPlaces
  );
};
