import React from 'react';
import {
  NotificationsIndicatorMain,
  StyledBodyBoldText,
} from './NotificationsIndicator.styled';

interface Props {
  text?: string;
}

/**
 * Adds an icon only button or indicator to the top right hand corner of an element for dismissal,
 * number, success or warning
 */
const NotificationsIndicator = (props: Props) => (
  <NotificationsIndicatorMain>
    <StyledBodyBoldText>{props.text}</StyledBodyBoldText>
  </NotificationsIndicatorMain>
);

export default NotificationsIndicator;
