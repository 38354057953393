import React from 'react';
import Icon from '@ui/components/Icon';
import useMediaLibrary from '@hooks/useMediaLibrary';
import useActiveArtist from '@hooks/useActiveArtist';
import { Wrapper } from './MediaLibraryToggle.styled';

const MediaLibraryToggle = () => {
  const { actId } = useActiveArtist();
  const openMediaLibrary = useMediaLibrary();

  return (
    <Wrapper
      isBlocked={!actId}
      onClick={() => actId && openMediaLibrary({ isSelectMode: false })}
      data-testid="media-library-toggle"
    >
      <Icon name="image" />
    </Wrapper>
  );
};

export default MediaLibraryToggle;
