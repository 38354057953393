import React from 'react';
import { Wrapper, BarTrack, Label, Bar, Message } from './ProgressBar.styled';

export interface ProgressBarProps {
  percentage?: number;
  hasLabel?: boolean;
  isPaused?: boolean;
  message?: string;
}

/**
 * Shows completeness of a process as a line
 */
const ProgressBar = ({
  hasLabel = true,
  percentage,
  ...props
}: ProgressBarProps) => (
  <>
    {props.message && <Message>{props.message}</Message>}
    <Wrapper {...props} hasLabel={hasLabel} isPaused={props.isPaused}>
      {hasLabel && <Label>%</Label>}
      {percentage && (
        <BarTrack>
          <Bar percentage={percentage} />
        </BarTrack>
      )}
    </Wrapper>
  </>
);

export default ProgressBar;
