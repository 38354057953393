import React from 'react';
import useUser from '@hooks/useUser';
import useActiveArtist from '@hooks/useActiveArtist';
import TitleBlock from '@ui/components/TitleBlock';
import ButtonWrapper from '@ui/components/ButtonWrapper';
import { BodyText } from '@ui/components/Typography';
import SubscriptionBtn from 'components/account/SubscriptionBtn';
import FormPage from 'components/app/FormPage/FormPage';

const templates = {
  missing_organization_subscription: {
    title: 'Missing Access',
    subtitle:
      "The artist owner's subscription has been cancelled or has expired",
    body: (
      <BodyText>
        You will not have access to this artist until the owner&apos;s
        subscription is reactivated.
      </BodyText>
    ),
  },
  can_trial: {
    title: 'Try Beatchain free for 14 days',
    subtitle: 'You can cancel at any time',
    body: (
      <ButtonWrapper>
        <SubscriptionBtn buttonText="Start Trial" />
      </ButtonWrapper>
    ),
  },
  can_not_trial: {
    title: 'Resubscribe',
    subtitle: 'You can cancel at any time',
    body: (
      <ButtonWrapper>
        <SubscriptionBtn buttonText="Resubscribe" />
      </ButtonWrapper>
    ),
  },
  failed_payments: {
    title: 'Payment Issues',
    subtitle: 'Reactivate your subscription',
    body: (
      <>
        <BodyText>
          We are having trouble processing your card payments, reactivate your
          subscription to regain access to features.
        </BodyText>
        <ButtonWrapper>
          <SubscriptionBtn buttonText="Reactivate" reactivate />
        </ButtonWrapper>
      </>
    ),
  },
};

const NoSubscriptionBlocker = () => {
  const {
    hasUsedInitialTrial,
    organizations,
    hasFailedPayments,
    subscriptionType,
  } = useUser();

  const { orgId, federated } = useActiveArtist();
  let type = 'can_trial';
  if (
    orgId &&
    federated &&
    !organizations?.some(el => el.organization_id === orgId && el.subscribed)
  ) {
    type = 'missing_organization_subscription';
  } else if (hasFailedPayments && subscriptionType !== 'Basic') {
    type = 'failed_payments';
  } else if (hasUsedInitialTrial) {
    type = 'can_not_trial';
  }

  return (
    <FormPage hasNoHeader>
      <TitleBlock
        title={templates[type].title}
        subtitle={templates[type].subtitle}
        hasNoBox
      />
      {templates[type].body}
    </FormPage>
  );
};

export default NoSubscriptionBlocker;
