import React from 'react';
import { useFormikContext, useField } from 'formik';
import Select from '../Select';
import Input from '../Input';
import { ErrorContainer, Error, Wrapper } from './Field.styled';

export interface FieldProps {
  gridArea?: string;
  isSelect?: boolean;
  label?: string;
  large?: boolean;
  name: string;
  options?: any;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  dataTestId?: string;
  autoComplete?: string;
}

/**
 * Styles and configures field elements of third-party form module 'formik'
 */
const Field = (props: FieldProps) => {
  const [field, meta, helpers] = useField(props);
  useFormikContext();

  return (
    <Wrapper gridArea={props.gridArea}>
      {props.isSelect ? (
        <Select
          name={field.name}
          onChange={option => helpers.setValue(option.value)}
          options={props.options}
          placeholder={props.placeholder}
          label={props.label}
          isSearchable
          defaultValue={props.options?.find(
            el => el.value === meta.initialValue
          )}
          isDisabled={props.disabled}
        />
      ) : (
        <Input
          {...props}
          {...field}
          onChange={val => field.onChange(field.name)(val)}
          // isLight={!props.dark}
          large={props.large}
        />
      )}
      <ErrorContainer>
        <Error>{meta.touched && meta.error ? meta.error : ''}</Error>
      </ErrorContainer>
    </Wrapper>
  );
};

export default Field;
