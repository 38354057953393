import ajax from '@helpers/ajax';

/**
 * Gets details about audio track
 */
export const getAudioDetails = async (fileUrl: string) => {
  try {
    const response = await ajax(
      `audio/validatetrack`,
      { s3Url: fileUrl },
      'POST',
      true,
      false
    );
    return response;
  } catch (e) {
    console.warn(e);
    return {};
  }
};
