import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { MediaLibraryModalContent, ModalContent } from '@ui/types';
import { logoutSuccess, resetStore } from '../auth';

interface UIState {
  modalContent: ModalContent;
  mediaLibraryModalContent: MediaLibraryModalContent;
}

const initialState: UIState = {
  modalContent: {
    isVisible: false,
    componentProps: {},
    Component: undefined,
    modalSize: 'medium',
    modalTitle: '',
    modalText: '',
    isWarning: false,
    isSuccess: false,
    isPayment: false,
    isAlertVisible: false,
    alertTitle: '',
    alertText: '',
    AlertComponent: undefined,
    alertComponentProps: {},
  },
  mediaLibraryModalContent: {
    isVisible: false,
    componentProps: {},
    modalTitle: '',
    isAlertVisible: false,
    alertTitle: '',
    alertText: '',
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setModalContent: (state, action: PayloadAction<Partial<ModalContent>>) => ({
      ...state,
      modalContent: {
        ...state.modalContent,
        ...action.payload,
      },
    }),
    setMediaLibraryModalContent: (
      state,
      action: PayloadAction<Partial<MediaLibraryModalContent>>
    ) => ({
      ...state,
      mediaLibraryModalContent: {
        ...state.mediaLibraryModalContent,
        ...action.payload,
      },
    }),
    resetModalContent: state => ({
      ...state,
      modalContent: initialState.modalContent,
    }),
    resetMediaLibraryModalContent: state => ({
      ...state,
      mediaLibraryModalContent: initialState.mediaLibraryModalContent,
    }),
  },
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(logoutSuccess, resetStore), () => initialState)
      .addDefaultCase(state => state);
  },
});

export const { actions, reducer } = uiSlice;

export const {
  setModalContent,
  setMediaLibraryModalContent,
  resetModalContent,
  resetMediaLibraryModalContent,
} = actions;
