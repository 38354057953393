import React, { ReactNode } from 'react';
import Icon from '@ui/components/Icon';
import {
  AlertWrapper,
  AlertMain,
  AlertHeader,
  AlertTitle,
  AlertClose,
  AlertContent,
  AlertText,
} from './Alert.styled';

interface Props {
  title?: string;
  closeHandler: () => void;
  text?: string;
  children?: ReactNode;
  noPadding?: boolean;
}

/**
 * An alert to provide the user with some information
 */
const Alert = (props: Props) => (
  <AlertWrapper>
    <AlertMain>
      <AlertHeader>
        <AlertTitle>{props.title}</AlertTitle>
        <AlertClose>
          <Icon name="cross" clickHandler={props.closeHandler} />
        </AlertClose>
      </AlertHeader>
      <AlertContent noPadding={props.noPadding}>
        {props.text && <AlertText>{props.text}</AlertText>}
        {props.children}
      </AlertContent>
    </AlertMain>
  </AlertWrapper>
);

export default Alert;
