import React, { useState } from 'react';
import styled from 'styled-components';
import useUser from '@hooks/useUser';
import TitleBlock from '@ui/components/TitleBlock';
import ButtonWrapper from '@ui/components/ButtonWrapper';
import Select from '@ui/components/Select';
import { BodyText, BodyStrongText } from '@ui/components/Typography';
import SubscriptionBtn from 'components/account/SubscriptionBtn';
import FormPage from 'components/app/FormPage/FormPage';
import useTheme from '@hooks/useTheme';

const WarningText = styled(BodyStrongText)`
  color: ${props => props.theme.warning};
`;

const ProSubscriptionPage = () => {
  const theme = useTheme();
  const { subscription } = useUser();
  const [artists, setArtists] = useState(1);
  const [teamMembers, setTeamMembers] = useState(1);
  const isBasic = subscription?.product_name === 'Basic';

  return (
    <FormPage hasNoHeader>
      <TitleBlock title="PRO Subscription" subtitle="MORE FEATURES!" hasNoBox />
      <BodyText>
        Start your PRO subscription now and get access to new insights, data
        exports, multiple artists, team member accounts, new campaigns and
        dedicated support. <br />
        Choose the number of artists to manage and the number of team
        members&apos; accounts:
      </BodyText>
      <Select
        options={[1, 2, 3, 4, 5, 6].map(i => ({
          label: `${i} artist${i > 1 ? 's' : ''}`,
          value: i.toString(),
        }))}
        onChange={({ value }) => setArtists(+value)}
        value={{
          label: `${artists} artist${artists > 1 ? 's' : ''}`,
          value: artists.toString(),
        }}
        isSearchable
      />

      <Select
        options={[1, 2, 3, 4, 5].map(i => ({
          label: `${i} team member${i > 1 ? 's' : ''}`,
          value: i.toString(),
        }))}
        onChange={({ value }) => setTeamMembers(+value)}
        value={{
          label: `${teamMembers} team member${teamMembers > 1 ? 's' : ''}`,
          value: teamMembers.toString(),
        }}
        isSearchable
      />
      <ButtonWrapper>
        {isBasic ? (
          <SubscriptionBtn
            buttonText="Subscribe"
            product="PRO"
            billingPeriod="month"
            redirectTo="/feed"
            quantities={{
              artists,
              teamMembers,
            }}
          />
        ) : (
          <WarningText>
            {`Please contact ${theme.title} to upgrade your current subscription`}
          </WarningText>
        )}
      </ButtonWrapper>
    </FormPage>
  );
};

export default ProSubscriptionPage;
