import styled from 'styled-components';
import Button from '@ui/components/Button';
export var Wrapper = styled.div.withConfig({
  displayName: "Downgradestyled__Wrapper",
  componentId: "sc-ehajxm-0"
})(["width:100%;height:100%;position:relative;box-sizing:border-box;color:", ";", ""], function (props) {
  return props.theme.black;
}, function (props) {
  return "padding-top: ".concat(props.theme.largeSpacer, "px;");
});
export var DowngradeWrapper = styled.div.withConfig({
  displayName: "Downgradestyled__DowngradeWrapper",
  componentId: "sc-ehajxm-1"
})(["display:flex;justify-content:center;align-items:center;width:100%;max-width:940px;margin:auto;@media (max-width:450px){width:", ";}"], function (props) {
  return "calc(100% - ".concat(props.theme.xlargeSpacer, "px)");
});
export var DowngradeMain = styled.div.withConfig({
  displayName: "Downgradestyled__DowngradeMain",
  componentId: "sc-ehajxm-2"
})(["color:", ";display:grid;grid-gap:", "px;width:100%;justify-content:center;align-items:center;"], function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.largeSpacer;
});
export var ConfirmationBlock = styled.div.withConfig({
  displayName: "Downgradestyled__ConfirmationBlock",
  componentId: "sc-ehajxm-3"
})(["display:flex;flex-direction:column;align-items:flex-end;"]);
export var CheckboxWrapper = styled.div.withConfig({
  displayName: "Downgradestyled__CheckboxWrapper",
  componentId: "sc-ehajxm-4"
})(["background:", ";color:", ";padding:", "px;padding-bottom:0;margin-bottom:", "px;"], function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.spacer;
});
export var ButtonsWrapper = styled.div.withConfig({
  displayName: "Downgradestyled__ButtonsWrapper",
  componentId: "sc-ehajxm-5"
})(["display:flex;"]);
export var ReturnButton = styled(Button).attrs({
  dark: true,
  squishy: true,
  text: 'return'
}).withConfig({
  displayName: "Downgradestyled__ReturnButton",
  componentId: "sc-ehajxm-6"
})(["margin-right:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var ConfirmButton = styled(Button).attrs({
  tertiary: true,
  squishy: true,
  text: 'Downgrade'
}).withConfig({
  displayName: "Downgradestyled__ConfirmButton",
  componentId: "sc-ehajxm-7"
})(["margin-right:", "px;"], function (props) {
  return props.theme.largeSpacer;
});