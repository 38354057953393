import React from 'react';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import ajax from '@helpers/ajax';
import useDevice from '@hooks/useDevice';
import { env } from 'config/backend.config';

async function handleVerify(captchaToken) {
  const response = await ajax(
    'public/verification-captcha',
    { captchaToken },
    'POST',
    false
  );

  window.localStorage.setItem('captcha_score', String(response?.score));
}

const Captcha = () => {
  const { isWebView } = useDevice();

  if (isWebView) {
    return null;
  }

  if (env !== 'prod') {
    return null;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcLFmkaAAAAADv9bAEGz5DzPcGvdP53vHlIzjM0">
      <GoogleReCaptcha onVerify={handleVerify} />
    </GoogleReCaptchaProvider>
  );
};

export default Captcha;
