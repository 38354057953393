import React, { ReactNode } from 'react';
import useDevice from '@hooks/useDevice';
import { SingleColumnMain } from './SingleColumn.styled';

interface Props {
  children: ReactNode;
}

/**
 * Layout component for a single column list such as the Activity Feed
 */
const SingleColumn = (props: Props) => {
  const { isMobile } = useDevice();

  return (
    <SingleColumnMain isMobile={isMobile}>{props.children}</SingleColumnMain>
  );
};

export default SingleColumn;
