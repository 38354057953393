import transformUserData from '@helpers/transformUserData';
import { useSelector } from './useStore';

/**
 * Get's current user data
 */
const useUser = () => {
  const { user, auth } = useSelector(state => state);

  return transformUserData(user, auth);
};

export default useUser;
