import styled from 'styled-components';
import { HeadingLabel, BodyText } from '@ui/components/Typography';
import Icon from '@ui/components/Icon';
export var BulletListMain = styled.div.withConfig({
  displayName: "BulletListstyled__BulletListMain",
  componentId: "sc-ffv7c5-0"
})(["display:flex;flex-direction:column;"]);
export var BulletListHeading = styled(HeadingLabel).withConfig({
  displayName: "BulletListstyled__BulletListHeading",
  componentId: "sc-ffv7c5-1"
})(["margin-bottom:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var ListItem = styled(BodyText).withConfig({
  displayName: "BulletListstyled__ListItem",
  componentId: "sc-ffv7c5-2"
})(["color:inherit;line-height:", "px;margin-bottom:", "px;display:flex;align-items:flex-start;justify-content:flex-start;a,a:hover,a:visited,a:focus{color:", ";}"], function (props) {
  return props.theme.iconCanvasSize;
}, function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.linkColour;
});
export var ItemBullet = styled(Icon).attrs({
  name: 'circle-solid',
  size: 'xs'
}).withConfig({
  displayName: "BulletListstyled__ItemBullet",
  componentId: "sc-ffv7c5-3"
})(["color:", ";font-size:", "px;margin-top:", "px;"], function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.spacer;
});
export var ItemText = styled.div.withConfig({
  displayName: "BulletListstyled__ItemText",
  componentId: "sc-ffv7c5-4"
})(["margin:0 ", "px;font-size:12px;", " ", ""], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.isTicked && 'font-weight: 600;';
}, function (props) {
  return props.isTextBig && "font-weight: 600;\n        font-size: 14px;\n  ";
});
export var ItemIcon = styled(Icon).withConfig({
  displayName: "BulletListstyled__ItemIcon",
  componentId: "sc-ffv7c5-5"
})(["color:", ";margin-left:auto;"], function (props) {
  return props.name === 'tick' ? props.theme.success : props.theme.tertiary;
});