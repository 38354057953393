import styled from 'styled-components';
var breakpoint = '440px';
export var Main = styled.div.withConfig({
  displayName: "ButtonWrapperstyled__Main",
  componentId: "sc-dqswos-0"
})(["display:grid;grid-auto-flow:column;grid-gap:", "px;width:100%;box-sizing:border-box;@media (max-width:", "){", " div{min-width:unset;flex:1;}}"], function (props) {
  return props.theme.spacer;
}, breakpoint, function (props) {
  return props.autoColumns ? 'grid-auto-columns: 1fr;' : 'grid-auto-flow: row;';
});
export var Left = styled.div.withConfig({
  displayName: "ButtonWrapperstyled__Left",
  componentId: "sc-dqswos-1"
})(["display:grid;grid-auto-flow:column;grid-gap:", "px;justify-content:start;@media (max-width:", "){justify-content:stretch;", "}"], function (props) {
  return props.theme.spacer;
}, breakpoint, function (props) {
  return props.autoColumns && 'grid-auto-columns: 1fr;';
});
export var Right = styled.div.withConfig({
  displayName: "ButtonWrapperstyled__Right",
  componentId: "sc-dqswos-2"
})(["display:grid;grid-auto-flow:column;grid-gap:", "px;justify-content:end;@media (max-width:", "){justify-content:stretch;", "}"], function (props) {
  return props.theme.spacer;
}, breakpoint, function (props) {
  return props.autoColumns && 'grid-auto-columns: 1fr;';
});