import { Auth } from '@aws-amplify/auth';
import { timedRequest } from '@helpers/connectionIssues';
import { setModalContent } from 'store/ui';
import { logout } from 'store/auth';
import { showErrorPage } from 'store/error';
import { store } from 'store';
import { Method } from '@ui/types';
import UnauthorizedModal from 'components/account/AccessManagement/UnauthorizedModal';

const defaultOptions = {
  credentials: 'omit',
};

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

interface Args {
  endpoint: string;
  method?: Method;
  params?: any;
  auth?: boolean;
  displayError?: boolean;
}

/**
 * Wrapper used to make api requests
 */
const api = async ({
  endpoint,
  method = 'GET',
  params,
  auth = true,
  displayError = true,
}: Args) => {
  const options: any = {
    ...defaultOptions,
    method,
    headers: {
      ...defaultHeaders,
    },
  };

  if (auth) {
    // Auth.currentSession automatically obtains new jwt if have valid refresh token
    const token = await Auth.currentSession()
      .then(session => session.getIdToken().getJwtToken())
      .catch(e => {
        console.error('CurrentSession error', e, e.toString());
      });

    if (!token) {
      console.error('UNABLE TO MAKE AUTHENTICATED REQUEST WITHOUT TOKEN');
      if (window?.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            method: 'missingSession',
          })
        );
      } else {
        store.dispatch(logout());
      }
      return null;
    }
    options.headers.Authorization = `Bearer ${token}`;
  }

  if (method !== 'GET') {
    options.body = JSON.stringify(params);
  }

  let response: Response;
  try {
    response = await timedRequest(endpoint, options);
  } catch (e) {
    response = new Response(null, { status: 500, statusText: 'Server Error' });
  }

  if (displayError && response.status >= 500 && response.status < 600) {
    store.dispatch(
      showErrorPage({
        errorCode: response.status,
        errorMessage: `API error: ${response.statusText}`,
        errorData: {
          response: await response.text(),
          request: { endpoint, ...options, headers: undefined },
        },
      })
    );
  }

  const contentType = response.headers.get('content-type');
  const hasJson = contentType && contentType.includes('application/json');
  let json;
  if (hasJson) {
    try {
      json = await response.json();
    } catch (e) {
      console.error(e);
    }
  }

  if (response.status !== 200) {
    if (
      response.status === 401 &&
      json?.reason === 'missing_federated_permissions'
    ) {
      store.dispatch(
        setModalContent({
          isVisible: true,
          Component: UnauthorizedModal,
          modalTitle: 'Unauthorized',
          componentProps: { data: json },
          modalSize: 'dialogue',
        })
      );
      throw new Error('Missing federated permissions');
    }

    // throw error body so can be added to asyncActionState of async action
    // if json (descriptive error to support client behavior)
    if (json) throw json;

    throw new Error(`API ERROR: ${response.status} (${endpoint})`);
  }

  return json ?? 'SUCCESS';
};

export default api;
