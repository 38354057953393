import React, { useRef, InputHTMLAttributes } from 'react';
import { omit, debounce } from 'lodash';
import Tooltip from '@ui/components/Tooltip';
import {
  Wrapper,
  Label,
  InputElement,
  InputIconWrapper,
  IconWrapper,
  IconCustomBase,
  Message,
  LockedValue,
  WithTooltip,
  HintWrapper,
  CustomIcon,
  HintMessage,
} from './Input.styled';

export interface InputProps
  // eslint-disable-next-line no-undef
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  label?: string;
  small?: boolean;
  large?: boolean;
  isLight?: boolean;
  isInBox?: boolean;
  error?: boolean;
  message?: string;
  icon?: string;
  iconFirst?: boolean;
  onChange?: (valueOrEvent: any) => void;
  onBlur?: (valueOrEvent: any) => void;
  isLocked?: boolean;
  isDisabled?: boolean;
  children?: React.ReactNode;
  tooltip?: string;
  avoidDisabledOpacity?: boolean;
  hideNumberToggles?: boolean;
  hint?: {
    message: string;
    iconColor?: string;
    messageColor?: string;
  };
  shouldUseEvent?: boolean;
  maxLength?: number;
  dataTestId?: string;
}

/**
 * Styled text input
 */
const Input = (props: InputProps) => {
  const ref: any = useRef();

  const handleChange = debounce(event => {
    if (props.onChange)
      props.onChange(props.shouldUseEvent ? event : ref.current.value);
  }, 250);

  function handleBlur(event) {
    if (props.onBlur)
      props.onBlur(props.shouldUseEvent ? event : ref.current.value);
  }

  return (
    <Wrapper className={props.className}>
      {props.label && !props.tooltip && <Label>{props.label}</Label>}
      {props.label && props.tooltip && (
        <WithTooltip>
          <Tooltip
            text={props.tooltip}
            id={props.tooltip}
            place="right"
            effect="solid"
          />
          <Label hasTooltip>{props.label}</Label>
        </WithTooltip>
      )}
      {props.isLocked ? (
        <>
          <LockedValue data-testid="locked">{props.value || '––'}</LockedValue>
          {props.message && (
            <Message error={props.error} data-testid="message">
              {props.message}
            </Message>
          )}
        </>
      ) : (
        <>
          <InputIconWrapper>
            <InputElement
              ref={ref}
              {...omit(props, 'className', 'children', 'value')}
              defaultValue={props.value}
              onChange={handleChange}
              onBlur={handleBlur}
              hasIcon={!!props.icon || !!props.children}
              data-testid={props.dataTestId}
              hideNumberToggles={props.hideNumberToggles}
              large={props.large}
              isLight={props.isLight}
              iconFirst={props.iconFirst}
              readOnly={props.isDisabled}
              maxLength={props.maxLength}
              onWheel={event => {
                event.currentTarget.blur();
              }}
            />
            {props.icon && (
              <IconCustomBase
                name={props.isLocked ? 'lock' : props.icon}
                isLight={props.isLight}
                error={props.error}
                iconFirst={props.iconFirst}
              />
            )}
            {props.children && (
              <IconWrapper
                isLight={props.isLight}
                error={props.error}
                iconFirst={props.iconFirst}
              >
                {props.children}
              </IconWrapper>
            )}
          </InputIconWrapper>
          {props.hint?.message && !props.message && (
            <HintWrapper>
              <CustomIcon
                name="exclamation-circle"
                size="xs"
                color={props.hint?.iconColor}
              />
              <HintMessage color={props.hint?.messageColor}>
                {props.hint?.message}
              </HintMessage>
            </HintWrapper>
          )}
          {props.message && (
            <Message error={props.error} data-testid="message">
              {props.message}
            </Message>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Input;
