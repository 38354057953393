import React, { useEffect } from 'react';
import { useSearchParams } from '@hooks/useRouting';
import ajax from '@helpers/ajax';
import UnsubscribeComponent from 'components/app/Unsubscribe';

const Unsubscribe = () => {
  const [search] = useSearchParams();
  const email = search.get('email');
  const actId = search.get('actId');
  const webAlias = search.get('webAlias');

  useEffect(() => {
    if (email) {
      ajax(`public/unsubscribe`, { email, actId, webAlias }, 'POST', false);
    }
  }, [email, actId]);

  if (email) {
    return <UnsubscribeComponent email={email as string} />;
  }

  return null;
};

export default Unsubscribe;
