import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { getStripeKey } from '@helpers/getStripeKey';
import PageLoader from '@ui/components/PageLoader';

/**
 * Styled Stripe inputs for credit card UIs
 */
export default (props: {
  accountType: 'ORIGINAL' | 'US';
  children: React.ReactNode;
}) => {
  let isMounted = true;

  const [stripe, setStripe] = useState<Stripe | null>(null);
  const key = getStripeKey(props.accountType || 'ORIGINAL');

  useEffect(() => {
    loadStripe(key).then(args => {
      if (isMounted) {
        setStripe(args);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [key]);

  if (!stripe)
    return <PageLoader isFullScreen message="Loading Your Account" />;

  return (
    <Elements
      stripe={stripe}
      key={key}
      options={{
        fonts: [
          {
            family: 'Beatchain Body',
            src: 'url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap")',
          },
          {
            family: 'Beatchain Body Italic',
            src: 'url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@300&display=swap")',
          },
        ],
      }}
    >
      {props.children}
    </Elements>
  );
};
