import React, { ReactNode, useState } from 'react';
import {
  Panel,
  Arrow,
  ExpandContentPanelMain,
  TopBar,
  Heading,
} from './ExpandContentPanel.styled';

interface Props {
  heading: string;
  children: ReactNode;
}

/**
 * Expandable panel for displaying potentially long text content, truncated by default
 */
const ExpandContentPanel = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <ExpandContentPanelMain>
      <TopBar onClick={() => setIsExpanded(!isExpanded)}>
        <Heading>{props.heading}</Heading>
        <Arrow rotation={isExpanded ? 90 : undefined} />
      </TopBar>
      {isExpanded && <Panel>{props.children}</Panel>}
    </ExpandContentPanelMain>
  );
};

export default ExpandContentPanel;
