import {
  TypedUseSelectorHook,
  useDispatch as _useDispatch,
  useSelector as _useSelector,
} from 'react-redux';
import { AppDispatch, RootState } from 'store';

/**
 * Wrapper around useDispatch from redux
 */
export const useDispatch = () => _useDispatch<AppDispatch>();

/**
 * Wrapper around useSelector from redux
 */
export const useSelector: TypedUseSelectorHook<RootState> = _useSelector;
