import numeral from 'numbro';

/**
 * Formats account balance to string e.g 99.00
 */
export const formatAccountBalance = (balanceValue: number) =>
  numeral(balanceValue).format({
    mantissa: 2,
    roundingFunction: Math.floor,
  });

/**
 * Takes formatted account balance and converts to number
 */
export const formatAccountBalanceToNumber = (formattedBalanceValue: string) =>
  parseFloat(formattedBalanceValue || '0');
