import React from 'react';
import LinkTile, { OptionType } from 'components/common/LinkTile';
import { OptionTilesMain } from './OptionTiles.styled';

interface Props {
  isRow?: boolean;
  options: OptionType[];
}

/**
 * Panels used to display a small number of options to the user
 */
const OptionTiles = (props: Props) => (
  <OptionTilesMain isRow={props.isRow}>
    {props.options.map(option => (
      <LinkTile {...option} />
    ))}
  </OptionTilesMain>
);

export default OptionTiles;
