import React, { FC } from 'react';
import { Wrapper, Main } from './FormErrorMessage.styled';

interface Props {
  isCentered?: boolean;
}

/**
 * Styled error message to appear in forms such as sign up, log in, passwords etc
 */
const FormErrorMessage: FC<Props> = props => (
  <Wrapper isCentered={props.isCentered}>
    <Main>{props.children}</Main>
  </Wrapper>
);

export default FormErrorMessage;
