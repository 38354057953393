import React from 'react';
import { Wrapper, IconCustom } from './Hamburger.styled';

interface Props {
  handleToggle: (isExpanded: boolean) => void;
  isSmall?: boolean;
  isExpanded: boolean;
}

const Hamburger = (props: Props) => (
  <Wrapper
    data-testid="hamburger-menu"
    onClick={() => props.handleToggle(!props.isExpanded)}
    isSmall={props.isSmall}
  >
    {props.isExpanded ? (
      <IconCustom name="cross" />
    ) : (
      <IconCustom name="bars" data-testid="hamburger-menu-icon" />
    )}
  </Wrapper>
);

export default Hamburger;
