import styled from 'styled-components';
export var AppWrapper = styled.div.withConfig({
  displayName: "AuthenticatedAppstyled__AppWrapper",
  componentId: "sc-k4vk9v-0"
})(["overflow:hidden;::-webkit-scrollbar{background-color:transparent;width:", "px;height:", "px;}background-color:", ";display:flex;flex-direction:column;min-height:100vh;width:100vw;box-sizing:border-box;position:fixed;"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.black;
});