import styled from 'styled-components';
import { getLuminance } from 'polished';
import { HeadingLabel } from '@ui/components/Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "Unsubscribestyled__Wrapper",
  componentId: "sc-1nz83a0-0"
})(["background:", ";display:flex;padding:", "px;overflow:hidden;height:fill-available;"], function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.largeSpacer;
});
export var WhiteBox = styled.div.withConfig({
  displayName: "Unsubscribestyled__WhiteBox",
  componentId: "sc-1nz83a0-1"
})(["max-width:400px;display:block;align-self:center;background:", ";padding:", "px;color:", ";margin:0 auto;"], function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.black;
});
export var TextWrapper = styled.div.withConfig({
  displayName: "Unsubscribestyled__TextWrapper",
  componentId: "sc-1nz83a0-2"
})(["margin-bottom:", "px;"], function (props) {
  return props.theme.spacer;
});
export var BoldHeadingLabel = styled(HeadingLabel).withConfig({
  displayName: "Unsubscribestyled__BoldHeadingLabel",
  componentId: "sc-1nz83a0-3"
})(["font-weight:900;color:", ";"], function (props) {
  return getLuminance(props.theme.tertiary) < 0.7 ? props.theme.tertiary : props.theme.primary;
});