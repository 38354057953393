import React, { useState, useEffect } from 'react';
import useFitText from '../../hooks/useFitText';
import { TextVersion, FontSize } from './PlatformLogo.styled';
import { PLATFORM } from '../../consts';
import isItLogo from './isItLogo/isItLogo';
import icons from './icons';

export interface PlatformLogoProps {
  name?: string;
  displayName?: string;
  isColour?: boolean;
  size?: number;
}

/**
 * Maps and styles logos for platforms referenced
 */
const PlatformLogo = ({
  name = PLATFORM.BEATCHAIN,
  displayName,
  size = 160,
  ...props
}: PlatformLogoProps) => {
  const SVG = icons[name];
  const hasLogo = isItLogo(name, icons);

  const { fontSize, ref } = useFitText(name);

  const [textFontSize, setTextFontSize] = useState(fontSize);

  useEffect(() => {
    setTextFontSize(fontSize);
  }, [fontSize]);

  return hasLogo ? (
    <SVG width={size} height={size * 0.3125} {...props} />
  ) : (
    <TextVersion ref={ref}>
      <FontSize
        style={{
          fontSize: `${textFontSize}%`,
          height: size * 0.3125,
          width: size,
        }}
      >
        {displayName || name}
      </FontSize>
    </TextVersion>
  );
};

export default PlatformLogo;
