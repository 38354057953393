import React, { ReactNode } from 'react';
import useDevice from '@hooks/useDevice';
import { useLocation } from '@hooks/useRouting';
import useUser from '@hooks/useUser';
import useActiveArtist from '@hooks/useActiveArtist';
import ArtistName from 'components/onboarding/ArtistName';
import { useFlags } from 'launchdarkly-react-client-sdk';
import NoSubscriptionBlocker from '../NoSubscriptionBlocker/NoSubscriptionBlocker';
import PaidFeatureNotice from '../PaidFeatureNotice';
import SingleColumn from '../SingleColumn';

import { PageMain } from './Page.styles';

const protectedRoutes = {
  insights: true,
  distribution: true,
  launchpad: true,
  'fan-builder': true,
  hypelinks: true,
  'email-marketing': true,
  welcome: true,
  'website-builder': true,
  royalties: true,
};

interface Props {
  isLightBg?: boolean;
  bgColour?: string;
  hasScroll?: boolean;
  children: ReactNode;
}

/**
 * High level layout component pages
 */
const Page = (props: Props) => {
  const { isMobile, isWebView } = useDevice();
  const location = useLocation();
  const { disableSubscriptionPaywallOps } = useFlags();

  const basePath = location.pathname.split('/')[1];

  const {
    subscriptionType,
    hasNonBasicRole,
    hasReadOnlyDistro,
    organizations,
    onboarded,
  } = useUser();
  const { actId, orgId, federated } = useActiveArtist();

  // Organization access (owner no longer subscribed)
  if (
    orgId &&
    federated &&
    !organizations?.some(el => el.organization_id === orgId && el.subscribed) &&
    !disableSubscriptionPaywallOps
  ) {
    return <NoSubscriptionBlocker />;
  }

  if (
    protectedRoutes[basePath] &&
    subscriptionType === 'Basic' &&
    !hasNonBasicRole &&
    !federated &&
    !disableSubscriptionPaywallOps
  ) {
    if (basePath === 'distribution' && hasReadOnlyDistro) {
      console.warn('READ ONLY');
    } else {
      return <NoSubscriptionBlocker />;
    }
  }
  if (protectedRoutes[basePath] && !actId && !onboarded) {
    return (
      <SingleColumn>
        <ArtistName showMoreOptions />
      </SingleColumn>
    );
  }

  return (
    <PageMain
      isFullHeight={isWebView}
      isLightBg={props.isLightBg}
      bgColour={props.bgColour}
      hasScroll={props.hasScroll}
      isMobile={isMobile}
    >
      <PaidFeatureNotice />
      {props.children}
    </PageMain>
  );
};

export default Page;
