import styled from 'styled-components';
export var LoaderWrapper = styled.div.withConfig({
  displayName: "PaymentRailsWidgetstyled__LoaderWrapper",
  componentId: "sc-21c6df-0"
})(["width:100%;height:100%;display:flex;justify-content:center;align-items:center;"]);
export var IframeWrapper = styled.div.withConfig({
  displayName: "PaymentRailsWidgetstyled__IframeWrapper",
  componentId: "sc-21c6df-1"
})(["display:flex;justify-content:center;height:100%;"]);
export var Iframe = styled.iframe.withConfig({
  displayName: "PaymentRailsWidgetstyled__Iframe",
  componentId: "sc-21c6df-2"
})(["width:100%;"]);