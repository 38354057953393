import ajax from '@helpers/ajax';

/**
 * Signs request for file
 */
export const signRequest = async (fileName: string, fileType: string) =>
  ajax(
    `file/signrequest?fileName=${encodeURIComponent(
      fileName
    )}&type=${encodeURIComponent(fileType)}`
  );

/**
 * Uploads file to s3
 */
export const uploadToS3 = (
  file: File,
  signedRequest: string,
  updateProgressCb: (
    file: File,
    progress: { loaded: number; total: number }
  ) => void,
  isPrivate?: boolean
) =>
  new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);
    xhr.setRequestHeader('x-amz-acl', isPrivate ? 'private' : 'public-read');

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(file);
      }
    };

    xhr.upload.onprogress = ev => {
      updateProgressCb(file, { loaded: ev.loaded, total: ev.total });
    };

    xhr.send(file);
  });
