import styled from 'styled-components';
import Icon from '@ui/components/Icon';
export var Wrapper = styled.div.withConfig({
  displayName: "Hamburgerstyled__Wrapper",
  componentId: "sc-g5ud3w-0"
})(["width:", "px;height:auto;display:flex;align-items:center;justify-content:center;cursor:pointer;"], function (props) {
  return props.isSmall ? props.theme.iconCanvasSize : props.theme.xlargeSpacer;
});
export var IconCustom = styled(Icon).withConfig({
  displayName: "Hamburgerstyled__IconCustom",
  componentId: "sc-g5ud3w-1"
})(["font-size:", "px;color:", ";"], function (props) {
  return props.theme.iconCanvasSize;
}, function (props) {
  return props.theme.secondary;
});