import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#FFF' : 'transparent'}
      d="M437.98 236.35l-72.73 72.72h-72.72l-63.64 63.63v-63.63h-81.8V36.37h290.88v199.98z"
    />
    <path
      fill={isColour ? '#9146FF' : 'currentColor'}
      d="M474.33 0v254.53L310.71 418.15h-72.72l-90.9 90.9v-90.9H38V90.9L128.9 0h345.43zm-36.36 36.36H147.08v272.71h81.81v63.63l63.64-63.63h72.73l72.71-72.72V36.36zM383.43 100v109.07h-36.36V100h36.36zm-100 0v109.07h-36.35V100h36.36z"
    />
  </svg>
);
