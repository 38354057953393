import 'url-search-params-polyfill';
import React from 'react';
import { useSelector } from '@hooks/useStore';
import useUser from '@hooks/useUser';
import useConversionsLogger from '@hooks/useConversionsLogger';
import AppErrorPage from '@ui/components/AppErrorPage/AppErrorPage';
import UnauthenticatedApp from './UnauthenticatedApp';
import AuthenticatedApp from './AuthenticatedApp';
import '@ui/components/Icon/iconLibrary';

const App = () => {
  const { loggedIn } = useUser();
  const error = useSelector(state => state.error);

  useConversionsLogger();

  if (error.errorCode) {
    console.warn('appError', error);
  }

  return (
    <>
      {!!error.errorCode && <AppErrorPage />}
      {!error.errorCode && !loggedIn && <UnauthenticatedApp />}
      {!error.errorCode && loggedIn && <AuthenticatedApp />}
    </>
  );
};

export default App;
