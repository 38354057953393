import React from 'react';
import { useParams, useLocation } from '@hooks/useRouting';
import * as oauth from '@helpers/oauth';
import PageLoader from '@ui/components/PageLoader';

const OAuthPage = () => {
  const location = useLocation();
  const { provider } = useParams();

  async function doAuth() {
    await oauth.methods[provider || ''](location.search);

    window.close();

    // In rare occasions window.close() won't work (for example in Safari, when the popup
    // is blocked and its allowed afterwards by the user, "can't close the window since it
    // was not opened by JavaScript").
    window.location.href = window.location.origin; // Redirect to main page
  }

  React.useEffect(() => {
    doAuth();
  }, []);

  return <PageLoader message="Loading Account" />;
};

export default OAuthPage;
