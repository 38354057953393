import React from 'react';
import useUser from '@hooks/useUser';
import useDevice from '@hooks/useDevice';
import PageLoader from '@ui/components/PageLoader';
import { Box } from 'components/common/Box/Box.styled';
import { BodyText, HeadingSmall } from '@ui/components/Typography';
import ConditionalWrapper from '@ui/components/ConditionalWrapper';
import { EquallySpacedRow } from 'components/common/EquallySpacedRow';
import { EquallySpacedColumn } from 'components/common/EquallySpacedColumn';
import { map } from 'lodash';
import { getCategorizedData } from '../helper/getCategorizedData';
import NewsFeedCardCategory from '../NewsFeedCardCategory/NewsFeedCardCategory';
import NewsFeedCard from '../NewsFeedCard';
import { NewsFeedWrapper } from './FeedContainer.styled';

const NewsFeedContainer = () => {
  const { feed, activeArtistId } = useUser();
  if (feed === null) {
    return <PageLoader isFullScreen message="Loading activity feed" />;
  }

  const filteredData =
    activeArtistId && feed
      ? feed.data?.filter(
          feedItem =>
            feedItem.act_id === activeArtistId ||
            feedItem.category_id === 'welcome'
        )
      : feed?.data;

  const { isMobile } = useDevice();

  // set up mock data for mobile & desk layout, to be commented out after testing:
  // if (filteredData) {
  //   filteredData[2] = { ...filteredData[2], isFeatured: true };
  //   filteredData[5] = { ...filteredData[5], isFeatured: true };
  // }

  const firstFeaturedIndex =
    filteredData?.findIndex(item => item.isFeatured) || -1;

  if (firstFeaturedIndex > -1 && filteredData) {
    filteredData[firstFeaturedIndex] = {
      ...filteredData[firstFeaturedIndex],
      isFirstFeatured: true,
    };
  }

  const firstFeaturedCard = filteredData?.[firstFeaturedIndex];

  // for desktop, remove the first featured card from main array
  const finalizedData = !isMobile
    ? filteredData?.filter(card => !card.isFirstFeatured)
    : filteredData;

  const formattedData = getCategorizedData(finalizedData || [], isMobile);

  const hasFeaturedCard = firstFeaturedIndex > -1;

  return (
    <NewsFeedWrapper hasFeaturedCard={hasFeaturedCard} isMobile={isMobile}>
      {filteredData && filteredData?.length > 0 ? (
        <ConditionalWrapper
          condition={!isMobile && hasFeaturedCard}
          wrapper={children => (
            <EquallySpacedRow gridTemplateColumns="3fr 2fr" isXlargeGap>
              {children}
            </EquallySpacedRow>
          )}
        >
          <>
            <EquallySpacedColumn>
              {map(formattedData, feedCardCategory =>
                feedCardCategory.cards?.length > 0 ? (
                  <NewsFeedCardCategory
                    {...feedCardCategory}
                    key={feedCardCategory.slug}
                  />
                ) : null
              )}
            </EquallySpacedColumn>
            {!isMobile && firstFeaturedCard && (
              <EquallySpacedColumn>
                <HeadingSmall>Featured</HeadingSmall>
                {/* using first card as mock data */}
                <NewsFeedCard {...firstFeaturedCard} />
              </EquallySpacedColumn>
            )}
          </>
        </ConditionalWrapper>
      ) : (
        <Box>
          <BodyText>No recent activity</BodyText>
        </Box>
      )}
    </NewsFeedWrapper>
  );
};

export default NewsFeedContainer;
