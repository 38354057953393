import styled from 'styled-components';
import { HeadingLabel } from '@ui/components/Typography';
export var SelectWrapper = styled.div.withConfig({
  displayName: "SelectCreatablestyled__SelectWrapper",
  componentId: "sc-1dykekf-0"
})(["letter-spacing:initial;text-transform:initial;flex:1;position:relative;max-width:600px;"]);
export var SelectLabel = styled(HeadingLabel).withConfig({
  displayName: "SelectCreatablestyled__SelectLabel",
  componentId: "sc-1dykekf-1"
})(["display:flex;align-items:center;margin-bottom:", "px;"], function (props) {
  return props.theme.spacer;
});
export var LockedValue = styled.div.withConfig({
  displayName: "SelectCreatablestyled__LockedValue",
  componentId: "sc-1dykekf-2"
})(["line-height:", "px;min-height:", "px;padding:0 ", "px;margin-top:", "px;display:flex;"], function (props) {
  return props.theme.buttonHeight;
}, function (props) {
  return props.theme.buttonHeight;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.spacer;
});