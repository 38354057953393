import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    viewBox="0 0 160 50"
    fill={isColour ? '#000' : 'currentColor'}
    {...props}
  >
    <path d="M93.39 20.42l6.03-1L105 25 80 50 64.75 34.75c.12-.24.25-.47.6-.66.37-.2 5.17-3.1 6.68-4.62 1.52-1.52 3.83-4.07 3.27-5.36a6.72 6.72 0 00-1.6-2.17l-.1-.1c-.2-.2-.43-.22-.8-.21l-.63.03c-.82.04-2.09.11-2.27.02-.13-.06-.31-.28-.5-.49-.19-.22-.37-.44-.48-.47-.2-.06-.29.08-.38.32-.1.23-.43 1.16-.43 1.16s-.21.14-.47.25l-.2.06c-.7.22-3 .95-4.95 2.54-1.96 1.62-1.85 1.77-1.2 2.62l.25.34s1.6.96 1.45-.4c0 0-.47.3-.79-.36 0 0-.04-.1.35-.36l.2-.15c.89-.62 3.99-2.8 4.43-2.21l-3.96 8.7L55 25 80 0l18.31 18.3c-1.64.16-4.73.45-5.13.5-.55.06-2.99.36-3.19.4-.2.06-.45.13-.57.24 0 0-.29.08-.16.56l.24.86.82.48-.88 1.16s-1.18.23-1.22.52c-.03.15.01.53.05.87.03.31.06.6.04.67-.04.18-.34.95-.54 1.37l-.24.52c-.17.4-.35.81-.47.95v.02c-.17.2-.22.26-.62.49l-.2.11c-.38.2-.77.41-.65.6.13.22 1.64 1.83 1.64 1.83s.92-.5 1.4-1.07c0 0 .49-.32 1.1-.59.6-.26 1.5-.66 2.04-.81l.45-.13c.8-.21 2.19-.6 3.03-.94l.03-.01c1.04-.44 2.27-.95 2.96-1 0 0 .57-.06.69.07 0 0 .32-.83.1-1.1-.23-.27-.36-.22-.45-.22-.09.01-2.3.5-3.46.8 0 0-1.84.48-2.94.7l-.57.13c-.93.2-1.82.38-1.84.27-.04-.14.05-.81.65-1.54l.27-.34c.34-.45.47-.61 1.32-.9a138.55 138.55 0 017.07-2.16s.59-1.05-.12-.93l-3.18.53-3.7.65s.3-1.29 1.3-1.44zM73.88 32.13c-.34 1.44.8 1.6.8 1.6s.6-.76 1.5-3.96c.88-3.12 2.62-6.5 2.84-6.93l.02-.03.87-1.63s.25-.27.16-.3c-.04 0-.16-.25-.3-.53-.15-.28-.31-.61-.44-.79-.22-.3-.37-.2-.6-.05l-.13.08-.09.05c-.22.1-.3.14-1.75 3.42a53.09 53.09 0 00-2.88 9.07zm4.78-5.63s-.51 1.64.24 2.53c.74.89 1.23 1.07 2.12.7.65-.28 3.3-1.57 5.3-3.05.17-.15.07-.23-.01-.28-.08-.05-.17-.01-.17-.01-.09.03-.18-.06-.29-.17-.18-.18-.4-.41-.75-.27-.2.08-.75.39-1.41.75-1.2.67-2.75 1.53-3.16 1.58-.63.08-.63-.1-.49-.85.15-.75 1.84-3.99 4.25-5.26 1.7-.9 1.78-.87 1.82-.85.02 0 .03.01.15-.05.4-.23.85-.83-.42-1.9 0 0-.41-.26-.9.08l-.24.15c-.94.56-3.87 2.28-6.04 6.9zm-4.63-2.81c.11.33.25.75-.22 1.41-.5.71-2.56 3.36-5.82 5.55l-2.13 1.27 2.7-6.67s.52-1.26.75-1.43c.22-.16.75-.53 1-.57.26-.04 2.49-.23 3.23-.02 0 0 .35.04.46.38l.03.08z" />
  </svg>
);
