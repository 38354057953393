import React, { FC } from 'react';
import { EquallySpacedRow } from 'components/common/EquallySpacedRow/EquallySpacedRow.styles';
import Button from '@ui/components/Button/Button';
import SocialIcon from '@ui/components/SocialIcon';
import Tooltip from '@ui/components/Tooltip';
import { ButtonProps } from '@ui/components/Button/types';
import { HeadingLabel } from '@ui/components/Typography';
import {
  HeadingGroup,
  StyledBox,
  HeadingLabelContainer,
  HeadingCustom,
} from './DashboardRow.styles';

interface Props {
  background?: string;
  isLightMode?: boolean;
  heading?: string;
  subheading?: string;
  socialIconName?: string;
  headingButton?: ButtonProps;
  tooltip?: string;
  hasLargeGap?: boolean;
}

/**
 * Row of Dashboard component
 */
const DashboardRow: FC<Props> = props => (
  <StyledBox
    background={props.background}
    isLightMode={props.isLightMode}
    hasLargeGap={props.hasLargeGap}
  >
    {props.heading && (
      <EquallySpacedRow
        gridTemplateColumns={props.headingButton ? '1fr auto' : undefined}
      >
        <HeadingGroup>
          <HeadingCustom>
            {!!props.socialIconName && (
              <SocialIcon platform={props.socialIconName} variant="colour" />
            )}
            {props.heading}
          </HeadingCustom>
          {props.subheading && (
            <HeadingLabelContainer>
              <HeadingLabel>{props.subheading}</HeadingLabel>
              {props.tooltip && (
                <Tooltip
                  text={props.tooltip}
                  id={props.tooltip}
                  place="right"
                  effect="solid"
                />
              )}
            </HeadingLabelContainer>
          )}
        </HeadingGroup>
        {props.headingButton && <Button {...props.headingButton} />}
      </EquallySpacedRow>
    )}
    {props.children}
  </StyledBox>
);

export default DashboardRow;
