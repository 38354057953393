import React from 'react';

import { VideoButton, StyledButton, Video, Inner } from './VideoPlayer.styles';

interface Props {
  url: string;
  clickHandler: () => void;
  isPlaying?: boolean;
  isHovered?: boolean;
  setIsHovered: (value: boolean) => void;
  thumbnailUrl?: string;
  videoRef: any;
  isAutoplay?: boolean;
}

/**
 * Styling and configuration for both HTML 5 and third-party video player 'react-player' used across the app
 */
const VideoPlayer = (props: Props) => (
  <>
    <VideoButton>
      <Inner
        onMouseEnter={() => props.setIsHovered(true)}
        onMouseLeave={() => props.setIsHovered(false)}
        isHovered={props.isHovered}
      >
        <StyledButton
          onPress={() => props.clickHandler()}
          icon={props.isPlaying ? 'pause' : 'play'}
          iconOnly
        />
      </Inner>
    </VideoButton>
    <Video
      ref={props.videoRef}
      controls={props.isPlaying}
      poster={props.thumbnailUrl}
      autoPlay={props.isAutoplay}
    >
      <source src={props.url} type="video/mp4" />
      Your browser does not support this video
    </Video>
  </>
);

export default VideoPlayer;
