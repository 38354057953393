import React from 'react';
import { useTheme } from 'styled-components';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { ClickWrapper } from './Icon.styled';

export const iconNames = {
  'add-artist': 'user-plus',
  'sign-out': 'sign-out-alt',
  'bar-chart': 'chart-bar',
  help: 'question',
  clock: ['far', 'clock'],
  'clock-solid': 'clock',
  image: ['far', 'image'],
  images: ['far', 'images'],
  'image-solid': 'image',
  'images-solid': 'images',
  edit: 'pen',
  undo: 'undo-alt',
  world: 'globe-africa',
  'external-link': 'external-link-alt',
  delete: 'trash',
  audio: 'headphones',
  file: ['far', 'file'],
  'edit-alt': 'edit',
  marker: 'map-marker-alt',
  view: 'eye',
  down: 'chevron-down',
  left: 'chevron-left',
  right: 'chevron-right',
  up: 'chevron-up',
  'down-circle': ['far', 'arrow-alt-circle-down'],
  'left-circle': ['far', 'arrow-alt-circle-left'],
  'right-circle': ['far', 'arrow-alt-circle-right'],
  'up-circle': ['far', 'arrow-alt-circle-up'],
  checkbox: ['far', 'circle'],
  tick: 'check',
  'tick-circle': 'check-circle',
  cross: 'times',
  'cross-circle': 'times-circle',
  clipboard: 'clipboard-check',
  confirm: 'check',
  revoke: 'times',
  error: 'exclamation',
  circle: ['far', 'circle'],
  'circle-solid': 'circle',
  lightbulb: ['far', 'lightbulb'],
  'expand-arrows': 'expand-arrows-alt',
  'compress-arrows': 'compress-arrows-alt',
  album: 'compact-disc',
  ticket: 'ticket-alt',
  smile: ['far', 'smile'],
  grip: 'grip-vertical',
  bank: 'university',
  grid: 'th',
  // features
  insights: 'chart-bar',
  launchpad: ['far', 'paper-plane'],
  distribution: 'cloud-upload-alt',
  website: 'globe-africa',
  hypelinks: 'link',
  email: 'mail-bulk',
  'fan-builder': 'rocket',
  // brands
  'cc-stripe': ['fab', 'cc-stripe'],
  'cc-visa': ['fab', 'cc-visa'],
  'cc-mastercard': ['fab', 'cc-mastercard'],
  'cc-amex': ['fab', 'cc-amex'],
  'cc-discover': ['fab', 'cc-discover'],
  chrome: ['fab', 'chrome'],
  safari: ['fab', 'safari'],
};

export interface IconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  name: string;
  clickHandler?: () => void;
  disabled?: boolean;
  dataTestId?: string;
}

/**
 * Maps and styles all icons used in Beatchain
 */
const Icon = ({
  name,
  size = '1x',
  clickHandler,
  disabled,
  className,
  dataTestId,
  ...props
}: IconProps) => {
  const theme = useTheme();
  const stackColours = {
    confirm: theme.success,
    revoke: theme.warning,
    error: theme.warning,
  };
  const stackShrinkBy = {
    confirm: 7,
    revoke: 5.5,
    error: 8,
  };
  if (['confirm', 'revoke', 'error'].includes(name))
    return (
      <span className={`fa-layers fa-${size}`} data-testid={dataTestId}>
        <FontAwesomeIcon icon="circle" color={stackColours[name]} />
        <FontAwesomeIcon
          icon={iconNames[name]}
          inverse
          transform={`shrink-${stackShrinkBy[name]}`}
        />
      </span>
    );

  if (clickHandler)
    return (
      <ClickWrapper
        onClick={clickHandler}
        className={className}
        disabled={disabled}
        data-testid={dataTestId}
      >
        <FontAwesomeIcon
          icon={iconNames[name] || name}
          size={size}
          {...props}
        />
      </ClickWrapper>
    );

  return (
    <FontAwesomeIcon
      icon={iconNames[name] || name}
      size={size}
      className={className}
      {...props}
    />
  );
};

export default Icon;
