import styled from 'styled-components';
export var BoxBase = styled.div.withConfig({
  displayName: "Boxstyled__BoxBase",
  componentId: "sc-1onz068-0"
})(["display:grid;grid-gap:", "px;background:", ";padding:", "px;grid-auto-flow:", ";", " color:", ";", " height:min-content;box-sizing:border-box;", " ", " ", ""], function (props) {
  return props.hasLargeGap ? props.theme.xlargeSpacer : props.theme.largeSpacer;
}, function (props) {
  return props.isLightMode ? props.theme.vvlgrey : props.theme.vvdgrey;
}, function (props) {
  return props.hasLargePadding ? props.theme.xlargeSpacer : props.theme.largeSpacer;
}, function (props) {
  return props.direction === 'row' ? 'column' : 'row';
}, function (props) {
  return props.direction === 'row' && "justify-content: space-between;";
}, function (props) {
  return props.isLightMode ? props.theme.black : props.theme.white;
}, function (props) {
  return props.background && props.theme[props.background] && "background: ".concat(props.theme[props.background], ";");
}, function (props) {
  return props.hasShadow && "box-shadow: ".concat(props.theme.solidShadowAttention, ";");
}, function (props) {
  return !!props.alignItems && "align-items: ".concat(props.alignItems, ";");
}, function (props) {
  return props.hasHover && "cursor: pointer;\n    &:hover {\n      transition: background-color 250ms;\n      background: ".concat(props.isLightMode ? props.theme.vlgrey : props.theme.vdgrey, ";\n    }");
});
/**
 * @deprecated in favour of Box
 */

export var BoxInBox = styled(BoxBase).withConfig({
  displayName: "Boxstyled__BoxInBox",
  componentId: "sc-1onz068-1"
})(["background:", ";"], function (props) {
  return props.isLightMode ? props.theme.vlgrey : props.theme.vdgrey;
});
/**
 * @deprecated in favour of Box
 */

export var BoxInBoxInBox = styled(BoxBase).withConfig({
  displayName: "Boxstyled__BoxInBoxInBox",
  componentId: "sc-1onz068-2"
})(["background:", ";"], function (props) {
  return props.isLightMode ? props.theme.vlgrey : props.theme.dgrey;
});
/* stylelint-disable */

/**
 * Widely used layout component that handles background colour and positioning
 * within grey panels used in various UIs
 */

export var Box = styled(BoxBase).withConfig({
  displayName: "Boxstyled__Box",
  componentId: "sc-1onz068-3"
})(["", " &{background:", ";", " &{background:", ";}}"], BoxBase, function (props) {
  return props.isLightMode ? props.theme.vlgrey : props.theme.vdgrey;
}, BoxBase, function (props) {
  return props.isLightMode ? props.theme.vlgrey : props.theme.dgrey;
});
/* stylelint-enable */