const stages = {
  'dev.beatcha.in': 'dev',
  'dev.beatchain.com': 'dev',
  localhost: 'dev',
  'beatchain.com': 'prod',
  'beatcha.in': 'prod',
  'ymd-test.net': 'prod',
  'qa.beatcha.in': 'qa',
  'qa.beatchain.com': 'qa',
};

const apis = {
  prod: 'https://api-v2.beatchain.com/core',
  dev: 'https://dev-api-v2.beatchain.com/core',
};

export const env = () => {
  if (typeof window === 'undefined') return 'prod';

  let { hostname } = window.location;

  if (hostname.includes('beatchain-preview-sites.netlify.app')) {
    hostname = 'dev.beatchain.com';
  }

  return stages[hostname] || 'prod'; // For custom domains
};

export const api = () => apis[env()] || apis.prod;
