import styled from 'styled-components';
import { SmallText } from '../Typography';
export var ErrorContainer = styled.div.withConfig({
  displayName: "Fieldstyled__ErrorContainer",
  componentId: "sc-mdq595-0"
})([""]);
export var Error = styled(SmallText).withConfig({
  displayName: "Fieldstyled__Error",
  componentId: "sc-mdq595-1"
})(["color:", ";"], function (props) {
  return props.theme.tertiary;
});
export var Wrapper = styled.div.withConfig({
  displayName: "Fieldstyled__Wrapper",
  componentId: "sc-mdq595-2"
})(["grid-area:", ";"], function (props) {
  return props.gridArea;
});