import styled from 'styled-components';
import { BoxInBox } from 'components/common/Box/Box.styled';
export var AutosuggestMain = styled.div.withConfig({
  displayName: "Autosuggeststyled__AutosuggestMain",
  componentId: "sc-67yipi-0"
})(["display:grid;grid-auto-flow:row;grid-gap:", "px;& .react-autosuggest__container{display:grid;grid-auto-flow:row;grid-gap:", "px;}"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
});
export var Suggestions = styled.div.withConfig({
  displayName: "Autosuggeststyled__Suggestions",
  componentId: "sc-67yipi-1"
})(["> ul{display:grid;grid-auto-flow:row;grid-gap:", "px;}width:100%;:empty{display:none;}"], function (props) {
  return props.theme.largeSpacer;
});
export var Suggestion = styled(BoxInBox).withConfig({
  displayName: "Autosuggeststyled__Suggestion",
  componentId: "sc-67yipi-2"
})(["display:grid;grid-auto-flow:column;justify-content:", ";align-items:center;grid-gap:", "px;cursor:", ";", " &:hover{background:", ";color:", ";}"], function (props) {
  return props.hasAvatars ? 'flex-start' : 'center';
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.isNotClickable ? 'default' : 'pointer';
}, function (props) {
  return props.isNotClickable && "\n    pointer-events: none;\n    color: ".concat(props.theme.grey, ";\n  ");
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.black;
});