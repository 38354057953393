import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg viewBox="0 0 160 50" fill="none" {...props}>
    <path
      fill={isColour ? '#fff' : 'transparent'}
      d="M140.623 20.238h-5.385v19.048h5.714v-9.149c0-3.192 1.37-5.137 4.242-5.137 2.366 0 3.377 2.223 3.377 4.756v9.53h5.714V28.714c0-5.659-1.605-8.714-7.36-8.714-2.998 0-5.422 1.503-6.243 2.983h-.059v-2.745zM131.428 20.238v19.048h-5.714V20.238h5.714zM131.871 14.938a3.562 3.562 0 00-.775-3.89 3.571 3.571 0 10.775 3.89z"
    />
    <path
      fill={isColour ? '#0073B1' : 'currentColor'}
      fillRule="evenodd"
      d="M157.103 5h-34.088C121.385 5 120 6.29 120 7.878V42.12c0 1.59.909 2.88 2.539 2.88h34.088c1.632 0 3.373-1.29 3.373-2.88V7.878A2.868 2.868 0 00157.103 5zm-21.865 15.238h5.385v2.745h.059c.821-1.48 3.246-2.983 6.244-2.983 5.754 0 7.36 3.055 7.36 8.714v10.572h-5.715v-9.53c0-2.533-1.011-4.756-3.377-4.756-2.872 0-4.242 1.945-4.242 5.137v9.149h-5.714V20.238zm-9.524 19.048h5.715V20.238h-5.715v19.048zm6.429-25.714a3.58 3.58 0 01-1.045 2.525 3.577 3.577 0 01-2.525 1.047 3.568 3.568 0 01-3.571-3.57 3.572 3.572 0 013.569-3.571 3.57 3.57 0 013.572 3.569z"
    />
    <path
      fill={isColour ? '#0073B1' : 'currentColor'}
      d="M114.286 39.286h-5.334v-2.269h-.057c-1.186 1.376-3.247 2.507-6.741 2.507-4.762 0-8.863-3.583-8.863-9.74 0-5.867 4.042-9.784 9.042-9.784 3.096 0 5.017 1.101 6.181 2.507h.057V10.715h5.715v28.571zm-10.517-15c-3.266 0-5.102 2.33-5.102 5.427 0 3.099 1.836 5.525 5.102 5.525 3.271 0 5.183-2.363 5.183-5.525 0-3.25-1.912-5.427-5.183-5.427zM90.783 36.217c-1.349 1.747-4.126 3.307-8.61 3.307-5.98 0-9.79-3.962-9.79-10.185 0-5.53 3.45-9.339 9.958-9.339 5.62 0 9.088 3.692 9.088 10.185 0 .648-.105 1.292-.105 1.292h-13.55l.032.588c.375 1.642 1.615 3.65 4.46 3.65 2.484 0 4.187-1.336 4.957-2.381l3.562 2.883h-.002zm-4.853-8.361c.038-2.008-1.437-4.046-3.76-4.046-2.766 0-4.242 2.16-4.397 4.048h8.157v-.002z"
    />
    <path
      fill={isColour ? '#0073B1' : 'currentColor'}
      d="M72.38 20.238h-6.666l-6.666 7.62V10.713h-5.715v28.572h5.715v-9.524l7.045 9.524h6.747l-8.078-10.414 7.619-8.634zm-41.904 0h5.385v2.745h.059c.82-1.48 3.246-2.983 6.244-2.983 5.754 0 7.36 3.651 7.36 8.714v10.572h-5.715v-9.53c0-2.804-1.01-4.756-3.377-4.756-2.872 0-4.242 2.274-4.242 5.137v9.149h-5.714V20.238zm-9.524 19.048h5.715V20.238h-5.715v19.048zM23.812 17a3.429 3.429 0 10.161-6.855A3.429 3.429 0 0023.811 17zM5.713 10.714H0v28.572h17.143V33.57H5.714V10.714z"
    />
  </svg>
);
