import React from 'react';
import { ThemeProvider as BaseProvider } from 'styled-components';
import { min } from 'lodash';
import { canUseDOM } from '@helpers/executionEnvironment';
import createTheme from '@ui/theme';
import { getThemeExtension } from '@ui/theme/themes';
import { Theme } from '@ui/types';

interface Props {
  children: React.ReactNode;
  theme?: Theme;
}

/**
 * Provides app theme and allows theme switching
 */
const ThemeProvider = ({ children, theme }: Props) => {
  const width = canUseDOM ? window.innerWidth : 360;
  const scale = min([1, width / 360]) as number;

  const defaultTheme = createTheme(scale);

  const compiledTheme = {
    ...defaultTheme,
    ...(theme && getThemeExtension(theme?.name, scale)),
    themeName: theme?.name,
  };

  return <BaseProvider theme={compiledTheme}>{children}</BaseProvider>;
};

export default ThemeProvider;
