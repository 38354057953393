import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { BodyText, HeadingLabel, HeadingSmall } from '../Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "TextAreastyled__Wrapper",
  componentId: "sc-hxafyb-0"
})(["display:flex;flex-direction:column;"]);
export var commonInputStyles = css(["resize:", ";overflow:auto;box-sizing:border-box;min-width:", ";background-color:", ";color:", ";border:none;border-radius:", "px;font-family:", ";font-size:", "px;padding:", "px;outline:none;width:100%;", " &:hover{outline:none;}&:active,&:focus{outline:", " solid 1px;}&:disabled{opacity:0.6;color:", ";}&::placeholder{font-family:", ";font-style:", ";font-size:", "px;", "}"], function (props) {
  return props.isResizable ? 'vertical' : 'none';
}, function (props) {
  return props.minWidth ? "".concat(props.minWidth, "px") : 'initial';
}, function (props) {
  return props.isLight ? props.theme.vvlgrey : props.theme.vdgrey;
}, function (props) {
  return props.isLight ? props.theme.black : props.theme.white;
}, function (props) {
  return props.theme.inputBorderRadius;
}, function (props) {
  return props.theme.bodyTextFont;
}, function (props) {
  return props.theme.bodyTextFontSize;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.isInBox && "background-color: ".concat(props.isLight ? props.theme.vlgrey : props.theme.dgrey, ";");
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.grey;
}, function (props) {
  return props.theme.placeholderTextFont;
}, function (props) {
  return props.theme.placeholderTextFontStyle;
}, function (props) {
  return props.theme.placeholderTextFontSize;
}, function (props) {
  return props.isLight && props.isInBox && "color: ".concat(props.theme.grey, ";");
});
export var AutoSizeInput = styled(TextareaAutosize).withConfig({
  displayName: "TextAreastyled__AutoSizeInput",
  componentId: "sc-hxafyb-1"
})(["min-height:", "px;", ""], function (props) {
  return props.theme.bodyTextLineHeight + props.theme.largeSpacer;
}, commonInputStyles);
export var Input = styled.textarea.withConfig({
  displayName: "TextAreastyled__Input",
  componentId: "sc-hxafyb-2"
})(["height:", "px;", ""], function (props) {
  return props.numLines * props.theme.bodyTextLineHeight + props.theme.largeSpacer;
}, commonInputStyles);
export var Label = styled(HeadingLabel).withConfig({
  displayName: "TextAreastyled__Label",
  componentId: "sc-hxafyb-3"
})(["margin-bottom:", "px;"], function (props) {
  return props.theme.spacer;
});
export var BodyTextWithLineBreaks = styled(BodyText).withConfig({
  displayName: "TextAreastyled__BodyTextWithLineBreaks",
  componentId: "sc-hxafyb-4"
})(["white-space:pre-line;"]);
export var HeadingSmallWithLineBreaks = styled(HeadingSmall).withConfig({
  displayName: "TextAreastyled__HeadingSmallWithLineBreaks",
  componentId: "sc-hxafyb-5"
})(["white-space:pre-line;"]);