import React, { useState } from 'react';
import { useNavigate } from '@hooks/useRouting';
import { useDispatch } from '@hooks/useStore';
import useTheme from '@hooks/useTheme';
import TextLinkButton from '@ui/components/TextLink/TextLinkButton';
import Link from '@ui/components/Link';
import { BodyText } from '@ui/components/Typography';
import TitleBlock from '@ui/components/TitleBlock';
import { truncateText } from '@helpers/truncateText';
import { requestResetPassword, resetPassword } from 'store/auth';
import FormPage from 'components/app/FormPage/FormPage';
import RequestPasswordResetForm from 'components/account/RequestPasswordResetForm';
import ResetPasswordForm from 'components/account/ResetPasswordForm';
import InviteCodeForm from 'components/account/InviteCodeForm';

enum Stage {
  REQUEST_CODE,
  ENTER_CODE,
  ENTER_PASSWORD,
}

const Forgot = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [stage, setStage] = useState(Stage.REQUEST_CODE);
  const [email, setEmail] = useState<string>();
  const [code, setCode] = useState<string>();
  const navigate = useNavigate();

  const initiatePasswordResetFlow = (emailValue?: string) => {
    setStage(Stage.ENTER_CODE);
    setEmail(emailValue);
    dispatch(requestResetPassword(emailValue));
  };

  const returnToRequestPasswordReset = async () => {
    setStage(Stage.REQUEST_CODE);
  };

  const handleVerificationCodeSubmit = (newPassword: string) => {
    dispatch(resetPassword({ email, code, newPassword }));
  };

  const handleOnCorrectEntry = () => {
    setStage(Stage.ENTER_PASSWORD);
  };

  const handleOnSubmitVerificationCode = async (codeValue?: string) => {
    setStage(Stage.ENTER_PASSWORD);
    setCode(codeValue);
    return true;
  };

  const handleBackButtonClick = () => {
    if (stage === Stage.ENTER_CODE) setStage(Stage.REQUEST_CODE);
    if (stage === Stage.ENTER_PASSWORD) setStage(Stage.ENTER_CODE);
    navigate(-1);
  };

  return (
    <>
      <FormPage handleBackButtonClick={handleBackButtonClick}>
        {stage === Stage.REQUEST_CODE && (
          <>
            <TitleBlock
              title="Change Password"
              subtitle="Enter e-mail address"
              hasNoBox
            />
            <RequestPasswordResetForm
              onSubmit={initiatePasswordResetFlow}
              email={email}
            />
          </>
        )}

        {stage === Stage.ENTER_CODE && (
          <>
            <TitleBlock
              title="Change Password"
              subtitle="Enter invite code"
              hasNoBox
            />
            <BodyText>
              Your invite code has been sent to {truncateText(email, 40)}
            </BodyText>
            <InviteCodeForm
              resendCode={returnToRequestPasswordReset}
              onCorrectEntry={handleOnCorrectEntry}
              onSubmitCode={handleOnSubmitVerificationCode}
            />
          </>
        )}

        {stage === Stage.ENTER_PASSWORD && (
          <>
            <TitleBlock
              title="Change Password"
              subtitle="Choose new password"
              hasNoBox
            />
            <ResetPasswordForm onSubmit={handleVerificationCodeSubmit} />
            <TextLinkButton
              text="Request another code"
              onPress={returnToRequestPasswordReset}
            />
            <Link to="/signin">Try to sign in again</Link>
            <BodyText>
              {'Not a member yet? '}
              <Link to="/signup">{`Join ${theme.title}`}</Link>
            </BodyText>
          </>
        )}
      </FormPage>
    </>
  );
};

export default Forgot;
