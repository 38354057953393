import { env } from '@helpers/stage';

const keys = {
  ORIGINAL: {
    live: 'pk_live_l92nhJl5GPbFbTDaoE8V9wdY',
    test: 'pk_test_R4BHiT5158rbAdGnSkoJkmFo',
  },
  US: {
    live: 'pk_live_51HIWnOHz9WwtyR9L69VVyuJzRH3wzfxsK5avCN0BsRkN7lwqmROUWk4m0VVDpL5I40nJsFrEUpJYRL37NQj3btqM00xataNs38',
    test: 'pk_test_51HIWnOHz9WwtyR9LDFxY3Ol2rtF5sAQ9d6raFWnXtyruT4VkEMDUJku7RnFSJR1FKIA2w5RvzVL4whfoqTOVgp2e00E3rk3H8r',
  },
};

const clientIds = {
  ORIGINAL: {
    live: 'ca_F5JuqvzSeGsQVuDNOstllxJ1sy0xxPgs',
    test: 'ca_F5JuQXRazfrSxVXGtFgvX3E4WgnVbq4U',
  },
};

/**
 * Gets stripe client id
 */
export const clientId = () =>
  clientIds.ORIGINAL[env() === 'prod' ? 'live' : 'test'];

/**
 * Gets stripe key
 */
export const getStripeKey = (type: 'ORIGINAL' | 'US') =>
  keys[type][env() === 'prod' ? 'live' : 'test'];
