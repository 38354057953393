import styled from 'styled-components';
import { Heading } from '../Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "Tabsstyled__Wrapper",
  componentId: "sc-a8xp8-0"
})(["display:grid;grid-auto-flow:column;grid-gap:", "px;width:fit-content;align-items:baseline;"], function (props) {
  return props.theme.largeSpacer;
});
export var Tab = styled(Heading).withConfig({
  displayName: "Tabsstyled__Tab",
  componentId: "sc-a8xp8-1"
})(["padding:", "px 0;cursor:pointer;white-space:nowrap;", " ", " &:focus{outline:none;}"], function (props) {
  return props.theme.largeSpacer - 4;
}, function (props) {
  return props.isActive && "color: ".concat(props.theme.tertiary, ";\n     cursor: default;\n     border-bottom: ").concat(props.theme.primary, " solid ").concat(props.theme.smallSpacer, "px;");
}, function (props) {
  return props.isDisabled && "color: ".concat(props.theme.grey, ";\n     pointer-events: none;\n     cursor: default;");
});