import styled from 'styled-components';
import { HeadingSmall } from '@ui/components/Typography';
export var UserNameAndProfileWrapper = styled.div.withConfig({
  displayName: "AccountMenustyled__UserNameAndProfileWrapper",
  componentId: "sc-1bkgrap-0"
})(["display:flex;justify-content:flex-end;align-items:center;grid-gap:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var UserProfile = styled(HeadingSmall).withConfig({
  displayName: "AccountMenustyled__UserProfile",
  componentId: "sc-1bkgrap-1"
})(["color:", ";justify-self:end;overflow:hidden;white-space:nowrap;"], function (props) {
  return props.theme.white;
});