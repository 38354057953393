import React from 'react';
import useStripeSubscription, {
  StripeSubscriptionProps,
} from '@hooks/useStripeSubscription';
import useUser from '@hooks/useUser';
import Button from '@ui/components/Button';

interface Props extends StripeSubscriptionProps {
  buttonText?: string;
  dark?: boolean;
}

const SubscriptionBtn = (props: Props) => {
  const { hasFailedPayments } = useUser();
  const { loading, onClick, disabled, subscription } = useStripeSubscription();

  let text = props.buttonText;
  if (!text) {
    if (subscription?.product_name !== 'Basic') text = 'Update';
    else if (hasFailedPayments) text = 'Reactivate';
    else text = 'Subscribe';
  }
  return (
    <Button
      text={text}
      loading={loading}
      disabled={disabled}
      squishy
      dark={props.dark}
      onPress={() => onClick(props)}
    />
  );
};

export default SubscriptionBtn;
