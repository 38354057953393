import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg viewBox="0 0 160 50" fill="none" {...props}>
    <path
      fill={isColour ? '#FF4500' : 'currentColor'}
      d="M138.09 20.38a3.16 3.16 0 100-6.33 3.16 3.16 0 000 6.33z"
    />
    <path
      fill={isColour ? '#222' : 'currentColor'}
      d="M92.92 30.7c1.55 0 2.45-1.17 2.39-2.3-.03-.6-.1-.98-.15-1.28-.96-4.12-4.3-7.16-8.27-7.16-4.71 0-8.53 4.26-8.53 9.52 0 5.25 3.82 9.51 8.53 9.51a8.4 8.4 0 006.6-2.77c.74-.84.6-2.12-.3-2.75-.75-.5-1.67-.33-2.36.24-.66.57-1.9 1.7-3.94 1.7-2.38 0-4.41-2.06-4.86-4.77h10.89v.06zm-6.06-7.13c2.15 0 4 1.67 4.69 3.97h-9.37c.69-2.33 2.53-3.97 4.68-3.97zM78.54 21.84c0-.9-.66-1.61-1.5-1.76a6.9 6.9 0 00-6.44 2.32v-.23c0-1.1-.9-1.8-1.79-1.8-.98 0-1.79.81-1.79 1.8v14.61c0 .96.72 1.8 1.67 1.85 1.05.06 1.91-.74 1.91-1.79V29.3c0-3.35 2.51-6.09 5.97-5.7h.36c.9-.06 1.6-.84 1.6-1.76zM139.88 23.75a1.8 1.8 0 00-3.58 0v13.1c0 .98.8 1.78 1.79 1.78.98 0 1.79-.8 1.79-1.79v-13.1zM113.27 13.84a1.8 1.8 0 00-3.58 0v7.97a6.18 6.18 0 00-4.57-1.82c-4.71 0-8.53 4.26-8.53 9.52 0 5.25 3.82 9.51 8.53 9.51 1.82 0 3.37-.62 4.6-1.85a1.8 1.8 0 003.55-.33v-23zm-8.12 21.63c-2.74 0-4.95-2.65-4.95-5.97 0-3.28 2.2-5.96 4.95-5.96 2.74 0 4.95 2.65 4.95 5.96 0 3.32-2.23 5.97-4.95 5.97zM131.94 13.84c0-.98-.8-1.79-1.79-1.79-.98 0-1.79.8-1.79 1.8v7.96a6.19 6.19 0 00-4.56-1.82c-4.72 0-8.54 4.26-8.54 9.52 0 5.25 3.82 9.51 8.54 9.51 1.82 0 3.37-.62 4.59-1.85a1.8 1.8 0 003.55-.33v-23zm-8.14 21.63c-2.75 0-4.96-2.65-4.96-5.97 0-3.28 2.21-5.96 4.96-5.96 2.74 0 4.95 2.65 4.95 5.96 0 3.32-2.2 5.97-4.95 5.97zM148.94 36.87v-13.3h1.59c.83 0 1.58-.63 1.64-1.5a1.6 1.6 0 00-1.58-1.7h-1.65v-2.5c0-.96-.71-1.79-1.66-1.85a1.79 1.79 0 00-1.91 1.79v2.6h-1.5c-.83 0-1.58.62-1.64 1.49a1.6 1.6 0 001.58 1.7h1.53v13.3c0 .99.8 1.8 1.78 1.8 1.05-.07 1.82-.84 1.82-1.83z"
    />
    <path
      fill={isColour ? '#FF4500' : 'currentColor'}
      d="M26.27 25a2.6 2.6 0 100 5.2 2.6 2.6 0 000-5.2zM32.03 36.4c.98 0 4.39-.12 6.18-1.91a.73.73 0 000-.96.67.67 0 00-.96 0c-1.1 1.14-3.52 1.52-5.22 1.52-1.7 0-4.09-.38-5.22-1.52a.67.67 0 00-.96 0 .67.67 0 000 .96c1.76 1.76 5.17 1.9 6.18 1.9zM35.13 27.63a2.6 2.6 0 105.2 0 2.6 2.6 0 00-5.2 0z"
    />
    <path
      fill={isColour ? '#FF4500' : 'currentColor'}
      fillRule="evenodd"
      d="M57 25a25 25 0 11-50 0 25 25 0 0150 0zm-11.96-3.64c2 0 3.64 1.61 3.64 3.64 0 1.5-.9 2.77-2.15 3.34.06.36.09.75.09 1.1 0 5.64-6.54 10.18-14.59 10.18-8.06 0-14.59-4.57-14.59-10.17 0-.4.03-.75.1-1.1a3.63 3.63 0 011.48-6.96c.99 0 1.88.39 2.54 1.01a18.07 18.07 0 019.9-3.1l1.85-8.7a.58.58 0 01.27-.43c.12-.09.3-.12.48-.09l6.05 1.29a2.6 2.6 0 11-.26 1.25l-5.43-1.16-1.67 7.81c3.81.15 7.25 1.31 9.75 3.1a3.66 3.66 0 012.54-1.01z"
    />
    <path
      fill={isColour ? '#fff' : 'transparent'}
      d="M48.68 25a3.64 3.64 0 00-6.18-2.63 18.13 18.13 0 00-9.75-3.1l1.67-7.81 5.43 1.16a2.6 2.6 0 10.26-1.25l-6.05-1.29a.63.63 0 00-.48.1.58.58 0 00-.27.41l-1.85 8.71a18.07 18.07 0 00-9.9 3.1 3.63 3.63 0 10-4.03 5.94 6.6 6.6 0 00-.09 1.1c0 5.61 6.53 10.18 14.59 10.18 8.05 0 14.59-4.54 14.59-10.17 0-.36-.03-.75-.1-1.1A3.68 3.68 0 0048.69 25zm-25 2.6a2.6 2.6 0 115.2 0 2.6 2.6 0 01-5.2 0zm14.53 6.89c-1.8 1.79-5.2 1.9-6.18 1.9-1.01 0-4.42-.14-6.18-1.9a.67.67 0 010-.96.67.67 0 01.96 0c1.13 1.14 3.52 1.52 5.22 1.52 1.7 0 4.12-.38 5.22-1.52a.67.67 0 01.96 0c.23.27.23.69 0 .96zm-.48-4.27a2.6 2.6 0 110-5.2 2.6 2.6 0 010 5.2z"
    />
  </svg>
);
