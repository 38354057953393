import React from 'react';
import { Wrapper, Tab } from './Tabs.styled';

interface TabItem {
  slug: string;
  label: string;
}

interface Props {
  tabs: TabItem[];
  selectedTab: string;
  setSelectedTab: (slug: string) => void;
}

const Tabs = ({ tabs, selectedTab, setSelectedTab }: Props) => (
  <Wrapper>
    {tabs?.map(tab => (
      <Tab
        key={tab.slug}
        isActive={tab.slug === selectedTab}
        onClick={() => setSelectedTab(tab.slug)}
      >
        {tab.label}
      </Tab>
    ))}
  </Wrapper>
);

export default Tabs;
