import React, { useEffect } from 'react';
import { useLocation, useNavigateWithSearch } from '@hooks/useRouting';
import useUser from '@hooks/useUser';

const Blockers = () => {
  const navigate = useNavigateWithSearch();
  const { pathname, search } = useLocation();
  const user = useUser();

  const whitelistedVerifyEmailBlocker = ['/user-profile', '/confirmation'];
  const showEmailVerificationBlocker =
    !whitelistedVerifyEmailBlocker.includes(pathname) &&
    user.confirmed === 0 &&
    user.authorizer === 'cognito';

  useEffect(() => {
    if (pathname !== '/invite-code' && showEmailVerificationBlocker) {
      navigate('/invite-code', { redirect_to: `${pathname}${search}` });
    }
  }, [pathname, showEmailVerificationBlocker, search]);

  return null;
};

export default React.memo(Blockers);
