import { Thumbnail, ThumbnailSize } from '@ui/types';

const findAreThumbnailsOfTheNewFormat = (thumbnails: Thumbnail) =>
  thumbnails?.square;

const findAreLegacyThumbnails = thumbnails =>
  !findAreThumbnailsOfTheNewFormat(thumbnails);

export const getThumbnailBySize = (
  thumbnails: Thumbnail | undefined,
  size: ThumbnailSize
): string | undefined => {
  const thumbnailsMap = {
    small: thumbnails?.small,
    medium: thumbnails?.medium,
    square: thumbnails?.square,
  };

  const areLegacyThumbnails = findAreLegacyThumbnails(thumbnails);

  if (areLegacyThumbnails) {
    return undefined;
  }

  return thumbnailsMap[size];
};
