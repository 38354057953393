import styled, { keyframes } from 'styled-components';
import { HeadingLabel } from '../Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "Loaderstyled__Wrapper",
  componentId: "sc-n0qsmz-0"
})(["display:grid;grid-gap:", "px;justify-items:center;justify-content:center;align-content:center;", ""], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.isHorizontal && "grid-auto-flow: column;";
});
export var Lines = styled.div.withConfig({
  displayName: "Loaderstyled__Lines",
  componentId: "sc-n0qsmz-1"
})(["height:", "px;display:flex;"], function (props) {
  return props.small ? 16 : 24;
});
var expand = keyframes(["0%{transform:scaleY(1);}25%{transform:scaleY(0.5);}50%{transform:scaleY(0.2);}"]);
export var Line = styled.div.withConfig({
  displayName: "Loaderstyled__Line",
  componentId: "sc-n0qsmz-2"
})(["animation:", " 800ms ease-in-out infinite alternate;transform-origin:bottom;display:inline-block;margin:0 1px;width:", "px;height:", "px;border-radius:1px;animation-delay:", "ms;background:", ";", " ", ""], expand, function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.small ? 16 : 24;
}, function (props) {
  return props.delay || 0;
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.color === 'black' && "background: ".concat(props.theme.black);
}, function (props) {
  return props.color === 'white' && "background: ".concat(props.theme.white);
});
export var Message = styled(HeadingLabel).withConfig({
  displayName: "Loaderstyled__Message",
  componentId: "sc-n0qsmz-3"
})(["color:", ";", ""], function (props) {
  return props.theme.lgrey;
}, function (props) {
  return (props.color === 'black' || props.color === 'dark') && "color: ".concat(props.theme.dgrey);
});