import React, { FC } from 'react';
import { use100vh } from 'react-div-100vh';
import useDevice from '@hooks/useDevice';
import Scrollbars from 'components/app/Scrollbars';
import Page from 'components/app/Page';
import { Wrapper, Content } from './AppLandingPage.styled';

interface Props {
  backgroundImageUrl: string;
}

const AppLandingPage: FC<Props> = props => {
  const { isMobile } = useDevice();
  const height = use100vh();

  return (
    <Page>
      <Wrapper
        backgroundImageUrl={props.backgroundImageUrl}
        isMobile={isMobile}
        height={height || 0}
      >
        <Scrollbars>
          <Content isMobile={isMobile}>{props.children}</Content>
        </Scrollbars>
      </Wrapper>
    </Page>
  );
};

export default AppLandingPage;
