import { SVGProps } from 'react';
import { PLATFORM } from '@ui/consts';

import Icon7digital from './Icon7digital';
import IconAmazon from './IconAmazon';
import IconAmazonMusic from './IconAmazonMusic';
import IconApple from './IconApple';
import IconAppleMusic from './IconAppleMusic';
import IconBandcamp from './IconBandcamp';
import IconBandsintown from './IconBandsintown';
import IconBeatchain from './IconBeatchain';
import IconBeatport from './IconBeatport';
import IconDeezer from './IconDeezer';
import IconFacebook from './IconFacebook';
import IconEmail from './IconEmail';
import IconGoogle from './IconGoogle';
import IconGoogleMusic from './IconGoogleMusic';
import IconGooglePlay from './IconGooglePlay';
import IconInstagram from './IconInstagram';
import IconItunesStore from './IconItunesStore';
import IconJoox from './IconJoox';
import IconLinkedin from './IconLinkedin';
import IconNapster from './IconNapster';
import IconPandora from './IconPandora';
import IconReddit from './IconReddit';
import IconRhapsody from './IconRhapsody';
import IconShazam from './IconShazam';
import IconSongkick from './IconSongkick';
import IconSoundcloud from './IconSoundcloud';
import IconSoundtrack from './IconSoundtrack';
import IconSpinrilla from './IconSpinrilla';
import IconSpotify from './IconSpotify';
import IconStripe from './IconStripe';
import IconTidal from './IconTidal';
import IconTiktok from './IconTiktok';
import IconTwitch from './IconTwitch';
import IconTwitter from './IconTwitter';
import IconYandex from './IconYandex';
import IconYoutube from './IconYoutube';
import IconYoutubeMusic from './IconYoutubeMusic';

export interface IconProps extends SVGProps<SVGSVGElement> {
  isColour?: boolean;
  tiled?: string;
  width?: number | string;
  height?: number | string;
  color?: string;
  platform?: string;
}

const icons = {
  [PLATFORM.SEVEN_DIGITAL]: Icon7digital,
  [PLATFORM.AMAZON]: IconAmazon,
  [PLATFORM.AMAZON_MUSIC]: IconAmazonMusic,
  [PLATFORM.APPLE]: IconApple,
  [PLATFORM.APPLE_MUSIC]: IconAppleMusic,
  [PLATFORM.BANDCAMP]: IconBandcamp,
  [PLATFORM.BANDSINTOWN]: IconBandsintown,
  [PLATFORM.BEATCHAIN]: IconBeatchain,
  [PLATFORM.BEATPORT]: IconBeatport,
  [PLATFORM.DEEZER]: IconDeezer,
  [PLATFORM.FACEBOOK]: IconFacebook,
  email: IconEmail,
  [PLATFORM.GOOGLE]: IconGoogle,
  [PLATFORM.GOOGLE_MUSIC]: IconGoogleMusic,
  [PLATFORM.GOOGLE_PLAY]: IconGooglePlay,
  [PLATFORM.INSTAGRAM]: IconInstagram,
  [PLATFORM.ITUNES_STORE]: IconItunesStore,
  [PLATFORM.JOOX]: IconJoox,
  [PLATFORM.LINKEDIN]: IconLinkedin,
  [PLATFORM.NAPSTER]: IconNapster,
  [PLATFORM.PANDORA]: IconPandora,
  [PLATFORM.REDDIT]: IconReddit,
  [PLATFORM.RHAPSODY]: IconRhapsody,
  [PLATFORM.SHAZAM]: IconShazam,
  [PLATFORM.SONGKICK]: IconSongkick,
  [PLATFORM.SOUNDCLOUD]: IconSoundcloud,
  [PLATFORM.SOUNDTRACK_YOUR_BAND]: IconSoundtrack,
  [PLATFORM.SPINRILLA]: IconSpinrilla,
  [PLATFORM.SPOTIFY]: IconSpotify,
  [PLATFORM.SPOTIFY_FOR_ARTISTS]: IconSpotify,
  [PLATFORM.STRIPE]: IconStripe,
  [PLATFORM.TIDAL]: IconTidal,
  [PLATFORM.TIKTOK]: IconTiktok,
  [PLATFORM.TWITCH]: IconTwitch,
  [PLATFORM.TWITTER]: IconTwitter,
  [PLATFORM.YANDEX]: IconYandex,
  [PLATFORM.YOUTUBE]: IconYoutube,
  [PLATFORM.YOUTUBE_MUSIC]: IconYoutubeMusic,
};

export default icons;
