import { WithDataInjectedProps } from '@ui/types';
import { formatNumbersNoDecimalPlaces } from '@helpers/formatStreamingNumbers';

/**
 * Returns account royalty balance
 */
const useRoyaltyBalance = (props: WithDataInjectedProps) => {
  const balance = props.data?.total;

  const value = formatNumbersNoDecimalPlaces(balance || 0);

  const accountBalance = !value ?? props.isLoading ? '€-' : `€${value}`;

  return accountBalance;
};

export default useRoyaltyBalance;
