import { useDispatch } from '@hooks/useStore';
import * as uiStore from 'store/ui';
import { MediaLibraryProps, MediaLibraryModalContent } from '@ui/types';

/**
 * Opens up media library
 */
const useMediaLibrary = () => {
  const dispatch = useDispatch();

  return (componentProps?: MediaLibraryProps) =>
    dispatch(
      uiStore.setMediaLibraryModalContent({
        isVisible: true,
        hasGoBackButton: componentProps?.hasGoBackButton,
        componentProps,
        modalTitle: componentProps?.isPreview ? 'Media Item' : 'Media Library',
      } as MediaLibraryModalContent)
    );
};

export default useMediaLibrary;
