import React from 'react';
import { useNavigate } from '@hooks/useRouting';
import useDevice from '@hooks/useDevice';
import Logo from '@ui/components/Logo';
import { Feature } from '@ui/types/feature';
import AccountToggle from 'components/app/AccountToggle';
import Navigation from 'components/app/Navigation';
import MediaLibraryToggle from 'components/app/MediaLibraryToggle';
import NewsFeedToggle from 'components/app/NewsFeedToggle';
import useTheme from '@hooks/useTheme';
import WithPullToRefresh from '../WithPullToRefresh';
import {
  AppHeaderBarMain,
  AppHeaderBarWrapper,
  LogoWrapper,
  LogoWrapperInner,
  NavigationWrapper,
  TogglesWrapper,
} from './AppHeaderBar.styles';

interface Props {
  toggleNavMenu: (isExpanded: boolean) => void;
  navMenuOpen: boolean;
  collapseNavs: () => void;
  onToggle: () => void;
  activeFeature?: Feature;
}

const AppHeaderBar = (props: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useDevice();

  return (
    <WithPullToRefresh>
      <AppHeaderBarWrapper isMobile={isMobile}>
        <AppHeaderBarMain
          isMobile={isMobile}
          data-testid="app-header"
          id="beatchain-logo"
        >
          <NavigationWrapper>
            <Navigation
              toggleNavMenu={props.toggleNavMenu}
              navMenuOpen={props.navMenuOpen}
            />
          </NavigationWrapper>
          {!isMobile && (
            <LogoWrapper>
              <LogoWrapperInner
                onClick={() => {
                  props.collapseNavs();
                  navigate('/welcome');
                }}
              >
                <Logo
                  color="primary"
                  type={theme.useFullLogoInHeader ? 'full' : 'icon'}
                />
              </LogoWrapperInner>
            </LogoWrapper>
          )}
          <TogglesWrapper>
            <NewsFeedToggle />
            <MediaLibraryToggle />
            <AccountToggle
              onToggle={props.onToggle}
              activeFeature={props.activeFeature}
            />
          </TogglesWrapper>
        </AppHeaderBarMain>
      </AppHeaderBarWrapper>
    </WithPullToRefresh>
  );
};

export default React.memo(AppHeaderBar);
