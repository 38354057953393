import React, { useState } from 'react';
import { format } from 'date-fns';
import useUser from '@hooks/useUser';
import useModal from '@hooks/useModal';
import Checkbox from '@ui/components/Checkbox';
import Dialogue from '@ui/components/Dialogue';
import TitleBlock from '@ui/components/TitleBlock';
import OptionTiles from 'components/common/OptionTiles';
import PageLoader from 'components/app/PageLoader';

import {
  Wrapper,
  DowngradeWrapper,
  DowngradeMain,
  ConfirmationBlock,
  CheckboxWrapper,
  ButtonsWrapper,
  ReturnButton,
  ConfirmButton,
} from './Downgrade.styled';

interface Props {
  loading: boolean;
  onConfirm: (params: any) => void;
  handleReturn: () => void;
}

const Downgrade = (props: Props) => {
  const { subscription, hasFailedPayments } = useUser();
  const [understood, setUnderstood] = useState(false);
  const openModal = useModal();

  const [reasonForDowngrade, setReasonForDowngrade] = useState({
    name: '',
    hasFeedback: false,
  });
  const [feedback, setFeedback] = useState({
    name: '',
    message: '',
  });

  const onConfirmSuccess = () => {
    const downgradeText = `Your Beatchain subscription will be terminated. You will still be able to access previously paid features until the ${format(
      subscription.expiry_ts,
      'dd MMMM yyyy'
    )}. 
        <p></p>
        On this date you'll lose access to the Beatchain toolset, and the following will be taken down or cancelled:
        <p></p>
        <ul>
          <li>All Releases submitted after <a href="https://help.beatchain.com/en/articles/5162535-faq-subscription-update-april-2021#h_7be13d0e65" target="_blank">April 23rd 2021*</a></li>
          <li>HypeLinks, Presaves and Webpages</li>
          <li>Scheduled Launchpad Posts</li>
          <li>Fanbuilder Campaigns</li>
        </ul>
        `;
    openModal({
      Component: Dialogue,
      modalSize: 'dialogue',
      modalTitle: `Subscription Update`,
      modalText: hasFailedPayments
        ? 'Your Beatchain subscription will be terminated'
        : downgradeText,
      componentProps: {
        actionButton: {
          text: 'Cancel Subscription',
          clickHandler: () =>
            props.onConfirm({
              ...reasonForDowngrade,
              feedback,
            }),
        },
        cancelButtonText: 'Go Back',
      },
    });
  };

  const isDisabled =
    !understood ||
    !reasonForDowngrade.name ||
    (reasonForDowngrade.hasFeedback && !feedback.message);

  const reasons = [
    {
      name: 'notGoodValue',
      title: 'Subscription was not good value',
      subtitle:
        'You found similar tools elsewhere for less? Let us know about them!',
      hasFeedback: true,
    },
    {
      name: 'cannotAffordIt',
      title: "Can't afford the subscription",
      subtitle:
        'You can still get free tips and tricks from the <a href="https://proxy.beatchain.com/blog" target="_blank">Beatchain blog</a> and on our socials',
      hasFeedback: false,
    },
    {
      name: 'notNeeded',
      title: "I don't need these features",
      subtitle: "We're always building new features so stay in touch",
      hasFeedback: false,
    },
    {
      name: 'notFitForPurpose',
      title: 'Our tools were not what you hoped',
      subtitle: 'Let us know why',
      hasFeedback: true,
    },
  ];

  return (
    <Wrapper data-testid="cancel-plan">
      {props.loading && <PageLoader message="updating" isFullScreen />}
      <DowngradeWrapper>
        <DowngradeMain>
          <TitleBlock
            title="Cancelling your plan?"
            subtitle="Please let us know why"
          />
          <OptionTiles
            options={reasons?.map(item => ({
              key: item.name,
              name: item.name,
              title: item.title,
              subtitle: item.subtitle,
              feedback: item.hasFeedback ? feedback : undefined,
              setFeedback,
              isChecked: item.name === reasonForDowngrade.name,
              setIsChecked: (_, isChecked) => {
                setFeedback({
                  name: '',
                  message: '',
                });
                setReasonForDowngrade(
                  isChecked ? item : { name: '', hasFeedback: false }
                );
              },
            }))}
          />
          <ConfirmationBlock>
            <CheckboxWrapper>
              <Checkbox
                name="understood"
                text="I understand that my features will disappear"
                handleChange={(name, isTicked) => setUnderstood(isTicked)}
                isTicked={understood}
              />
            </CheckboxWrapper>
            <ButtonsWrapper>
              <ReturnButton onPress={props.handleReturn} />
              <ConfirmButton disabled={isDisabled} onPress={onConfirmSuccess} />
            </ButtonsWrapper>
          </ConfirmationBlock>
        </DowngradeMain>
      </DowngradeWrapper>
    </Wrapper>
  );
};

export default Downgrade;
