import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg viewBox="0 0 160 50" fill="none" {...props}>
    <path
      fill={isColour ? '#FF0000' : 'currentColor'}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.545 7.136a7.357 7.357 0 011.897 3.294c1.223 4.59 1.229 14.16 1.229 14.16s0 9.572-1.229 14.16a7.357 7.357 0 01-5.187 5.201c-4.572 1.228-22.92 1.228-22.92 1.228s-18.347 0-22.922-1.23a7.357 7.357 0 01-5.184-5.2C0 34.162 0 24.59 0 24.59s0-9.574 1.229-14.159A7.357 7.357 0 016.416 5.23C10.99 4 29.336 4 29.336 4s18.347 0 22.921 1.229a7.357 7.357 0 013.288 1.907zm-16.84 17.458l-15.242 8.822V15.765l15.242 8.829z"
    />
    <path
      fill={isColour ? '#FFF' : 'none'}
      d="M23.463 33.416l15.242-8.822-15.242-8.83v17.652z"
    />
    <path
      fill={isColour ? '#000' : 'currentColor'}
      d="M84.434 19.973c-1.05 5.341-1.845 11.865-2.266 14.554h-.296c-.333-2.776-1.134-9.254-2.22-14.514L76.967 6.936h-8.18v35.295h5.076V13.137l.502 2.715 5.157 26.373h5.077l5.075-26.37.545-2.73v29.104h5.077V6.936H87.03l-2.594 13.037h-.002zm24.581 16.955c-.464.968-1.468 1.642-2.478 1.642-1.174 0-1.636-.926-1.636-3.196v-18.93H99.11v19.263c0 4.756 1.549 6.94 4.993 6.94 2.347 0 4.235-1.051 5.539-3.575h.125l.504 3.153h4.529V16.44h-5.789v20.49h.004v-.002zm16.99-10.306c-1.889-1.389-3.061-2.313-3.061-4.333 0-1.429.67-2.228 2.263-2.228 1.639 0 2.184 1.139 2.222 5.008l4.868-.208c.375-6.268-1.676-8.877-7.007-8.877-4.949 0-7.383 2.228-7.383 6.813 0 4.165 2.013 6.06 5.282 8.537 2.811 2.189 4.448 3.41 4.448 5.173 0 1.345-.841 2.27-2.309 2.27-1.718 0-2.728-1.641-2.472-4.498l-4.908.083c-.751 5.341 1.387 8.454 7.09 8.454 4.994 0 7.593-2.313 7.593-6.938.004-4.208-2.097-5.889-6.626-9.256zm9.818-10.178h5.539V42.23h-5.539V16.444zm2.811-10.178c-2.141 0-3.144.797-3.144 3.573 0 2.86 1.003 3.573 3.144 3.573 2.182 0 3.144-.716 3.144-3.573 0-2.649-.962-3.573-3.144-3.573zm21.35 26.668l-5.075-.252c0 4.541-.502 6.013-2.222 6.013s-2.013-1.64-2.013-6.982v-5.01c0-5.172.333-6.813 2.057-6.813 1.593 0 2.014 1.553 2.014 6.353l5.032-.333c.334-3.998-.168-6.732-1.72-8.288-1.134-1.137-2.852-1.68-5.245-1.68-5.622 0-7.929 3.028-7.929 11.526v3.619c0 8.75 1.97 11.567 7.717 11.567 2.436 0 4.11-.502 5.245-1.597 1.637-1.527 2.266-4.132 2.139-8.126v.003z"
    />
  </svg>
);
