import React from 'react';
import Link from '@ui/components/Link';
import { SmallText } from '@ui/components/Typography';
import { FooterWrapper, FooterMain, Links, Copyright } from './Footer.styled';

interface Props {
  showTerms?: boolean;
}

const Footer = (props: Props) => (
  <FooterWrapper>
    <FooterMain>
      {props.showTerms && (
        <Links>
          <Link to="/privacy-policy" target="_blank">
            <SmallText>Privacy Policy</SmallText>
          </Link>
          <Link to="/terms-and-conditions" target="_blank">
            <SmallText>Terms of Service</SmallText>
          </Link>
        </Links>
      )}
      <Copyright>
        <SmallText data-testid="footer-brand">
          {`© Beatchain Limited ${new Date().getFullYear()}. All rights reserved.`}
        </SmallText>
      </Copyright>
    </FooterMain>
  </FooterWrapper>
);

export default Footer;
