import React from 'react';
import trackEvent from '@helpers/tracking';
import SocialButton from '@ui/components/SocialButton';
import FederatedLoginButton from 'components/account/FederatedLoginButton';

interface Props {
  isSignUp?: boolean;
  setUseEmail: (isEmailSignUp: boolean) => void;
  useEmailButtonText: string;
}

const SignUpOptions = (props: Props) => {
  const handleChooseEmail = () => {
    if (props.isSignUp) {
      trackEvent('Sign Up With Email');
    }
    props.setUseEmail(true);
  };

  const trackFederatedLogin = provider => {
    trackEvent('Sign Up With Federated Login', { provider });
  };

  return (
    <>
      {process.env.REACT_APP_DOMAIN !== 'YOUNG_MONEY' && (
        <>
          <FederatedLoginButton
            isSignUpFlow={props.isSignUp}
            provider="facebook"
            trackFederatedLogin={trackFederatedLogin}
          />
          <FederatedLoginButton
            isSignUpFlow={props.isSignUp}
            provider="google"
            trackFederatedLogin={trackFederatedLogin}
          />
          <FederatedLoginButton
            isSignUpFlow={props.isSignUp}
            provider="apple"
            trackFederatedLogin={trackFederatedLogin}
          />
        </>
      )}
      <SocialButton
        platform="email"
        text={props.useEmailButtonText}
        onPress={() => handleChooseEmail()}
        dark
        large
        darkShadow={false}
        data-testid="signIn-email-button"
      />
    </>
  );
};

export default SignUpOptions;
