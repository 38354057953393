import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';
import { Scrollbars as ReactCustomScrollbars } from 'rc-scrollbars';
var rerender = keyframes(["from{box-shadow:inset rgba(0,0,0,0) 0 0 0;}to{box-shadow:inset rgba(0,0,0,0.0000001) 0 0 0 10px;}"]);
export var StyledCustomScrollBars = styled(ReactCustomScrollbars).withConfig({
  displayName: "Scrollbarsstyled__StyledCustomScrollBars",
  componentId: "sc-3dh300-0"
})(["animation-name:", ";animation-duration:4s;animation-iteration-count:infinite;", " overflow:visible;"], rerender, function (props) {
  return props.autoHeight && 'max-height: none !important;';
});
export var ThumbVertical = styled.div.withConfig({
  displayName: "Scrollbarsstyled__ThumbVertical",
  componentId: "sc-3dh300-1"
})(["background-color:", ";border-radius:", "px;"], function (props) {
  return props.isSubtle ? props.theme.dgrey : rgba(props.theme.tertiary, 0.5);
}, function (props) {
  return props.theme.scrollbarWidth / 2;
});
export var ThumbHorizontal = styled.div.withConfig({
  displayName: "Scrollbarsstyled__ThumbHorizontal",
  componentId: "sc-3dh300-2"
})(["background-color:", ";border-radius:", "px;"], function (props) {
  return rgba(props.theme.tertiary, 0.5);
}, function (props) {
  return props.theme.scrollbarWidth / 2;
});
export var TrackHorizontal = styled.div.withConfig({
  displayName: "Scrollbarsstyled__TrackHorizontal",
  componentId: "sc-3dh300-3"
})(["background-color:", ";z-index:1;bottom:0;height:", "px !important;border-radius:", "px;"], function (props) {
  return rgba(props.theme.dgrey, 0.5);
}, function (props) {
  return props.theme.scrollbarWidth;
}, function (props) {
  return props.theme.scrollbarWidth / 2;
});
export var TrackVertical = styled.div.withConfig({
  displayName: "Scrollbarsstyled__TrackVertical",
  componentId: "sc-3dh300-4"
})(["background-color:", ";height:calc(100% - ", "px);margin-top:", "px;right:", "px;width:", "px !important;z-index:1;border-radius:", "px;"], function (props) {
  return rgba(props.theme.dgrey, 0.5);
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.scrollbarWidth;
}, function (props) {
  return props.theme.scrollbarWidth / 2;
});
export var View = styled.div.withConfig({
  displayName: "Scrollbarsstyled__View",
  componentId: "sc-3dh300-5"
})(["", " ", ";"], function (props) {
  return props.autoHeight && 'max-height: none !important;';
}, function (props) {
  return props.isDesktop && "padding-right: ".concat(props.isContentPaddedForScrollbar ? "".concat(props.theme.scrollbarWidth + props.theme.spacer, "px") : "0", ";");
});
export var HiddenScrollElement = styled.div.withConfig({
  displayName: "Scrollbarsstyled__HiddenScrollElement",
  componentId: "sc-3dh300-6"
})(["display:none;opacity:0 !important;visibility:hidden;"]);
export var HiddenVerticalScrollElement = styled(HiddenScrollElement).withConfig({
  displayName: "Scrollbarsstyled__HiddenVerticalScrollElement",
  componentId: "sc-3dh300-7"
})(["overflow:visible;"]);