import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
export var WrapperSection = styled.div.withConfig({
  displayName: "MediaModalstyled__WrapperSection",
  componentId: "sc-19wcqtl-0"
})(["height:100%;"]);
export var MediaPreviewWrapper = styled.div.withConfig({
  displayName: "MediaModalstyled__MediaPreviewWrapper",
  componentId: "sc-19wcqtl-1"
})(["display:grid;grid-template-rows:0.5fr min-content min-content;grid-gap:", "px;padding:0 1px 1px 1px;", ""], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.isMobile && "padding: ".concat(props.theme.largeSpacer, "px");
});
export var ImagePreview = styled.div.withConfig({
  displayName: "MediaModalstyled__ImagePreview",
  componentId: "sc-19wcqtl-2"
})(["width:196px;position:relative;display:flex;"]);
export var ButtonsWrapper = styled.div.withConfig({
  displayName: "MediaModalstyled__ButtonsWrapper",
  componentId: "sc-19wcqtl-3"
})(["display:grid;grid-template-columns:min-content min-content;grid-gap:", "px;"], function (props) {
  return props.theme.spacer;
});
export var Image = styled.img.withConfig({
  displayName: "MediaModalstyled__Image",
  componentId: "sc-19wcqtl-4"
})(["width:100%;height:", ";object-fit:cover;background-color:", ";"], function (props) {
  return props.isImage ? '100%' : '196px';
}, function (props) {
  return props.theme.vlgrey;
});
export var ImageBackground = styled.div.withConfig({
  displayName: "MediaModalstyled__ImageBackground",
  componentId: "sc-19wcqtl-5"
})(["display:flex;justify-content:center;align-items:center;background-color:", ";width:100%;"], function (props) {
  return props.theme.vvlgrey;
});
export var Wrapper = styled.div.withConfig({
  displayName: "MediaModalstyled__Wrapper",
  componentId: "sc-19wcqtl-6"
})(["width:100%;height:100%;box-sizing:border-box;padding:", "px;display:grid;grid-template-columns:1fr 0.3fr;grid-template-rows:minmax(0,1fr) ", "px;column-gap:", "px;row-gap:", "px;grid-template-areas:'content menu' 'footer footer';", ""], function (props) {
  return props.isMobile ? props.theme.largeSpacer : props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.buttonHeight;
}, function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.isMobile && "display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: calc(100% - ".concat(props.theme.buttonHeight, "px - ").concat(3 * props.theme.largeSpacer, "px);\n    padding-bottom: ").concat(props.theme.xlargeSpacer, "px;\n    box-sizing: border-box;\n    overflow-y: auto;");
});
export var Content = styled.div.withConfig({
  displayName: "MediaModalstyled__Content",
  componentId: "sc-19wcqtl-7"
})(["grid-area:content;display:grid;grid-template-rows:auto minmax(0,1fr);grid-gap:", "px;height:100%;"], function (props) {
  return props.theme.largeSpacer;
});
export var Preview = styled.div.withConfig({
  displayName: "MediaModalstyled__Preview",
  componentId: "sc-19wcqtl-8"
})(["height:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;"]);
export var CircularProgress = styled(CircularProgressbar).withConfig({
  displayName: "MediaModalstyled__CircularProgress",
  componentId: "sc-19wcqtl-9"
})(["height:150px;.CircularProgressbar-path{stroke:", " !important;stroke-linecap:unset !important;}"], function (props) {
  return props.theme.tertiary;
});
export var Audio = styled.audio.withConfig({
  displayName: "MediaModalstyled__Audio",
  componentId: "sc-19wcqtl-10"
})(["outline:none;width:100%;"]);
export var Menu = styled.div.withConfig({
  displayName: "MediaModalstyled__Menu",
  componentId: "sc-19wcqtl-11"
})(["height:100%;"]);