import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useActiveArtist from '@hooks/useActiveArtist';
import useModal from '@hooks/useModal';
import ajax from '@helpers/ajax';
import {
  formatAccountBalance,
  formatAccountBalanceToNumber,
} from '@helpers/formatAccountBalance';
import { getPayoutsMessage } from '@helpers/getPayoutsMessage';
import { OpenAlertFn } from '@ui/types';
import Button from '@ui/components/Button';
import Input from '@ui/components/Input';
import Dialogue from '@ui/components/Dialogue';
import { BodyText } from '@ui/components/Typography';
import DashboardRow from 'components/infographics/DashboardRow';
import { DesktopColumnsMobileRows } from 'components/common/DesktopColumnsMobileRows/DesktopColumnsMobileRows.styles';
import ExportCsv from 'components/royalties/RoyaltiesDashboard/ExportCsv';
import useAlertModal from '@hooks/useAlertModal';
import { reloadPage } from '@helpers/reloadPage';
import { Data } from '../types';
import AccountHistory from '../AccountHistory';
import { Wrapper, WarningText, AmountInput } from './AccountPayouts.styled';
import { payment } from '../../../../config/payment.config';

const CURRENCY = {
  EUR: '€',
  USD: '$',
  GBP: '£',
};

interface Props extends Data {
  refreshData: () => void;
  openAlert: OpenAlertFn;
}

const AccountPayouts = (props: Props) => {
  const { actId } = useActiveArtist();
  const openModal = useModal();

  const [amount, setAmount] = useState(
    props.balance >= 25 ? formatAccountBalance(props.balance) : ''
  );
  const openAlertModal = useAlertModal();
  const [loading, setLoading] = useState(false);

  const buildPayoutUrl = (subPath: string) =>
    `payments/payment-rails/${subPath}/payout`;

  const buildPayoutUrlForActorId = (userActId: string) =>
    buildPayoutUrl(userActId);

  const getPendingTotal = () => {
    const pendingRecords = props.payouts.filter(
      el => el.status === 'requested'
    );
    let sum = 0;
    if (pendingRecords.length > 0) {
      pendingRecords.forEach(el => {
        sum += el.amount;
      });
    }
    return sum;
  };

  const amountAsNumber = formatAccountBalanceToNumber(amount);

  const hasPayoutMethod = props.recipient?.status === 'active';

  const withdrawErrorMessage = getPayoutsMessage(
    props.balance,
    getPendingTotal(),
    amount,
    hasPayoutMethod
  );

  const handleExport = () => {
    openModal({
      Component: ExportCsv,
      modalTitle: 'Export into csv',
      modalSize: 'alert',
      showOverflow: true,
    });
  };

  const showCopySuccess = () => {
    openAlertModal({
      modalTitle: `Payout request confirmation`,
      modalText: 'Payout successfully requested.',
      componentProps: {
        onCloseAlert: setTimeout(() => {
          reloadPage();
        }, 3000),
      },
    });
  };

  const handleTransferRequest = async () => {
    setLoading(true);
    if (!hasPayoutMethod) {
      toast.error('Please add a payout method');
      setLoading(false);
    } else {
      try {
        const url = actId
          ? buildPayoutUrlForActorId(actId)
          : buildPayoutUrl('splits');
        await ajax(
          url,
          {
            amount: amountAsNumber,
            currency: 'EUR',
          },
          'POST'
        );
        props.refreshData();
        setLoading(false);
        showCopySuccess();
      } catch (e) {
        toast.error('Payout request failed');
        setLoading(false);
      }
    }
  };

  const openConfirmPayoutDialogue = () => {
    props.openAlert(
      `Confirm`,
      `Confirm withdrawal of ${CURRENCY[props?.currency || 'EUR']}${amount}?`,
      Dialogue,
      {
        actionButton: {
          text: 'Confirm',
          clickHandler: handleTransferRequest,
        },
        noPadding: true,
      }
    );
  };

  return (
    <Wrapper>
      <DashboardRow isLightMode heading="Payouts history">
        <AccountHistory payouts={props.payouts} />
      </DashboardRow>
      <DashboardRow heading="New Payout" isLightMode>
        {withdrawErrorMessage && (
          <WarningText>{withdrawErrorMessage}</WarningText>
        )}
        <DesktopColumnsMobileRows>
          <AmountInput>
            <BodyText>€</BodyText>
            <Input
              value={amount}
              placeholder="Enter Amount"
              onChange={value => setAmount(value)}
              disabled={props.balance < payment.minPayout}
              isLight
              isInBox
            />
          </AmountInput>
          <Button
            disabled={loading || withdrawErrorMessage.length > 0}
            onPress={openConfirmPayoutDialogue}
            squishy
            text="withdraw"
          />
        </DesktopColumnsMobileRows>
      </DashboardRow>
      <DashboardRow heading="Export data" isLightMode>
        <Button text="Export" small onPress={handleExport} />
      </DashboardRow>
    </Wrapper>
  );
};

export default AccountPayouts;
