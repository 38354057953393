import { ReactNode } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { useSelector } from '@hooks/useStore';
import useDevice from '@hooks/useDevice';
import useStripeSubscription from '@hooks/useStripeSubscription';

const PaidFeatureNotice = () => {
  const { isMobile } = useDevice();
  const stripeSubscription = useStripeSubscription();
  const { hasFailedPayments, subscription, onboarded } = useSelector(
    state => state.user
  );

  const toastId = 'PaidFeatureNotice';
  const cookie = Cookies.get(toastId);

  let text: ReactNode = null;
  if (hasFailedPayments) {
    text =
      'We are having issues processing your subscription payments, please update your payment details here';
  } else if (subscription?.status === 'cancelled_expiring') {
    text =
      'Your subscription has been cancelled, you will lose access to premium features. You can reactivate your subscription here';
  }

  if (!cookie && text && onboarded) {
    toast.warn(text, {
      toastId,
      position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.TOP_RIGHT,
      onClose: () => Cookies.set(toastId, true, { expires: 1 }),
      autoClose: false,
      closeOnClick: false,
      onClick: () => stripeSubscription.onClick({}),
    });
  }

  return null;
};

export default PaidFeatureNotice;
