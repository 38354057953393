import React from 'react';

import { capitalizeFirstLetter } from '@helpers/textFormatting';
import useDevice from '@hooks/useDevice';
import {
  MediaLibraryItem,
  MediaLibraryProps,
  MediaSectionSortMethod,
} from '@ui/types';
import { HeadingSmall } from '@ui/components/Typography';
import { orderBy } from 'lodash';
import MediaItem from '../MediaItem';
import { Header, Items, Wrapper } from './MediaSection.styled';

interface Props {
  title?: string;
  goToMediaItem: (item: MediaLibraryItem) => void;
  data: MediaLibraryItem[];
  sortMethod: MediaSectionSortMethod;
}

const getSortingItemIteratee =
  (sortMethod: MediaSectionSortMethod) => (item: MediaLibraryItem) => {
    switch (sortMethod) {
      case 'size_largest':
        return item.meta.fileSize;
      case 'size_smallest':
        return item.meta.fileSize;
      case 'name_asc':
        return item.name;
      case 'name_des':
        return item.name;
      case 'date_newest':
        return new Date(item.updated_date);
      case 'date_oldest':
        return new Date(item.updated_date);
      default:
        return 0;
    }
  };

const getSortingOrder = (sortMethod: MediaSectionSortMethod) => {
  if (sortMethod === 'size_largest') {
    return 'desc';
  }
  if (sortMethod === 'size_smallest') {
    return 'asc';
  }
  if (sortMethod === 'name_asc') {
    return 'asc';
  }
  if (sortMethod === 'name_des') {
    return 'desc';
  }
  if (sortMethod === 'date_newest') {
    return 'desc';
  }
  if (sortMethod === 'date_oldest') {
    return 'asc';
  }
  return 'asc';
};

const sortData = (
  data: MediaLibraryItem[],
  sortMethod: MediaSectionSortMethod
) => {
  if (sortMethod === 'none') {
    return data;
  }
  return orderBy(
    data,
    getSortingItemIteratee(sortMethod),
    getSortingOrder(sortMethod)
  );
};

const MediaSection = (props: Props & MediaLibraryProps) => {
  const { isMobile } = useDevice();
  const sortedData = sortData(props.data, props.sortMethod);
  return (
    <Wrapper>
      <Header>
        <HeadingSmall>
          {props.title && capitalizeFirstLetter(props.title)}
        </HeadingSmall>
      </Header>

      <Items isMobile={isMobile} isList={isMobile && props.title === 'audio'}>
        {sortedData.map(item => (
          <MediaItem
            key={item.media_id}
            {...item}
            {...props}
            onItemClick={() => {
              props.goToMediaItem(item);
            }}
          />
        ))}
      </Items>
    </Wrapper>
  );
};

export default MediaSection;
