import styled from 'styled-components';
import { rgba } from 'polished';
export var Main = styled.div.withConfig({
  displayName: "PageLoaderstyled__Main",
  componentId: "sc-tnmvp8-0"
})(["opacity:", ";transition:opacity 0.2s;width:100%;height:100%;background-color:", ";display:flex;justify-content:center;align-items:center;", " ", ""], function (props) {
  return props.isHidden ? '0' : '1';
}, function (props) {
  return props.isLightBg ? props.theme.white : 'none';
}, function (props) {
  return props.isFullScreen && "position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    z-index: 300;";
}, function (props) {
  return props.isOverlay && "\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 1;\n    width: 100%;\n    height: 100%;\n    background-color: ".concat(props.isLightBg ? rgba(props.theme.white, 0.8) : rgba(props.theme.black, 0.8), ";\n  ");
});