import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { trackPixelEvent } from '@helpers/tracking';
import { useLocation } from './useRouting';

const useConversionsLogger = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const eventId = uuid();

    trackPixelEvent('PageView', {}, { eventID: eventId });
  }, [pathname]);
};

export default useConversionsLogger;
