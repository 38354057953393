import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "LinkTilestyled__Wrapper",
  componentId: "sc-1vhnbul-0"
})(["display:grid;grid-gap:", "px;align-content:start;background-color:", ";padding:", "px;"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.vdgrey;
}, function (props) {
  return props.theme.largeSpacer;
});