/* eslint-disable no-console */
import queryString from 'query-string';

/**
 * Removes any password key from query string
 */
export const cleanUrl = () => {
  const parsed = queryString.parse(window.location.search);
  if (parsed.password) {
    delete parsed.password;
    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${queryString.stringify(parsed)}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
};

/**
 * tracks event
 */
const trackEvent = (event, data = {}) => {
  if (
    typeof window === 'undefined' ||
    !(<any>window).analytics?.track ||
    window.location.host === 'dev.beatcha.in' ||
    window.location.host.startsWith?.('localhost') ||
    (process.env.REACT_APP_API_BASE && // check REACT_APP_API_BASE is not undefined
      process.env.REACT_APP_API_BASE !== 'prod')
  ) {
    console.log('Event to be tracked', { event, data });
    return;
  }

  try {
    (<any>window).analytics.track(event, data);
  } catch (e) {
    console.error(e);
  }
};

/**
 * tracks pixel event
 */
export const trackPixelEvent = (...params) => {
  if (process.env.REACT_APP_API_BASE !== 'prod') return;
  if (typeof window === 'undefined') return;

  console.log('trackPixelEvent', params);

  try {
    if ((<any>window).fbq) {
      (<any>window).fbq('track', ...params);
    }
  } catch (e) {
    console.error(e);
  }
};

// <!-- Event snippet for Web Signup conversion page
// In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->
// <script>
//   function gtag_report_conversion(url) {
//   var callback = function () {
//   if (typeof(url) != 'undefined') {
//   window.location = url;
// }
// };
//   gtag('event', 'conversion', {
//   'send_to': 'AW-788296124/UKrjCO_Gg-QBELzj8fcC',
//   'event_callback': callback
// });
//   return false;
// }
// </script>

/**
 * tracks google event
 */
export const trackGoogleEvent = (type, data) => {
  if (process.env.REACT_APP_API_BASE !== 'prod') return;
  if (typeof window === 'undefined') return;

  try {
    console.log(`firing google attribution`);

    const callback = () => {
      console.log(`google attribution success`);
    };

    if ((<any>window).gtag) {
      (<any>window).gtag('event', type, {
        ...data,
        send_to: 'AW-788296124/UKrjCO_Gg-QBELzj8fcC',
        event_callback: callback,
      });
    }
  } catch (e) {
    console.error(e);
  }
};

const conversionTags = {
  sign_up: 'AW-788296124/UKrjCO_Gg-QBELzj8fcC',
  purchase: 'AW-788296124/U9G1CPvPqZECELzj8fcC',
};

/**
 * tracks google conversion
 */
export const trackGoogleConversion = (type, data) => {
  if (process.env.REACT_APP_API_BASE !== 'prod') return;
  if (typeof window === 'undefined') return;

  if (!conversionTags[type]) {
    console.error('Invalid google conversion type', { type });
    return;
  }

  try {
    console.log(`firing google attribution`);

    const callback = () => {
      console.log(`google attribution success`);
    };

    if ((<any>window).gtag) {
      const event = {
        ...data,
        send_to: conversionTags[type],
        event_callback: callback,
      };
      console.log({ event });
      (<any>window).gtag('event', 'conversion', event);
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * tracks intercom event
 */
export const trackIntercomEvent = (name, data) => {
  if (process.env.REACT_APP_API_BASE !== 'prod') return;
  if (typeof window === 'undefined') return;

  try {
    if ((<any>window).Intercom) {
      (<any>window).Intercom('trackEvent', name, data);
    }
  } catch (e) {
    console.error(e);
  }
};

export default trackEvent;
