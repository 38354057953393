import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg viewBox="0 0 160 50" fill="none" {...props}>
    <path
      fill={isColour ? '#FF9900' : 'currentColor'}
      d="M99.2608 39.7069C89.963 46.5602 76.4863 50.2166 64.8831 50.2166C48.6136 50.2166 33.9668 44.1991 22.886 34.1909C22.0154 33.4039 22.7954 32.3313 23.8401 32.9442C35.7985 39.9019 50.5846 44.0877 65.8582 44.0877C76.1589 44.0877 87.4905 41.9565 97.9097 37.5339C99.4837 36.8653 100.8 38.5647 99.2608 39.7069Z"
    />
    <path
      fill={isColour ? '#FF9900' : 'currentColor'}
      d="M103.126 35.2844C101.942 33.7661 95.2701 34.567 92.2753 34.9222C91.3629 35.0336 91.2236 34.2397 92.0454 33.6686C97.3595 29.9285 106.079 31.008 107.096 32.2617C108.113 33.5223 106.831 42.263 101.838 46.4348C101.072 47.0756 100.34 46.7343 100.682 45.8846C101.803 43.0848 104.317 36.8096 103.126 35.2844Z"
    />
    <path
      fill={isColour ? '#000000' : 'currentColor'}
      d="M92.4842 7.2653V3.62973C92.4842 3.07952 92.9021 2.71039 93.4036 2.71039H109.68C110.202 2.71039 110.62 3.08648 110.62 3.62973V6.74295C110.613 7.2653 110.175 7.94784 109.395 9.02737L100.96 21.0693C104.094 20.9927 107.403 21.4594 110.244 23.0612C110.885 23.4234 111.059 23.9527 111.108 24.4751V28.3544C111.108 28.8837 110.523 29.5036 109.91 29.1832C104.902 26.5575 98.251 26.272 92.7141 29.2111C92.1499 29.5175 91.5579 28.9046 91.5579 28.3753V24.691C91.5579 24.099 91.5649 23.0891 92.1569 22.1906L101.928 8.17768H93.4245C92.9021 8.17768 92.4842 7.80855 92.4842 7.2653Z"
    />
    <path
      fill={isColour ? '#000000' : 'currentColor'}
      d="M33.1102 29.9493H28.1583C27.6847 29.9145 27.3086 29.5593 27.2738 29.1066V3.69244C27.2738 3.18401 27.6986 2.78006 28.2279 2.78006H32.8455C33.3261 2.80094 33.7092 3.17008 33.744 3.62975V6.95191H33.8345C35.0394 3.74118 37.3029 2.24377 40.3535 2.24377C43.4528 2.24377 45.389 3.74118 46.7819 6.95191C47.9798 3.74118 50.703 2.24377 53.6212 2.24377C55.6967 2.24377 57.9672 3.10043 59.3532 5.02269C60.9202 7.16085 60.5999 10.2671 60.5999 12.9903L60.5929 29.03C60.5929 29.5384 60.168 29.9493 59.6387 29.9493H54.6938C54.1993 29.9145 53.8023 29.5175 53.8023 29.03V15.5603C53.8023 14.4877 53.8998 11.8133 53.663 10.7964C53.2939 9.09008 52.1865 8.60951 50.7518 8.60951C49.5538 8.60951 48.3002 9.41045 47.7918 10.692C47.2833 11.9735 47.3321 14.1186 47.3321 15.5603V29.03C47.3321 29.5384 46.9073 29.9493 46.3779 29.9493H41.433C40.9315 29.9145 40.5415 29.5175 40.5415 29.03L40.5346 15.5603C40.5346 12.7256 41.0012 8.55379 37.484 8.55379C33.9251 8.55379 34.0644 12.6212 34.0644 15.5603V29.03C34.0644 29.5384 33.6395 29.9493 33.1102 29.9493"
    />
    <path
      fill={isColour ? '#000000' : 'currentColor'}
      d="M124.633 2.24377C131.981 2.24377 135.958 8.55379 135.958 16.5771C135.958 24.3288 131.563 30.4787 124.633 30.4787C117.418 30.4787 113.49 24.1687 113.49 16.3055C113.49 8.3936 117.467 2.24377 124.633 2.24377ZM124.675 7.43248C121.026 7.43248 120.796 12.4053 120.796 15.5046C120.796 18.6108 120.747 25.2412 124.633 25.2412C128.471 25.2412 128.652 19.8923 128.652 16.6328C128.652 14.4877 128.561 11.9247 127.914 9.89102C127.356 8.12199 126.249 7.43248 124.675 7.43248Z"
    />
    <path
      fill={isColour ? '#000000' : 'currentColor'}
      d="M145.486 29.9493H140.555C140.06 29.9145 139.663 29.5175 139.663 29.03L139.656 3.60886C139.698 3.14222 140.109 2.78006 140.61 2.78006H145.2C145.632 2.80094 145.987 3.09347 146.085 3.49046V7.37676H146.175C147.561 3.90137 149.504 2.24377 152.924 2.24377C155.146 2.24377 157.312 3.04472 158.705 5.23859C160 7.27229 160 10.692 160 13.1505V29.1484C159.944 29.5941 159.533 29.9493 159.046 29.9493H154.08C153.627 29.9145 153.251 29.5802 153.202 29.1484V15.3444C153.202 12.5655 153.523 8.49807 150.103 8.49807C148.898 8.49807 147.791 9.30598 147.241 10.5318C146.544 12.0849 146.454 13.6311 146.454 15.3444V29.03C146.447 29.5384 146.015 29.9493 145.486 29.9493"
    />
    <path
      fill={isColour ? '#000000' : 'currentColor'}
      fillRule="evenodd"
      d="M79.509 17.8099C79.509 19.7391 79.5577 21.3479 78.5827 23.0612C77.7957 24.4542 76.542 25.3108 75.156 25.3108C73.2547 25.3108 72.1403 23.8622 72.1403 21.724C72.1403 17.5034 75.9222 16.7373 79.509 16.7373V17.8099ZM84.5027 29.8797C84.1753 30.1722 83.7017 30.1931 83.3326 29.9981C81.6889 28.633 81.3894 27.9992 80.491 26.6968C77.7748 29.4688 75.8455 30.2976 72.3284 30.2976C68.1565 30.2976 64.9179 27.7276 64.9179 22.5807C64.9179 18.5621 67.0909 15.8249 70.1972 14.4877C72.8856 13.3037 76.6395 13.0948 79.509 12.7674V12.1267C79.509 10.9496 79.5995 9.5567 78.9031 8.53986C78.3041 7.62748 77.1479 7.25138 76.1241 7.25138C74.2367 7.25138 72.5582 8.21948 72.1473 10.2253C72.0637 10.6711 71.7364 11.1098 71.2837 11.1307L66.485 10.6153C66.081 10.5248 65.6283 10.1974 65.7467 9.5776C66.8472 3.75511 72.1125 2 76.8206 2C79.2304 2 82.3784 2.64075 84.2798 4.4655C86.6896 6.7151 86.4597 9.71689 86.4597 12.9833V20.7002C86.4597 23.0195 87.4209 24.0363 88.3263 25.29C88.6397 25.7357 88.7094 26.272 88.3054 26.6063C87.2955 27.449 85.4986 29.0161 84.5096 29.8936L84.5026 29.8797"
    />
    <path
      fill={isColour ? '#000000' : 'currentColor'}
      fillRule="evenodd"
      d="M14.591 17.8101C14.591 19.7393 14.6398 21.3482 13.6647 23.0615C12.8777 24.4544 11.631 25.3111 10.2381 25.3111C8.33674 25.3111 7.22936 23.8624 7.22936 21.7243C7.22936 17.5037 11.0112 16.7375 14.591 16.7375V17.8101ZM19.5847 29.8799C19.2574 30.1724 18.7838 30.1933 18.4147 29.9983C16.771 28.6333 16.4785 27.9995 15.5731 26.6971C12.8568 29.469 10.9346 30.2978 7.41044 30.2978C3.24554 30.2978 0 27.7278 0 22.5809C0 18.5623 2.17995 15.8252 5.27924 14.488C7.96761 13.3039 11.7216 13.095 14.591 12.7677V12.1269C14.591 10.9499 14.6816 9.55694 13.9921 8.5401C13.3861 7.62772 12.23 7.25163 11.2132 7.25163C9.32573 7.25163 7.64027 8.21972 7.22936 10.2256C7.14578 10.6713 6.81844 11.1101 6.3727 11.131L1.56706 10.6156C1.1631 10.525 0.717363 10.1977 0.828798 9.57784C1.93619 3.75535 7.19453 2.00024 11.9027 2.00024C14.3124 2.00024 17.4605 2.641 19.3619 4.46575C21.7716 6.71534 21.5418 9.71713 21.5418 12.9836V20.7005C21.5418 23.0197 22.5029 24.0365 23.4083 25.2902C23.7287 25.7359 23.7984 26.2722 23.3944 26.6065C22.3845 27.4493 20.5876 29.0163 19.5986 29.8939L19.5847 29.8799"
    />
  </svg>
);
