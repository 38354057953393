import styled from 'styled-components';
import Button from '@ui/components/Button';
import LargeCheckbox from '@ui/components/LargeCheckbox/LargeCheckbox';
import { HeadingLarge, BodyText } from '@ui/components/Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "ChecklistItemstyled__Wrapper",
  componentId: "sc-t6es8v-0"
})(["width:100%;height:100%;display:grid;grid-template-columns:", "px 1fr;grid-template-rows:minmax(48px,auto) auto 1fr auto;grid-template-areas:'check title' 'subtitle subtitle' 'postTitle postTitle' 'button button';grid-gap:", ";"], function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return "".concat(props.theme.spacer, "px ").concat(props.theme.largeSpacer, "px");
});
export var Title = styled(HeadingLarge).withConfig({
  displayName: "ChecklistItemstyled__Title",
  componentId: "sc-t6es8v-1"
})(["grid-area:title;"]);
export var StyledLargeCheckbox = styled(LargeCheckbox).withConfig({
  displayName: "ChecklistItemstyled__StyledLargeCheckbox",
  componentId: "sc-t6es8v-2"
})(["grid-area:check;"]);
export var Subtitle = styled(BodyText).withConfig({
  displayName: "ChecklistItemstyled__Subtitle",
  componentId: "sc-t6es8v-3"
})(["grid-area:subtitle;"]);
export var PostTitle = styled.div.withConfig({
  displayName: "ChecklistItemstyled__PostTitle",
  componentId: "sc-t6es8v-4"
})(["grid-area:postTitle;"]);
export var StyledButton = styled(Button).withConfig({
  displayName: "ChecklistItemstyled__StyledButton",
  componentId: "sc-t6es8v-5"
})(["grid-area:button;"]);