import React from 'react';
import { TextLink } from './TextLink.styled';

interface Props {
  onPress: () => void;
  text: string;
}

/**
 * Styles inline text links used in instructions and other copy
 */
const TextLinkButton = (props: Props) => (
  <TextLink onClick={() => props.onPress()}>{props.text}</TextLink>
);

export default TextLinkButton;
