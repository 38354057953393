import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "CustomHeaderstyled__Wrapper",
  componentId: "sc-13blwp2-0"
})(["display:grid;grid-gap:", "px;grid-template-columns:auto 1fr 1fr auto;align-items:center;padding:", "px;background:", ";color:", ";"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.white;
});