import styled from 'styled-components';
import { rgba } from 'polished';
import { Heading, SmallText } from '../Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "ProgressBarstyled__Wrapper",
  componentId: "sc-z6dqdx-0"
})(["width:100%;height:auto;display:grid;grid-template-columns:48px 1fr;grid-gap:", "px ", "px;", " ", ""], function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return !props.hasLabel && 'display: flex;';
}, function (props) {
  return props.isPaused && 'filter: saturate(0) brightness(0.4);';
});
export var Message = styled(SmallText).withConfig({
  displayName: "ProgressBarstyled__Message",
  componentId: "sc-z6dqdx-1"
})(["margin-bottom:", "px;"], function (props) {
  return props.theme.smallSpacer;
});
export var Label = styled(Heading).withConfig({
  displayName: "ProgressBarstyled__Label",
  componentId: "sc-z6dqdx-2"
})(["display:flex;align-items:center;justify-content:center;"]);
export var BarTrack = styled.div.withConfig({
  displayName: "ProgressBarstyled__BarTrack",
  componentId: "sc-z6dqdx-3"
})(["height:", "px;width:100%;background-color:", ";border-radius:", "px;align-self:center;"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return rgba(props.theme.grey, 0.5);
}, function (props) {
  return props.theme.smallSpacer;
});
export var Bar = styled.div.withConfig({
  displayName: "ProgressBarstyled__Bar",
  componentId: "sc-z6dqdx-4"
})(["height:100%;width:", "%;background-color:", ";border-radius:", "px;"], function (props) {
  return props.percentage;
}, function (props) {
  return props.theme.success;
}, function (props) {
  return props.theme.smallSpacer;
});