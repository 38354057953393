import React from 'react';
import { Helmet } from 'react-helmet-async';

const mappings = [
  // Beatchain
  {
    fontFamily: 'Beatchain Brand Heading',
    sourceFont: 'SourceCodePro-SemiBold',
  },
  {
    fontFamily: 'Beatchain Heading',
    sourceFont: 'SourceSansPro-SemiBold',
  },
  {
    fontFamily: 'Beatchain Body',
    sourceFont: 'SourceSansPro-Regular',
  },
  {
    fontFamily: 'Beatchain Body Strong',
    sourceFont: 'SourceSansPro-Bold',
  },
  {
    fontFamily: 'Beatchain Body Bold',
    sourceFont: 'SourceSansPro-Bold',
  },
  {
    fontFamily: 'Beatchain Body Italic',
    sourceFont: 'SourceSansPro-LightItalic',
  },
  {
    fontFamily: 'Beatchain Subheading',
    sourceFont: 'SourceCodePro-Regular',
  },
  {
    fontFamily: 'Beatchain Button',
    sourceFont: 'SourceCodePro-SemiBold',
  },
  // Generic
  {
    fontFamily: 'Generic Brand Heading',
    sourceFont: 'inter-semibold-webfont',
  },
  {
    fontFamily: 'Generic Infographic Number',
    sourceFont: 'inter-semibold-webfont',
  },
  {
    fontFamily: 'Generic Heading',
    sourceFont: 'inter-semibold-webfont',
  },
  {
    fontFamily: 'Generic Body',
    sourceFont: 'inter-regular-webfont',
  },
  {
    fontFamily: 'Generic Body Strong',
    sourceFont: 'inter-semibold-webfont',
  },
  {
    fontFamily: 'Generic Body Bold',
    sourceFont: 'inter-semibold-webfont',
  },
  {
    fontFamily: 'Generic Body Italic',
    sourceFont: 'inter-extralight-beta-webfont',
  },
  {
    fontFamily: 'Generic Subheading',
    sourceFont: 'inter-regular-webfont',
  },
  {
    fontFamily: 'Generic Button',
    sourceFont: 'inter-semibold-webfont',
  },
];

/**
 * Font mapping
 */
const GlobalFontFace = () => (
  <Helmet>
    {mappings.map(font => (
      <style key={font.fontFamily}>
        {`
            @font-face {
              font-family: ${font.fontFamily};
              src: url('https://static.beatchain.com/fonts/${font.sourceFont}.woff2') format('woff2'),
              url('https://static.beatchain.com/fonts/${font.sourceFont}.woff') format('woff');
              font-weight: normal;
              font-style: normal;
              font-display: auto;
            }
          `}
      </style>
    ))}
  </Helmet>
);

export default GlobalFontFace;
