import React from 'react';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconNames } from '../Icon';
import { Wrapper, Counter } from './IconNotification.styled';

interface Props {
  children?: React.ReactNode;
  showNotification: boolean;
  iconName?: string;
  colour?: string;
  backgroundColour?: string;
  counter?: string | number;
  size?: number;
  isTopRightOfParent?: boolean;
  clickHandler?: () => void;
}

/**
 * Additional tag to appear on icons indication a number, warning or success
 */
const IconNotification = (props: Props) => {
  const theme = useTheme();

  const handleClick = e => {
    e.stopPropagation();
    if (props.clickHandler) props.clickHandler();
  };

  return (
    <Wrapper
      className="fa-layers fa-fw"
      size={props.size}
      isTopRightOfParent={props.isTopRightOfParent}
      onClick={props.clickHandler ? handleClick : undefined}
      isClickable={!!props.clickHandler}
    >
      {props.children}
      {props.iconName && props.showNotification && (
        <>
          <FontAwesomeIcon
            icon="circle"
            color={theme[props.backgroundColour || 'success']}
            transform="shrink-6 up-6 right-6"
          />
          <FontAwesomeIcon
            icon={iconNames[props.iconName]}
            color={theme[props.colour || 'white']}
            transform="shrink-11 up-6 right-6"
          />
        </>
      )}

      {props.counter && props.showNotification && (
        <Counter className="fa-layers-counter">{props.counter}</Counter>
      )}
    </Wrapper>
  );
};

export default IconNotification;
