/**
 * Truncates text to a given length
 */
export const truncateText = (
  str?: string,
  length: number = 100,
  ending: string = '...'
) => {
  if (!str) {
    return '';
  }
  return str && str.length > length
    ? str.substring(0, length - ending.length) + ending
    : str;
};
