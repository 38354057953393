import React from 'react';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { Wrapper } from './Markdown.styled';

const linkWithTarget = ({ node, ...props }) => (
  <a href={props.href} target="_blank" rel="nofollow noreferrer noopener">
    {props.children}
  </a>
);

/**
 * Handles markdown conversion
 */
const Markdown = (props: { children: string }) => (
  <Wrapper>
    <ReactMarkdown
      remarkPlugins={[gfm]}
      rehypePlugins={[rehypeRaw]}
      components={{ a: linkWithTarget }}
    >
      {props.children}
    </ReactMarkdown>
  </Wrapper>
);

export default Markdown;
