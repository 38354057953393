import React from 'react';
import ThemeProvider from '@ui/components/ThemeProvider';
import FontFace from '@ui/components/FontFace';
import EventListeners from 'components/app/EventListeners';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import '@ui/components/Icon/iconLibrary';
import useTheme from '@hooks/useTheme';
import { GlobalStyle } from './App.styled';

interface Props {
  children: React.ReactNode;
}

const AppUtilities = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={theme ? `%s | ${theme.title}` : '%s | Beatchain'}
        defaultTitle={theme ? `${theme.title}` : 'Beatchain'}
      />
      <ThemeProvider theme={theme}>
        <FontFace />
        <GlobalStyle />
        <EventListeners />
        {children}
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default AppUtilities;
