import { getCDNUrl } from '@helpers/getCDNUrl';
import { getThumbnailBySize } from '@helpers/getThumbnailBySize';
import { MediaLibraryItem, Thumbnail, ThumbnailSize } from '@ui/types';

export const getMediaItemThumbnail = (
  mediaItem: Partial<MediaLibraryItem> | undefined,
  size: ThumbnailSize
): string | undefined => {
  const mediaItemThumbnails: Thumbnail | undefined = mediaItem?.thumbnails;

  const thumbnail = getThumbnailBySize(mediaItemThumbnails, size);

  const url = encodeURI(thumbnail || '').replace(/(?=[() ])/g, '\\');

  return getCDNUrl(url);
};
