import { useEffect } from 'react';
import useInterval from 'use-interval';

import ajax from '@helpers/ajax';
import trackEvent, {
  trackGoogleConversion,
  trackPixelEvent,
} from '@helpers/tracking';
import { attachStatusListener } from '@helpers/connectionIssues';
import * as userStore from '../store/user';
import { actions } from '../store/credits';
import { useSearchParams } from './useRouting';
import { useDispatch, useSelector } from './useStore';

const useAppLoadHook = () => {
  const user = useSelector(state => state.user);
  const [search, setSearch] = useSearchParams();
  // Stripe Checkout session. Need to wait until the webhooks triggered
  // by the checkout session are delivered to our backend before
  // fetching user metadata.
  const waitForWebhooks = search.get('stripe_verify_webhooks') === 'true';
  const preventPageClose = e => {
    e.preventDefault();
    // This is not shown, the browser shows their owen string usually
    return 'Please do not close this page while we update your subscription';
  };

  const { email, name } = user || {};

  const dispatch = useDispatch();

  useInterval(
    async () => {
      const res = await ajax(
        `payments/stripe/check-pending-webhooks?${search.toString()}`
      );
      if (res.done) {
        for (const key of search.keys()) {
          if (key.startsWith('stripe_')) search.delete(key);
        }
        setSearch(search);
        if (res.subscriptionData) {
          trackPixelEvent(
            'Purchase',
            {
              currency: res.subscriptionData.currency,
              content_name: res.subscriptionData.name,
              content_type: res.subscriptionData.interval,
              value: res.subscriptionData.price,
            },
            {
              eventID: `purchase.${email}`,
            }
          );
          trackEvent('Purchase', res.subscriptionData);
          trackGoogleConversion('purchase', {
            currency: res.subscriptionData.currency,
            coupon: res.subscriptionData.coupon,
            value: res.subscriptionData.price,
            items: [
              {
                name: res.subscriptionData.name,
                variant: res.subscriptionData.interval,
              },
            ],
          });
        }
      }
    },
    waitForWebhooks ? 2000 : null
  );

  useEffect(() => {
    if (waitForWebhooks) {
      window.addEventListener('beforeunload', preventPageClose);
    } else {
      window.removeEventListener('beforeunload', preventPageClose);
    }

    return () => {
      window.removeEventListener('beforeunload', preventPageClose);
    };
  }, [waitForWebhooks]);

  useEffect(() => {
    dispatch(userStore.fetchUserMetadata());
    dispatch(actions.fetchActions());
    dispatch(actions.fetchProducts());

    window?.zE?.('webWidget', 'show');
    window?.zE?.('webWidget', 'prefill', {
      email: {
        value: email,
        readOnly: true,
      },
      name: {
        value: name,
        readOnly: false,
      },
    });

    attachStatusListener();

    return () => {
      if (window?.zE) window.zE('webWidget', 'hide');
    };
  }, []);

  return {
    waitForWebhooks,
    loadingUser: !!user.FETCH_USER_METADATA.fetching,
  };
};

export default useAppLoadHook;
