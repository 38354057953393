/**
 * Gets and format the track duration of a track
 */
const trackDuration = (trackLength: number) => {
  const hours = Math.floor(trackLength / 3600);
  const minutes = Math.floor((trackLength - hours * 3600) / 60);
  const seconds = Math.floor(trackLength - hours * 3600 - minutes * 60);
  const minutesDisplay =
    minutes > 9 || hours < 1 ? minutes : `0${minutes || 0}`;
  const secondsDisplay = seconds > 9 ? seconds : `0${seconds || 0}`;
  return hours > 0
    ? `${hours}:${minutesDisplay}:${secondsDisplay}`
    : `${minutesDisplay}:${secondsDisplay}`;
};

export default trackDuration;
