import React, { useEffect, useState } from 'react';
import useDevice from '@hooks/useDevice';
import { FileWithProgress } from '@ui/types';
import { BodyText } from '@ui/components/Typography';
import Loader from '@ui/components/Loader';

import {
  Wrapper,
  Header,
  LoaderWrapper,
  Title,
  Close,
  Files,
  File,
  FileTypeIcon,
  CircularProgress,
  TickIcon,
} from './UploadProgress.styled';

interface Props {
  files: FileWithProgress[];
  reset: () => void;
}

const UploadProgress = (props: Props) => {
  const { isMobile } = useDevice();
  const [showLoader, setShowLoader] = useState(false);

  const title = props.files.some(file => !file.progress || file.progress < 100)
    ? `Uploading ${props.files.length} items`
    : `Upload completed`;

  useEffect(() => {
    if (props.files.every(file => file.progress && file.progress === 100)) {
      setShowLoader(true);

      setTimeout(() => {
        // After 3 seconds set the show value to false
        setShowLoader(false);
      }, 3000);
    }
  }, [props.files]);

  return (
    <Wrapper isMobile={isMobile}>
      <Header>
        {showLoader ? (
          <LoaderWrapper>
            <Loader hasMessage={false} small />
            <Title>Processing...</Title>
          </LoaderWrapper>
        ) : (
          <Title>{title}</Title>
        )}
        <Close clickHandler={props.reset} />
      </Header>
      <Files>
        {props.files?.map(file => {
          const fileType = file.type?.split('/')[0];
          return (
            <File key={file.upload_id}>
              <FileTypeIcon
                name={fileType === 'image' ? 'image-solid' : fileType}
              />
              <BodyText numberOfLines={1}>{file.path}</BodyText>
              {file.progress === 100 ? (
                <TickIcon />
              ) : (
                <CircularProgress value={file.progress ?? 0} />
              )}
            </File>
          );
        })}
      </Files>
    </Wrapper>
  );
};

export default UploadProgress;
