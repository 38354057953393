/**
 * Maps urls to specific urls depending on the cdn
 */
export const getCDNUrl = (url: string | undefined): string | undefined => {
  const thumbnailFileStoreCDN = 'thumbnail-filestore.beatchain.com';

  if (
    url?.includes('s3.eu-west-2.amazonaws.com') &&
    (url?.includes('static.beatchain.com') ||
      url?.includes('filestore.beatchain.com/'))
  ) {
    return url.replace('s3.eu-west-2.amazonaws.com/', '');
  }
  if (
    url?.includes('s3.eu-west-1.amazonaws.com') &&
    url?.includes('filestore.beatchain.com.thumbnail/')
  ) {
    return url.replace(
      's3.eu-west-1.amazonaws.com/filestore.beatchain.com.thumbnail',
      thumbnailFileStoreCDN
    );
  }
  if (
    url?.includes('s3.eu-west-1.amazonaws.com') &&
    url?.includes('filestore2.beatchain.com/')
  ) {
    return url.replace('s3.eu-west-1.amazonaws.com/', '');
  }

  const fileStoreEuWest1 =
    'filestore.beatchain.com.thumbnail.s3.eu-west-1.amazonaws.com';

  if (url?.includes(fileStoreEuWest1)) {
    return url.replace(fileStoreEuWest1, thumbnailFileStoreCDN);
  }

  return url;
};
