import React from 'react';

import { IconProps } from './index';

export default ({ isColour, tiled, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    {isColour ? (
      <>
        <path
          fill="url(#shazam_linear)"
          d="M256.009 0C114.631 0 0 114.595 0 255.996c0 141.362 114.631 256.002 256.009 256.002C397.387 511.998 512 397.358 512 255.996 512 114.595 397.387 0 256.009 0zm-42.095 356.637c-27.389 0-54.736-9.062-75.732-27.216-25.821-22.308-41.132-52.673-43.153-85.505-1.912-31.843 8.904-62.562 30.532-86.428 24.271-26.817 67.821-68.509 69.655-70.274 11.808-11.311 30.515-10.86 41.817.954 11.281 11.828 10.88 30.561-.936 41.856-.444.424-43.842 41.982-66.718 67.226-10.807 11.98-16.258 27.268-15.293 43.065 1.034 16.768 9.109 32.498 22.762 44.31 17.963 15.543 53.39 18.941 75.56-.911 13.183-11.853 29.198-29.252 29.358-29.423 11.05-12.044 29.779-12.819 41.788-1.741 12.022 11.049 12.824 29.802 1.764 41.837-.733.769-18.1 19.629-33.435 33.403-21.37 19.194-49.688 28.847-77.969 28.847zm178.865-2.107c-24.294 26.797-67.837 68.482-69.685 70.27a29.518 29.518 0 01-20.413 8.186c-7.822 0-15.581-3.073-21.39-9.145-11.302-11.839-10.872-30.552.932-41.857.428-.429 43.862-41.997 66.718-67.255 10.809-11.934 16.249-27.259 15.284-43.071-1.023-16.754-9.098-32.484-22.767-44.278-17.944-15.51-53.377-18.961-75.54.933-13.196 11.817-29.193 29.222-29.34 29.398-11.097 12.024-29.782 12.809-41.814 1.765-12.015-11.057-12.831-29.801-1.763-41.836.736-.805 18.079-19.681 33.434-33.438 42.092-37.77 111.037-38.522 153.702-1.636 25.829 22.317 41.144 52.7 43.147 85.509 1.95 31.852-8.911 62.574-30.505 86.455z"
        />
        <path
          fill="#fff"
          d="M138.182 329.424c20.996 18.153 48.342 27.215 75.732 27.215 28.28 0 56.599-9.653 77.969-28.846 14.188-12.745 30.115-29.842 32.999-32.937l.435-.467c11.061-12.035 10.259-30.787-1.763-41.837-12.009-11.077-30.738-10.303-41.788 1.741l-.054.059c-1.04 1.125-16.506 17.858-29.304 29.365-22.171 19.852-57.597 16.454-75.56.91-13.653-11.811-21.728-27.542-22.762-44.31-.965-15.797 4.486-31.085 15.293-43.065 22.876-25.244 66.274-66.802 66.718-67.226 11.815-11.295 12.216-30.027.936-41.856-11.302-11.813-30.009-12.265-41.817-.954-.061.06-.169.163-.321.308-4.398 4.223-45.873 44.044-69.334 69.966-21.628 23.867-32.444 54.585-30.532 86.428 2.02 32.832 17.332 63.197 43.153 85.506z"
        />
        <path
          fill="#fff"
          d="M323.733 424.187c5.786-5.565 46.044-44.283 69.046-69.654 21.594-23.881 32.455-54.604 30.505-86.456-2.003-32.808-17.318-63.191-43.147-85.509-42.665-36.886-111.611-36.133-153.702 1.637-15.355 13.756-32.698 32.632-33.434 33.438-11.068 12.035-10.252 30.778 1.763 41.835 12.031 11.045 30.717 10.259 41.814-1.765.147-.176 16.144-17.581 29.34-29.398 22.163-19.894 57.596-16.443 75.54-.932 13.669 11.793 21.744 27.524 22.767 44.277.965 15.812-4.476 31.138-15.284 43.071-21.355 23.599-60.673 61.437-66.095 66.654l-.623.601c-11.805 11.306-12.235 30.018-.933 41.857 5.809 6.072 13.568 9.145 21.391 9.145a29.52 29.52 0 0020.412-8.185l.64-.616z"
        />
      </>
    ) : (
      <path
        fill="currentColor"
        d={
          tiled
            ? 'M190.272 401.566c-39.617 0-79.172-13.107-109.542-39.365-37.347-32.268-59.494-76.188-62.417-123.678-2.766-46.059 12.878-90.491 44.162-125.012C97.58 74.723 160.573 14.418 163.226 11.864c17.08-16.36 44.139-15.707 60.486 1.38 16.316 17.11 15.736 44.205-1.354 60.542-.643.614-63.414 60.725-96.503 97.238-15.631 17.329-23.516 39.441-22.12 62.291 1.495 24.254 13.175 47.006 32.923 64.091 25.983 22.483 77.225 27.397 109.293-1.317 19.068-17.145 42.233-42.312 42.464-42.559 15.983-17.421 43.073-18.541 60.444-2.519 17.389 15.983 18.549 43.107 2.55 60.515-1.06 1.113-26.179 28.392-48.36 48.316-30.911 27.762-71.872 41.724-112.777 41.724zm258.716-3.046c-35.14 38.759-98.122 99.053-100.796 101.64A42.696 42.696 0 01318.667 512c-11.315 0-22.538-4.445-30.94-13.228-16.347-17.124-15.725-44.19 1.349-60.543.619-.62 63.443-60.746 96.503-97.28 15.634-17.261 23.503-39.428 22.107-62.299-1.48-24.233-13.159-46.986-32.931-64.044-25.954-22.436-77.206-27.426-109.263 1.348-19.087 17.093-42.226 42.268-42.439 42.522-16.051 17.392-43.078 18.529-60.48 2.554-17.379-15.994-18.56-43.105-2.551-60.513 1.066-1.165 26.151-28.467 48.361-48.365 60.882-54.632 160.607-55.721 222.319-2.367 37.36 32.28 59.512 76.227 62.409 123.682 2.821 46.073-12.889 90.51-44.123 125.053z'
            : 'M256.009 0C114.631 0 0 114.595 0 255.996c0 141.362 114.631 256.002 256.009 256.002C397.387 511.998 512 397.358 512 255.996 512 114.595 397.387 0 256.009 0zm-42.095 356.637c-27.389 0-54.736-9.062-75.732-27.216-25.821-22.308-41.132-52.673-43.153-85.505-1.912-31.843 8.904-62.562 30.532-86.428 24.271-26.817 67.821-68.509 69.655-70.274 11.808-11.311 30.515-10.86 41.817.954 11.281 11.828 10.88 30.561-.936 41.856-.444.424-43.842 41.982-66.718 67.226-10.807 11.98-16.258 27.268-15.293 43.065 1.034 16.768 9.109 32.498 22.762 44.31 17.963 15.543 53.39 18.941 75.56-.911 13.183-11.853 29.198-29.252 29.358-29.423 11.05-12.044 29.779-12.819 41.788-1.741 12.022 11.049 12.824 29.802 1.764 41.837-.733.769-18.1 19.629-33.435 33.403-21.37 19.194-49.689 28.847-77.969 28.847zm178.865-2.107c-24.294 26.797-67.837 68.482-69.685 70.27a29.518 29.518 0 01-20.413 8.186c-7.822 0-15.582-3.073-21.39-9.145-11.302-11.839-10.872-30.552.932-41.857.428-.429 43.862-41.997 66.718-67.255 10.809-11.934 16.249-27.259 15.284-43.071-1.023-16.754-9.098-32.484-22.767-44.278-17.944-15.51-53.377-18.961-75.54.933-13.196 11.817-29.193 29.222-29.34 29.398-11.097 12.024-29.782 12.809-41.814 1.765-12.015-11.057-12.831-29.801-1.763-41.836.736-.805 18.079-19.681 33.434-33.438 42.092-37.77 111.037-38.522 153.702-1.636 25.829 22.317 41.144 52.7 43.147 85.509 1.95 31.852-8.911 62.574-30.505 86.455z'
        }
      />
    )}

    <defs>
      <linearGradient
        id="shazam_linear"
        x1="256.116"
        x2="256.116"
        y1="512.004"
        y2="-.005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#25F" />
        <stop offset="1" stopColor="#0AF" />
      </linearGradient>
    </defs>
  </svg>
);
