import styled from 'styled-components';
import { BodyBoldText } from '../Typography';
export var NotificationsIndicatorMain = styled.div.withConfig({
  displayName: "NotificationsIndicatorstyled__NotificationsIndicatorMain",
  componentId: "sc-1ske65o-0"
})(["display:flex;align-items:center;justify-content:center;position:absolute;top:-", "px;right:-", "px;color:", ";height:", "px;width:", "px;border-radius:50%;background-color:", ";"], function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.primary;
});
export var StyledBodyBoldText = styled(BodyBoldText).withConfig({
  displayName: "NotificationsIndicatorstyled__StyledBodyBoldText",
  componentId: "sc-1ske65o-1"
})(["font-size:10px;"]);