import React from 'react';
import Icon from '@ui/components/Icon';
import { useSelector } from '@hooks/useStore';
import { useNavigate } from '@hooks/useRouting';
import IconNotification from '@ui/components/IconNotification';
import { NewsFeedToggleMain } from './NewsFeedToggle.styled';

interface Props {
  isSmall?: boolean;
  isExpanded?: boolean;
}

const NewsFeedToggle = (props: Props) => {
  const { feed } = useSelector(state => state.user);
  let found = false;
  const navigate = useNavigate();
  // Feed items not seen (until there is one that is seen)
  const feedCount = (feed?.data || []).filter(el => {
    if (found) return false;
    if (el.seen) {
      found = true;
      return false;
    }
    return true;
  }).length;

  return (
    <NewsFeedToggleMain
      isExpanded={props.isExpanded}
      onClick={() => navigate('/welcome')}
      isSmall={props.isSmall}
    >
      <IconNotification
        showNotification={!!feedCount}
        counter={feedCount}
        size={14}
      >
        <Icon name="bell" />
      </IconNotification>
    </NewsFeedToggleMain>
  );
};

export default NewsFeedToggle;
