import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import { HeadingSmall } from '@ui/components/Typography';
export var PageLoaderWrapper = styled(Div100vh).withConfig({
  displayName: "PageLoaderstyled__PageLoaderWrapper",
  componentId: "sc-31vbp7-0"
})(["width:100%;height:100%;background-color:", ";display:flex;justify-content:center;align-items:center;", ""], function (props) {
  return props.isLightBg ? props.theme.white : props.theme.black;
}, function (props) {
  return props.isFullScreen && "\n  position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    z-index: 300;\n  ";
});
export var ScreenWrapper = styled(Div100vh).withConfig({
  displayName: "PageLoaderstyled__ScreenWrapper",
  componentId: "sc-31vbp7-1"
})(["position:fixed;top:0;left:0;width:100%;display:flex;justify-content:center;align-items:center;"]);
export var FullScreenMessageWrapper = styled.div.withConfig({
  displayName: "PageLoaderstyled__FullScreenMessageWrapper",
  componentId: "sc-31vbp7-2"
})(["width:150px;text-align:center;display:flex;flex-direction:column;align-items:center;"]);
export var StyledHeading = styled(HeadingSmall).withConfig({
  displayName: "PageLoaderstyled__StyledHeading",
  componentId: "sc-31vbp7-3"
})(["margin-top:", "px;color:", ";"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.white;
});