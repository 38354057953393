import styled from 'styled-components';
import Icon from '@ui/components/Icon';
import { BodyText } from '@ui/components/Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "SwitchArtistsstyled__Wrapper",
  componentId: "sc-19brula-0"
})([""]);
export var Search = styled.div.withConfig({
  displayName: "SwitchArtistsstyled__Search",
  componentId: "sc-19brula-1"
})(["padding:0 ", "px;margin-bottom:", "px;"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.spacer;
});
export var Artists = styled.div.withConfig({
  displayName: "SwitchArtistsstyled__Artists",
  componentId: "sc-19brula-2"
})(["max-height:200px;overflow-y:scroll;"]);
export var Artist = styled.div.withConfig({
  displayName: "SwitchArtistsstyled__Artist",
  componentId: "sc-19brula-3"
})(["display:flex;align-items:center;cursor:pointer;padding:", "px;padding-left:", "px;color:", ";&:hover{background-color:", ";}"], function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.dgrey;
});
export var AddArtistBlocker = styled.div.withConfig({
  displayName: "SwitchArtistsstyled__AddArtistBlocker",
  componentId: "sc-19brula-4"
})(["text-align:center;cursor:pointer;padding:", "px;color:", ";"], function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.tertiary;
});
export var Text = styled(BodyText).withConfig({
  displayName: "SwitchArtistsstyled__Text",
  componentId: "sc-19brula-5"
})(["flex:1;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;margin:0 ", "px;"], function (props) {
  return props.theme.spacer;
});
export var TickIcon = styled(Icon).attrs({
  name: 'tick'
}).withConfig({
  displayName: "SwitchArtistsstyled__TickIcon",
  componentId: "sc-19brula-6"
})(["color:", ";margin-right:", "px;"], function (props) {
  return props.theme.primary;
}, function (props) {
  return props.theme.smallSpacer;
});
export var LinkIcon = styled(Icon).attrs({
  name: 'link'
}).withConfig({
  displayName: "SwitchArtistsstyled__LinkIcon",
  componentId: "sc-19brula-7"
})(["color:", ";margin-right:", "px;"], function (props) {
  return props.theme.linkColour;
}, function (props) {
  return props.theme.smallSpacer;
});
export var AddArtistIcon = styled(Icon).attrs({
  name: 'add-artist'
}).withConfig({
  displayName: "SwitchArtistsstyled__AddArtistIcon",
  componentId: "sc-19brula-8"
})(["margin:6px;font-size:12px;"]);
export var AddArtist = styled.div.withConfig({
  displayName: "SwitchArtistsstyled__AddArtist",
  componentId: "sc-19brula-9"
})(["display:grid;grid-template-columns:auto 1fr;align-items:center;grid-gap:", "px;background-color:", ";margin-top:", "px;padding:", "px;color:black;cursor:pointer;"], function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.smallSpacer;
});