import React from 'react';
import { WithDataInjectedProps } from '@ui/types';
import { BoxInBox } from 'components/common/Box/Box.styled';
import { InfoPair } from 'components/common/InfoPair/InfoPair.styled';
import entities from '@api/entities';
import { withData } from '@api/withData';
import Button from '@ui/components/Button';
import useOpenWallet from '@hooks/useOpenWallet';
import {
  HeadingLabelSmall,
  InfographicNumber,
} from '@ui/components/Typography';
import PageLoader from '@ui/components/PageLoader/PageLoader';
import useRoyaltyBalance from '@hooks/useRoyaltyBalance';

interface Props {
  collapseNav?: () => void;
}

/**
 * Displays the users current account balance can be placed anywhere in the app
 */
const AccountBalance = (props: Props & WithDataInjectedProps) => {
  /* refactor styled component */

  const balance = useRoyaltyBalance(props);

  const openWallet = useOpenWallet();

  function handleWithdrawClick() {
    props.collapseNav?.();
    openWallet();
  }

  return !props.data ? (
    <BoxInBox>
      <PageLoader message="Getting balance" />
    </BoxInBox>
  ) : (
    <BoxInBox direction="row" alignItems="end">
      <InfoPair>
        <InfographicNumber data-testid="account-balance_displayed">
          {balance}
        </InfographicNumber>
        <HeadingLabelSmall>Current Balance</HeadingLabelSmall>
      </InfoPair>
      <Button
        text="withdraw"
        small
        secondary
        onPress={handleWithdrawClick}
        dataTestId="wallet-withdraw-button"
      />
    </BoxInBox>
  );
};

export default withData({
  component: AccountBalance,
  primaryEntity: entities.payment,
  requireAct: false,
});
