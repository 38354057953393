import React, { useState } from 'react';
import useDevice from '@hooks/useDevice';
import { MediaLibraryProps } from '@ui/types';
import Input from '@ui/components/Input';
import Select from '@ui/components/Select';
import { Wrapper } from './MediaMenu.styled';
import Dropzone from '../../common/Dropzone/Dropzone';

interface Props {
  updateSearchValue: (value: string) => void;
  sortBy: string;
  onFileDrop: (files: any[]) => void;
  handleSelect: (filteredOption) => void;
}

const sortOptions = [
  { value: 'date_newest', label: 'Newest First' },
  { value: 'date_oldest', label: 'Oldest First' },
  { value: 'size_largest', label: 'Largest First' },
  { value: 'size_smallest', label: 'Smallest First' },
  { value: 'name_asc', label: 'Order by name (A-Z)' },
  { value: 'name_des', label: 'Order by name (Z-A)' },
];

const MediaMenu = (props: Props & MediaLibraryProps) => {
  const { isMobile } = useDevice();
  const [searchInput, setSearchInput] = useState('');

  const sortLabel = sortOptions.find(
    option => option.value === props.sortBy
  )?.label;

  const handleSearchChange = value => {
    setSearchInput(value);
    props.updateSearchValue(value);
  };

  return (
    <>
      {!isMobile && (
        <Wrapper>
          <Input
            small
            value={searchInput}
            icon="search"
            onChange={handleSearchChange}
            placeholder="Search"
            isLight
          />
          <Select
            isSmall
            options={sortOptions}
            value={[
              {
                value: props.sortBy || 'date_newest',
                label: sortLabel!,
              },
            ]}
            onChange={props.handleSelect}
            placeholder="Sort"
            isLight
          />
        </Wrapper>
      )}
      <Dropzone
        onDrop={props.onFileDrop}
        accept={props.accept}
        multiple
        text={props.dropzoneText}
        showButton
      />
    </>
  );
};

export default MediaMenu;
