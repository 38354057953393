import React from 'react';
import ReactSelect, {
  Props as SelectProps,
  components,
  PlaceholderProps,
} from 'react-select';
import { isNull } from 'lodash';
import { useTheme } from 'styled-components';
import { Option } from '@ui/types';
import { getReactSelectStyles } from '../../theme/moduleStyles/reactSelectStyles';
import { PlaceholderText } from '../Typography';
import { Wrapper, Label } from './Select.styled';

interface Props<OptionType, IsMulti extends boolean>
  extends Omit<SelectProps<OptionType, IsMulti>, 'onChange'> {
  label?: string;
  isSmall?: boolean;
  className?: string;
  isLight?: boolean;
  isInBox?: boolean;
  isClearable?: boolean;
  onChange: (
    option: IsMulti extends true ? readonly Option[] : Option,
    arg?: any
  ) => void;
}

/**
 * Wraps, configures and styles third-party select box 'react-select'
 */
const Select = <OptionType extends Option, IsMulti extends boolean = false>(
  props: Props<OptionType, IsMulti>
) => {
  const theme = useTheme();
  const styles = getReactSelectStyles(theme, props);
  return (
    <Wrapper className={props.className}>
      {props.label && <Label>{props.label}</Label>}

      <ReactSelect
        {...props}
        styles={styles}
        isClearable={props.isClearable}
        value={props.value}
        onChange={option => {
          if (!isNull(option))
            props.onChange(
              option as IsMulti extends true ? readonly Option[] : Option
            );
        }}
        placeholder={props.placeholder || 'Select...'}
        closeMenuOnSelect
        components={{
          Input: cprops => <components.Input {...cprops} autoComplete="none" />,
          Placeholder: ({ children, ...cprops }: PlaceholderProps<any>) => (
            <components.Placeholder {...cprops}>
              <PlaceholderText>{children}</PlaceholderText>
            </components.Placeholder>
          ),
        }}
      />
    </Wrapper>
  );
};

export default Select;
