import React from 'react';
import { getYear, getMonth } from 'date-fns';
import { range } from 'lodash';
import Icon from '@ui/components/Icon';
import Select from '@ui/components/Select';

import { Wrapper } from './CustomHeader.styled';

interface Props {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  maxDate?: Date;
  minDate: Date;
}

const CustomHeader = (props: Props) => {
  const years = props.maxDate
    ? range(getYear(props.maxDate), getYear(props.minDate) - 1, -1)
    : range(getYear(props.minDate), getYear(new Date()) + 6, 1);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return (
    <Wrapper>
      <Icon
        name="left"
        clickHandler={props.decreaseMonth}
        disabled={props.prevMonthButtonDisabled}
      />
      <Select
        isSmall
        value={[
          {
            value: getYear(props.date).toString(),
            label: getYear(props.date).toString(),
          },
        ]}
        options={years.map(year => ({
          value: year.toString(),
          label: year.toString(),
        }))}
        onChange={({ value }) => props.changeYear(+value)}
      />
      <Select
        isSmall
        value={[
          {
            value: getMonth(props.date).toString(),
            label: months[getMonth(props.date)],
          },
        ]}
        options={months.map((month, i) => ({
          value: i.toString(),
          label: month,
        }))}
        onChange={({ value }) => props.changeMonth(+value)}
      />
      <Icon
        name="right"
        clickHandler={props.increaseMonth}
        disabled={props.nextMonthButtonDisabled}
      />
    </Wrapper>
  );
};

export default CustomHeader;
