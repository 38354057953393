import React from 'react';
import { useNavigateWithSearch } from '@hooks/useRouting';
import { setCookie, cookieNames } from '@helpers/checkAndSetCookie';
import { ModalInjectedProps } from '@ui/types';
import { PLATFORM_TITLES } from '@ui/consts';
import ButtonWrapper from '@ui/components/ButtonWrapper';
import Button from '@ui/components/Button';
import { BodyText, HeadingLabel } from '@ui/components/Typography';
import { Box } from 'components/common/Box/Box.styled';
import { ModalFooter } from 'components/common/ModalFooter/ModalFooter.styled';
import { ModalPadding } from 'components/common/ModalPadding/ModalPadding.styles';
import BulletList from 'components/common/BulletList';

type Social = {
  platform: string;
  reason: string;
};

interface Props {
  actId: string;
  socials: Social[];
}

const ReconnectSocials = (props: Props & ModalInjectedProps) => {
  const navigate = useNavigateWithSearch();

  const dismiss = () => {
    setCookie(`${cookieNames.reconnectSocials}-${props.actId}`, 1);
    props.closeClickHandler();
  };

  const reconnect = () => {
    const social = props.socials.length === 1 ? props.socials[0].platform : '';
    navigate(`social-profiles`, social ? { modalPlatform: social } : {});
    props.closeClickHandler();
  };

  const messages = type => {
    if (type === 'invalid') return 'Session is no longer valid.';
    if (type === 'expired') return 'Session has expired.';
    return 'Session is no longer valid.';
  };

  const socials = props.socials
    ?.map(el => PLATFORM_TITLES[el.platform])
    .join(', ');
  return (
    <ModalPadding>
      <Box isLightMode>
        <BodyText>
          Your {socials} accounts are connected, but your security token has
          expired. This is a normal security practice that will protect you in
          case you leave yourself logged in for too long. Some Beatchain
          functionality will be unavailable until you reconnect your social
          accounts.`
        </BodyText>
        <BodyText>Until you reconnect a social account:</BodyText>
        <BulletList
          items={[
            {
              slug: 'no-new-posts',
              label: "You won't be able to schedule new posts.",
            },
            {
              slug: 'failed-posts',
              label: 'Already scheduled posts will not go through.',
            },
            {
              slug: 'insights',
              label: 'Insights data will not be collected.',
            },
          ]}
        />
        <HeadingLabel>Platforms affected:</HeadingLabel>
        <BulletList
          items={props.socials?.map(el => ({
            slug: el.platform,
            label: `${PLATFORM_TITLES[el.platform]}: ${messages(el.reason)}`,
          }))}
        />
      </Box>
      <ModalFooter>
        <ButtonWrapper numberOnRight={2}>
          <Button squishy dark onPress={dismiss} text="Dismiss" />
          <Button text="Reconnect here" onPress={reconnect} icon="right" />
        </ButtonWrapper>
      </ModalFooter>
    </ModalPadding>
  );
};

export default ReconnectSocials;
