import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#FFF' : 'transparent'}
      d="M263.97 195.05h-68.92v243.8h73.14v-117.1c0-40.86 17.53-65.75 54.3-65.75 30.28 0 43.22 28.45 43.22 60.88v121.98h73.15V303.54c0-72.44-20.56-111.54-94.21-111.54-38.38 0-69.41 19.24-79.92 38.18h-.76v-35.13zM146.28 195.05v243.8H73.14v-243.8h73.14zM151.96 127.2a45.7 45.7 0 1 0-84.48-34.94 45.7 45.7 0 0 0 84.48 34.94z"
    />
    <path
      fill={isColour ? '#0073B0' : 'currentColor'}
      fillRule="evenodd"
      d="M474.92 0H38.59C17.72 0 0 16.5 0 36.84v438.3C0 495.49 11.63 512 32.5 512h436.32c20.9 0 43.18-16.5 43.18-36.87V36.84A36.7 36.7 0 0 0 474.92 0zM195.05 195.05h68.92v35.13h.76c10.5-18.94 41.54-38.18 79.92-38.18 73.65 0 94.2 39.1 94.2 111.54v135.32h-73.14V316.88c0-32.43-12.94-60.88-43.22-60.88-36.77 0-54.3 24.9-54.3 65.75v117.1h-73.14v-243.8zm-121.9 243.8h73.14v-243.8H73.14v243.8zm82.28-329.14a45.7 45.7 0 1 1-91.42.05 45.7 45.7 0 0 1 91.42-.05z"
      clipRule="evenodd"
    />
  </svg>
);
