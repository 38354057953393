import { DefaultTheme } from 'styled-components';
import { canUseDOM } from '@helpers/executionEnvironment';
import { COLOURS } from '../consts';

const createTheme = (scale: number): DefaultTheme => ({
  // Scale
  scale,

  // Window
  windowWidth: canUseDOM ? window.innerWidth : 0,
  windowHeight: canUseDOM ? window.innerHeight : 0,

  // Spacer
  spacer: 8 * scale,
  xSmallSpacer: 2 * scale,
  smallSpacer: 4 * scale,
  largeSpacer: 16 * scale,
  xlargeSpacer: 32 * scale,
  xxlargeSpacer: 48 * scale,
  xxxlargeSpacer: 64 * scale,

  // Radius
  borderRadius: 3 * scale,

  // Buttons
  buttonHeightSmall: 24 * scale,
  buttonHeight: 36 * scale,
  buttonHeightLarge: 44 * scale,
  buttonMinWidth: 136 * scale,
  buttonMinWidthSmall: 90 * scale,
  buttonFooterHeight: 88 * scale,

  // Inputs
  inputBorderRadius: 6,

  // Columns
  centeredDesktopColumnWidth: 448,

  // Colors
  background: 'rgb(12,12,12)',
  midground: 'rgba(255, 255, 255, 0.048)',
  foreground: 'rgb(255, 255, 255)',
  primary: 'rgb(92, 255, 16)',
  secondary: 'rgb(222, 255, 26)',
  tertiary: 'rgb(255, 97, 203)',
  success: 'rgb(92, 255, 16)',
  warning: 'rgb(164, 37, 59)',
  attention: 'rgb(222, 255, 26)',
  linkColour: 'rgb(255, 97, 203)',
  info: 'rgb(41, 73, 139)',
  yellow: 'rgb(222, 255, 26)',
  pink: 'rgb(255, 97, 203)',
  green: 'rgb(92, 255, 16)',
  blue: 'rgb(41, 73, 139)',
  red: 'rgb(164, 37, 59)',
  white: 'rgb(255, 255, 255)',
  vvlgrey: 'rgb(243, 243, 243)',
  vlgrey: 'rgb(231, 231, 231)',
  mgrey: 'rgb(219, 219, 219)',
  lgrey: 'rgb(183, 183, 183)',
  grey: 'rgb(119, 119, 119)',
  dgrey: 'rgb(72, 72, 72)',
  vdgrey: 'rgb(48, 48, 48)',
  vvdgrey: 'rgb(24, 24, 24)',
  black: 'rgb(0, 0, 0)',
  darkShade: 'rgba(0, 0, 0, 0.5)',
  lightShade: 'rgba(0, 0, 0, 0.2)',

  // Shadows
  shadow: `${1 * scale}px ${2 * scale}px ${4 * scale}px rgba(0, 0, 0, 0.12)`,
  darkShadow: `0px 0px ${16 * scale}px rgba(0, 0, 0, 0.6)`,
  solidShadow: `${3 * scale}px ${3 * scale}px 0px 0px rgba(128, 128, 128, 0.5)`,
  solidShadowLight: `${3 * scale}px ${3 * scale}px 0px 0px #FFF`,
  solidShadowAttention: `${3 * scale}px ${
    3 * scale
  }px 0px 0px rgb(255, 97, 203)`,
  solidShadowFacebook: `${3 * scale}px ${3 * scale}px 0px 0px ${
    COLOURS.facebook
  }`,
  solidShadowInstagram: `${3 * scale}px ${3 * scale}px 0px 0px ${
    COLOURS.instagram
  }`,
  solidShadowTwitter: `${3 * scale}px ${3 * scale}px 0px 0px ${
    COLOURS.twitters
  }`,
  shadowsDarkerLonger: '1.5px 3px 6px 1.5px rgba(51, 51, 51, 0.5)',

  // icons (Avatar, icons etc...)
  iconCanvasSize: 24,

  // Top Bar
  topbarHeightDesktop: 96,
  topbarHeightMobile: 56,

  // Scrollbars
  scrollbarWidth: 8,

  // Modals
  modalMediumWidth: 1000,
  modalMediumHeight: 600,

  // Alert
  alertWidth: 500,

  // Z-Index
  zIndexModal: 300,
  zIndexShading: 200,
  zIndexSideNav: 187,
  zIndexAppHeader: 175,
  zIndexTopNav: 150,
  zIndexFadeScreen: 75,

  // Fonts
  buttonFont: 'Beatchain Button',
  headingFont: 'Beatchain Heading',
  headingBrandFont: 'Beatchain Brand Heading',
  bodyFont: 'Beatchain Body',
  bodyStrongFont: 'Beatchain Body Strong',
  bodyBoldFont: 'Beatchain Body Bold',
  bodyItalicFont: 'Beatchain Body Italic',
  infographicFont: 'Beatchain Subheading',

  // Typography
  headingFontSize: 17 * scale,
  headingLetterSpacing: -0.68 * scale,
  headingLineHeight: 18.7 * scale, //

  headingLargeFont: 'Beatchain Brand Heading',
  headingLargeFontSize: 24 * scale,
  headingLargeLetterSpacing: -1 * scale,
  headingLargeLineHeight: 28 * scale,

  headingXLargeFont: 'Beatchain Brand Heading',
  headingXLargeFontSize: 40 * scale,
  headingXLargeLetterSpacing: -1.6 * scale,
  headingXLargeLineHeight: 44 * scale,

  headingSmallFont: 'Beatchain Heading',
  headingSmallFontSize: 14 * scale,
  headingSmallLetterSpacing: -0.56 * scale,
  headingSmallLineHeight: 16 * scale,

  headingLabelFont: 'Beatchain Subheading',
  headingLabelFontSize: 13 * scale,
  headingLabelLineHeight: 14.2 * scale,
  headingLabelLetterSpacing: 1 * scale,
  headingLabelTextTransform: 'uppercase',

  headingLabelSmallFontSize: 8 * scale,
  headingLabelSmallLineHeight: 9.2 * scale,

  headingLabelLargeFontSize: 16 * scale,
  headingLabelLargeLineHeight: 18.7 * scale,

  headingInfographicFont: 'Beatchain Subheading',
  headingInfographicFontSize: 24 * scale,
  headingInfographicLineHeight: 24 * scale,
  headingInfographicLetterSpacing: -1 * scale,
  headingInfographicTextTransform: 'uppercase',

  infographicNumberFont: 'Beatchain Body Bold',
  infographicNumberFontSize: 35 * scale,
  infographicNumberLineHeight: 35 * scale,
  infographicNumberLetterSpacing: -1.4 * scale,
  infographicNumberTextTransform: 'uppercase',

  bodyTextFont: 'Beatchain Body',
  bodyTextFontSize: 14 * scale,
  bodyTextLineHeight: 20 * scale,

  bodyStrongTextFont: 'Beatchain Body Strong',
  bodyStrongTextFontSize: 14 * scale,
  bodyStrongTextLetterSpacing: -0.56 * scale,
  bodyStrongTextLineHeight: 20 * scale,

  descriptionTextFont: 'Beatchain Body',
  descriptionTextFontSize: 12 * scale,
  descriptionTextLineHeight: 20 * scale,

  smallTextFont: 'Beatchain Body',
  smallTextFontSize: 11 * scale,
  smallTextLineHeight: 12 * scale,

  buttonTextFont: 'Beatchain Button',
  buttonTextFontSize: 15 * scale,
  buttonTextLineHeight: 15 * scale,
  buttonTextLetterSpacing: 0 * scale,
  buttonTextTextTransform: 'uppercase',

  buttonSmallTextFont: 'Beatchain Button',
  buttonSmallTextFontSize: 12 * scale,
  buttonSmallTextLineHeight: 12 * scale,
  buttonSmallTextLetterSpacing: 0 * scale,
  buttonSmallTextTextTransform: 'uppercase',

  inputTextFont: 'Beatchain Body',
  inputTextFontSize: 14 * scale,
  inputTextLineHeight: 20 * scale,

  placeholderTextFont: 'Beatchain Body Italic',
  placeholderTextFontSize: 14 * scale,
  placeholderTextLineHeight: 20 * scale,
  placeholderTextFontStyle: 'normal',

  headingTitleFont: 'Beatchain Heading',
  headingTitleFontSize: 32 * scale,
  headingTitleLetterSpacing: -1.28 * scale,
  headingTitleLineHeight: 35.2 * scale,

  headingSubtitleFont: 'Beatchain Subheading',
  headingSubtitleFontSize: 24.5 * scale,
  headingSubtitleLineHeight: 35.2 * scale,
  headingSubtitleLetterSpacing: -1 * scale,
  headingSubtitleTextTransform: 'uppercase',
});

export default createTheme;
