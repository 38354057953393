import React from 'react';
import useTheme from '@hooks/useTheme';
import Loader from '../Loader';
import Icon from '../Icon';
import { ButtonProps } from './types';

import { Wrapper, Content, Text, LoaderWrapper } from './Button.styled';

/**
 * Button with many options for size, cta, icon, colour etc.
 */
const Button = ({
  text,
  small,
  loading,
  squishy,
  dark,
  light,
  disabled,
  secondary,
  tertiary,
  grey,
  darkShadow = true,
  lightShadow,
  attentionShadow,
  icon,
  iconFirst,
  iconSize,
  iconOnly,
  colour,
  clean,
  fullWidth,
  type = 'submit',
  onPress = () => {},
  style,
  shouldNotStretch,
  warning,
  className,
  alignRight,
  dataTestId,
  outline,
  roundedCorners,
  ...props
}: ButtonProps) => {
  const theme = useTheme();

  return (
    <Wrapper
      data-testid={dataTestId || 'button'}
      small={small}
      disabled={disabled || loading}
      visuallyDisabled={disabled}
      squishy={squishy}
      iconOnly={iconOnly}
      fullWidth={fullWidth}
      dark={dark}
      darkShadow={darkShadow}
      light={light}
      lightShadow={lightShadow}
      secondary={secondary}
      tertiary={tertiary}
      grey={grey}
      warning={warning}
      attentionShadow={attentionShadow}
      {...props}
      colour={colour}
      clean={clean}
      type={type}
      style={style}
      shouldNotStretch={shouldNotStretch}
      alignRight={alignRight}
      onClick={e => {
        e.stopPropagation();
        onPress();
      }}
      className={className}
      outline={theme.isButtonOutlined}
      roundedCorners={theme.isButtonOutlined}
    >
      {!iconOnly && (
        <Content
          iconLast={!!icon && !iconFirst}
          iconFirst={!!icon && iconFirst}
          small={small}
        >
          {loading && (
            <LoaderWrapper>
              <Loader
                color={dark ? 'white' : 'black'}
                small
                hasMessage={false}
              />
            </LoaderWrapper>
          )}

          <Text hidden={loading} small={small}>
            {text}
          </Text>
        </Content>
      )}
      {icon &&
        (iconOnly && loading ? (
          <Loader
            color={dark ? 'white' : 'black'}
            small={small}
            hasMessage={false}
          />
        ) : (
          <Icon
            name={icon}
            size={(small ? 'sm' : iconSize || '1x') as any}
            fixedWidth={iconOnly}
          />
        ))}
    </Wrapper>
  );
};

export default Button;
