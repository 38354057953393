import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg viewBox="0 0 160 50" fill="none" {...props}>
    <path
      fill={isColour ? '#9147FE' : 'currentColor'}
      d="M152.68 35.53h-10.55V22.37h-2.64v13.16h-10.55V2.63h10.55v9.21h7.92l5.27 5.27v18.42zm-26.37-13.16h-10.55v2.64h10.55v10.53H110.5l-5.28-5.27V17.11l5.28-5.27h15.82v10.53zm-23.73 0h-9.24v2.64h9.24v10.53h-14.5l-5.28-5.27V2.64h10.55v9.2h9.23v10.53zM80.16 9.21H69.61V2.64h10.55V9.2zm0 26.32H69.61V11.84h10.55v23.69zm-13.2 0H35.34l-5.28-5.26V11.84h10.56V25h2.63V11.84H53.8V25h2.64V11.84h10.55l-.02 23.69zM27.43 22.37h-9.23v2.64h9.24v10.53H12.92l-5.28-5.27V2.64h10.55v9.2h9.24l-.01 10.53zm121.3-13.16h-6.59V0h-14.47L121 9.21h-11.83l-3.96 3.95V9.21h-9.23V0h-29v9.21H26.15L19.51 0H5v31.58L23.46 50h18.46v-5.26L47.2 50h35.6v-5.26L88.08 50h17.13v-5.26L110.5 50h32.97l11.87-11.84V15.79l-6.6-6.58"
    />
    <path
      fill={isColour ? '#fff' : 'transparent'}
      d="M128.95 35.53h10.55V22.37h2.63v13.16h10.55V17.11l-5.27-5.27h-7.91v-9.2h-10.55v32.89zM82.8 30.27l5.27 5.26h14.5V25h-9.22v-2.63h9.23V11.84h-9.23v-9.2H82.8v27.63zm22.41 0l5.28 5.26h15.82V25h-10.55v-2.63h10.55V11.84H110.5l-5.28 5.27v13.16zM69.61 2.63h10.55v6.58H69.61V2.63zm-42.2 9.21H18.2v-9.2H7.64v27.63l5.27 5.26h14.5V25H18.2v-2.63h9.23V11.84zm2.64 18.43l5.28 5.26h31.64V11.84H56.43V25h-2.64V11.84H43.24V25H40.6V11.84H30.05v18.43zm39.56-18.43h10.55v23.69H69.61V11.84z"
    />
  </svg>
);
