import React, { useState, useEffect } from 'react';
import DefaultAvatar from './DefaultAvatar';
import { Main, Image } from './Avatar.styled';

export interface AvatarProps {
  size: number;
  source?: string;
  noScale?: boolean;
  outlined?: boolean;
  color?: string;
  isClickable?: boolean;
  onClick?: () => void;
}

/**
 * Takes in image URL and displays circular avatar with fallback for missing source
 */
const Avatar = ({ source, ...props }: AvatarProps) => {
  const [src, setSrc] = useState(source);
  useEffect(() => {
    setSrc(source);
  }, [source]);

  return (
    <Main {...props}>
      {src ? (
        <Image
          src={src}
          onError={() => setSrc('')}
          data-testid="account_avatar"
        />
      ) : (
        <DefaultAvatar />
      )}
    </Main>
  );
};

export default Avatar;
