import React from 'react';

import { IconProps } from './index';

const IconStripe = ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill={isColour ? '#635BFF' : 'currentColor'}
      d="M10.672 7.147c0-1.028.845-1.424 2.242-1.424 2.005 0 4.537.607 6.54 1.688V1.213C17.267.343 15.105 0 12.915 0 7.56 0 4 2.796 4 7.464c0 7.279 10.022 6.118 10.022 9.257 0 1.213-1.055 1.609-2.532 1.609-2.189 0-4.985-.897-7.2-2.11v6.277A18.283 18.283 0 0 0 11.49 24c5.486 0 9.257-2.716 9.257-7.437-.026-7.86-10.075-6.462-10.075-9.416Z"
    />
  </svg>
);

export default IconStripe;
