import { useEffect } from 'react';
import { useLocation } from '@hooks/useRouting';
import { cleanUrl } from '@helpers/tracking';

/**
 * Button which scrolls a page to the top
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // register a new page visit when the SPA route changes
    cleanUrl();
    window.analytics?.page(pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
