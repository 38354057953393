import React from 'react';
import { useParams } from '@hooks/useRouting';
import ajax from '@helpers/ajax';
import trackEvent from '@helpers/tracking';
import Page from 'components/app/Page';
import Scrollbars from 'components/app/Scrollbars';
import Affiliate from 'components/app/Affiliate';

const getPartner = async slug => {
  const data = await ajax(`public/partners/${slug}`, {}, 'GET', false);
  if (data) {
    localStorage.setItem('affiliate_partner', data.slug);
    localStorage.setItem('referral_code', data.referralCode);
  }
  return data;
};

const AffiliatePage = (props: { isUnauthenticated: boolean }) => {
  const { partner } = useParams();
  const [affiliate, setAffiliate] = React.useState();

  React.useEffect(() => {
    trackEvent('Affiliate Page Hit', { affiliate_slug: partner });
  }, []);

  const fetchData = React.useCallback(async () => {
    const data = await getPartner(partner);
    setAffiliate(data);
  }, [partner]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!affiliate) {
    return 'Loading';
  }
  if (!props.isUnauthenticated) {
    return (
      <Page>
        <Scrollbars>
          {/* @ts-ignore */}
          <Affiliate {...affiliate} />
        </Scrollbars>
      </Page>
    );
  }

  // @ts-ignore
  return <Affiliate {...affiliate} />;
};

export default AffiliatePage;
