/**
 * Creates a query string from an object of key value pairs
 */
const constructQueryParam = (
  param?: { [key: string]: any },
  hasPriorParam?
) => {
  if (!param) {
    return '';
  }

  const params = Object.keys(param)
    .map(key => {
      const value = param[key];
      if (!value) return false;
      return `${key}=${value}`;
    })
    .filter(Boolean);

  return hasPriorParam ? `&${params.join('&')}` : `?${params.join('&')}`;
};

export default constructQueryParam;
