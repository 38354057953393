import React from 'react';
import Icon from '@ui/components/Icon';
import { BodyText } from '@ui/components/Typography';

import { Wrapper } from './MetaItem.styled';

interface Props {
  text: string;
  iconName?: string;
  clickHandler?: () => void;
}

/**
 * UI for selection of items such as artists, locations, etc
 */
const MetaItem = (props: Props) => (
  <Wrapper>
    <BodyText>{props.text}</BodyText>
    {props.iconName && (
      <Icon name={props.iconName} size="sm" clickHandler={props.clickHandler} />
    )}
  </Wrapper>
);

export default MetaItem;
