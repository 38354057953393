import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill={isColour ? '#ffffff' : 'currentColor'}
      d="M21.75 3H2.25C1.00734 3 0 4.00734 0 5.25V18.75C0 19.9927 1.00734 21 2.25 21H21.75C22.9927 21 24 19.9927 24 18.75V5.25C24 4.00734 22.9927 3 21.75 3ZM21.75 5.25V7.16273C20.699 8.01862 19.0234 9.3495 15.4412 12.1545C14.6518 12.7754 13.0881 14.2672 12 14.2498C10.9121 14.2674 9.34786 12.7752 8.55877 12.1545C4.97719 9.34992 3.30117 8.01877 2.25 7.16273V5.25H21.75ZM2.25 18.75V10.0499C3.32409 10.9054 4.8473 12.1059 7.16897 13.9239C8.19352 14.7304 9.98775 16.5108 12 16.5C14.0024 16.5108 15.7739 14.7563 16.8306 13.9243C19.1522 12.1064 20.6759 10.9055 21.75 10.05V18.75H2.25Z"
    />
  </svg>
);
