import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Scrollbars as ReactCustomScrollbars,
  ScrollbarsProps,
} from 'rc-scrollbars';
import useDevice from '@hooks/useDevice';
import ScrollFade from '@benestudioco/react-scrollfade';

import {
  StyledCustomScrollBars,
  ThumbVertical,
  ThumbHorizontal,
  TrackVertical,
  TrackHorizontal,
  View,
  HiddenScrollElement,
  HiddenVerticalScrollElement,
} from './Scrollbars.styled';

interface Props extends Partial<ScrollbarsProps> {
  className?: string;
  isContentPaddedForScrollbar?: boolean;
  hasScrollFade?: boolean;
  shouldRememberScrollPosition?: boolean;
  scrollId?: string;
}

/**
 * Styles and configures a third-party scrollbars module 'rc-scrollbars'
 */
const Scrollbars: FC<Props> = props => {
  const { isDesktop } = useDevice();
  const scrollbar = useRef<ReactCustomScrollbars>(null);
  const [offset, setOffset] = useState<number>(0);

  if (props.shouldRememberScrollPosition) {
    useEffect(
      () => () => {
        sessionStorage.setItem(`${props.scrollId}`, offset.toString());
      },
      [offset]
    );
  }

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem(`${props.scrollId}`);
    if (scrollPosition !== null && props.shouldRememberScrollPosition) {
      scrollbar.current?.view?.scroll({
        top: parseInt(scrollPosition, 10),
        behavior: 'smooth',
      });
    }
  }, []);
  const scrollToTop = () => {
    scrollbar.current?.view?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollCustomProps = {
    scrollToTop,
  };

  const children = React.Children.map(props.children, child =>
    React.isValidElement(child)
      ? React.cloneElement(child, { scrollCustomProps })
      : child
  );

  return (
    <StyledCustomScrollBars
      ref={scrollbar}
      hideTracksWhenNotNeeded
      autoHide
      onUpdate={scroll =>
        props.shouldRememberScrollPosition && setOffset(scroll.scrollTop)
      }
      autoHeight={props.autoHeight}
      onScrollStart={props.onScrollStart}
      onScrollStop={props.onScrollStop}
      className={`Scrollbars ${props.className}`}
      renderTrackHorizontal={scrollProps =>
        isDesktop ? (
          <TrackHorizontal {...scrollProps} />
        ) : (
          <HiddenScrollElement />
        )
      }
      renderTrackVertical={scrollProps =>
        isDesktop ? (
          <TrackVertical {...scrollProps} />
        ) : (
          <HiddenVerticalScrollElement />
        )
      }
      renderThumbHorizontal={scrollProps =>
        isDesktop ? (
          <ThumbHorizontal {...scrollProps} />
        ) : (
          <HiddenScrollElement />
        )
      }
      renderThumbVertical={scrollProps =>
        isDesktop ? <ThumbVertical {...scrollProps} /> : <HiddenScrollElement />
      }
      renderView={scrollProps => (
        <View
          {...scrollProps}
          isDesktop={isDesktop}
          autoHeight={props.autoHeight}
          isContentPaddedForScrollbar={props.isContentPaddedForScrollbar}
        />
      )}
    >
      {props.hasScrollFade && <ScrollFade />}
      {children}
    </StyledCustomScrollBars>
  );
};

export default Scrollbars;
