import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BodyText } from '@ui/components/Typography';
import { TextLink } from '@ui/components/TextLink/TextLink.styled';
import InputCode from '@ui/components/InputCode';
import Loader from '@ui/components/Loader';

interface Props {
  resendCode: () => Promise<void>;
  onCorrectEntry: () => void;
  onSubmitCode: (code?: string) => Promise<boolean>;
}

const InviteCode = (props: Props) => {
  const [codeValue, setCodeValue] = useState<string>();
  const [state, setState] = useState<string>('input');
  const [codeResent, setCodeResent] = useState<boolean>(false);

  const handleChange = (value: string) => {
    setCodeValue(value);
  };

  useEffect(() => {
    const checkCode = async () => {
      setState('loading');
      const ok = await props.onSubmitCode(codeValue);
      if (!ok) {
        toast.error('Incorrect code');
        setCodeValue('');
        setState('incorrect');
        setState('input');
        return;
      }

      await props.onCorrectEntry();
    };
    if (codeValue?.length === 6) {
      checkCode();
    }
  }, [codeValue]);

  const resendCode = async () => {
    setState('loading');
    await props.resendCode();
    setCodeResent(true);
    toast.success('Code resent');
    setState('input');
  };

  return (
    <>
      {state === 'loading' ? (
        <Loader message="Checking Invite Code" />
      ) : (
        <InputCode
          label="Invite Code"
          onChange={(value: string) => handleChange(value)}
          isIncorrect={state === 'incorrect'}
          isLocked={state === 'loading'}
          dataTestId="invite-code"
        />
      )}
      {!codeResent && (
        <BodyText>
          <TextLink onClick={resendCode}>Resend code?</TextLink>
        </BodyText>
      )}
    </>
  );
};

export default InviteCode;
