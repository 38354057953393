import styled from 'styled-components';
import { readableColor } from 'polished';
import { Heading } from '@ui/components/Typography';
import Icon from '@ui/components/Icon';
export var Wrapper = styled.div.withConfig({
  displayName: "ModalWrapperstyled__Wrapper",
  componentId: "sc-4m4qzs-0"
})(["display:flex;align-items:center;justify-content:center;position:fixed;height:", "px;width:100vw;overflow:hidden;background-color:#000000c4;z-index:", ";"], function (props) {
  return props.height;
}, function (props) {
  return props.theme.zIndexModal;
});
export var CloseHitArea = styled.div.withConfig({
  displayName: "ModalWrapperstyled__CloseHitArea",
  componentId: "sc-4m4qzs-1"
})(["position:absolute;width:100%;height:100%;"]);
export var Modal = styled.div.withConfig({
  displayName: "ModalWrapperstyled__Modal",
  componentId: "sc-4m4qzs-2"
})(["position:relative;background:", ";display:grid;grid-template-rows:auto 1fr;max-width:calc(100% - ", "px);", ";", " ", " ", " ", " ", " ", " ", " ", " ", ""], function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return !props.showOverflow ? 'overflow: hidden;' : 'overflow: initial;';
}, function (props) {
  return props.size === 'alert' && "width: 304px;\n    height: auto;";
}, function (props) {
  return props.size === 'dialogue' && "max-width: 500px;";
}, function (props) {
  return props.size === 'small' && "width: 304px;\n    height: 300px;\n    ";
}, function (props) {
  return props.size === 'tall' && "width: 400px;\n    height: ".concat(props.theme.modalMediumHeight, "px;");
}, function (props) {
  return props.size === 'medium-small' && "width: 600px;\n    height: 400px;";
}, function (props) {
  return props.size === 'medium' && "width: ".concat(props.theme.modalMediumWidth, "px;\n    height: ").concat(props.theme.modalMediumHeight, "px;");
}, function (props) {
  return props.size === 'wizard' && "width: ".concat(props.theme.modalMediumWidth, "px;\n    height: 90vh;");
}, function (props) {
  return props.size === 'large' && "width: 90vw;\n    height: 90vh;";
}, function (props) {
  return props.isMobile && ['tall', 'medium', 'wizard', 'large'].includes(props.size) && "height: calc(100% - ".concat(props.theme.largeSpacer, "px);\n    width: calc(100% - ").concat(props.theme.largeSpacer, "px);");
});
export var Header = styled.div.withConfig({
  displayName: "ModalWrapperstyled__Header",
  componentId: "sc-4m4qzs-3"
})(["padding:", "px;display:grid;grid-template-columns:1fr ", "px;grid-gap:", "px;align-items:center;background:", ";color:", ";height:45px;box-sizing:border-box;", " ", " ", " ", ""], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.iconCanvasSize;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.info;
}, function (props) {
  return readableColor(props.theme.info);
}, function (props) {
  return props.hasGoBackButton && "grid-template-columns: ".concat(props.theme.iconCanvasSize, "px 1fr ").concat(props.theme.iconCanvasSize, "px;\n  ");
}, function (props) {
  return props.isSuccess && "background: ".concat(props.theme.success, ";\n    color: ").concat(readableColor(props.theme.success), ";");
}, function (props) {
  return props.isWarning && "background: ".concat(props.theme.warning, ";\n    color: ").concat(readableColor(props.theme.warning), ";");
}, function (props) {
  return props.isPayment && "background: ".concat(props.theme.vdgrey, ";\n    color: ").concat(readableColor(props.theme.vdgrey), ";");
});
export var ModalTitle = styled(Heading).withConfig({
  displayName: "ModalWrapperstyled__ModalTitle",
  componentId: "sc-4m4qzs-4"
})(["padding:0 ", "px;"], function (props) {
  return props.theme.spacer;
});
export var CloseButton = styled(Icon).attrs({
  name: 'cross'
}).withConfig({
  displayName: "ModalWrapperstyled__CloseButton",
  componentId: "sc-4m4qzs-5"
})(["font-size:", "px;height:100%;width:100%;"], function (props) {
  return props.theme.largeSpacer;
});
export var Content = styled.div.withConfig({
  displayName: "ModalWrapperstyled__Content",
  componentId: "sc-4m4qzs-6"
})(["position:relative;display:flex;justify-content:center;align-items:center;", ";", ""], function (props) {
  return !props.showOverflow && 'overflow: hidden';
}, function (props) {
  return props.isAlert && "justify-content: flex-start;";
});