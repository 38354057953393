import { useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import useUser from '@hooks/useUser';
import { useDispatch } from '@hooks/useStore';
import { fetchUserFeed, liveUserFeed } from '../store/user';
import { websocketBase } from '../config/backend.config';
import useActiveArtist from './useActiveArtist';

/**
 * Sets up web sockets connections
 */
const useWebSockets = () => {
  const { onboarded } = useUser();
  const { actId } = useActiveArtist();
  const dispatch = useDispatch();

  const fetchFeed = onboarded && !!actId;

  useEffect(() => {
    let ws: WebSocket;

    const connectLiveFeed = async () => {
      const session = await Auth.currentSession();
      const token = encodeURIComponent(session.getIdToken().getJwtToken());
      ws = new WebSocket(`${websocketBase}?token=${token}&channels=live_feed`);

      ws.onmessage = event => {
        const data = JSON.parse(event.data);
        dispatch(
          liveUserFeed({
            data: data.entries,
          })
        );
      };

      ws.onclose = event => {
        console.warn('Websocket connection closed', event);
        if (event.reason !== 'component unmounted') {
          setTimeout(connectLiveFeed, 5000);
        }
      };
    };

    if (fetchFeed) {
      dispatch(fetchUserFeed({ type: 'initial' }));
      connectLiveFeed();
    }

    return () => ws && ws.close(3333, 'component unmounted');
  }, [fetchFeed]);
};

export default useWebSockets;
