import styled from 'styled-components';
import Icon from '../Icon';
export var Label = styled.label.withConfig({
  displayName: "Checkboxstyled__Label",
  componentId: "sc-1icdtic-0"
})(["display:flex;position:relative;padding-left:", "px;cursor:", ";user-select:none;margin-bottom:", "px;"], function (props) {
  return props.theme.largeSpacer + props.theme.spacer;
}, function (props) {
  return props.isReadOnly ? 'default' : 'pointer';
}, function (props) {
  return props.theme.spacer;
});
export var Input = styled.input.attrs({
  type: 'checkbox'
}).withConfig({
  displayName: "Checkboxstyled__Input",
  componentId: "sc-1icdtic-1"
})(["position:absolute;opacity:0;height:0;width:0;"]);
export var Check = styled.span.withConfig({
  displayName: "Checkboxstyled__Check",
  componentId: "sc-1icdtic-2"
})(["position:absolute;top:", "px;left:0;height:", "px;width:", "px;border:solid ", " 1px;box-sizing:border-box;", " ", " &:after{content:'';position:absolute;display:", ";left:5px;top:3px;width:3px;height:7px;border:solid white;border-width:0 ", "px ", "px 0;transform:rotate(45deg);}"], function (props) {
  return props.theme.xSmallSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.color;
}, function (props) {
  return props.isTicked && 'border: none;';
}, function (props) {
  return props.isTicked && "background-color: ".concat(props.color || props.isSuccess ? props.theme.success : props.theme.grey, ";");
}, function (props) {
  return props.isTicked ? 'blocked' : 'none';
}, function (props) {
  return props.theme.xSmallSpacer;
}, function (props) {
  return props.theme.xSmallSpacer;
});
export var ReadOnlyIcon = styled(Icon).withConfig({
  displayName: "Checkboxstyled__ReadOnlyIcon",
  componentId: "sc-1icdtic-3"
})(["position:absolute;top:", "px;left:", "px;color:", ";"], function (props) {
  return props.theme.xSmallSpacer;
}, function (props) {
  return props.theme.xSmallSpacer;
}, function (props) {
  return props.color;
});