import React, { useState } from 'react';
import { Formik } from 'formik';
import validateForm from '@helpers/validateForm';
import ajax from '@helpers/ajax';
import { useSelector } from '@hooks/useStore';
import PageLoader from '@ui/components/PageLoader';
import { BodyText } from '@ui/components/Typography';
import ButtonWrapper from '@ui/components/ButtonWrapper';
import Button from '@ui/components/Button';
import Field from '@ui/components/Field';
import { FormWrapper } from 'components/common/FormWrapper';
import FormErrorMessage from 'components/common/FormErrorMessage';
import { toast } from 'react-toastify';

interface Props {
  onSubmit: (email?: string) => void;
  email?: string;
}

const RequestPasswordResetForm = (props: Props) => {
  const [resendEmailLoading, setResendEmailLoading] = useState(false);
  const [resendEmailSent, setResendEmailSent] = useState(false);

  const authResponse = useSelector(state => state.auth.REQUEST_RESET_PASSWORD);
  const errorMessage = authResponse.error?.message
    ?.replace(' or phone_number', '')
    ?.replace('registered/', '');

  const validationSchema = {
    email: {
      type: String,
      required: true,
      message: 'Email is required',
    },
  };

  const handleResendVerificationEmail = async (email?: string) => {
    setResendEmailLoading(true);
    setResendEmailSent(false);
    const res = await ajax(
      'public/verification-email',
      { email },
      'POST',
      false
    );
    if (res.error) {
      toast.error(res.error);
    }
    setResendEmailLoading(false);
    setResendEmailSent(!res.error);
  };

  const needsEmailVerification =
    errorMessage ===
    'Cannot reset password for the user as there is no verified email';

  return (
    <Formik
      initialValues={{
        email: props.email,
      }}
      validate={values => validateForm(validationSchema, values)}
      onSubmit={({ email }) => {
        props.onSubmit(email);
      }}
    >
      {formikProps => (
        <FormWrapper>
          <Field
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
            large
            dataTestId="forgot-enter-email"
          />

          {authResponse.fetching ? (
            <PageLoader message="Sending reset code" />
          ) : (
            <ButtonWrapper>
              <Button
                text="Next"
                icon="right"
                type="submit"
                loading={authResponse.fetching}
                data-testid="forgot-password-button"
              />
            </ButtonWrapper>
          )}
          {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
          {needsEmailVerification && (
            <>
              {resendEmailSent ? (
                <BodyText>
                  Follow the instructions in the email. You will be able to
                  request a password reset once your email address is verified.
                </BodyText>
              ) : (
                <ButtonWrapper>
                  <Button
                    text="Resend verification email"
                    light
                    onPress={() =>
                      handleResendVerificationEmail(formikProps.values.email)
                    }
                    loading={resendEmailLoading}
                  />
                </ButtonWrapper>
              )}
            </>
          )}
        </FormWrapper>
      )}
    </Formik>
  );
};

export default RequestPasswordResetForm;
