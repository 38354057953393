import React from 'react';
import { TitleBlockMain, Verb, Title, Subtitle } from './TitleBlock.styled';

interface Props {
  verb?: string;
  title?: string;
  subtitle?: string;
  shouldGoSmall?: boolean;
  className?: string;
  hasNoBox?: boolean;
}

/**
 * Styling of Title and Subtitle used in marketing
 */
const TitleBlock = (props: Props) => (
  <TitleBlockMain className={props.className} hasNoBox={props.hasNoBox}>
    {props.verb && (
      <Verb shouldGoSmall={props.shouldGoSmall}>{props.verb}</Verb>
    )}
    <Title shouldGoSmall={props.shouldGoSmall}>{props.title}</Title>
    <Subtitle shouldGoSmall={props.shouldGoSmall}>
      {` / ${props.subtitle}`}
    </Subtitle>
  </TitleBlockMain>
);

TitleBlock.defaultProps = {
  verb: '',
  title: 'You take care of the music',
  subtitle: "We'll take care of the industry",
};

export default TitleBlock;
