import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  BulletListMain,
  BulletListHeading,
  ListItem,
  ItemBullet,
  ItemText,
  ItemIcon,
} from './BulletList.styled';

interface Props {
  heading?: string;
  items?: {
    slug: string;
    isTicked?: boolean;
    isTextBig?: boolean;
    label: string;
  }[];
  stringItems?: string[];
}

/**
 * Proprietary bullet list
 */
const BulletList = (props: Props) => (
  <BulletListMain>
    {props.heading && <BulletListHeading>{props.heading}</BulletListHeading>}
    {props.items &&
      props.items.map(item => (
        <ListItem key={`item-${item.slug}`}>
          <ItemBullet />
          <ItemText isTicked={item.isTicked} isTextBig={item.isTextBig}>
            {ReactHtmlParser(item.label)}
          </ItemText>
          {item.isTicked !== undefined && (
            <ItemIcon name={item.isTicked ? 'tick' : 'cross'} />
          )}
        </ListItem>
      ))}
    {props.stringItems &&
      props.stringItems.map((stringItem, index) => (
        <ListItem key={index}>
          <ItemBullet />
          <ItemText>{stringItem}</ItemText>
        </ListItem>
      ))}
  </BulletListMain>
);

export default BulletList;
