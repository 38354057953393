import numeral from 'numbro';
import { isFinite, isNaN } from 'lodash';

const currencySymbols = {
  GBP: '£',
  EUR: '€',
  USD: '$',
};

export const getFormattedAmountWithCurrency = (
  value: number,
  currency?: string,
  exact: boolean = false,
  use3DecimalPlaces: boolean = false
) => {
  const mantissa = use3DecimalPlaces ? 3 : 2;
  const formatted = numeral(value).formatCurrency({
    thousandSeparated: true,
    mantissa: value < 1000 || exact ? mantissa : 0,
    currencySymbol: currencySymbols[currency || 'USD'],
  });
  return isNaN(value) || !isFinite(value) ? '-' : formatted;
};
