import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg viewBox="0 0 160 50" fill="none" {...props}>
    <path
      fill={isColour ? '#40AB5D' : 'currentColor'}
      d="M31.745 19.872H24.88v4.016h6.864v-4.016z"
    />
    <path
      fill={isColour ? 'url(#deezer__paint0_linear)' : 'currentColor'}
      d="M31.745 25.44H24.88v4.016h6.864V25.44z"
    />
    <path
      fill={isColour ? 'url(#deezer__paint1_linear)' : 'currentColor'}
      d="M31.745 31.008H24.88v4.016h6.864v-4.016z"
    />
    <path
      fill={isColour ? 'url(#deezer__paint2_linear)' : 'currentColor'}
      d="M6.864 36.576H0v4.016h6.864v-4.016z"
    />
    <path
      fill={isColour ? 'url(#deezer__paint3_linear)' : 'currentColor'}
      d="M15.151 36.576H8.287v4.016h6.864v-4.016z"
    />
    <path
      fill={isColour ? 'url(#deezer__paint4_linear)' : 'currentColor'}
      d="M23.456 36.576h-6.864v4.016h6.864v-4.016z"
    />
    <path
      fill={isColour ? 'url(#deezer__paint5_linear)' : 'currentColor'}
      d="M31.745 36.576H24.88v4.016h6.864v-4.016z"
    />
    <path
      fill={isColour ? 'url(#deezer__paint6_linear)' : 'currentColor'}
      d="M23.456 31.008h-6.864v4.016h6.864v-4.016z"
    />
    <path
      fill={isColour ? 'url(#deezer__paint7_linear)' : 'currentColor'}
      d="M15.151 31.008H8.287v4.016h6.864v-4.016z"
    />
    <path
      fill={isColour ? 'url(#deezer__paint8_linear)' : 'currentColor'}
      d="M15.151 25.44H8.287v4.016h6.864V25.44z"
    />
    <path
      fill={isColour ? '#000' : 'currentColor'}
      d="M37.455 30.304c0 6.416 3.952 10.256 9.824 10.256 2.928 0 5.392-.816 6.768-2.96v2.96h5.36V10H53.84v12.992c-1.264-2.144-3.6-3.104-6.528-3.104-5.728-.016-9.856 3.904-9.856 10.416zm16.56 0c0 3.664-2.496 5.952-5.488 5.952-3.104 0-5.488-2.288-5.488-5.952 0-3.728 2.4-6.064 5.488-6.064 2.992 0 5.488 2.368 5.488 6.064zM76.735 32.672c-.64 2.368-2.256 3.552-4.8 3.552-2.96 0-5.392-1.792-5.456-4.96H80.51a11.47 11.47 0 00.256-2.528c0-5.68-3.872-8.88-9.584-8.88-6.096 0-10.288 4.336-10.288 10.224 0 6.56 4.624 10.496 11.024 10.496 4.832 0 8.112-2.016 9.552-6.032l-4.736-1.872zm-10.256-5.12c.528-2.288 2.432-3.632 4.72-3.632 2.496 0 4.192 1.344 4.192 3.456l-.032.176h-8.88zM97.391 32.672c-.64 2.368-2.256 3.552-4.8 3.552-2.96 0-5.392-1.792-5.456-4.96h14.032a11.47 11.47 0 00.256-2.528c0-5.68-3.872-8.88-9.584-8.88-6.096 0-10.288 4.336-10.288 10.224 0 6.56 4.624 10.496 11.024 10.496 4.832 0 8.112-2.016 9.552-6.032l-4.736-1.872zm-10.256-5.12c.528-2.288 2.432-3.632 4.72-3.632 2.496 0 4.192 1.344 4.192 3.456l-.032.176h-8.88zM121.071 40.592v-5.04h-11.696l11.376-11.072v-4.608h-18v4.8h10.992l-11.344 11.12v4.8h18.672zM137.343 32.672c-.64 2.368-2.256 3.552-4.8 3.552-2.96 0-5.392-1.792-5.456-4.96h14.032a11.47 11.47 0 00.256-2.528c0-5.68-3.872-8.88-9.584-8.88-6.096 0-10.288 4.336-10.288 10.224 0 6.56 4.624 10.496 11.024 10.496 4.832 0 8.112-2.016 9.552-6.032l-4.736-1.872zm-10.256-5.12c.528-2.288 2.432-3.632 4.72-3.632 2.496 0 4.192 1.344 4.192 3.456l-.032.176h-8.88zM154.575 27.024v.592h5.424v-1.584c0-3.552-2.224-6.128-5.952-6.128-2.4 0-4.096 1.168-4.976 3.104v-3.104h-5.6v20.688h5.6V27.264c0-2.112 1.136-3.28 2.88-3.28 1.6.016 2.624 1.52 2.624 3.04z"
    />
    <defs>
      <linearGradient
        id="deezer__paint0_linear"
        x1={28.346}
        y1={29.491}
        x2={28.282}
        y2={25.381}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#358C7B" />
        <stop offset={0.526} stopColor="#33A65E" />
      </linearGradient>
      <linearGradient
        id="deezer__paint1_linear"
        x1={24.78}
        y1={34.818}
        x2={31.849}
        y2={31.216}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222B90" />
        <stop offset={1} stopColor="#367B99" />
      </linearGradient>
      <linearGradient
        id="deezer__paint2_linear"
        x1={0.001}
        y1={38.586}
        x2={6.86}
        y2={38.586}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F90" />
        <stop offset={1} stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="deezer__paint3_linear"
        x1={8.295}
        y1={38.586}
        x2={15.153}
        y2={38.586}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8000" />
        <stop offset={1} stopColor="#CC1953" />
      </linearGradient>
      <linearGradient
        id="deezer__paint4_linear"
        x1={16.59}
        y1={38.586}
        x2={23.448}
        y2={38.586}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CC1953" />
        <stop offset={1} stopColor="#241284" />
      </linearGradient>
      <linearGradient
        id="deezer__paint5_linear"
        x1={24.877}
        y1={38.586}
        x2={31.735}
        y2={38.586}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222B90" />
        <stop offset={1} stopColor="#3559A6" />
      </linearGradient>
      <linearGradient
        id="deezer__paint6_linear"
        x1={16.319}
        y1={34.099}
        x2={23.719}
        y2={31.935}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CC1953" />
        <stop offset={1} stopColor="#241284" />
      </linearGradient>
      <linearGradient
        id="deezer__paint7_linear"
        x1={8.051}
        y1={31.68}
        x2={15.397}
        y2={34.354}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.003} stopColor="#FC0" />
        <stop offset={1} stopColor="#CE1938" />
      </linearGradient>
      <linearGradient
        id="deezer__paint8_linear"
        x1={8.871}
        y1={24.816}
        x2={14.577}
        y2={30.081}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.003} stopColor="#FFD100" />
        <stop offset={1} stopColor="#FD5A22" />
      </linearGradient>
    </defs>
  </svg>
);
