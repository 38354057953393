import styled from 'styled-components';
import Icon from '@ui/components/Icon/Icon';
import { BodyText } from '@ui/components/Typography';
export var ExpandContentPanelMain = styled.div.withConfig({
  displayName: "ExpandContentPanelstyled__ExpandContentPanelMain",
  componentId: "sc-1vx7scz-0"
})(["display:flex;flex-direction:column;width:100%;"]);
export var TopBar = styled.div.withConfig({
  displayName: "ExpandContentPanelstyled__TopBar",
  componentId: "sc-1vx7scz-1"
})(["display:grid;grid-template-columns:auto 1fr;grid-gap:", "px;justify-items:flex-start;align-items:center;cursor:pointer;"], function (props) {
  return props.theme.largeSpacer;
});
export var Arrow = styled(Icon).attrs({
  name: 'right'
}).withConfig({
  displayName: "ExpandContentPanelstyled__Arrow",
  componentId: "sc-1vx7scz-2"
})(["color:", ";transition:transform 0.3s;"], function (props) {
  return props.theme.tertiary;
});
export var Panel = styled.div.withConfig({
  displayName: "ExpandContentPanelstyled__Panel",
  componentId: "sc-1vx7scz-3"
})(["margin:0 auto;padding-top:", "px;max-width:650px;"], function (props) {
  return props.theme.largeSpacer;
});
export var Heading = styled(BodyText).withConfig({
  displayName: "ExpandContentPanelstyled__Heading",
  componentId: "sc-1vx7scz-4"
})(["color:", ";text-decoration:underline;"], function (props) {
  return props.theme.tertiary;
});