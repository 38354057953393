import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 160 50"
    fill={isColour ? '#000' : 'currentColor'}
    {...props}
  >
    <path d="M15.25 12.24c.24.77-.6 3.04-1.48 4.02a4.91 4.91 0 01-2.93 1.54l-.7.02.02-.83c.02-2 1.93-4.25 4.07-4.83.84-.23.92-.23 1.02.08z" />
    <path
      fillRule="evenodd"
      d="M39.01 14.23a7.32 7.32 0 014.4 4.46c.26.75.32 1.14.32 2.76 0 2.08-.23 3.03-1 4.25a8.34 8.34 0 01-2.62 2.46c-1.49.82-1.97.9-5.48.98l-3.2.06v7.5h-4.3V13.83l5.56.04c5.32.06 5.56.07 6.32.35zm-7.57 11.58h1.66c2.98 0 4.3-.3 5.3-1.26a3.4 3.4 0 001.07-2.29c.21-1.77-.31-3.34-1.37-4.12-.91-.67-1.64-.82-4.27-.89l-2.4-.06v8.62zM80.07 36.7h-4.02l-.08-2.56-.55.73c-1.67 2.13-5.12 2.74-7.86 1.35a7.65 7.65 0 01-2.63-2.68c-.24-.46-.57-1.3-.74-1.88-.27-.95-.31-1.36-.31-3.43.01-2.63.16-3.41.94-4.96a6.44 6.44 0 016.84-3.62 5.94 5.94 0 014.07 2.47l.3.4v-8.66h4.04v22.83zM69.23 24.1A4.26 4.26 0 0171.2 23a3.87 3.87 0 014.33 2.65c.83 2.32.36 5.5-1.03 6.9-.77.77-1.33.99-2.57.99-.89 0-1.2-.06-1.73-.34a4.16 4.16 0 01-1.84-2.26c-.37-.92-.5-3.36-.27-4.46a6.1 6.1 0 011.13-2.39z"
    />
    <path d="M140.19 19.83l3.15.08v3.07l-3.16.08v4.63c0 4.35.02 4.66.26 5.07.35.57 1.13.88 2.1.82l.74-.04.07 3.13-1.04.1c-2.05.2-4.17-.28-5.05-1.12-1.05-1-1.17-1.8-1.17-7.77V23l-2.35-.08-.08-3.02h2.43v-1.92c0-1.06.02-1.96.05-1.99.03-.03.93-.04 1.99-.03l1.92.03.13 3.84zM16.19 18.22c1.29.28 2.2.75 2.95 1.56l.63.67-.88.85a5.29 5.29 0 00-1.21 6.4 5.3 5.3 0 002.24 2.29c.27.15.5.28.5.3 0 .32-1 2.41-1.53 3.23-1.78 2.72-2.85 3.55-4.42 3.4a6.55 6.55 0 01-1.83-.5 3.44 3.44 0 00-2.15-.37 5.2 5.2 0 00-2.12.5c-1.02.44-2.08.55-2.8.28-.9-.35-2.9-2.73-3.82-4.57A14.41 14.41 0 01.1 25.68a8.48 8.48 0 01.75-4 6 6 0 017.91-3c1.3.52 1.8.52 3.21 0 1.87-.69 2.68-.78 4.2-.46z" />
    <path d="M55.7 19.77c1.31.33 2.2.76 3.06 1.47 2.02 1.67 3.01 4.29 2.86 7.53-.12 2.74-.78 4.46-2.3 5.99-1.57 1.57-3.34 2.26-5.85 2.24-3.76 0-6.44-1.84-7.62-5.24-.35-1-.36-1.17-.36-3.46 0-2.3.01-2.48.36-3.5a9.15 9.15 0 012.2-3.42c1.86-1.63 4.99-2.29 7.65-1.61zm-3.36 3.17A4.34 4.34 0 0050 25.3a9.67 9.67 0 00-.43 4.11c.36 2.88 1.7 4.33 3.98 4.33 1.36 0 2.17-.45 2.95-1.57 1.21-1.76 1.36-5.26.33-7.3a3.72 3.72 0 00-4.5-1.93z" />
    <path d="M92.9 19.7a6.79 6.79 0 015.37 5.05c.1.47.2.94.2 1.02 0 .14-.47.18-1.92.18h-1.92l-.16-.6a3.43 3.43 0 00-3.41-2.5c-1.17 0-1.92.34-2.73 1.24-.86.96-1.17 1.87-1.24 3.67-.04.8 0 1.83.08 2.24.45 2.43 2.2 3.91 4.34 3.68.43-.04 1.02-.2 1.3-.35a3.62 3.62 0 001.65-1.96l.17-.59h3.87l-.1.57c-.45 2.75-2.4 4.78-5.3 5.5-1 .23-3.36.2-4.42-.08a7.69 7.69 0 01-5.46-5.53c-.12-.43-.25-1.52-.3-2.4-.14-3.14.68-5.51 2.55-7.31 1.72-1.68 4.69-2.4 7.43-1.83z" />
    <path
      fillRule="evenodd"
      d="M110.21 19.7c2.1.39 3.66 1.45 4.41 3l.44.9.08 13.1h-3.9v-2.47l-.45.57a5.93 5.93 0 01-4.33 2.12c-2.6.18-4.71-.95-5.68-3.02-.3-.65-.36-.96-.36-1.98s.05-1.33.36-1.97c.66-1.41 2.13-2.45 4.07-2.86.53-.11 2.13-.27 3.56-.35 1.44-.08 2.68-.2 2.74-.27.08-.07.08-.52-.01-1.2-.23-1.73-.98-2.41-2.77-2.52-1.64-.1-2.74.44-3.3 1.65l-.28.6h-3.67l.08-.67c.4-3.4 4.5-5.5 9.01-4.63zm-4.43 10.16c.54-.27 1.63-.42 4.31-.6h.14c.49-.04.72-.06.83.05.1.1.1.3.1.7v.13c-.03 1.15-.25 1.76-.84 2.42-1.7 1.86-5.22 1.66-5.78-.34-.27-.95.22-1.88 1.24-2.36z"
    />
    <path d="M127.95 19.95c.57.2 1.31.54 1.62.76a5.07 5.07 0 012.23 3.62l.06.6h-3.75l-.12-.46a2.6 2.6 0 00-1.3-1.61c-.41-.2-.8-.27-1.62-.27-1.23 0-1.77.17-2.35.79-.33.36-.4.55-.4 1.09 0 1.23.67 1.64 3.9 2.35 1.19.25 2.4.55 2.7.66 2.3.8 3.3 2.04 3.31 4.1.03 2.92-2.05 4.88-5.73 5.4-1.93.25-4.5-.2-5.96-1.04a5.62 5.62 0 01-2.56-3.76l-.07-.6h3.9l.22.6c.52 1.32 1.71 1.92 3.6 1.79 1.7-.11 2.55-.76 2.57-1.93 0-.48-.07-.67-.36-.98-.48-.5-1.08-.72-3.3-1.22-2.45-.55-3.35-.86-4.3-1.49a4 4 0 01-1.75-2.42c-.23-.92-.1-2.46.24-3.24.6-1.38 2.35-2.59 4.3-3a11 11 0 014.92.26zM154.7 19.7c2 .42 3.63 1.58 4.35 3.08.26.6.57 2.02.44 2.16-.04.04-.87.07-1.87.07-1.6 0-1.8-.03-1.87-.25a3.1 3.1 0 00-1.14-1.73c-.5-.32-.72-.38-1.76-.42-1-.04-1.27 0-1.77.25-1.16.55-1.5 1.74-.75 2.6.53.6 1.09.8 3.88 1.44 4.04.9 5.3 1.77 5.64 3.99.45 2.77-1.31 5.05-4.55 5.9-1.16.3-3.76.3-4.97-.02-1.84-.45-3.5-1.62-4.15-2.87-.31-.6-.7-2.11-.58-2.23.04-.04.94-.08 1.99-.08h1.92l.17.55c.08.3.35.76.58 1 1.16 1.23 4.58 1.1 5.35-.2.35-.59.3-1.38-.12-1.88-.4-.47-1.08-.7-4.09-1.42-3.65-.87-5-1.92-5.37-4.16a4.68 4.68 0 012.75-5.1 9.5 9.5 0 015.91-.68z" />
  </svg>
);
