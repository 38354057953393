import React, { useState } from 'react';
import { orderBy } from 'lodash';
import { setActiveArtist } from 'store/user';
import { useDispatch } from '@hooks/useStore';
import { useNavigate, useLocation } from '@hooks/useRouting';
import useUser from '@hooks/useUser';
import useModal from '@hooks/useModal';
import { featureIds } from 'config/features';
import Tooltip from '@ui/components/Tooltip';
import Avatar from '@ui/components/Avatar';
import { HeadingSmall } from '@ui/components/Typography';
import Input from '@ui/components/Input';
import ArtistName from 'components/onboarding/ArtistName';
import { getMediaItemThumbnail } from '@helpers/getMediaItemThumbnail';
import {
  Wrapper,
  Search,
  Artists,
  Artist,
  Text,
  TickIcon,
  AddArtistIcon,
  AddArtistBlocker,
  LinkIcon,
  AddArtist,
} from './SwitchArtists.styled';

interface Props {
  collapseNav?: () => void;
}

const SwitchArtists = (props: Props) => {
  const { artists, activeArtistId, canAddArtist, hasFeature } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const [showBlocker, setShowBlocker] = useState(false);
  const openModal = useModal();

  const dispatch = useDispatch();

  function switchArtistClick(actId) {
    if (props.collapseNav) props.collapseNav();
    // Always go to root of feature unless it's in royalties page;
    const getLocation = () => {
      if (location.pathname.split('/')[1] === 'royalties') {
        return location.pathname;
      }
      return location.pathname.split('/', 2).join('/') || '/welcome';
    };
    navigate(getLocation());
    if (actId !== activeArtistId) dispatch(setActiveArtist({ actId }));
  }

  const addArtistClick = e => {
    e.stopPropagation();
    if (props.collapseNav) props.collapseNav();

    if (!canAddArtist()) {
      setShowBlocker(true);
      return;
    }

    openModal({
      Component: ArtistName,
      modalTitle: 'Add new artist',
      modalSize: 'medium',
      componentProps: {
        showToast: true,
        isLightMode: true,
        standalone: true,
      },
    });
  };

  const orderedArtists = orderBy(artists, ({ actName }) =>
    actName?.toLowerCase()
  );

  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredArtists = searchTerm
    ? orderedArtists.filter(
        x => x.actName?.toLowerCase().indexOf(searchTerm?.toLowerCase()) > -1
      )
    : orderedArtists;

  return (
    <Wrapper>
      <Search>
        <Input
          dataTestId="artist-search_input"
          small
          autoFocus
          onChange={setSearchTerm}
          placeholder="Search"
        />
      </Search>
      <Artists>
        {filteredArtists.map(artist => (
          <Artist
            data-testid={artist.actName}
            key={artist.actId}
            onClick={e => {
              e.stopPropagation();
              switchArtistClick(artist.actId);
            }}
          >
            <Avatar
              source={
                getMediaItemThumbnail(artist.profile_image, 'square') ||
                artist.appProfileImage
              }
              size={24}
            />
            <Text>{artist.actName}</Text>
            {Boolean(artist.federated) && (
              <Tooltip
                text="This artist is not owned by you"
                id={`lined_artist_${artist.actId}`}
              >
                <LinkIcon />
              </Tooltip>
            )}
            {activeArtistId === artist.actId && <TickIcon />}
          </Artist>
        ))}
      </Artists>
      {hasFeature(featureIds.multiArtist) && !showBlocker && (
        <AddArtist onClick={addArtistClick} data-testid="switch-artists-button">
          <AddArtistIcon />
          <HeadingSmall>Add new artist</HeadingSmall>
        </AddArtist>
      )}
      {showBlocker && (
        <AddArtistBlocker>
          Reached maximum number of artists included in your subscription
        </AddArtistBlocker>
      )}
    </Wrapper>
  );
};

export default SwitchArtists;
