import styled from 'styled-components';

/**
 * Layout utility for spacing elements with consistent padding and alignment cross browser
 */
export var EquallySpacedColumn = styled.div.withConfig({
  displayName: "EquallySpacedColumnstyles__EquallySpacedColumn",
  componentId: "sc-e9tida-0"
})(["display:grid;grid-auto-flow:row;grid-gap:", "px;", " ", " height:min-content;"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.isSmallGap && "grid-gap: ".concat(props.theme.spacer, "px;");
}, function (props) {
  return props.isXlargeGap && "grid-gap: ".concat(props.theme.xlargeSpacer, "px;");
});