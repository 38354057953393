import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "Markdownstyled__Wrapper",
  componentId: "sc-uo5z5s-0"
})(["word-break:break-word;strong{font-weight:700;}p{margin-bottom:", "px;}ul{list-style:none;li{padding-left:", "px;margin-bottom:", "px;&::before{content:'\u2022';color:", ";font-weight:bold;display:inline-block;width:1em;margin-left:-1em;}}}h3{display:none !important;}"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.tertiary;
});