import { DefaultTheme } from 'styled-components';

export const getReactSelectStyles = (theme: DefaultTheme, props: any) => ({
  clearIndicator: base => ({
    ...base,
    margin: 0,
    padding: theme.smallSpacer,
    ...(props.isSmall && { width: theme.iconCanvasSize }),
  }),
  container: base => ({ ...base, maxHeight: 124 }),
  control: (base, { isFocused, isDisabled }) => ({
    ...base,
    margin: 0,
    width: '100%',
    backgroundColor: props.isLight ? theme.vvlgrey : theme.vdgrey,
    border: 'none',
    borderRadius: theme.inputBorderRadius,
    ...(isFocused && {
      outline: `${theme.tertiary} solid 1px`,
    }),
    ...(props.isInBox && {
      backgroundColor: props.isLight ? theme.vlgrey : theme.dgrey,
    }),
    ...(isDisabled && {
      backgroundColor: base.backgroundColor,
    }),
    minHeight: theme.buttonHeight,
    paddingTop: theme.xSmallSpacer,
    paddingBottom: theme.xSmallSpacer,
    paddingLeft: theme.spacer,
    paddingRight: theme.spacer,
    ...(props.isSmall && {
      minHeight: theme.buttonHeightSmall,
      height: theme.buttonHeightSmall,
      paddingRight: theme.smallSpacer,
      flexWrap: 'unset',
    }),
    cursor: 'pointer',
    boxShadow: 'none',
  }),
  dropdownIndicator: base => ({
    ...base,
    margin: 0,
    color: theme.lgrey,
    padding: theme.smallSpacer,
    ...(props.isSmall && { width: theme.iconCanvasSize }),
    ':hover': {
      color: props.isLight ? theme.black : theme.white,
    },
  }),
  group: base => ({ ...base }),
  groupHeading: base => ({ ...base }),
  indicatorsContainer: base => ({ ...base }),
  indicatorSeparator: () => ({ display: 'none' }),
  input: base => ({
    ...base,
    margin: 0,
    color: props.isLight ? theme.black : theme.white,
  }),
  loadingIndicator: base => ({ ...base }),
  loadingMessage: base => ({ ...base }),
  menu: base => ({
    ...base,
    borderRadius: theme.inputBorderRadius,
    marginTop: props.isSmall ? theme.smallSpacer : theme.spacer,
    padding: props.isSmall ? theme.smallSpacer : 6,
    backgroundColor: props.isLight ? theme.white : theme.vdgrey,
    ...(props.isInBox && {
      backgroundColor: props.isLight ? theme.vvlgrey : theme.dgrey,
    }),
    zIndex: 1000,
  }),
  menuList: base => ({
    ...base,
    padding: 0,
    maxHeight: 160,
    '::-webkit-scrollbar': {
      width: theme.smallSpacer,
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: props.isLight ? theme.vvlgrey : theme.vdgrey,
      borderRadius: 0,
    },
  }),
  menuPortal: base => ({ ...base }),
  multiValue: base => ({
    ...base,
    borderRadius: theme.inputBorderRadius / 2,
    width: 'fit-content',
    color: props.isLight ? theme.black : theme.white,
    backgroundColor: props.isLight ? theme.mgrey : theme.dgrey,
    ...(props.isInBox && {
      backgroundColor: props.isLight ? theme.mgrey : theme.grey,
    }),
  }),
  multiValueLabel: base => ({
    ...base,
    color: props.isLight ? theme.black : theme.white,
  }),
  multiValueRemove: (base, state) =>
    (state.data as any).isFixed
      ? { ...base, margin: 0, display: 'none' }
      : {
          ...base,
          ':hover': {
            background: theme.grey,
            borderRadius: theme.inputBorderRadius / 2,
          },
        },
  noOptionsMessage: base => ({ ...base }),
  option: (base, { isDisabled, isSelected, isFocused }) => ({
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    wordWrap: 'break-word',
    padding: 6,
    borderRadius: theme.inputBorderRadius,
    color: props.isLight ? theme.black : theme.white,
    boxSizing: 'border-box',
    cursor: 'pointer',
    ...(props.isSmall && {
      fontSize: theme.smallTextFontSize,
      lineHeight: `${theme.inputTextFontSize}px`,
      padding: theme.smallSpacer,
    }),
    ':hover': {
      background: props.isLight ? theme.vvlgrey : theme.dgrey,
      ...(props.isInBox && {
        background: props.isLight ? theme.vlgrey : theme.grey,
      }),
    },
    ...(isFocused && {
      background: props.isLight ? theme.vvlgrey : theme.dgrey,
      ...(props.isInBox && {
        background: props.isLight ? theme.vlgrey : theme.grey,
      }),
    }),
    ...(isSelected && {
      background: props.isLight ? theme.primary : theme.vvdgrey,
      ':hover': {
        background: props.isLight ? theme.primary : theme.vvdgrey,
        cursor: 'default',
      },
    }),
    ...(isSelected &&
      props.isInBox &&
      !props.isLight && {
        background: theme.vdgrey,
        ':hover': {
          background: theme.vdgrey,
        },
      }),
    ...(isDisabled && {
      background: props.isLight ? theme.primary : theme.vvdgrey,
      color: theme.grey,
      opacity: 0.5,
      ':hover': {
        background: props.isLight ? theme.primary : theme.vvdgrey,
        cursor: 'default',
      },
    }),
  }),
  placeholder: base => ({
    ...base,
    margin: 0,
    fontFamily: theme.placeholderTextFont,
    fontSize: `${theme.placeholderTextFontSize}px`,
    fontStyle: theme.placeholderTextFontStyle,
    color: props.isLight && props.isInBox ? theme.grey : theme.lgrey,
  }),
  singleValue: base => ({
    ...base,
    margin: 0,
    width: 'fit-content',
    color: props.isLight ? theme.black : theme.white,
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    color: props.isLight ? theme.black : theme.white,
    gap: theme.smallSpacer,
    wordBreak: 'break-word',
    ...(props.isSmall && {
      fontSize: 12,
      lineHeight: 1.1,
    }),
  }),
});
