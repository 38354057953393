import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#b5b8bd' : 'currentColor'}
      d="M256.45 121c-.35-3.1-.52-6.39-.52-9.67 0-24.5 10.7-50.92 29.69-72.5 9.5-10.87 21.58-19.84 36.25-27.1C336.37 4.5 350.17.7 363.29 0c.18 3.28.35 6.73.35 10.18 0 25.55-9.32 49.54-27.96 71.64-22.62 26.23-49.72 41.6-79.23 39.18zm-84.4 1.55c-22.62.35-43.68 6.21-63.18 17.95-19.33 11.4-34.87 27.1-46.6 47.12C47.42 212.31 40 242 40 276.34c0 29.87 5.52 60.93 16.74 92.52 10.36 29.69 23.48 55.58 39.36 78.02 14.84 20.88 27.1 35.73 37.1 44.36 15.54 14.5 30.9 21.4 46.61 20.71 10.19-.17 23.48-3.8 40.22-10.53 16.57-6.73 32.1-10 46.6-10 13.81 0 28.83 3.27 45.23 10 16.4 6.9 30.38 10.19 42.11 10.19 16.23-.35 31.42-7.08 45.57-20.03 9.32-8.1 21.06-22.26 35.56-42.97a285.39 285.39 0 0027.44-49.54c3.1-7.6 6.22-15.54 8.98-23.65a115.37 115.37 0 01-35.56-23.3c-23.3-21.58-35.21-48.85-35.56-81.47-.51-41.94 18.47-74.57 57.14-98.04-21.4-30.73-54.03-47.64-97-51.1-15.89-1.38-35.22 2.08-58.35 10.36-24.34 8.98-38.5 13.47-42.8 13.47-5.53 0-18.47-3.97-38.67-11.4-20.02-7.6-36.25-11.39-48.68-11.39z"
    />
  </svg>
);
