import styled from 'styled-components';
import { darken } from 'polished';
import { HeadingSmall, HeadingLabelSmall } from '@ui/components/Typography';
export var LinkWrapper = styled.div.withConfig({
  displayName: "NavbarLinkstyled__LinkWrapper",
  componentId: "sc-1vjlj6f-0"
})(["background-color:", ";padding:", "px ", "px;cursor:pointer;height:", "px;display:flex;align-items:center;user-select:none;justify-content:flex-start;color:", ";", ";", ";", " &:hover{", " background:", ";}&:active{background:", ";background:", ";color:", ";div{color:", ";}}", ";", ";"], function (props) {
  return props.theme.vdgrey;
}, function (props) {
  return props.hasDescription ? props.theme.smallSpacer : '0';
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.hasDescription ? props.theme.buttonHeight : props.theme.buttonHeight - 3;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.isBlocked && 'opacity: 0.4;';
}, function (props) {
  return props.isInverted && "background: ".concat(props.theme.secondary, ";\n    &:hover {\n      background: ").concat(darken(0.2, props.theme.secondary), ";\n    }");
}, function (props) {
  return props.isTertiary && "background: ".concat(props.theme.tertiary, ";\n    &:hover {\n      background: ").concat(darken(0.2, props.theme.tertiary), "\n    }");
}, function (props) {
  return props.isBlocked && 'cursor: not-allowed;';
}, function (props) {
  return !props.isBlocked && !props.isActive && !props.isInverted && !props.isTertiary && props.theme.dgrey;
}, function (props) {
  return props.isTertiary && props.theme.tertiary;
}, function (props) {
  return !props.isBlocked && !props.isTertiary && props.theme.secondary;
}, function (props) {
  return !props.isBlocked && props.theme.black;
}, function (props) {
  return !props.isBlocked && props.theme.black;
}, function (props) {
  return props.isHighlighted && "color: ".concat(props.theme.secondary, ";");
}, function (props) {
  return props.isActive && "\n      background: ".concat(props.theme.secondary, ";\n      color: ").concat(props.theme.black, ";\n      cursor: default;\n    ");
});
export var LinkTag = styled.a.withConfig({
  displayName: "NavbarLinkstyled__LinkTag",
  componentId: "sc-1vjlj6f-1"
})(["text-decoration:none;color:inherit;"]);
export var LinkText = styled(HeadingSmall).withConfig({
  displayName: "NavbarLinkstyled__LinkText",
  componentId: "sc-1vjlj6f-2"
})(["display:flex;flex-direction:column;position:relative;", ";", ";"], function (props) {
  return props.isInverted && "color: ".concat(props.theme.vdgrey, ";");
}, function (props) {
  return props.isTertiary && "color: ".concat(props.theme.black, ";");
});
export var LinkLabel = styled(HeadingLabelSmall).withConfig({
  displayName: "NavbarLinkstyled__LinkLabel",
  componentId: "sc-1vjlj6f-3"
})(["padding-top:", "px;"], function (props) {
  return props.theme.xSmallSpacer * 1.5;
});