import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg viewBox="0 0 160 50" fill="none" {...props}>
    <g fill={isColour ? '#000' : 'currentColor'} fillRule="evenodd">
      <path d="M57.948 34.649H53.86v-14.5h-5.287v-3.504h14.663v3.504h-5.288zM75.467 34.649h4.113V16.645h-4.113zM152.147 31.096h7.113v3.553h-11.224V16.645h4.111zM97.26 31.12h2.55c3.431 0 5.717-2.14 5.717-5.547 0-3.235-2.31-5.424-5.62-5.424h-2.648V31.12zm-3.99-14.475h6.661c4.915 0 9.806 2.603 9.806 8.953 0 5.986-4.794 9.05-9.562 9.05h-6.906V16.646zM124.754 27.898l2.712-7.041 2.686 7.041h-5.398zm7.803 6.75h4.521l-7.417-18.003h-4.245l-7.49 18.003h4.399l1.327-3.551h7.591l1.314 3.551zM21.079 19.268l-5.269 5.27-5.271-5.27 5.271-5.267zM21.079 29.809l-5.269 5.27-5.271-5.27 5.271-5.27zM10.54 19.269l-5.27 5.27L0 19.27 5.27 14z" />
      <path d="M31.618 19.269l-5.269 5.27-5.271-5.27L26.349 14z" />
    </g>
  </svg>
);
