import React, { useState, useRef, ComponentType } from 'react';
import { isObject, reject, omit, map, debounce, isArray } from 'lodash';
import ajax from '@helpers/ajax';
import constructQueryParam from '@helpers/constructQueryParam';
import guid from '@helpers/guid';
import useActiveArtist from '@hooks/useActiveArtist';
import { WithDataInjectedProps } from '@ui/types';
// import { useDispatch } from '@hooks/useStore';
// import { updateArtistState, updateUserState } from 'store/user';

// TODO debounce api calls
// TODO caching
// TODO error handling
// TODO unit tests
// TODO documentation

const updateDebounced = debounce(async (endpoint, data) => {
  const response = await ajax(endpoint, data, 'POST');
  return response;
}, 250);

export const withData =
  <P extends WithDataInjectedProps & { customDependencyArray?: any }>({
    component,
    primaryEntity,
    secondaryEntities = {},
    dataKey = '',
    swallowErrors = false,
    requireAct = true,
    preloadData = true,
  }: // redux = null, // TODO move to withGlobalState helper
  {
    component: ComponentType<P>;
    primaryEntity: (
      props: any
    ) => string | { path: string; params?: any; bodyParams?: any };
    secondaryEntities?: any;
    dataKey?: string;
    swallowErrors?: boolean;
    requireAct?: boolean;
    preloadData?: boolean;
  }): ComponentType<Omit<P, keyof WithDataInjectedProps>> =>
  props => {
    let isMounted = true;

    const { actId, actName, webAlias } = useActiveArtist() || {};

    const [primaryData, setPrimaryData] = useState<any>();
    const [secondaryData, setSecondaryData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const primaryDataRef = useRef(primaryData);
    primaryDataRef.current = primaryData;

    // const dispatch = useDispatch();
    // TODO: handle updating arrays...
    // const updateRedux = (key, data) => {
    //   if (!redux) return;
    //   if (redux?.isAct) {
    //     dispatch(
    //       updateArtistState({
    //         actId,
    //         datum: [
    //           {
    //             data,
    //             path: redux.path(key),
    //           },
    //         ],
    //       })
    //     );
    //   } else {
    //     dispatch(updateUserState({ [redux.path(key)]: data }));
    //   }
    // };

    if (!primaryEntity) {
      throw new Error('no primaryEntity defined, this is a mandatory field');
    }
    if (!component) {
      throw new Error('no component defined, this is a mandatory field');
    }

    const getApiRoute = (
      entity: (
        p: any
      ) => string | { path: string; params?: any; bodyParams?: any },
      id?: string
    ) => {
      const base = entity({ ...props, actId, actName, webAlias });
      if (id === undefined || isObject(base)) return base;

      const idx = base.indexOf('?');
      if (idx === -1) return `${base}/${id}`;
      return `${base.substring(0, idx)}/${id}${base.substring(idx)}`;
    };

    const tryToSave = async (id, itemToUpdate) => {
      const res = await ajax(
        getApiRoute(primaryEntity, id) as string,
        itemToUpdate,
        'POST'
      );
      return res;
    };

    const addItem = async (
      item: { [key: string]: any },
      canBeSaved: boolean
    ) => {
      const id = item[dataKey as string] ?? guid();

      const res = await tryToSave(id, item).catch(x => x);

      if (canBeSaved) {
        if (res?.error) return res;
      }

      if (res.conflicts) return res;
      if (primaryDataRef.current) {
        setPrimaryData([
          {
            ...item,
            [dataKey as string]: id,
          },
          ...primaryDataRef.current,
        ]);
      }
      // if (redux) updateRedux(id, item);

      return { id };
    };

    // TODO add bulk API endpoint
    // async function addItems(items) {
    //   for (const item of items) {
    //     await addItem(item);
    //   }
    // }

    async function deleteItem(id) {
      setPrimaryData(
        reject(primaryData, item => item[dataKey as string] === id)
      );

      const res = await ajax(
        getApiRoute(primaryEntity, id) as string,
        {},
        'DELETE'
      );
      // if (redux) updateRedux(id, undefined);
      return res;
    }

    async function deleteItems(ids) {
      setPrimaryData(
        primaryData.filter(item => !ids.includes(item[dataKey as string]))
      );

      await ajax(getApiRoute(primaryEntity) as string, { ids }, 'DELETE');
    }

    const updateItem = async (
      id: string,
      itemToUpdate: any,
      saveToApi = true,
      callback?: () => void,
      canBeSaved = false
    ) => {
      if (saveToApi && canBeSaved) {
        const response = await tryToSave(id, itemToUpdate).catch(x => x);
        callback?.();
        if (response?.error) return response;
      }

      if (!isArray(primaryData)) {
        setPrimaryData({
          ...primaryData,
          [id]: { ...primaryData[id], ...itemToUpdate },
        });
      }

      setPrimaryData(
        primaryData?.map(item =>
          item[dataKey as string] === id
            ? { ...item, ...omit(itemToUpdate, [dataKey as string]) }
            : item
        )
      );

      if (saveToApi && !canBeSaved) {
        tryToSave(id, itemToUpdate).catch(x => x);
        callback?.();
        return {};
      }
      // if (redux) updateRedux(id, itemToUpdate);
      return {};
    };

    const updateData = async (itemToUpdate: any, saveToApi = true) => {
      if (isArray(primaryData)) return {};
      setPrimaryData({
        ...primaryData,
        ...itemToUpdate,
      });
      if (saveToApi) {
        const res = await updateDebounced(
          getApiRoute(primaryEntity),
          itemToUpdate
        );
        return res;
      }
      return {};
    };

    const getData = async (paramOverride?: any, callback?: () => void) => {
      if (!isLoading) {
        setIsLoading(true);
      }

      const secondaryEntitiesAsArray = map(secondaryEntities, item => item);

      const promises = [primaryEntity, ...secondaryEntitiesAsArray].map(
        path => {
          const payload = getApiRoute(path);

          if (isObject(payload)) {
            const baseUri = payload.path;
            const filters = constructQueryParam({
              ...payload.params,
              ...paramOverride,
            });
            return ajax(
              baseUri + filters,
              payload.bodyParams ?? {},
              payload.bodyParams ? 'POST' : 'GET',
              true,
              !swallowErrors
            );
          }
          const filters = constructQueryParam(paramOverride);
          return ajax(payload + filters, {}, 'GET', true, !swallowErrors);
        }
      );

      return Promise.all(promises)
        .then(responses => {
          if (isMounted) {
            const primaryResponse = responses[0];
            const secondaryResponse = {};

            const secondaryResponses = reject(responses, (item, i) => i === 0);

            if (secondaryResponses.length > 0) {
              secondaryResponses.forEach((item, i) => {
                secondaryResponse[Object.keys(secondaryEntities)[i]] = item;
              });
            }

            setPrimaryData(primaryResponse);
            setSecondaryData(secondaryResponse);
            setIsLoading(false);
            callback?.();
          }
        })
        .catch(e => {
          if (!swallowErrors) throw e;
          setIsLoading(false);
          setPrimaryData(undefined);
          setSecondaryData({});
          callback?.();
          console.error(
            'WithData: getData failed ',
            primaryEntity,
            secondaryEntities,
            e
          );
        });
    };

    async function fetchMore(paramOverride, callback) {
      const payload = getApiRoute(primaryEntity);

      const filters = constructQueryParam({
        // ...payload.params,
        cursor: primaryData.cursor,
        sortBy: primaryData.sortBy,
        sortDirection: primaryData.sortDirection,
        search: primaryData.search,
        ...paramOverride,
      });
      const promise = ajax(payload + filters, {}, 'GET', true, !swallowErrors);

      return promise
        .then(res => {
          if (isMounted) {
            setPrimaryData({
              ...res,
              feed: (primaryData?.feed || []).concat(res?.feed || []),
            });
            callback?.();
          }
        })
        .catch(e => {
          if (!swallowErrors) throw e;
          setPrimaryData(undefined);
          callback?.();
          console.error(
            'WithData: getData failed ',
            primaryEntity,
            secondaryEntities,
            e
          );
        });
    }

    // eslint-disable-next-line react/prop-types
    const deps = [requireAct, actId, ...(props.customDependencyArray || [])];

    React.useEffect(() => {
      if ((!requireAct || actId) && preloadData) getData();
      return () => {
        isMounted = false;
      };
    }, deps);

    const Component = component;

    return (
      <Component
        {...(props as P)}
        data={primaryData}
        secondaryData={secondaryData}
        isLoading={isLoading}
        actId={actId}
        getData={getData}
        addItem={addItem}
        // addItems={addItems}
        deleteItem={deleteItem}
        deleteItems={deleteItems}
        updateItem={updateItem}
        fetchMore={fetchMore}
        updateData={updateData}
      />
    );
  };
