import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    viewBox="0 0 160 50"
    fill={isColour ? '#5000FF' : 'currentColor'}
    {...props}
  >
    <path d="M159.79 25.37c0-7.84-4.9-9.11-9.58-9.11h-6.36V34.5h6.36c4.67 0 9.58-1.26 9.58-9.14zm-3.3 0c0 5.86-3.06 6.25-6.28 6.25h-1.6c-.63 0-.96.1-1.26.27l-.7.45-.58-.58.4-.23c.27-.18.47-.44.6-.8.07-.22.11-.47.11-.78v-9.2a1.64 1.64 0 00-.7-1.44l-.4-.23.57-.58.7.44c.13.08.29.14.48.19l.3.03h2.07c3.23 0 6.28.39 6.28 6.22zm-15.73 2.36V16.26h-3.32v10.23c0 3.44-.7 5.39-4.5 5.39s-4.5-1.95-4.5-5.4V16.27h-3.33v11.47c0 4.56 2.58 7.04 7.83 7.04 5.22 0 7.82-2.48 7.82-7.04zm-18.25-2.36c0-5.3-3.38-9.37-8.96-9.37-5.6 0-8.99 4.06-8.99 9.37 0 5.34 3.38 9.4 8.99 9.4 5.58 0 8.96-4.06 8.96-9.4zm-3.33 0c0 3.86-1.86 6.5-5.63 6.5-3.77 0-5.66-2.64-5.66-6.5 0-3.83 1.89-6.47 5.66-6.47 3.77 0 5.63 2.64 5.63 6.47zm-30.03 9.4l13.37-2.34v-3.2L89.56 31.5l-.37-.4c.39-.12 1.32-.48 1.32-1.2V16.27H87.2v16.6c0 1.24.72 1.94 1.96 1.91zm-19.23-9.4c0-4.01 2.12-6.47 5.74-6.47 2.89 0 4.9 1.55 5.32 4.4h3.33c-.54-4.5-3.77-7.3-8.7-7.3-5.61 0-9.02 4.06-9.02 9.37 0 5.34 3.4 9.4 9.01 9.4 4.94 0 8.17-2.8 8.7-7.32h-3.32c-.42 2.85-2.43 4.43-5.32 4.43-3.62 0-5.74-2.47-5.74-6.5zm-14.39 2.81l8.65 6.24V30.6l-7.33-5.2 7.33-5.2v-3.84l-8.65 6.27h-.93l-8.63-6.27v3.83l7.31 5.2-7.3 5.21v3.83l8.62-6.24h.93zm-30.65-1.13h18.26v-3.32H24.88v3.32zm-6.85 7.46h3.33V16.26h-6.59l-3.66 17.4h-.86l-3.67-17.4H0V34.5h3.33V19.3l-.6-2.18h1.05l3.85 17.4h6.12l3.83-17.4h1.07l-.62 2.18V34.5z" />
  </svg>
);
