import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "PhoneInputstyled__Wrapper",
  componentId: "sc-51rocw-0"
})([".form-control{width:100%;font-family:", ";font-size:", "px;line-height:", "px;height:", "px;border:none;border-radius:", "px;background-color:", ";color:", ";&:active,&:focus{outline:1px solid ", ";}&:disabled{opacity:0.8;border:1px solid ", ";}::placeholder{font-family:", ";font-style:", ";font-size:", "px;color:", ";}}.flag-dropdown{color:", ";border-radius:5px 0 0 5px;background-color:", ";border:none;box-sizing:border-box;&:active,&:focus{outline:1px solid ", ";}&:disabled{opacity:0.8;border:1px solid ", ";}&.open{border-radius:5px 0 0 5px;outline:1px solid ", ";}.selected-flag{border-radius:5px 0 0 5px;&:hover{background-color:", ";}}&.open .selected-flag{border-radius:5px 0 0 5px;}.country-list{border-radius:", "px;padding:6px;.country{border-radius:", "px;&.highlight{background-color:", ";}}}}"], function (props) {
  return props.theme.inputTextFont;
}, function (props) {
  return props.theme.inputTextFontSize;
}, function (props) {
  return props.theme.inputTextLineHeight;
}, function (props) {
  return props.theme.buttonHeight;
}, function (props) {
  return props.theme.inputBorderRadius;
}, function (props) {
  return props.theme.vvlgrey;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.placeholderTextFont;
}, function (props) {
  return props.theme.placeholderTextFontStyle;
}, function (props) {
  return props.theme.placeholderTextFontSize;
}, function (props) {
  return props.theme.grey;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.vlgrey;
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.vlgrey;
}, function (props) {
  return props.theme.inputBorderRadius;
}, function (props) {
  return props.theme.inputBorderRadius;
}, function (props) {
  return props.theme.vvlgrey;
});