import styled from 'styled-components';

/**
 * Layout component which places a footer in a modal
 */
export var ModalFooter = styled.div.withConfig({
  displayName: "ModalFooterstyled__ModalFooter",
  componentId: "sc-3zou04-0"
})(["display:flex;justify-content:flex-end;margin-top:", "px;"], function (props) {
  return props.isLarge ? props.theme.xlargeSpacer : props.theme.largeSpacer;
});