import React from 'react';
import Loader from '@ui/components/Loader';
import {
  FullScreenMessageWrapper,
  StyledHeading,
  PageLoaderWrapper,
  ScreenWrapper,
} from './PageLoader.styled';

const FullScreenMessage = (props: { message?: string }) => (
  <FullScreenMessageWrapper>
    <Loader />
    <StyledHeading>{props.message || 'Fetching Data'}</StyledHeading>
  </FullScreenMessageWrapper>
);

interface Props {
  message?: string;
  error?: string;
  isFullScreen?: boolean;
}

const PageLoader = (props: Props) => (
  <PageLoaderWrapper>
    {props.error && console.error(props.error)}
    <ScreenWrapper>
      {props.isFullScreen && <FullScreenMessage message={props.message} />}
      {!props.isFullScreen && (
        <Loader hasMessage message={props.message || 'Fetching Data'} />
      )}
    </ScreenWrapper>
  </PageLoaderWrapper>
);

export default PageLoader;
