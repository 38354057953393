import React from 'react';
import trackEvent from '@helpers/tracking';
import openExternalWebpage from '@helpers/openExternalWebpage';
import Button from '@ui/components/Button';
import TitleBlock from '@ui/components/TitleBlock';
import AppLandingPage from 'components/app/AppLandingPage';

const MusicVideos = () => (
  <AppLandingPage backgroundImageUrl="https://static.beatchain.com/butter/ILXjKEXSrGdNDGfLQQhdHeroes_Images_MDW_SPO_07.jpg">
    <TitleBlock
      title="Build stunning music videos in minutes"
      subtitle="10% Discount via our partnership with Rotor videos"
    />
    <Button
      text="Get started with Rotor"
      onPress={() => {
        trackEvent('Rotor Click');
        openExternalWebpage('https://rotorvideos.com/beatchain');
      }}
    />
  </AppLandingPage>
);

export default MusicVideos;
