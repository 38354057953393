import React, { useState, useEffect } from 'react';
import { Main } from './PageLoader.styled';
import Loader from '../Loader';

export interface PageLoaderProps {
  isLightBg?: boolean;
  isFullScreen?: boolean;
  message?: string;
  isOverlay?: boolean;
  delayInMilliseconds?: number;
}

/**
 * A wrapper for Loader for full page or within a parent positioning
 */
const PageLoader = ({
  isLightBg,
  isFullScreen,
  message,
  isOverlay,
  delayInMilliseconds = 1,
}: PageLoaderProps) => {
  const [isHidden, setIsHidden] = useState(!!delayInMilliseconds);
  useEffect(() => {
    const timer = setTimeout(() => setIsHidden(false), delayInMilliseconds);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Main
      isLightBg={isLightBg}
      isFullScreen={isFullScreen}
      isOverlay={isOverlay}
      isHidden={isHidden}
    >
      <Loader message={message} color={isLightBg ? 'dark' : 'light'} />
    </Main>
  );
};

export default PageLoader;
