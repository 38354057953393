import React from 'react';
import { Formik } from 'formik';
import validateForm from '@helpers/validateForm';
import Button from '@ui/components/Button';
import { FormWrapper } from 'components/common/FormWrapper';
import Field from '@ui/components/Field';
import { Heading } from '@ui/components/Typography';

interface Props {
  invalidatedPassword?: string;
  onSubmit: (values: { password: string }) => void;
  isSubmitting: boolean;
}

const NewPasswordForm = (props: Props) => {
  const validationSchema = {
    password: {
      type: String,
      match: /(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])/,
      required: true,
      length: {
        min: 8,
      },
      message: {
        match: 'Must contain a capital, lowercase, and number',
        required: 'Password is required',
      },
    },
  };

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: '',
      }}
      validate={values => {
        if (values.passwordConfirmation !== values.password) {
          return { passwordConfirmation: 'Passwords must be the same' };
        }
        if (values.password === props.invalidatedPassword) {
          return {
            passwordConfirmation:
              'New password must be different than the current one',
          };
        }
        return validateForm(validationSchema, values);
      }}
      onSubmit={values => props.onSubmit(values)}
    >
      <FormWrapper data-testid="new-password-form">
        <Heading>Please create a new password</Heading>
        <Field
          data-testid="password"
          label="New Password"
          name="password"
          type="password"
          placeholder="New Password"
          disabled={props.isSubmitting}
        />
        <Field
          data-testid="repeatpassword"
          label="Repeat Password"
          name="passwordConfirmation"
          type="password"
          placeholder="Repeat Password"
          disabled={props.isSubmitting}
        />
        <Button text="Submit" type="submit" loading={props.isSubmitting} />
      </FormWrapper>
    </Formik>
  );
};

export default NewPasswordForm;
