import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    {isColour ? (
      <>
        <path
          fill="#FC0"
          d="M251.012 512c113.224 0 205.011-91.674 205.011-204.76 0-113.086-91.787-204.761-205.011-204.761C137.787 102.479 46 194.154 46 307.24S137.787 512 251.012 512z"
        />
        <path
          fill="#F33"
          fillRule="evenodd"
          d="M353.668 98.526l.002 191.555-.453.151.001.245c0 18.736-1.36 36.212-3.901 45.132-12.217 42.897-51.738 74.011-98.605 74.011-56.613 0-101.905-45.687-101.905-102.23 0-56.542 45.292-102.23 101.905-102.23 26.674 0 50.969 10.177 69.205 26.854a102.98 102.98 0 0115.805 18.3c-1.258-6.004-2.498-13.557-2.498-20.014V0l133.421 40.832v92.472L353.668 98.526z"
          clipRule="evenodd"
        />
      </>
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M301.861 149.526l.002 191.555-.453.151v.245c0 18.736-1.36 36.212-3.9 45.132-12.217 42.897-51.738 74.011-98.605 74.011C142.292 460.62 97 414.933 97 358.39c0-56.542 45.292-102.23 101.905-102.23 26.674 0 50.969 10.177 69.204 26.854a102.98 102.98 0 0115.805 18.3c-1.258-6.004-2.497-13.557-2.497-20.014V51l133.421 40.832v92.472l-112.977-34.778z"
        clipRule="evenodd"
      />
    )}
  </svg>
);
