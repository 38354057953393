import styled from 'styled-components';
export var NewsFeedWrapper = styled.div.withConfig({
  displayName: "FeedContainerstyled__NewsFeedWrapper",
  componentId: "sc-ux2z5x-0"
})(["width:100%;max-width:", ";align-self:center;align-content:flex-start;padding-top:", "px;"], function (props) {
  return props.hasFeaturedCard && !props.isMobile ? '950px' : "".concat(props.theme.centeredDesktopColumnWidth, "px");
}, function (props) {
  return props.theme.largeSpacer;
});
export var LoaderContainer = styled.div.withConfig({
  displayName: "FeedContainerstyled__LoaderContainer",
  componentId: "sc-ux2z5x-1"
})(["width:100%;"]);