import React, { useState } from 'react';
import ReactAutosuggest, { RenderInputComponentProps } from 'react-autosuggest';
import { Heading } from '@ui/components/Typography';
import Avatar from '@ui/components/Avatar';
import Input from '@ui/components/Input';
import { useDebouncedCallback } from 'use-debounce';
import { AutosuggestMain, Suggestions, Suggestion } from './Autosuggest.styled';

interface Props {
  onAddClick: (suggestion: any) => void;
  search: (value: string) => Promise<any> | undefined;
  emptyResultMessage?: string;
  inputLabel?: string;
  suggestionTextPropName?: string;
  hasAvatars?: boolean;
  avatarPropName?: string;
  allowNotFound?: boolean;
  isLightMode?: boolean;
  isInBox?: boolean;
  initialValue?: string;
}

interface SuggestionType {
  [key: string]: string;
}

/**
 * Styles and configures third-party autosuggest form input 'react-autosuggest'
 */
const Autosuggest = (props: Props) => {
  const [inputValue, setValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [isEmptyResult, setIsEmptyResult] = useState<boolean>(false);

  const onChange = (_, { newValue }) => {
    setValue(newValue);
    setIsEmptyResult(false);
  };

  const renderInputComponent = (inputProps: RenderInputComponentProps) => (
    <Input
      label={props.inputLabel}
      {...inputProps}
      large
      isLight={props.isLightMode}
      isInBox={props.isInBox}
      icon="search"
      shouldUseEvent
      iconFirst
      dataTestId="search-autosuggest-input"
    />
  );

  const renderSuggestionsContainer = ({ containerProps, children }) => (
    <Suggestions {...containerProps}>{children}</Suggestions>
  );

  const renderSuggestion = suggestion => {
    const isNotClickable: boolean = !(
      props.suggestionTextPropName && suggestion[props.suggestionTextPropName]
    );

    return (
      <Suggestion
        isNotClickable={isNotClickable}
        hasAvatars={props.hasAvatars}
        isLightMode={props.isLightMode}
        data-testid={suggestion.artist_name}
      >
        {props.hasAvatars && !isNotClickable && (
          <Avatar
            size={32}
            source={props.avatarPropName && suggestion[props.avatarPropName]}
          />
        )}
        <Heading>
          {(props.suggestionTextPropName &&
            suggestion[props.suggestionTextPropName]) ||
            suggestion.message}
        </Heading>
      </Suggestion>
    );
  };

  const getEmptyResultObject: () => SuggestionType = () =>
    props.allowNotFound
      ? { [props.suggestionTextPropName || 'suggestion']: inputValue }
      : { message: `${inputValue} ${props.emptyResultMessage}` };

  const onSuggestionSelected = async (_, { suggestion }) =>
    props.onAddClick(suggestion);

  const formatSuggestions = (): Array<SuggestionType> => {
    const formattedSuggestions =
      props.suggestionTextPropName === 'spotify_name'
        ? [...suggestions]
        : [
            ...suggestions.filter(
              currentSuggestion => currentSuggestion.artist_name !== inputValue
            ),
          ];

    return [
      ...(isEmptyResult || props.allowNotFound ? [getEmptyResultObject()] : []),
      ...formattedSuggestions,
    ];
  };

  return (
    <AutosuggestMain>
      <ReactAutosuggest
        suggestions={
          fetching ? [{ message: 'Searching...' }] : formatSuggestions()
        }
        onSuggestionsFetchRequested={useDebouncedCallback(
          async ({ value }) => {
            const handleEmpty = () => {
              setSuggestions([]);
              setIsEmptyResult(true);
              setFetching(false);
            };
            try {
              const res = await props.search(value);
              if (res?.length === 0) {
                setSuggestions([]);
                setFetching(true);
              }
              if (res?.length > 0) {
                setSuggestions(res);
                setFetching(false);
                setIsEmptyResult(false);
              } else {
                handleEmpty();
              }
            } catch (exception) {
              handleEmpty();
            }
          },
          500,
          {
            leading: true,
          }
        )}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
          setValue('');
        }}
        getSuggestionValue={suggestion => suggestion?.artist_name}
        onSuggestionSelected={onSuggestionSelected}
        focusInputOnSuggestionClick={false}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSuggestion={renderSuggestion}
        highlightFirstSuggestion
        inputProps={{
          value: inputValue || '',
          onChange,
          spellCheck: 'false',
          autoCorrect: 'off',
        }}
      />
    </AutosuggestMain>
  );
};

export default Autosuggest;
