import React, { useState, useRef } from 'react';
import { includes } from 'lodash';
import VideoPlayer from './VideoPlayer';
import { VideoPlayerWrapper, StyledReactPlayer } from './VideoPlayer.styles';

interface Props {
  url: string;
  thumbnailUrl?: string;
  orientation?: 'landscape' | 'portrait' | 'square';
  isAutoplay?: boolean;
  children?: any;
}

const VideoPlayerContainer = (props: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(!!props.isAutoplay);
  const [isHovered, setIsHovered] = useState(false);

  const shouldShowHTMLVideoPlayer =
    includes(props.url, '.mp4') ||
    includes(props.url, '.mov') ||
    props.url === '';

  const clickHandler = () => {
    if (!videoRef?.current) return;
    if (isPlaying) videoRef.current.pause();
    if (!isPlaying) videoRef.current.play();
    setIsHovered(true);
    setIsPlaying(!isPlaying);
    setTimeout(() => setIsHovered(false), 3000);
  };

  return (
    <VideoPlayerWrapper orientation={props.orientation}>
      {shouldShowHTMLVideoPlayer ? (
        <VideoPlayer
          clickHandler={clickHandler}
          isPlaying={isPlaying}
          isHovered={isHovered}
          setIsHovered={setIsHovered}
          {...props}
          videoRef={videoRef}
          isAutoplay={props.isAutoplay}
        />
      ) : (
        <StyledReactPlayer
          url={[`${props.url}?wmode=transparent`]}
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: {
                wmode: 'transparent',
              },
            },
          }}
        />
      )}
      {props.children}
    </VideoPlayerWrapper>
  );
};

export default VideoPlayerContainer;
