import React, { FC } from 'react';
import Scrollbars from 'components/app/Scrollbars';
import { useNavigate } from '@hooks/useRouting';
import Logo from '@ui/components/Logo';
import useTheme from '@hooks/useTheme';
import useDevice from '@hooks/useDevice';
import { Wrapper, InnerWrap, Main, LogoContainer } from './Navbar.styled';

interface Props {
  slideDirection: 'down' | 'left' | 'right';
  hasBeatchainLogo?: boolean;
  isExpanded?: boolean;
}

const Navbar: FC<Props> = props => {
  const { isMobile, isTablet } = useDevice();
  const isDesktop = !isMobile && !isTablet;
  const navigate = useNavigate();
  const theme = useTheme();

  const navBarMainMarkup = (
    <Main slideDirection={props.slideDirection}>
      {props.hasBeatchainLogo && isMobile && (
        <LogoContainer onClick={() => navigate('/welcome')}>
          <Logo
            color="tertiary"
            type={theme.useFullLogoInHeader ? 'full' : 'icon'}
          />
        </LogoContainer>
      )}
      {props.children}
    </Main>
  );

  return (
    <Wrapper
      isExpanded={props.isExpanded}
      isDesktop={isDesktop}
      slideDirection={props.slideDirection}
    >
      <InnerWrap
        isMobile={isMobile}
        slideDirection={props.slideDirection}
        isExpanded={props.isExpanded}
      >
        {props.slideDirection === 'down' ? (
          navBarMainMarkup
        ) : (
          <Scrollbars isContentPaddedForScrollbar={false}>
            {navBarMainMarkup}
          </Scrollbars>
        )}
      </InnerWrap>
    </Wrapper>
  );
};

export default Navbar;
