import styled from 'styled-components';
export var AccountToggleMain = styled.div.withConfig({
  displayName: "AccountTogglestyled__AccountToggleMain",
  componentId: "sc-ngrnot-0"
})(["display:flex;align-items:center;justify-content:center;cursor:pointer;position:relative;margin-right:", "px;z-index:1;", ";"], function (props) {
  return !props.isMobile && props.theme.spacer;
}, function (props) {
  return props.isBlocked && "\n    cursor: not-allowed;\n    opacity: 0.4;\n  ";
});
export var UserWrapper = styled.div.withConfig({
  displayName: "AccountTogglestyled__UserWrapper",
  componentId: "sc-ngrnot-1"
})(["display:flex;"]);
export var UserInnerWrapper = styled.div.withConfig({
  displayName: "AccountTogglestyled__UserInnerWrapper",
  componentId: "sc-ngrnot-2"
})(["margin-left:", "px;margin-right:", "px;user-select:none;overflow:hidden;text-overflow:ellipsis;max-width:250px;"], function (props) {
  return props.theme.spacer * 2;
}, function (props) {
  return props.theme.spacer;
});
export var IconWrapper = styled.div.withConfig({
  displayName: "AccountTogglestyled__IconWrapper",
  componentId: "sc-ngrnot-3"
})(["padding:0;align-self:center;"]);