import React, { useState, useEffect } from 'react';
import ajax from '@helpers/ajax';
import { useParams } from '@hooks/useRouting';
import { websiteUrl } from 'config/backend.config';

const DistributionPreview = () => {
  const { userId, releaseId } = useParams();
  const [release, setRelease] = useState<any>();

  useEffect(() => {
    const getRelease = async () => {
      const response = await ajax(
        `public/distribution/${userId}/${releaseId}`,
        {},
        'GET',
        false
      );
      setRelease(response);
    };

    getRelease();
  }, [userId, releaseId]);

  if (release) {
    window.location.href = `${websiteUrl}/${release.artistData.webAlias}/${release.release_slug}`;
  }

  return <></>;
};

export default DistributionPreview;
