import React from 'react';
import Loader from '@ui/components/Loader';
import Link from '@ui/components/Link';
import Logo from '@ui/components/Logo';
import Footer from 'components/common/Footer';
import StaticBackground from 'components/common/StaticBackground';
import {
  ConfirmationWrapper,
  Header,
  Content,
  StyledHeading,
  SubHeading,
  LoaderWrapper,
  FooterWrapper,
} from './Confirmation.styled';

interface Props {
  confirming: boolean;
  success: boolean;
  alreadyConfirmed: boolean;
  error: boolean;
}

const Confirmation = (props: Props) => (
  <ConfirmationWrapper>
    <StaticBackground
      src="https://static.beatchain.com/images/beatchain_background_2.png"
      brightness="20%"
    />
    <Header>
      <Link to="/">
        <Logo color="white" />
      </Link>
    </Header>
    <Content>
      {props.confirming && (
        <>
          <StyledHeading>Confirming...</StyledHeading>
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </>
      )}
      {props.success && (
        <>
          <StyledHeading>
            {`Email ${
              props.alreadyConfirmed ? 'had already been' : ''
            } confirmed!`}
          </StyledHeading>
          <SubHeading>Redirecting...</SubHeading>
        </>
      )}
      {props.error && (
        <>
          <StyledHeading>Oops..</StyledHeading>
          <SubHeading>
            Email confirmation not possible right now - please contact
            support@beatchain.com
          </SubHeading>
        </>
      )}
    </Content>
    <FooterWrapper>
      <Footer />
    </FooterWrapper>
  </ConfirmationWrapper>
);

export default Confirmation;
