import React from 'react';

import { Wrapper, Lines, Line, Message } from './Loader.styled';

export interface LoaderProps {
  color?: 'black' | 'white' | 'dark' | 'light';
  small?: boolean;
  message?: string;
  hasMessage?: boolean;
  isHorizontal?: boolean;
  className?: string;
}

/**
 * Loading animation used throughout Beatchain
 */
const Loader = ({
  color = 'light',
  small,
  message = 'Loading',
  hasMessage = true,
  isHorizontal,
  className,
}: LoaderProps) => (
  <Wrapper className={className} isHorizontal={isHorizontal}>
    <Lines small={small || isHorizontal}>
      <Line color={color} small={small || isHorizontal} />
      <Line color={color} small={small || isHorizontal} delay={360} />
      <Line color={color} small={small || isHorizontal} delay={180} />
      <Line color={color} small={small || isHorizontal} delay={540} />
    </Lines>
    {hasMessage && <Message color={color}>{message}</Message>}
  </Wrapper>
);

export default Loader;
