import React, { useEffect, useState } from 'react';
import { Wrapper, Input, Check } from './LargeCheckbox.styled';

interface Props {
  name: string;
  isChecked?: boolean;
  isReadOnly?: boolean;
  handleChange: (name: string, isChecked: boolean) => void;
  className?: string;
  isSmaller?: boolean;
}

/**
 * Large Checkbox
 */
const LargeCheckbox = (props: Props) => {
  const [isChecked, setIsChecked] = useState(props.isChecked);

  useEffect(() => {
    setIsChecked(props.isChecked);
  }, [props.isChecked]);

  const handleChange = e => {
    const { name, checked } = e.target;
    if (props.isReadOnly) return;
    setIsChecked(checked);
    props.handleChange(name, checked);
  };

  return (
    <Wrapper isSmaller={props.isSmaller} className={props.className}>
      <Input
        name={props.name}
        type="checkbox"
        checked={isChecked}
        onChange={e => handleChange(e)}
      />
      <Check isSmaller={props.isSmaller} isChecked={isChecked} />
    </Wrapper>
  );
};

export default LargeCheckbox;
