import {
  useNavigate as _useNavigate,
  useParams as _useParams,
  useLocation as _useLocation,
  useSearchParams as _useSearchParams,
  createSearchParams as _createSearchParams,
  Location,
} from 'react-router-dom';

/**
 * Returns an imperative method for changing the location.
 * @see https://reactrouter.com/docs/en/v6/api#usenavigate
 */
export const useNavigate = _useNavigate;

/**
 * Returns a method for changing the location with a query object.
 */
export const useNavigateWithSearch = () => {
  const navigate = _useNavigate();
  return (pathname: string, search?: Record<string, string | string[]>) => {
    navigate({
      pathname,
      search: search ? `?${_createSearchParams(search)}` : undefined,
    });
  };
};

/**
 * Returns an object of key/value pairs of the dynamic params from the current URL that were matched by the route path.
 * @see https://reactrouter.com/docs/en/v6/api#useparams
 */
export const useParams = _useParams;

interface LocationWithState extends Location {
  state: {
    platforms?: string[];
    returnPath?: string;
    scheduled?: number;
    immediate?: boolean;
    copy?: string;
  } | null;
}

/**
 * Returns the current location object, which represents the current URL in web browsers.
 * @see https://reactrouter.com/docs/en/v6/api#uselocation
 */
export const useLocation = () => _useLocation() as LocationWithState;

/**
 * A convenient wrapper for reading and writing search parameters via the URLSearchParams interface.
 */
export const useSearchParams = _useSearchParams;

/**
 * Creates a URLSearchParams object using the given initializer.
 */
export const createSearchParams = _createSearchParams;
