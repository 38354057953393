import React from 'react';
import { useTheme } from 'styled-components';
import { getThemeLogoComponent } from '@ui/theme/themes';
import { IconBeatchain, IconBeatchainFull } from './icons';
import { Wrapper } from './Logo.styled';

export interface LogoProps {
  color: string;
  size?: number;
  type?: string;
  tileShape?: string;
}

/**
 * Beatchain logo
 */
const Logo = ({
  color = 'black',
  size = 32,
  type = 'full',
  tileShape = 'square',
}: LogoProps) => {
  const theme = useTheme();

  const getDefaultLogoComponent = logoType =>
    logoType === 'full' ? IconBeatchainFull : IconBeatchain;

  const LogoComponent = theme.themeName
    ? getThemeLogoComponent(theme.themeName, type)
    : getDefaultLogoComponent(type);

  return (
    <Wrapper color={color} size={size} type={type} tileShape={tileShape}>
      <LogoComponent color={theme[color]} />
    </Wrapper>
  );
};

export default Logo;
