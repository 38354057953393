import React, { FC } from 'react';
import useDevice from '@hooks/useDevice';
import ConditionalWrapper from '@ui/components/ConditionalWrapper';
import AuthFlowHeaderBar from 'components/app/AppHeaderBar/AuthFlowHeaderBar';
import { Box } from 'components/common/Box/Box.styled';
import SocialProofPanel from 'components/common/SocialProofPanel/SocialProofPanel';
import Scrollbars from 'components/app/Scrollbars';
import { FormPageWrapper, FormPageMain } from './FormPage.styles';

interface Props {
  hasNoHeader?: boolean;
  handleBackButtonClick?: () => void;
  hasLogOutButton?: boolean;
  hideSocialProofPanel?: boolean;
  hasScrollbars?: boolean;
  isLightMode?: boolean;
}

/**
 * Layout component for form pages such as login and sign up
 */
const FormPage: FC<Props> = props => {
  const { isMobile } = useDevice();
  return (
    <ConditionalWrapper
      condition={!!props.hasScrollbars}
      wrapper={children => <Scrollbars>{children}</Scrollbars>}
    >
      <FormPageWrapper>
        {!props.hasNoHeader && (
          <AuthFlowHeaderBar
            isMobile={isMobile}
            handleBackButtonClick={props.handleBackButtonClick}
            hasLogOutButton={props.hasLogOutButton}
          />
        )}
        <FormPageMain
          isMobile={isMobile}
          hideSocialProofPanel={props.hideSocialProofPanel}
        >
          <Box hasLargePadding hasLargeGap isLightMode={props.isLightMode}>
            {props.children}
          </Box>
          {!isMobile && !props.hideSocialProofPanel && <SocialProofPanel />}
        </FormPageMain>
      </FormPageWrapper>
    </ConditionalWrapper>
  );
};

export default FormPage;
