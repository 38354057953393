import styled from 'styled-components';
import { HeadingSmallClean } from '@ui/components/Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "Navigationstyled__Wrapper",
  componentId: "sc-1qcy0p7-0"
})(["display:grid;grid-template-columns:", "px 1fr;grid-gap:", "px;color:", ";z-index:1;user-select:none;"], function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.white;
});
export var Item = styled.div.withConfig({
  displayName: "Navigationstyled__Item",
  componentId: "sc-1qcy0p7-1"
})(["display:flex;align-items:center;a{text-decoration:none;}"]);
export var Label = styled(HeadingSmallClean).withConfig({
  displayName: "Navigationstyled__Label",
  componentId: "sc-1qcy0p7-2"
})(["color:white;"]);