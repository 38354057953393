import React from 'react';

interface Props {
  color: string;
}

export default ({ color, ...props }: Props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.942 2.457c.185.204 4.73 4.96 10.098 10.57L21.8 23.224v3.631c0 2.904-.035 3.597-.173 3.46-.096-.094-3.208-4.03-6.917-8.747L7.97 12.994l-3.922-.037-3.922-.037v40.994h7.78l.035-13.582.036-13.582 8.379 10.66c4.608 5.863 8.422 10.687 8.475 10.72.054.032.676.025 1.383-.016l1.285-.074-.007-16.134-.008-16.133-6.558-6.843-6.558-6.842-6.38-.001-6.382-.001.337.37ZM34.905 8.93l-6.558 6.843-.008 16.133-.007 16.134 1.285.074c.707.04 1.33.048 1.383.015.053-.032 3.867-4.856 8.475-10.719l8.38-10.66.035 13.582.035 13.582h7.78V12.92l-3.922.037-3.922.036-6.742 8.576c-3.709 4.717-6.821 8.653-6.917 8.747-.139.137-.173-.556-.173-3.46v-3.631l9.761-10.199c5.369-5.61 9.913-10.365 10.098-10.57l.337-.37-6.381.001-6.381.001-6.558 6.842Z"
      fill={color || '#000'}
    />
  </svg>
);
