import React from 'react';
import { MediaMeta } from '@ui/types';
import { formatNumberToContainBytes } from '@helpers/formatNumberToContainBytes';
import trackDuration from '@helpers/trackDuration';
import {
  formatNumbersTwoDecimalPlaces,
  formatNumbersWithUnit,
} from '@helpers/formatStreamingNumbers';
import ExternalTextLink from '@ui/components/ExternalTextLink';
import { HeadingLabel, HeadingSmall } from '@ui/components/Typography';
import { EquallySpacedColumn } from 'components/common/EquallySpacedColumn';
import { getCDNUrl } from '@helpers/getCDNUrl';
import { InfoPair } from '../../common/InfoPair';
import { HeadingBreakWord } from './MediaItemMeta.styled';
import { getFileExtension } from '../../../@helpers/getFileExtension';

interface Props {
  currentMediaItemMeta: Partial<MediaMeta>;
  name: string;
  url: string;
  isScrapped?: boolean;
}

const MediaItemMetaInformation = (props: Props & Partial<MediaMeta>) => {
  const renderFileType = mediaItemMeta => {
    if (props.isScrapped) {
      return '-';
    }
    return mediaItemMeta.filetype_info
      ? mediaItemMeta.filetype_info.user_provided_extension
      : getFileExtension(props.url);
  };

  const cdnUrl = getCDNUrl(props.url) || '';

  return (
    <EquallySpacedColumn>
      <>
        <InfoPair>
          <HeadingLabel>File Name</HeadingLabel>
          <HeadingBreakWord>{props.name}</HeadingBreakWord>
        </InfoPair>
        <InfoPair>
          <HeadingLabel>File Type</HeadingLabel>
          <HeadingSmall>
            {renderFileType(props.currentMediaItemMeta)}
          </HeadingSmall>
        </InfoPair>
        <InfoPair>
          <HeadingLabel>File Size</HeadingLabel>
          <HeadingSmall>
            {!props.currentMediaItemMeta.fileSize
              ? '-'
              : formatNumberToContainBytes(props.currentMediaItemMeta.fileSize)}
          </HeadingSmall>
        </InfoPair>
        <InfoPair>
          <HeadingLabel>URL</HeadingLabel>
          <HeadingBreakWord>
            <ExternalTextLink href={cdnUrl} text={cdnUrl} />
          </HeadingBreakWord>
        </InfoPair>
      </>

      {props.currentMediaItemMeta.fileType === 'audio' && (
        <>
          {props.currentMediaItemMeta.duration && (
            <InfoPair>
              <HeadingLabel>File Duration</HeadingLabel>
              <HeadingSmall>
                {trackDuration(props.currentMediaItemMeta.duration)}
              </HeadingSmall>
            </InfoPair>
          )}
          {props.currentMediaItemMeta.sample_rate && (
            <InfoPair>
              <HeadingLabel>Sample Rate</HeadingLabel>
              <HeadingSmall>
                {formatNumbersWithUnit(props.currentMediaItemMeta.sample_rate)}
              </HeadingSmall>
            </InfoPair>
          )}
          {props.currentMediaItemMeta.lufs && (
            <InfoPair>
              <HeadingLabel>Loudness</HeadingLabel>
              <HeadingSmall>
                {`${formatNumbersTwoDecimalPlaces(
                  props.currentMediaItemMeta.lufs
                )} LUFS`}
              </HeadingSmall>
            </InfoPair>
          )}
          {props.currentMediaItemMeta.bit_depth && (
            <InfoPair>
              <HeadingLabel>Bit Depth</HeadingLabel>
              <HeadingSmall>
                {props.currentMediaItemMeta.bit_depth}
              </HeadingSmall>
            </InfoPair>
          )}
        </>
      )}
    </EquallySpacedColumn>
  );
};
export default MediaItemMetaInformation;
