import styled from 'styled-components';
import { BodyText } from '@ui/components/Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "AccountPayoutsstyled__Wrapper",
  componentId: "sc-1knihd5-0"
})(["display:grid;grid-gap:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var WarningText = styled(BodyText).withConfig({
  displayName: "AccountPayoutsstyled__WarningText",
  componentId: "sc-1knihd5-1"
})(["color:", ";"], function (props) {
  return props.theme.warning;
});
export var AmountInput = styled.div.withConfig({
  displayName: "AccountPayoutsstyled__AmountInput",
  componentId: "sc-1knihd5-2"
})(["display:grid;grid-template-columns:auto 1fr;grid-gap:", "px;align-items:center;color:", ";"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.black;
});