import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg viewBox="0 0 160 50" fill="none" {...props}>
    <path
      fill={isColour ? '#4285F4' : 'currentColor'}
      d="M41.377 17.68h-17.74v5.264h12.58c-.62 7.382-6.762 10.53-12.56 10.53-7.416 0-13.888-5.836-13.888-14.015 0-7.968 6.17-14.104 13.905-14.104 5.968 0 9.485 3.805 9.485 3.805l3.686-3.817S32.114.076 23.485.076C12.5.076 4 9.35 4 19.366 4 29.18 11.995 38.75 23.766 38.75c10.353 0 17.932-7.093 17.932-17.58 0-2.212-.322-3.491-.322-3.491z"
    />
    <path
      fill={isColour ? '#EA4335' : 'currentColor'}
      d="M55.909 13.868c-7.28 0-12.496 5.69-12.496 12.328 0 6.735 5.06 12.532 12.581 12.532 6.81 0 12.387-5.204 12.387-12.387 0-8.233-6.488-12.473-12.472-12.473zm.072 4.882c3.58 0 6.972 2.894 6.972 7.558 0 4.564-3.378 7.54-6.988 7.54-3.968 0-7.097-3.177-7.097-7.576 0-4.306 3.09-7.521 7.113-7.521z"
    />
    <path
      fill={isColour ? '#FBBC05' : 'currentColor'}
      d="M83.086 13.868c-7.279 0-12.495 5.69-12.495 12.328 0 6.735 5.06 12.532 12.58 12.532 6.81 0 12.388-5.204 12.388-12.387 0-8.233-6.489-12.473-12.473-12.473zm.073 4.882c3.58 0 6.971 2.894 6.971 7.558 0 4.564-3.377 7.54-6.988 7.54-3.967 0-7.097-3.177-7.097-7.576 0-4.306 3.091-7.521 7.114-7.521z"
    />
    <path
      fill={isColour ? '#4285F4' : 'currentColor'}
      d="M109.741 13.881c-6.682 0-11.933 5.852-11.933 12.42 0 7.482 6.088 12.443 11.818 12.443 3.542 0 5.426-1.406 6.817-3.02v2.451c0 4.289-2.604 6.857-6.534 6.857-3.797 0-5.702-2.824-6.363-4.426l-4.778 1.997c1.695 3.584 5.107 7.32 11.18 7.32 6.643 0 11.706-4.183 11.706-12.959V14.628h-5.211v2.106c-1.602-1.727-3.793-2.853-6.702-2.853zm.483 4.873c3.276 0 6.64 2.797 6.64 7.573 0 4.856-3.357 7.531-6.712 7.531-3.562 0-6.876-2.892-6.876-7.484 0-4.773 3.442-7.62 6.948-7.62z"
    />
    <path
      fill={isColour ? '#34A853' : 'currentColor'}
      d="M125.286 38h5.475V1.36h-5.475V38z"
    />
    <path
      fill={isColour ? '#EA4335' : 'currentColor'}
      d="M144.826 13.852c-6.302 0-11.594 5.014-11.594 12.413 0 7.83 5.898 12.473 12.2 12.473 5.259 0 8.486-2.877 10.413-5.455l-4.297-2.86c-1.115 1.731-2.979 3.422-6.09 3.422-3.495 0-5.101-1.913-6.097-3.767l16.668-6.915-.865-2.027c-1.611-3.97-5.368-7.284-10.338-7.284zm.217 4.78c2.271 0 3.906 1.207 4.6 2.655l-11.13 4.652c-.48-3.602 2.932-7.307 6.53-7.307z"
    />
  </svg>
);
