import styled from 'styled-components';
import { DescriptionText } from '@ui/components/Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "FormErrorMessagestyled__Wrapper",
  componentId: "sc-1f1f585-0"
})(["padding:", ";display:flex;justify-content:", ";"], function (props) {
  return "".concat(props.theme.smallSpacer, "px ").concat(props.theme.spacer, "px");
}, function (props) {
  return props.isCentered ? "center" : "flex-end";
});
export var Main = styled(DescriptionText).withConfig({
  displayName: "FormErrorMessagestyled__Main",
  componentId: "sc-1f1f585-1"
})(["color:", ";"], function (props) {
  return props.theme.tertiary;
});