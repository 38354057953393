import React from 'react';
import trackEvent from '@helpers/tracking';
import openExternalWebpage from '@helpers/openExternalWebpage';
import Button from '@ui/components/Button';
import TitleBlock from '@ui/components/TitleBlock';
import AppLandingPage from 'components/app/AppLandingPage';

const Mastering = () => (
  <AppLandingPage backgroundImageUrl="https://static.beatchain.com/butter/ILXjKEXSrGdNDGfLQQhdHeroes_Images_MDW_SPO_07.jpg">
    <TitleBlock
      title="AI Mastering"
      subtitle="CloudBounce is your mastering tool that is available 24/7 wherever you go."
    />
    <Button
      text="Master a track now"
      onPress={() => {
        trackEvent('CloudBounce Click');
        openExternalWebpage(
          'https://affiliate.cloudbounce.com/idevaffiliate/idevaffiliate.php?id=3819'
        );
      }}
    />
  </AppLandingPage>
);

export default Mastering;
