import { map } from 'lodash';
import { Platform } from './types';

export const PLATFORM: Platform = {
  SEVEN_DIGITAL: '7digital',
  AMAZON: 'amazonstore',
  AMAZON_MUSIC: 'amazonmusic',
  APPLE: 'apple',
  APPLE_MUSIC: 'applemusic',
  BANDCAMP: 'bandcamp',
  BANDSINTOWN: 'bandsintown',
  BEATCHAIN: 'beatchain',
  BEATPORT: 'beatport',
  DEEZER: 'deezer',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  GOOGLE_MUSIC: 'googlemusic',
  GOOGLE_PLAY: 'googlestore',
  INSTAGRAM: 'instagram',
  ITUNES_STORE: 'itunes',
  JOOX: 'joox',
  LINKEDIN: 'linkedin',
  NAPSTER: 'napster',
  PANDORA: 'pandora',
  REDDIT: 'reddit',
  RHAPSODY: 'rhapsody',
  SHAZAM: 'shazam',
  SONGKICK: 'songkick',
  SOUNDCLOUD: 'soundcloud',
  SOUNDTRACK_YOUR_BAND: 'soundtrackyourbrand',
  SPINRILLA: 'spinrilla',
  SPOTIFY: 'spotify',
  SPOTIFY_FOR_ARTISTS: 'spotifyforartists',
  TIDAL: 'tidal',
  TIKTOK: 'tiktok',
  TWITCH: 'twitch',
  TWITTER: 'twitter',
  YANDEX: 'yandex',
  YOUTUBE: 'youtube',
  YOUTUBE_MUSIC: 'youtubemusic',
  // tickets
  DICE: 'dice',
  DREAMSTAGE: 'dreamstage',
  EVENTBRITE: 'eventbrite',
  SEE_TICKETS: 'seetickets',
  TICKETMASTER: 'ticketmaster',
  // mix tapes, radio and podcasts
  ACAST: 'acast',
  APPLE_PODCASTS: 'applepodcasts',
  DATPIFF: 'datpiff',
  GOOGLE_PODCASTS: 'googlepodcasts',
  MIXCLOUD: 'mixcloud',
  TUNEIN: 'tunein',
  STRIPE: 'stripe',
  // marketing, distribution
  MAILCHIMP: 'mailchimp',
  FUGA: 'fuga',
  DRIP: 'drip',
  // chats
  WHATSAPP: 'whatsapp',
  // payment
  PAYPAL: 'paypal',
};

export const getPlatformName = platform => {
  const correctNames = map(PLATFORM, platformName => platformName);
  if (correctNames.includes(platform)) {
    return platform;
  }
  switch (platform) {
    case 'amazonMusic' || 'amazonmusic':
      return PLATFORM.AMAZON_MUSIC;
    case 'amazonStore' || 'amazon' || 'amazonstore':
      return PLATFORM.AMAZON;
    case 'appleMusic' || 'applemusic':
      return PLATFORM.APPLE_MUSIC;
    case 'youtubemusic' || 'youtubeMusic':
      return PLATFORM.YOUTUBE_MUSIC;
    default:
      return platform;
  }
};

export const COLOURS = {
  // primary: '#5CFF10',
  // secondary: '#DEFF1A',
  // yellow: '#DEFF1A',
  // lime: '#5CFF10',
  // attention: '#FF5BCB',
  // denim: '#29498B',
  // blood: '#A4253B',
  // white: '#FFF',
  // vlgrey: '#CFCFCF',
  // lgrey: '#B7B7B7',
  // grey: '#777',
  // dgrey: '#484848',
  // vdgrey: '#303030',
  // vvdgrey: '#181818',
  // black: '#000',
  [PLATFORM.SEVEN_DIGITAL]: '#254AA5',
  [PLATFORM.AMAZON]: '#F6A61F',
  [PLATFORM.AMAZON_MUSIC]: '#4E00FF',
  [PLATFORM.APPLE]: '#000',
  [PLATFORM.APPLE_MUSIC]: '#b5b8bd',
  [PLATFORM.BANDCAMP]: '#6399A8',
  [PLATFORM.BANDSINTOWN]: '#00B4B3',
  [PLATFORM.BEATCHAIN]: '#FFFFFF',
  [PLATFORM.BEATPORT]: '#A8E00F',
  [PLATFORM.DEEZER]: '#812296',
  [PLATFORM.FACEBOOK]: '#1877F2',
  [PLATFORM.GOOGLE]: '#518EF8',
  [PLATFORM.GOOGLE_MUSIC]: '#F4481E',
  [PLATFORM.GOOGLE_PLAY]: '#00D2FF',
  [PLATFORM.INSTAGRAM]: '#EF0176',
  [PLATFORM.ITUNES_STORE]: '#D13BF2',
  [PLATFORM.JOOX]: '#00DE75',
  [PLATFORM.LINKEDIN]: '#0073B0',
  [PLATFORM.NAPSTER]: '#005CAB',
  [PLATFORM.PANDORA]: '#00A0EE',
  [PLATFORM.REDDIT]: '#FF4500',
  [PLATFORM.RHAPSODY]: '#F78F11',
  [PLATFORM.SHAZAM]: '#0088FF',
  [PLATFORM.SONGKICK]: '#F80046',
  [PLATFORM.SOUNDCLOUD]: '#FF7700',
  [PLATFORM.SOUNDTRACK_YOUR_BAND]: '#F23440',
  [PLATFORM.SPINRILLA]: '#460856',
  [PLATFORM.SPOTIFY]: '#1DB954',
  [PLATFORM.SPOTIFY_FOR_ARTISTS]: '#4100f5',
  [PLATFORM.TIDAL]: '#000',
  [PLATFORM.TIKTOK]: '#444',
  [PLATFORM.TWITCH]: '#9146FF',
  [PLATFORM.TWITTER]: '#1DA1F2',
  [PLATFORM.YANDEX]: '#FFCC00',
  [PLATFORM.YOUTUBE]: '#FF0000',
  [PLATFORM.YOUTUBE_MUSIC]: '#F9061B',
  [PLATFORM.STRIPE]: '#635BFF',
  [PLATFORM.MAILCHIMP]: '#FFE019',
  [PLATFORM.FUGA]: '#80FF7F',
  [PLATFORM.DRIP]: '#CD20CC',
  [PLATFORM.WHATSAPP]: '#25D366',
};

export const PLATFORM_TITLES = {
  [PLATFORM.SEVEN_DIGITAL]: '7digital',
  [PLATFORM.AMAZON]: 'Amazon',
  [PLATFORM.AMAZON_MUSIC]: 'Amazon Music',
  [PLATFORM.APPLE]: 'Apple',
  [PLATFORM.APPLE_MUSIC]: 'Apple Music',
  [PLATFORM.BANDCAMP]: 'Bandcamp',
  [PLATFORM.BANDSINTOWN]: 'Bandsintown',
  [PLATFORM.BEATCHAIN]: 'Beatchain',
  [PLATFORM.BEATPORT]: 'Beatport',
  [PLATFORM.DEEZER]: 'Deezer',
  [PLATFORM.FACEBOOK]: 'Facebook',
  [PLATFORM.GOOGLE]: 'Google',
  [PLATFORM.GOOGLE_MUSIC]: 'Google Music',
  [PLATFORM.GOOGLE_PLAY]: 'Google Play',
  [PLATFORM.INSTAGRAM]: 'Instagram',
  [PLATFORM.ITUNES_STORE]: 'iTunes Store',
  [PLATFORM.JOOX]: 'Joox',
  [PLATFORM.LINKEDIN]: 'LinkedIn',
  [PLATFORM.NAPSTER]: 'Napster',
  [PLATFORM.PANDORA]: 'Pandora',
  [PLATFORM.REDDIT]: 'Reddit',
  [PLATFORM.RHAPSODY]: 'Rhapsody',
  [PLATFORM.SONGKICK]: 'Songkick',
  [PLATFORM.SOUNDCLOUD]: 'SoundCloud',
  [PLATFORM.SOUNDTRACK_YOUR_BAND]: 'Soundtrack Your Brand',
  [PLATFORM.SPINRILLA]: 'Spinrilla',
  [PLATFORM.SPOTIFY]: 'Spotify',
  [PLATFORM.TIDAL]: 'Tidal',
  [PLATFORM.TIKTOK]: 'TikTok',
  [PLATFORM.TWITCH]: 'Twitch',
  [PLATFORM.TWITTER]: 'Twitter',
  [PLATFORM.YANDEX]: 'Yandex',
  [PLATFORM.YOUTUBE]: 'YouTube',
  [PLATFORM.YOUTUBE_MUSIC]: 'YouTube Music',
  [PLATFORM.STRIPE]: 'Stripe',
  [PLATFORM.MAILCHIMP]: 'Mailchimp',
  [PLATFORM.FUGA]: 'Fuga',
  [PLATFORM.DRIP]: 'Drip',
  [PLATFORM.SPOTIFY_FOR_ARTISTS]: 'Spotify for Artists',
};

export const PLATFORM_URL_CONNECT = {
  [PLATFORM.FACEBOOK]: 'www.facebook.com/',
  [PLATFORM.TWITTER]: 'twitter.com/',
  [PLATFORM.INSTAGRAM]: 'www.instagram.com/',
  [PLATFORM.YOUTUBE]: 'www.youtube.com/channel/',
  [PLATFORM.SPOTIFY]: 'open.spotify.com/artist/',
  [PLATFORM.BANDSINTOWN]: 'www.bandsintown.com/en/a/',
  [PLATFORM.SONGKICK]: 'www.songkick.com/artists/',
  [PLATFORM.SOUNDCLOUD]: 'soundcloud.com/',
  [PLATFORM.DEEZER]: 'www.deezer.com/en/artist/',
  [PLATFORM.TIKTOK]: 'www.tiktok.com/@',
  [PLATFORM.AMAZON_MUSIC]: 'music.amazon.co.uk/artists/',
  [PLATFORM.APPLE_MUSIC]: 'music.apple.com/artist/',
  [PLATFORM.TIDAL]: 'tidal.com/browse/artist/',
  [PLATFORM.BANDCAMP]: 'YOURPROFILE.bandcamp.com/',
  [PLATFORM.TWITCH]: 'www.twitch.tv/',
  [PLATFORM.GOOGLE_PLAY]: 'play.google.com/store/music/artist?id=',
  [PLATFORM.NAPSTER]: 'us.napster.com/artist/',
  [PLATFORM.BEATPORT]: 'www.beatport.com/artist/i/',
  [PLATFORM.YOUTUBE_MUSIC]: 'music.youtube.com/channel/',
  [PLATFORM.PANDORA]: 'www.pandora.com/artist/i/',
};

export const PLATFORM_CTA = {
  [PLATFORM.SEVEN_DIGITAL]: 'play',
  [PLATFORM.AMAZON_MUSIC]: 'play',
  [PLATFORM.AMAZON]: 'buy',
  [PLATFORM.APPLE_MUSIC]: 'play',
  [PLATFORM.BANDCAMP]: 'play',
  [PLATFORM.BEATCHAIN]: 'play',
  [PLATFORM.BEATPORT]: 'play',
  [PLATFORM.DEEZER]: 'play',
  [PLATFORM.FACEBOOK]: 'play',
  [PLATFORM.GOOGLE_MUSIC]: 'play',
  [PLATFORM.GOOGLE_PLAY]: 'buy',
  [PLATFORM.ITUNES_STORE]: 'buy',
  [PLATFORM.JOOX]: 'play',
  [PLATFORM.NAPSTER]: 'play',
  [PLATFORM.PANDORA]: 'play',
  [PLATFORM.SHAZAM]: 'play',
  [PLATFORM.SOUNDCLOUD]: 'play',
  [PLATFORM.SPOTIFY]: 'play',
  [PLATFORM.TIDAL]: 'play',
  [PLATFORM.YANDEX]: 'play',
  [PLATFORM.YOUTUBE]: 'watch',
  [PLATFORM.YOUTUBE_MUSIC]: 'play',
  [PLATFORM.DICE]: 'buy',
  [PLATFORM.DREAMSTAGE]: 'buy',
  [PLATFORM.EVENTBRITE]: 'buy',
  [PLATFORM.SEE_TICKETS]: 'buy',
  [PLATFORM.SPINRILLA]: 'play',
  [PLATFORM.TICKETMASTER]: 'buy',
  [PLATFORM.TWITCH]: 'watch',
  [PLATFORM.ACAST]: 'listen',
  [PLATFORM.APPLE_PODCASTS]: 'listen',
  [PLATFORM.DATPIFF]: 'listen',
  [PLATFORM.GOOGLE_PODCASTS]: 'listen',
  [PLATFORM.MIXCLOUD]: 'listen',
  [PLATFORM.TUNEIN]: 'listen',
};

export const KEY_METRIC_LABELS = {
  [PLATFORM.AMAZON_MUSIC]: 'Monthly Streams',
  [PLATFORM.APPLE_MUSIC]: 'Monthly Streams',
  [PLATFORM.BANDSINTOWN]: 'Trackers',
  [PLATFORM.DEEZER]: 'Monthly Streams',
  [PLATFORM.FACEBOOK]: 'Page likes',
  [PLATFORM.INSTAGRAM]: 'Followers',
  [PLATFORM.SOUNDCLOUD]: 'Followers',
  [PLATFORM.SPOTIFY]: 'Listeners',
  [PLATFORM.TIKTOK]: 'Followers',
  [PLATFORM.TWITTER]: 'Followers',
  [PLATFORM.TWITCH]: 'Views',
  [PLATFORM.YOUTUBE_MUSIC]: 'Monthly Streams',
  [PLATFORM.YOUTUBE]: 'Subscribers',
  stripe: 'Revenue',
};

export const PLATFORM_IDS = {
  facebook: 1,
  twitter: 2,
  instagram: 3,
  youtube: 6,
  spotify: 7,
  bandsintown: 8,
  songkick: 9,
  soundcloud: 10,
  tiktok: 11,
};
