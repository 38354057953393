import React from 'react';
import { useDispatch } from '@hooks/useStore';
import { useNavigate } from '@hooks/useRouting';
import useModal from '@hooks/useModal';
import useActiveArtist from '@hooks/useActiveArtist';
import useUser from '@hooks/useUser';
import useStripeSubscription from '@hooks/useStripeSubscription';
import openExternalWebpage from '@helpers/openExternalWebpage';
import { truncateText } from '@helpers/truncateText';
import Avatar from '@ui/components/Avatar';
import Dialogue from '@ui/components/Dialogue';
import { webOrigin } from 'config/backend.config';
import { featureIds } from 'config/features';
import { logout } from 'store/auth';
import NavbarLink from 'components/app/NavbarLink';
import NavbarBox from 'components/app/NavbarBox';
import Navbar from 'components/app/Navbar';
import AccountBalance from 'components/account/AccountBalance';
import SwitchArtists from 'components/account/SwitchArtists';

import { UserNameAndProfileWrapper, UserProfile } from './AccountMenu.styled';

interface Props {
  onToggle: () => void;
  isExpanded: boolean;
  activeItem: string;
  setActiveItem: (path: string) => void;
}

const AccountMenu = (props: Props) => {
  const stripeSubscription = useStripeSubscription();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openModal = useModal();
  const { onboarded, subscription, isPro, hasFeature } = useUser();
  const { actName, appProfileImage } = useActiveArtist();
  const { email } = useUser();

  const collapseNav = () => props.onToggle();

  const createLink = (name: string, path: string, isInternal = true) => ({
    name,
    path,
    onClick: () => {
      collapseNav();
      if (isInternal) {
        navigate(path);
        props.setActiveItem(path);
      } else {
        openExternalWebpage(path);
      }
    },
  });

  function openLogOutModal() {
    openModal({
      Component: Dialogue,
      modalSize: 'dialogue',
      modalTitle: 'Log Out?',
      modalText: 'Are you sure you want to log out?',
      componentProps: {
        actionButton: {
          text: 'Log out',
          clickHandler: () => {
            dispatch(logout());
          },
        },
      },
    });
  }

  const accountLinks = [
    {
      label: '',
      linkComponent: NavbarLink,
      isBlocked: ['Premium', 'Pro'].includes(subscription?.product_name),
      noCollapse: true,
      links: [
        {
          name: 'Upgrade to Premium',
          onClick: () => {
            stripeSubscription.onClick({ product: 'Premium' });
          },
        },
      ],
    },
    isPro && {
      label: 'Artists',
      isBlocked: !onboarded,
      isCustomWithProps: true,
      linkComponent: SwitchArtists,
    },
    {
      label: 'Royalty Wallet',
      isCustomWithProps: true,
      linkComponent: AccountBalance,
    },
    {
      label: '',
      linkComponent: NavbarLink,
      links: [
        { ...createLink('Connect Social Profiles', '/social-profiles') },
        {
          ...createLink('Artist Settings', '/artist-profile'),
          isBlocked: !onboarded,
        },
        createLink('User Settings', '/user-profile'),
        hasFeature(featureIds.artistFederation) &&
          createLink('Team Members', '/team-members'),
        createLink(
          'Terms & Conditions',
          `${webOrigin}/terms-and-conditions`,
          false
        ),
        createLink('Privacy Policy', `${webOrigin}/privacy-policy`, false),
        createLink(
          'Help & Tips',
          'http://help.beatchain.com/en/collections/1798004-all-help-articles',
          false
        ),
        {
          name: 'Log Out',
          isTertiary: true,
          onClick: () => {
            collapseNav();
            openLogOutModal();
          },
        },
      ].filter(Boolean),
    },
  ].filter(Boolean);

  const displayNameOrEmail = actName && actName !== '' ? actName : email;

  return (
    <Navbar isExpanded={props.isExpanded} slideDirection="left">
      <UserNameAndProfileWrapper onClick={collapseNav}>
        <UserProfile>{truncateText(displayNameOrEmail, 25)}</UserProfile>
        <Avatar source={appProfileImage} size={36} />
      </UserNameAndProfileWrapper>

      {accountLinks.map((item, key) => (
        <NavbarBox
          key={key}
          {...item}
          linkComponent={item.linkComponent}
          collapseNav={item.noCollapse ? undefined : collapseNav}
          activeItem={props.activeItem}
        />
      ))}
    </Navbar>
  );
};

export default React.memo(AccountMenu);
