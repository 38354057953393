import { isWindowExists } from '@helpers/isWindowExists';

const key = 'beatchain_redirect_to';

/**
 * Sets the login redirect in local storage
 */
export const setLoginRedirect = (addr?: string) => {
  if (isWindowExists && addr) {
    window.localStorage.setItem(key, addr);
  }
};

/**
 * Gets the login redirect from local storage
 */
export const getLoginRedirect = () => {
  if (isWindowExists) {
    const res = window.localStorage.getItem(key);
    window.localStorage.removeItem(key);
    return res;
  }
  return null;
};
