/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { use100vh } from 'react-div-100vh';

import { useNavigate, useParams } from '@hooks/useRouting';
import trackEvent from '@helpers/tracking';
import Logo from '@ui/components/Logo';
import Button from '@ui/components/Button';
import {
  Wrapper,
  Banner,
  AffiliateHero,
  AffiliateContent,
  LogoContainer,
  PartnerLogo,
  LogoVerticalCenter,
  X,
  CtaText,
  CtaHeader,
  CtaSubHeader,
  ButtonWrapper,
  AffiliateBackground,
} from './Affiliate.styled';

interface Props {
  bannerMsg: string;
  bannerCta: string;
  partnerLogo: string;
  landingPageHeader: string;
  landingPageSubHeader: string;
}

const Affiliate = (props: Props) => {
  const navigate = useNavigate();
  const { partner } = useParams();
  const height = use100vh();

  return (
    <Wrapper height={height}>
      <Banner>
        <span>{props.bannerMsg}</span>
        <a
          role="link"
          tabIndex={0}
          onClick={() => {
            navigate('/signup');
            trackEvent('Affiliate Signup Click', { affiliate_slug: partner });
          }}
        >
          {props.bannerCta}
        </a>
      </Banner>
      <AffiliateHero>
        <AffiliateBackground />
        <AffiliateContent>
          <LogoContainer>
            {props.partnerLogo && (
              <>
                <PartnerLogo imageUrl={props.partnerLogo} />
                <X>x</X>
              </>
            )}
            <LogoVerticalCenter>
              <Logo color="white" size={50} />
            </LogoVerticalCenter>
          </LogoContainer>
          <CtaText>
            <CtaHeader>{props.landingPageHeader}</CtaHeader>
            <CtaSubHeader>{props.landingPageSubHeader}</CtaSubHeader>
          </CtaText>
          <ButtonWrapper>
            <Button
              tertiary
              text="Join Beatchain Now"
              onPress={() => {
                navigate('/signup');
                trackEvent('Affiliate Signup Click', {
                  affiliate_slug: partner,
                });
              }}
            />
          </ButtonWrapper>
        </AffiliateContent>
      </AffiliateHero>
    </Wrapper>
  );
};

export default Affiliate;
