import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    viewBox="0 0 160 50"
    fill={isColour ? '#36B4A7' : 'currentColor'}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M125.4 11.82c0-.25-.2-.46-.45-.46h-2.27c-.25 0-.45.2-.45.46v8.54c0 .14-.16.18-.2.05l-3.12-8.64a.56.56 0 00-.57-.4h-2.7c-.25 0-.46.2-.46.45v15c0 .25.2.45.46.45h2.27c.25 0 .45-.2.45-.45v-7.87c0-.13.16-.18.2-.04l3.1 7.97c.07.19.23.39.61.39h2.68c.25 0 .46-.2.46-.45v-15zm-12.74 12.84c0-.25-.2-.46-.46-.46h-1.93c-.13 0-.23-.09-.23-.22v-9.32c0-.14.1-.23.23-.23h1.7c.26 0 .46-.2.46-.45v-2.16c0-.25-.2-.46-.45-.46h-7.05c-.25 0-.45.2-.45.46v2.16c0 .25.2.45.45.45h1.7c.14 0 .23.1.23.23v9.32c0 .13-.09.22-.22.22h-1.94c-.25 0-.45.2-.45.46v2.16c0 .25.2.45.45.45h7.5c.25 0 .46-.2.46-.45v-2.16zm-20.34-9.89H26.69a.28.28 0 00-.28.29V46.3c0 .15.13.28.28.28h65.34c.16 0 .29-.13.29-.28V14.77zM136.64 1.7v35.23c0 .94-.77 1.7-1.7 1.7H95.72v9.67c0 .94-.77 1.7-1.7 1.7H24.7a1.7 1.7 0 01-1.7-1.7V13.07c0-.94.76-1.7 1.7-1.7h67.62V1.7c0-.94.76-1.7 1.7-1.7h40.91c.94 0 1.7.76 1.7 1.7zm-54.4 33.75h-5.38c-.13 0-.23-.09-.23-.23V32.5c0-.14.1-.23.23-.23h5.11c.25 0 .46-.2.46-.45v-2.28c0-.25-.2-.45-.46-.45h-5.1c-.14 0-.24-.1-.24-.23v-2.72c0-.14.1-.23.23-.23h5.3c.25 0 .45-.2.45-.46v-2.27c0-.25-.2-.45-.45-.45H73.9c-.25 0-.46.2-.46.45v15c0 .25.2.45.46.45h8.34c.25 0 .45-.2.45-.45V35.9c0-.25-.2-.46-.45-.46zM70.48 22.73H68.2c-.25 0-.45.2-.45.45v8.55c0 .13-.16.18-.2.04l-3.12-8.63a.56.56 0 00-.57-.41h-2.7c-.25 0-.46.2-.46.45v15c0 .25.2.45.46.45h2.27c.25 0 .45-.2.45-.45v-7.86c0-.14.16-.18.2-.05l3.1 7.98c.07.18.23.38.61.38h2.68c.25 0 .46-.2.46-.45v-15c0-.25-.2-.45-.46-.45zm-12.81 0h-2.3c-.25 0-.45.2-.45.45v10.68c0 1-.82 1.82-2.2 1.82-1.35 0-2.17-.82-2.17-1.82V23.18c0-.25-.2-.45-.45-.45h-2.3c-.25 0-.45.2-.45.45v10.68c0 2.75 2.27 5.02 5.36 5.02 3.14 0 5.41-2.27 5.41-5.02V23.18c0-.25-.2-.45-.45-.45zm-12.5.45v2.27c0 .25-.2.46-.46.46h-2.84c-.13 0-.22.09-.22.23v12.04c0 .25-.21.45-.46.45h-2.27a.46.46 0 01-.46-.45V26.14c0-.14-.09-.23-.22-.23h-2.84a.46.46 0 01-.46-.46v-2.27c0-.25.2-.45.46-.45h9.31c.25 0 .46.2.46.45z"
      clipRule="evenodd"
    />
  </svg>
);
