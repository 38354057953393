import api from '@helpers/api';
import { CreditsAction, StripeProduct } from '@ui/types/credits';
import { apiBase } from 'config/backend.config';
import { put, takeLatest } from 'redux-saga/effects';
import { actions } from './reducer';

function* fetchActionsApi() {
  try {
    const result: CreditsAction[] = yield api({
      endpoint: `${apiBase}/credits/actions`,
    });

    yield put(
      actions.fetchActionsSuccess({
        actions: result,
      })
    );
  } catch (err: any) {
    window.Raven?.captureException(err);
    yield put(actions.fetchActionsError(err.toString()));
  }
}

export function* fetchActionsSaga() {
  yield takeLatest(actions.fetchActions, fetchActionsApi);
}

function* fetchProductsApi() {
  try {
    const result: StripeProduct[] = yield api({
      endpoint: `${apiBase}/credits/products`,
    });

    yield put(
      actions.fetchProductsSuccess({
        products: result,
      })
    );
  } catch (err: any) {
    window.Raven?.captureException(err);
    yield put(actions.fetchProductsError(err.toString()));
  }
}

export function* fetchProductsSaga() {
  yield takeLatest(actions.fetchProducts, fetchProductsApi);
}
