import React from 'react';
import { PLATFORM, COLOURS } from '@ui/consts';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    viewBox="0 0 500 150"
    fill={isColour ? COLOURS[PLATFORM.STRIPE] : 'currentColor'}
    {...props}
  >
    <g id="svg_1">
      <path
        id="svg_2"
        d="m360,77.4c0,-25.6 -12.4,-45.8 -36.1,-45.8c-23.8,0 -38.2,20.2 -38.2,45.6c0,30.1 17,45.3 41.4,45.3c11.9,0 20.9,-2.7 27.7,-6.5l0,-20c-6.8,3.4 -14.6,5.5 -24.5,5.5c-9.7,0 -18.3,-3.4 -19.4,-15.2l48.9,0c0,-1.3 0.2,-6.5 0.2,-8.9zm-49.4,-9.5c0,-11.3 6.9,-16 13.2,-16c6.1,0 12.6,4.7 12.6,16l-25.8,0z"
      />
      <path
        id="svg_3"
        d="m247.1,31.6c-9.8,0 -16.1,4.6 -19.6,7.8l-1.3,-6.2l-22,0l0,116.6l25,-5.3l0.1,-28.3c3.6,2.6 8.9,6.3 17.7,6.3c17.9,0 34.2,-14.4 34.2,-46.1c-0.1,-29 -16.6,-44.8 -34.1,-44.8zm-6,68.9c-5.9,0 -9.4,-2.1 -11.8,-4.7l-0.1,-37.1c2.6,-2.9 6.2,-4.9 11.9,-4.9c9.1,0 15.4,10.2 15.4,23.3c0,13.4 -6.2,23.4 -15.4,23.4z"
      />
      <polygon
        id="svg_4"
        points="169.8000030517578,25.700000762939453 194.89999389648438,20.299999237060547 194.89999389648438,0 169.8000030517578,5.299999237060547 "
      />
      <rect id="svg_5" height="87.5" width="25.1" y="33.3" x="169.8" />
      <path
        id="svg_6"
        d="m142.9,40.7l-1.6,-7.4l-21.6,0l0,87.5l25,0l0,-59.3c5.9,-7.7 15.9,-6.3 19,-5.2l0,-23c-3.2,-1.2 -14.9,-3.4 -20.8,7.4z"
      />
      <path
        id="svg_7"
        d="m92.9,11.6l-24.4,5.2l-0.1,80.1c0,14.8 11.1,25.7 25.9,25.7c8.2,0 14.2,-1.5 17.5,-3.3l0,-20.3c-3.2,1.3 -19,5.9 -19,-8.9l0,-35.5l19,0l0,-21.3l-19,0l0.1,-21.7z"
      />
      <path
        id="svg_8"
        d="m25.3,58.7c0,-3.9 3.2,-5.4 8.5,-5.4c7.6,0 17.2,2.3 24.8,6.4l0,-23.5c-8.3,-3.3 -16.5,-4.6 -24.8,-4.6c-20.3,0 -33.8,10.6 -33.8,28.3c0,27.6 38,23.2 38,35.1c0,4.6 -4,6.1 -9.6,6.1c-8.3,0 -18.9,-3.4 -27.3,-8l0,23.8c9.3,4 18.7,5.7 27.3,5.7c20.8,0 35.1,-10.3 35.1,-28.2c-0.1,-29.8 -38.2,-24.5 -38.2,-35.7z"
      />
    </g>
  </svg>
);
