import { createContext } from 'react';
import { GlobalStateType } from '@ui/types/globalState';

const GlobalState = createContext<GlobalStateType>({
  cms: {},
  isMobile: false,
  isTablet: false,
  currency: undefined,
  isWebView: false,
  width: undefined,
});

export default GlobalState;
