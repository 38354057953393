import { Feature } from '@ui/types/feature';

export const featureIds = {
  launchPad: 1,
  insights: 2,
  websiteBuilder: 3,
  distribution: 4,
  distributionFast: 5,
  fanBuilder: 6,
  fanFinderCampaign: 7,
  fanFinderCampaignUnlimited: 8,
  promoteShowCampaign: 9,
  hypelinks: 10,
  presavesSpotify: 11,
  presavesApple: 12,
  csvExports: 13,
  activityFeed: 14,
  multiArtist: 15,
  artistFederation: 16,
  royaltySplits: 17,
  emailCampaigns: 18,
  dedicatedSupport: 20,
  instagram: 21,
  smartDashboards: 22,
  dataConnectors: 23,
  customDomains: 24,
};

export const featureCategories = {
  analyze: {
    categoryName: 'analyze',
    categoryLabel: 'Analyze',
  },
  create: {
    categoryName: 'distribution',
    categoryLabel: 'Distribution',
  },
  promote: {
    categoryName: 'promote',
    categoryLabel: 'Promote',
  },
  know: {
    categoryName: 'succeed',
    categoryLabel: 'Succeed',
  },
};

interface Features {
  [path: string]: Feature;
}

export const features: Features = {
  insights: {
    name: 'insights',
    shortLabel: 'Insights',
    longLabel: 'Fan Insights',
    description: 'Artist dashboard',
    slug: '/insights',
    path: '/insights',
    category: 'analyze',
  },
  discography: {
    name: 'discography',
    shortLabel: 'Discography',
    longLabel: 'Discography',
    description: 'YOUR TRACKS',
    slug: '/tracks',
    path: '/tracks',
    category: 'analyze',
  },
  distribution: {
    name: 'distribution',
    shortLabel: 'Releases',
    longLabel: 'Releases',
    extraLabel: 'Unlimited Distribution',
    description: 'Manage your releases and splits',
    slug: '/distribution',
    path: '/distribution',
    category: 'distribution',
  },
  royalties: {
    name: 'royalties',
    shortLabel: 'Royalties',
    longLabel: 'Streaming Royalties',
    description: 'Your listener and revenue data',
    slug: '/royalties',
    path: '/royalties/all-tracks',
    category: 'distribution',
  },
  splits: {
    name: 'splits',
    shortLabel: 'Royalty Splits',
    longLabel: 'Royalty Splits',
    description: 'Your earnings from others',
    slug: '/splits',
    path: '/splits',
    category: 'distribution',
  },
  hypelinks: {
    name: 'hypeLinks',
    shortLabel: 'HypeLinks',
    longLabel: 'HypeLinks',
    extraLabel: 'Unlimited HypeLinks & Pre-saves',
    description: 'Smart links, pre-saves, link in bio',
    slug: '/hypelinks',
    path: '/hypelinks',
    category: 'promote',
  },
  fanBuilder: {
    name: 'fanBuilder',
    shortLabel: 'Fan Builder',
    longLabel: 'Fan Builder',
    description: 'Promote with hyper-targeted ads',
    slug: '/fan-builder',
    path: '/fan-builder',
    category: 'promote',
  },
  launchpad: {
    name: 'launchpad',
    shortLabel: 'Launchpad',
    longLabel: 'Launchpad',
    description: 'Schedule your social media',
    slug: '/launchpad',
    path: '/launchpad',
    category: 'promote',
  },
  blog: {
    name: 'blog',
    shortLabel: 'Tips',
    longLabel: 'Tips & Tricks',
    slug: '/blog',
    path: '/blog',
    category: 'succeed',
    external: 'https://beatchain.com/blog',
  },
  websiteBuilder: {
    name: 'websiteBuilder',
    shortLabel: 'Website',
    longLabel: 'Website Builder',
    description: 'Edit your legacy website',
    slug: '/website-builder',
    path: '/website-builder',
    category: 'promote',
  },
  videos: {
    name: 'videos',
    shortLabel: 'Music Videos',
    longLabel: 'Music Videos',
    slug: '/music-videos',
    path: '/music-videos',
    category: 'succeed',
  },
  mastering: {
    name: 'mastering',
    shortLabel: 'Mastering',
    longLabel: 'Mastering',
    slug: '/mastering',
    path: '/mastering',
    category: 'succeed',
  },
  // multipleArtists: {
  //   name: 'multipleArtists',
  //   shortLabel: 'Multiple Artists',
  //   longLabel: 'Multiple Artists',
  //   slug: '',
  //   path: '',
  // },
  /* email: {
    name: 'email',
    shortLabel: 'Email',
    longLabel: 'Email Marketing',
    extraLabel: 'Mailing List Management',
    slug: '/email-marketing',
    path: '/email-marketing',
    category: 'promote',
  }, */
  publishingRoyalties: {
    name: 'publishingRoyalties',
    shortLabel: 'Publishing Royalties',
    longLabel: 'Publishing Royalties',
    slug: '/publishing-royalties',
    path: '/publishing-royalties',
    category: 'succeed',
  },
  socialProfiles: {
    name: 'social-profiles',
    shortLabel: 'Social Profiles',
    longLabel: 'Social Profiles',
    slug: '/social-profiles',
    path: '/social-profiles',
  },
  artistProfile: {
    name: 'artist-profile',
    shortLabel: 'Artist Settings',
    longLabel: 'Artist Settings',
    slug: '/artist-profile',
    path: '/artist-profile',
  },
  userProfile: {
    name: 'user-profile',
    shortLabel: 'User Profile',
    longLabel: 'User Profile',
    slug: '/user-profile',
    path: '/user-profile',
  },
  teamMembers: {
    name: 'team-members',
    shortLabel: 'Team Members',
    longLabel: 'Team Members',
    slug: '/team-members',
    path: '/team-members',
  },
  cancelSubscription: {
    name: 'cancel',
    shortLabel: 'User Profile',
    longLabel: 'User Profile',
    slug: '/cancel',
    path: '/user-profile',
  },
  activityFeed: {
    name: 'activity-feed',
    shortLabel: 'Activity Feed',
    longLabel: 'Activity Feed',
    slug: '/welcome',
    path: '/welcome',
  },
  // smartDashboards: {
  //   name: 'smart-dashboards',
  //   shortLabel: 'Smart Dashboards',
  //   longLabel: 'Smart Dashboards',
  //   slug: '/smart-dashboards',
  //   path: '/smart-dashboards',
  //   category: 'analyze',
  //   requiredFeatureId: featureIds.smartDashboards,
  //   isPro: true,
  // },
};
// Welcome only on React Native
