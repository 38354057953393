import { DefaultTheme } from 'styled-components';
import { Themes } from '@ui/types';
import { IconOddchild, LogoOddchild } from '@ui/theme/themeAssets/oddchild';
import { IconBeatchain, IconBeatchainFull } from '@ui/components/Logo/icons';
import {
  IconYoungMoney,
  LogoYoungMoney,
} from '@ui/theme/themeAssets/youngMoney';

export const themes: Themes = {
  beatchain: {
    name: 'beatchain',
    title: 'Beatchain',
    IconComponent: IconBeatchain,
    LogoComponent: IconBeatchainFull,
    useFullLogoInHeader: true,
    isButtonOutlined: false,
    isButtonRounded: false,
  },
  oddchild: {
    name: 'oddchild',
    title: 'Oddchild',
    IconComponent: IconOddchild,
    LogoComponent: LogoOddchild,
    useFullLogoInHeader: true,
    isButtonOutlined: true,
    isButtonRounded: true,
  },
  youngMoney: {
    name: 'youngMoney',
    title: 'Young Money Distribution',
    IconComponent: IconYoungMoney,
    LogoComponent: LogoYoungMoney,
    useFullLogoInHeader: false,
    isButtonOutlined: true,
    isButtonRounded: true,
  },
};

const envDict = {
  YOUNG_MONEY: 'youngMoney',
  ODDCHILD: 'oddchild',
};

export const getTheme = () =>
  // @ts-ignore
  themes[envDict[process.env.REACT_APP_DOMAIN] || 'youngMoney'];

export const getThemeExtension: (
  themeName: string,
  scale: number
) => Partial<DefaultTheme> = (themeName, scale) => {
  const themeExtensions: { [key: string]: Partial<DefaultTheme> } = {
    youngMoney: {
      primary: 'rgb(255, 255, 255)',
      secondary: 'rgb(255, 255, 255)',
      tertiary: 'rgb(255, 255, 255)',
      buttonFont: 'Generic Button',
      headingFont: 'Generic Heading',
      headingBrandFont: 'Generic Brand Heading',
      bodyFont: 'Generic Body',
      bodyStrongFont: 'Generic Body Strong',
      bodyBoldFont: 'Generic Body Bold',
      bodyItalicFont: 'Generic Body Italic',
      infographicFont: 'Generic Subheading',
      headingLargeFont: 'Generic Brand Heading',
      headingXLargeFont: 'Generic Brand Heading',
      headingSmallFont: 'Generic Heading',
      headingLabelFont: 'Generic Subheading',
      headingInfographicFont: 'Generic Subheading',
      infographicNumberFont: 'Generic Infographic Number',
      bodyTextFont: 'Generic Body',
      bodyStrongTextFont: 'Generic Body Strong',
      descriptionTextFont: 'Generic Body',
      smallTextFont: 'Generic Body',
      buttonTextFont: 'Generic Button',
      buttonSmallTextFont: 'Generic Button',
      inputTextFont: 'Generic Body',
      placeholderTextFont: 'Generic Body Italic',
      headingTitleFont: 'Generic Heading',
      headingSubtitleFont: 'Generic Subheading',
    },
    oddchild: {
      buttonFont: 'Generic Button',
      headingFont: 'Generic Heading',
      headingBrandFont: 'Generic Brand Heading',
      bodyFont: 'Generic Body',
      bodyStrongFont: 'Generic Body Strong',
      bodyBoldFont: 'Generic Body Bold',
      bodyItalicFont: 'Generic Body Italic',
      infographicFont: 'Generic Subheading',
      headingLargeFont: 'Generic Brand Heading',
      headingXLargeFont: 'Generic Brand Heading',
      headingSmallFont: 'Generic Heading',
      headingLabelFont: 'Generic Subheading',
      headingInfographicFont: 'Generic Subheading',
      infographicNumberFont: 'Generic Infographic Number',
      bodyTextFont: 'Generic Body',
      bodyStrongTextFont: 'Generic Body Strong',
      descriptionTextFont: 'Generic Body',
      smallTextFont: 'Generic Body',
      buttonTextFont: 'Generic Button',
      buttonSmallTextFont: 'Generic Button',
      inputTextFont: 'Generic Body',
      placeholderTextFont: 'Generic Body Italic',
      headingTitleFont: 'Generic Heading',
      headingSubtitleFont: 'Generic Subheading',
    },
    beatchain: {},
  };

  return themeExtensions[themeName];
};

export const getThemeLogoComponent = (themeName, logoType) =>
  logoType === 'full'
    ? themes[themeName].LogoComponent
    : themes[themeName].IconComponent;
