import React from 'react';
import useDevice from '@hooks/useDevice';
import Scrollbars from 'components/app/Scrollbars';
import { ContentWithFooterMain } from './ContentWithFooter.styles';

interface Props {
  children: [React.ReactNode, React.ReactNode];
  hasNoPadding?: boolean;
  smallPadding?: boolean;
}

/**
 * Adds a footer section to any piece of content eg. within a modal
 */
const ContentWithFooter = (props: Props) => {
  const { isMobile } = useDevice();
  const childrenArray = React.Children.toArray(props.children);
  return (
    <ContentWithFooterMain
      isMobile={isMobile}
      hasNoPadding={props.hasNoPadding}
      smallPadding={props.smallPadding}
    >
      <Scrollbars>{childrenArray[0]}</Scrollbars>
      {childrenArray[1]}
    </ContentWithFooterMain>
  );
};

export default ContentWithFooter;
