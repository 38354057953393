import styled from 'styled-components';
import { Heading, HeadingLarge, HeadingSmall } from '@ui/components/Typography';
export var ConfirmationWrapper = styled(Heading).withConfig({
  displayName: "Confirmationstyled__ConfirmationWrapper",
  componentId: "sc-jfmbd7-0"
})(["height:100vh;display:grid;color:", ";grid-template-rows:120px auto 150px;grid-template-columns:auto 380px auto;"], function (props) {
  return props.theme.white;
});
export var Header = styled.div.withConfig({
  displayName: "Confirmationstyled__Header",
  componentId: "sc-jfmbd7-1"
})(["grid-row:1/2;grid-column:1/4;padding:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var Content = styled.div.withConfig({
  displayName: "Confirmationstyled__Content",
  componentId: "sc-jfmbd7-2"
})(["height:530px;grid-row:2/3;grid-column:2/3;align-self:center;"]);
export var StyledHeading = styled(HeadingLarge).withConfig({
  displayName: "Confirmationstyled__StyledHeading",
  componentId: "sc-jfmbd7-3"
})(["color:", ";margin:", "px 0;"], function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.largeSpacer;
});
export var SubHeading = styled(HeadingSmall).withConfig({
  displayName: "Confirmationstyled__SubHeading",
  componentId: "sc-jfmbd7-4"
})(["margin:", "px 0;"], function (props) {
  return props.theme.largeSpacer;
});
export var LoaderWrapper = styled.div.withConfig({
  displayName: "Confirmationstyled__LoaderWrapper",
  componentId: "sc-jfmbd7-5"
})(["margin:", "px;"], function (props) {
  return props.theme.xxlargeSpacer;
});
export var FooterWrapper = styled.div.withConfig({
  displayName: "Confirmationstyled__FooterWrapper",
  componentId: "sc-jfmbd7-6"
})(["grid-row:3/4;grid-column:2/3;"]);