import styled from 'styled-components';
import Text from '../Text';

export const Heading = styled(Text)(props => ({
  fontFamily: props.theme.headingFont,
  fontSize: `${props.theme.headingFontSize}px`,
  lineHeight: `${props.theme.headingLineHeight}px`,
}));

export const HeadingBrandFont = styled(Text)(props => ({
  fontFamily: props.theme.headingBrandFont,
  fontSize: `${props.theme.headingFontSize}px`,
  lineHeight: `${props.theme.headingLineHeight}px`,
}));

export const HeadingLarge = styled(Text)(props => ({
  fontFamily: props.theme.headingLargeFont,
  fontSize: `${props.theme.headingLargeFontSize}px`,
  lineHeight: `${props.theme.headingLargeLineHeight}px`,
}));

export const HeadingLargeClean = styled(Text)(props => ({
  fontFamily: props.theme.headingFont,
  fontSize: `${props.theme.headingLargeFontSize}px`,
  lineHeight: `${props.theme.headingLargeLineHeight}px`,
}));

export const HeadingXLarge = styled(Text)(props => ({
  fontFamily: props.theme.headingXLargeFont,
  fontSize: `${props.theme.headingXLargeFontSize}px`,
  lineHeight: `${props.theme.headingXLargeLineHeight}px`,
}));

export const HeadingXLargeClean = styled(Text)(props => ({
  fontFamily: props.theme.headingFont,
  fontSize: `${props.theme.headingXLargeFontSize}px`,
  lineHeight: `${props.theme.headingXLargeLineHeight}px`,
}));

export const HeadingSmall = styled(Text)(props => ({
  fontFamily: props.theme.headingSmallFont,
  fontSize: `${props.theme.headingSmallFontSize}px`,
  lineHeight: `${props.theme.headingSmallLineHeight}px`,
}));

export const HeadingSmallClean = styled(Text)(props => ({
  fontFamily: props.theme.headingBrandFont,
  fontSize: `${props.theme.headingSmallFontSize}px`,
  lineHeight: `${props.theme.headingSmallLineHeight}px`,
}));

export const HeadingLabel = styled(Text)(props => ({
  fontFamily: props.theme.headingLabelFont,
  fontSize: `${props.theme.headingLabelFontSize}px`,
  lineHeight: `${props.theme.headingLabelLineHeight}px`,
  letterSpacing: props.theme.headingLabelLetterSpacing,
  textTransform: props.theme.headingLabelTextTransform,
}));

export const HeadingLabelSmall = styled(Text)(props => ({
  fontFamily: props.theme.headingLabelFont,
  fontSize: `${props.theme.headingLabelSmallFontSize}px`,
  lineHeight: `${props.theme.headingLabelSmallLineHeight}px`,
  letterSpacing: props.theme.headingLabelLetterSpacing,
  textTransform: props.theme.headingLabelTextTransform,
}));

export const HeadingLabelLarge = styled(Text)(props => ({
  fontFamily: props.theme.headingLabelFont,
  fontSize: `${props.theme.headingLabelLargeFontSize}px`,
  lineHeight: `${props.theme.headingLabelLargeLineHeight}px`,
  letterSpacing: props.theme.headingLabelLetterSpacing,
  textTransform: props.theme.headingLabelTextTransform,
}));

export const HeadingInfographic = styled(Text)(props => ({
  fontFamily: props.theme.headingInfographicFont,
  fontSize: `${props.theme.headingInfographicFontSize}px`,
  lineHeight: `${props.theme.headingInfographicLineHeight}px`,
  letterSpacing: props.theme.headingInfographicLetterSpacing,
  textTransform: props.theme.headingInfographicTextTransform,
}));

export const InfographicNumber = styled(Text)(props => ({
  fontFamily: props.theme.infographicNumberFont,
  fontSize: `${props.theme.infographicNumberFontSize}px`,
  lineHeight: `${props.theme.infographicNumberLineHeight}px`,
  letterSpacing: props.theme.infographicNumberLetterSpacing,
  textTransform: props.theme.infographicNumberTextTransform,
}));

export const InfographicNumberMedium = styled(Text)(props => ({
  fontFamily: props.theme.infographicNumberFont,
  fontSize: `${props.theme.infographicNumberFontSize * 0.75}px`,
  lineHeight: `${props.theme.infographicNumberLineHeight * 0.75}px`,
  letterSpacing: props.theme.infographicNumberLetterSpacing,
  textTransform: props.theme.infographicNumberTextTransform,
}));

export const InfographicNumberSmall = styled(Text)(props => ({
  fontFamily: props.theme.infographicNumberFont,
  fontSize: `${props.theme.infographicNumberFontSize / 2}px`,
  lineHeight: `${props.theme.infographicNumberLineHeight / 2}px`,
  letterSpacing: props.theme.infographicNumberLetterSpacing,
  textTransform: props.theme.infographicNumberTextTransform,
}));

const clickableStyles = props =>
  props.onClick
    ? {
        color: props.theme.linkColour,
        textDecoration: 'underline',
        cursor: 'pointer',
      }
    : {};

export const BodyText = styled(Text)(props => ({
  fontFamily: props.theme.bodyTextFont,
  fontSize: `${props.theme.bodyTextFontSize}px`,
  lineHeight: `${props.theme.bodyTextLineHeight}px`,
  ...clickableStyles(props),
}));

export const BodyBoldText = styled(BodyText)(props => ({
  fontFamily: props.theme.bodyBoldFont,
  ...clickableStyles(props),
}));

export const BodyItalicText = styled(BodyText)(props => ({
  fontFamily: props.theme.bodyItalicFont,
  ...clickableStyles(props),
}));

export const BodyStrongText = styled(Text)(props => ({
  fontFamily: props.theme.bodyStrongTextFont,
  fontSize: `${props.theme.bodyStrongTextFontSize}px`,
  lineHeight: `${props.theme.bodyStrongTextLineHeight}px`,
  letterSpacing: props.theme.bodyStrongTextLetterSpacing,
  ...clickableStyles(props),
}));

export const DescriptionText = styled(Text)(props => ({
  fontFamily: props.theme.descriptionTextFont,
  fontSize: `${props.theme.descriptionTextFontSize}px`,
  lineHeight: `${props.theme.descriptionTextLineHeight}px`,
  ...clickableStyles(props),
}));

export const SmallText = styled(Text)(props => ({
  fontFamily: props.theme.smallTextFont,
  fontSize: `${props.theme.smallTextFontSize}px`,
  lineHeight: `${props.theme.smallTextLineHeight}px`,
  ...clickableStyles(props),
}));

export const ButtonText = styled(Text)(props => ({
  fontFamily: props.theme.buttonTextFont,
  fontSize: `${props.theme.buttonTextFontSize}px`,
  lineHeight: `${props.theme.buttonTextLineHeight}px`,
  letterSpacing: props.theme.buttonTextLetterSpacing,
  textTransform: props.theme.buttonTextTextTransform,
}));

export const ButtonSmallText = styled(Text)(props => ({
  fontFamily: props.theme.buttonSmallTextFont,
  fontSize: `${props.theme.buttonSmallTextFontSize}px`,
  lineHeight: `${props.theme.buttonSmallTextLineHeight}px`,
  letterSpacing: props.theme.buttonSmallTextLetterSpacing,
  textTransform: props.theme.buttonSmallTextTextTransform,
}));

export const InputText = styled(Text)(props => ({
  fontFamily: props.theme.inputTextFont,
  fontSize: `${props.theme.inputTextFontSize}px`,
  lineHeight: `${props.theme.inputTextLineHeight}px`,
}));

export const PlaceholderText = styled(Text)(props => ({
  fontFamily: props.theme.placeholderTextFont,
  fontSize: `${props.theme.placeholderTextFontSize}px`,
  lineHeight: `${props.theme.placeholderTextLineHeight}px`,
  fontStyle: props.theme.placeholderTextFontStyle,
}));

export const HeadingTitle = styled(Text)(props => ({
  fontFamily: props.theme.headingTitleFont,
  fontSize: `${props.theme.headingTitleFontSize}px`,
  letterSpacing: props.theme.headingTitleLetterSpacing,
  lineHeight: `${props.theme.headingTitleLineHeight}px`,
}));

export const HeadingTitleClean = styled(Text)(props => ({
  fontFamily: props.theme.headingBrandFont,
  fontSize: `${props.theme.headingTitleFontSize}px`,
  letterSpacing: props.theme.headingTitleLetterSpacing,
  lineHeight: `${props.theme.headingTitleLineHeight}px`,
}));

export const HeadingSubtitle = styled(Text)(props => ({
  fontFamily: props.theme.headingSubtitleFont,
  fontSize: `${props.theme.headingSubtitleFontSize}px`,
  lineHeight: `${props.theme.headingSubtitleLineHeight}px`,
  letterSpacing: props.theme.headingSubtitleLetterSpacing,
  textTransform: props.theme.headingSubtitleTextTransform,
}));
