import React from 'react';

export default () => (
  <svg width="100%" height="100%" viewBox="0 0 512 512">
    <path d="M512 0H0v512h512V0z" fill="#E5E1D9" />
    <path
      d="M252.093 232.413c27.673 0 50.1-22.432 50.1-50.115 0-27.656-22.43-50.085-50.1-50.085-27.651 0-50.1 22.442-50.1 50.085 0 27.67 22.446 50.115 50.1 50.115zm-62.968 14.443c-16.785-16.38-27.212-39.251-27.212-64.558 0-49.786 40.401-90.165 90.18-90.165 49.811 0 90.18 40.379 90.18 90.165 0 25.309-10.42 48.182-27.204 64.562 57.234 27.529 97.344 91.443 97.344 165.913H91.773c0-74.473 40.114-138.39 97.352-165.917zm62.968 25.637c-53.661 0-100.34 41.76-115.263 100.2h230.527c-14.924-58.44-61.603-100.2-115.264-100.2z"
      fill="#ACA79B"
    />
  </svg>
);
