import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    {props.tiled ? (
      <path fill="#fff" d="M180 134h283L330.85 377.95h-283L180.01 134" />
    ) : (
      <>
        <path
          fill={isColour ? '#61929C' : 'currentColor'}
          fillRule="evenodd"
          d="M512 256C512 114.62 397.38 0 256 0S0 114.62 0 256s114.62 256 256 256 256-114.62 256-256zm-119-80.5H206.22L119 336.51h186.78L393 175.5z"
          clipRule="evenodd"
        />
        <path
          fill={isColour ? '#fff' : 'transparent'}
          d="M206.22 175.5H393l-87.22 161.01H119l87.22-161.01"
        />
      </>
    )}
  </svg>
);
