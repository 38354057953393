import * as changeCase from 'change-case';

/**
 * Changes case to specified case
 */
export const changeCaseTo = changeCase;

/**
 * Capitalizes first letter of string
 */
export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Converts snake case to kebab case
 */
export const snakeCaseToKebabCase = (string?: string) =>
  string ? string.toLowerCase().split('_').join('-') : '';

/**
 * Converts kebab case to snake case
 */
export const kebabCaseToSnakeCase = (string?: string) =>
  string ? string.toLowerCase().split('-').join('_') : '';

/**
 * Converts screaming snake case to title case
 */
export const screamingSnakeCaseToTitleCase = (string?: string) =>
  string
    ? string.toLowerCase().split('_').map(capitalizeFirstLetter).join(' ')
    : '';
