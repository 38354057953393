import React, { useState, useEffect } from 'react';
import ajax from '@helpers/ajax';
import guid from '@helpers/guid';
import useActiveArtist from '@hooks/useActiveArtist';
import { ModalInjectedProps } from '@ui/types';
import PageLoader from '@ui/components/PageLoader';
import { Data } from './types';
import RoyaltyPayouts from './RoyaltyPayouts';
import PaymentRailsWidget from './PaymentRailsWidget';
import { WrapperSimple } from './RoyaltyPayouts.styled';

const Container = (props: ModalInjectedProps) => {
  const [data, setData] = useState<Data | undefined>();
  const [showWidget, setShowWidget] = useState(false);
  const { actId } = useActiveArtist();

  const fetchPaymentRailsData = async () => {
    setData(undefined);
    const url = !actId
      ? 'payments/payment-rails/splits/recipient'
      : `payments/payment-rails/${actId}/recipient`;
    const res = await ajax(url);
    setData(res);
  };

  const fetchData = () => {
    fetchPaymentRailsData();
  };

  useEffect(() => {
    fetchData();
  }, [actId]);

  const openPaymentRails = () => {
    props.updateModalContent({
      modalTitle: 'Payout method',
      hasGoBackButton: true,
      onGoBack: () => {
        props.updateModalContent({
          modalTitle: 'Withdraw royalties',
          hasGoBackButton: false,
        });
        setShowWidget(false);
        fetchData();
      },
    });
    setShowWidget(true);
  };

  if (!data) {
    return (
      <WrapperSimple>
        <PageLoader message="Loading Wallet" />
      </WrapperSimple>
    );
  }

  if (showWidget) {
    return <PaymentRailsWidget />;
  }

  return (
    <WrapperSimple id={guid()} key={guid()}>
      <RoyaltyPayouts
        {...data}
        refreshData={fetchData}
        openPaymentRails={openPaymentRails}
        openAlert={props.openAlert}
      />
    </WrapperSimple>
  );
};

export default Container;
