import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "TitleBlockDialoguestyled__Wrapper",
  componentId: "sc-fm0f2p-0"
})(["display:flex;flex-direction:column;width:100%;"]);
export var ButtonWrapper = styled.div.withConfig({
  displayName: "TitleBlockDialoguestyled__ButtonWrapper",
  componentId: "sc-fm0f2p-1"
})(["display:flex;justify-content:flex-end;margin:0 ", "px ", "px 0;"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
});