import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Button from '../Button';
export var VideoPlayerWrapper = styled.div.withConfig({
  displayName: "VideoPlayerstyles__VideoPlayerWrapper",
  componentId: "sc-w5srma-0"
})(["position:relative;padding-bottom:", ";height:0;width:100%;"], function (props) {
  if (props.orientation === 'portrait') return '177.78%';
  if (props.orientation === 'square') return '100%';
  return '56.25%';
});
export var StyledReactPlayer = styled(ReactPlayer).withConfig({
  displayName: "VideoPlayerstyles__StyledReactPlayer",
  componentId: "sc-w5srma-1"
})(["width:100%;height:100%;top:0;left:0;position:absolute;"]);
export var Video = styled.video.withConfig({
  displayName: "VideoPlayerstyles__Video",
  componentId: "sc-w5srma-2"
})(["width:100%;height:100%;top:0;left:0;position:absolute;"]);
export var VideoButton = styled.div.withConfig({
  displayName: "VideoPlayerstyles__VideoButton",
  componentId: "sc-w5srma-3"
})(["display:flex;justify-content:center;align-items:center;position:absolute;width:100%;height:100%;pointer-events:none;"]);
export var Inner = styled.div.withConfig({
  displayName: "VideoPlayerstyles__Inner",
  componentId: "sc-w5srma-4"
})(["pointer-events:all;z-index:1;position:relative;display:flex;width:50%;height:50%;justify-content:center;align-items:center;transition:opacity 0.4s ease-in-out;opacity:", ";"], function (props) {
  return props.isHovered ? 1 : 0;
});
export var StyledButton = styled(Button).withConfig({
  displayName: "VideoPlayerstyles__StyledButton",
  componentId: "sc-w5srma-5"
})(["height:64px;width:64px;align-self:center;z-index:1;"]);