import React from 'react';
import useDevice from '@hooks/useDevice';
import { OpenAlertFn } from '@ui/types';
import Scrollbars from 'components/app/Scrollbars';
import AccountInfo from './AccountInfo';
import AccountPayouts from './AccountPayouts';
import { AccountWrapper } from './RoyaltyPayouts.styled';
import { Data } from './types';

interface Props extends Data {
  openPaymentRails: () => void;
  refreshData: () => void;
  openAlert: OpenAlertFn;
}

const RoyaltyPayouts = (props: Props) => {
  const { isMobile } = useDevice();

  return (
    <Scrollbars>
      <AccountWrapper isMobile={isMobile}>
        <AccountInfo {...props} />
        <AccountPayouts {...props} />
      </AccountWrapper>
    </Scrollbars>
  );
};

export default RoyaltyPayouts;
