import React from 'react';
import { isWindowExists } from '@helpers/isWindowExists';
import Logo from '@ui/components/Logo';
import useTheme from '@hooks/useTheme';
import BackButton from 'components/common/BackButton';
import LogOutButton from 'components/common/LogOutButton';
import * as styled from './AppHeaderBar.styles';

interface Props {
  isMobile?: boolean;
  hasLogOutButton?: boolean;
  handleBackButtonClick?: () => void;
}

const AuthFlowHeaderBar = (props: Props) => {
  const theme = useTheme();
  return (
    <styled.AppHeaderBarWrapper isMobile={props.isMobile}>
      <styled.AppHeaderBarMain isMobile={props.isMobile} isAuthFlow>
        <styled.NavigationWrapper>
          {props.handleBackButtonClick && (
            <BackButton
              onClick={props.handleBackButtonClick}
              isSmall={props.isMobile}
            />
          )}
        </styled.NavigationWrapper>
        <styled.LogoWrapper>
          <styled.LogoWrapperInner
            onClick={() => {
              if (isWindowExists) {
                window.location.href = window.location.origin;
              }
            }}
          >
            <Logo
              color="tertiary"
              type={theme.useFullLogoInHeader ? 'full' : 'icon'}
            />
          </styled.LogoWrapperInner>
        </styled.LogoWrapper>
        <styled.TogglesWrapper>
          {props.hasLogOutButton && <LogOutButton isSmall={props.isMobile} />}
        </styled.TogglesWrapper>
      </styled.AppHeaderBarMain>
    </styled.AppHeaderBarWrapper>
  );
};

export default React.memo(AuthFlowHeaderBar);
