import styled from 'styled-components';
export var FeaturePageMain = styled.div.withConfig({
  displayName: "FeaturePagestyled__FeaturePageMain",
  componentId: "sc-1no0eyc-0"
})(["height:calc( ", "px - ", "px );width:100vw;display:flex;flex-direction:column;", ""], function (props) {
  return props.height;
}, function (props) {
  return (props.isMobile ? props.theme.topbarHeightMobile : props.theme.topbarHeightDesktop) + props.theme.largeSpacer;
}, function (props) {
  return props.isScrollable && 'overflow: auto;';
});
export var FeaturePageContent = styled.div.withConfig({
  displayName: "FeaturePagestyled__FeaturePageContent",
  componentId: "sc-1no0eyc-1"
})(["padding:0 ", "px;box-sizing:border-box;display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start;position:relative;height:100%;width:100%;"], function (props) {
  return props.isMobile ? props.theme.largeSpacer : props.theme.xlargeSpacer;
});