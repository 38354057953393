import styled from 'styled-components';
var breakpoints = {
  mobile: '@media screen and (max-width: 599px)',
  desktop: '@media screen and (min-width: 600px)'
};

/**
 * A simple wrapper that displays children in columns or rows depending on the device
 */
export var DesktopColumnsMobileRows = styled.div.withConfig({
  displayName: "DesktopColumnsMobileRowsstyles__DesktopColumnsMobileRows",
  componentId: "sc-1nhcmq6-0"
})(["display:grid;grid-gap:", "px;grid-auto-flow:column;", " ", "{grid-auto-flow:row;height:min-content;grid-template-columns:unset;}"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return !!props.gridTemplateColumns && "\n    grid-template-columns: ".concat(props.gridTemplateColumns, ";\n  ");
}, breakpoints.mobile);