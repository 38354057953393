import { isWindowExists } from '@helpers/isWindowExists';

/**
 * Opens website in current tab
 */
const openInternalPage = (url: string) => {
  if (isWindowExists) {
    // eslint-disable-next-line no-restricted-syntax
    window.open(url, '_self');
  }
};

export default openInternalPage;
