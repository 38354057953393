import styled from 'styled-components';
import Masonry from 'react-masonry-css';
export var MasonryDashboard = styled(Masonry).withConfig({
  displayName: "Dashboardstyles__MasonryDashboard",
  componentId: "sc-15odiy3-0"
})(["display:flex;margin-left:-", "px;margin-bottom:-", "px;width:", ";.masonry-grid_column{padding-left:", "px;background-clip:padding-box;box-sizing:border-box;min-width:min-content;}a{text-decoration:none;}> .masonry-grid_column > *{margin-bottom:", "px;height:unset;}"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return "calc(100% + ".concat(props.theme.largeSpacer, "px)");
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
});
export var FixedLayoutDashboard = styled.div.withConfig({
  displayName: "Dashboardstyles__FixedLayoutDashboard",
  componentId: "sc-15odiy3-1"
})(["display:grid;grid-auto-flow:dense;grid-gap:", ";@media screen and (min-width:", "px){grid-template-columns:", ";}@media screen and (min-width:", "px) and (max-width:", "px){grid-template-columns:repeat(2,1fr);}@media screen and (max-width:", "px){grid-template-columns:1fr;}"], function (props) {
  return props.hasLargeGap ? "".concat(props.theme.largeSpacer, "px ").concat(props.theme.xxlargeSpacer, "px") : "".concat(props.theme.largeSpacer, "px");
}, function (props) {
  return props.breakpoints.threeCol;
}, function (props) {
  return props.isMaxTwoCol ? '1fr 1fr' : 'repeat(3, 1fr)';
}, function (props) {
  return props.breakpoints.twoCol;
}, function (props) {
  return props.breakpoints.threeCol - 1;
}, function (props) {
  return props.breakpoints.twoCol - 1;
});
export var FixedLayoutGridItem = styled.div.withConfig({
  displayName: "Dashboardstyles__FixedLayoutGridItem",
  componentId: "sc-15odiy3-2"
})(["@media screen and (min-width:", "px){grid-column-end:span ", ";grid-row-end:span ", ";}@media screen and (min-width:", "px) and (max-width:", "px){grid-column-end:span ", ";grid-row-end:span ", ";}@media screen and (max-width:", "px){grid-template-columns:1fr;grid-column-end:span 1;grid-row-end:span 1;}height:100%;width:100%;> *{height:100% !important;}"], function (props) {
  return props.breakpoints.threeCol;
}, function (props) {
  return props.isMaxTwoCol ? props.colSpans.twoCol : props.colSpans.threeCol;
}, function (props) {
  return props.isMaxTwoCol ? props.colSpans.twoCol : props.colSpans.threeCol;
}, function (props) {
  return props.breakpoints.twoCol;
}, function (props) {
  return props.breakpoints.threeCol - 1;
}, function (props) {
  return props.colSpans.twoCol;
}, function (props) {
  return props.rowSpans.twoCol;
}, function (props) {
  return props.breakpoints.twoCol - 1;
});