import { differenceInHours } from 'date-fns';
import { FeedCard, FeedCardCategories } from '@ui/types';

export const getCategorizedData: (
  data: FeedCard[],
  isMobile: Boolean
) => FeedCardCategories = (data, isMobile) => {
  const categorizedDataTemplate: FeedCardCategories = {
    new: {
      slug: 'new',
      title: 'New',
      cards: [],
    },
    featured: {
      slug: 'featured',
      title: 'Featured',
      cards: [],
    },
    today: {
      slug: 'today',
      title: 'New',
      cards: [],
    },
    yesterday: {
      slug: 'yesterday',
      title: 'Yesterday',
      cards: [],
    },
    thisWeek: {
      slug: 'thisWeek',
      title: 'This Week',
      cards: [],
    },
    earlier: {
      slug: 'earlier',
      title: 'Earlier',
      cards: [],
    },
  };

  data.forEach(item => {
    const now = new Date();
    const compare = new Date(item.date_ts);
    const difference = differenceInHours(now, compare);

    if (difference < 1) {
      const newItems = categorizedDataTemplate.new.cards.concat(item);
      categorizedDataTemplate.new.cards = newItems;
    } else if (
      // only concat the first featured item when isMobile is true
      item.isFeatured &&
      isMobile === true &&
      item.isFirstFeatured
    ) {
      const featuredItems = categorizedDataTemplate.featured.cards.concat(item);
      categorizedDataTemplate.featured.cards = featuredItems;
    } else if (difference < 24) {
      const todayItems = categorizedDataTemplate.today.cards.concat(item);
      categorizedDataTemplate.today.cards = todayItems;
    } else if (difference < 48) {
      const yesterdayItems =
        categorizedDataTemplate.yesterday.cards.concat(item);
      categorizedDataTemplate.yesterday.cards = yesterdayItems;
    } else if (difference < 168) {
      const weekItems = categorizedDataTemplate.thisWeek.cards.concat(item);
      categorizedDataTemplate.thisWeek.cards = weekItems;
    } else {
      const earlierItems = categorizedDataTemplate.earlier.cards.concat(item);
      categorizedDataTemplate.earlier.cards = earlierItems;
    }
  });

  return categorizedDataTemplate;
};
