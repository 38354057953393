import styled from 'styled-components';
export var AppHeaderBarWrapper = styled.div.withConfig({
  displayName: "AppHeaderBarstyles__AppHeaderBarWrapper",
  componentId: "sc-1hk9cw-0"
})(["padding:", "px;background:", ";", " position:relative;"], function (props) {
  return props.isMobile ? props.theme.largeSpacer : props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return !props.isMobile && "z-index: ".concat(props.theme.zIndexAppHeader, ";");
});
export var AppHeaderBarMain = styled.div.withConfig({
  displayName: "AppHeaderBarstyles__AppHeaderBarMain",
  componentId: "sc-1hk9cw-1"
})(["color:", ";box-sizing:border-box;position:relative;width:100%;", " font-size:14px;align-items:center;display:grid;grid-template-columns:1fr auto 1fr;grid-gap:", "px;grid-template-areas:'orientation logo account';", ";", ";"], function (props) {
  return props.theme.white;
}, function (props) {
  return !props.isMobile && "height: ".concat(props.theme.xlargeSpacer, "px;");
}, function (props) {
  return props.isMobile ? 0 : props.theme.xxlargeSpacer;
}, function (props) {
  return props.isMobile && !props.isAuthFlow && "\n    grid-template-columns: 1fr 150px;\n    grid-template-areas: 'orientation account'\n  ";
}, function (props) {
  return props.isMobile && props.isAuthFlow && "\n    grid-template-columns: ".concat(props.theme.xlargeSpacer, " auto ").concat(props.theme.xlargeSpacer, ";\n    grid-template-areas: 'orientation logo account'\n  ");
});
export var LogoWrapper = styled.div.withConfig({
  displayName: "AppHeaderBarstyles__LogoWrapper",
  componentId: "sc-1hk9cw-2"
})(["grid-area:logo;justify-content:center;z-index:1;display:flex;"]);
export var LogoWrapperInner = styled.div.withConfig({
  displayName: "AppHeaderBarstyles__LogoWrapperInner",
  componentId: "sc-1hk9cw-3"
})(["height:", "px;cursor:pointer;"], function (props) {
  return props.theme.xlargeSpacer;
});
export var TogglesWrapper = styled.div.withConfig({
  displayName: "AppHeaderBarstyles__TogglesWrapper",
  componentId: "sc-1hk9cw-4"
})(["grid-area:account;display:grid;grid-auto-flow:column;width:fit-content;margin-left:auto;align-items:center;grid-gap:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var NavigationWrapper = styled.div.withConfig({
  displayName: "AppHeaderBarstyles__NavigationWrapper",
  componentId: "sc-1hk9cw-5"
})(["grid-area:orientation;@media (min-width:600px){display:flex;flex-direction:column;justify-content:center;}"]);