import { differenceInDays, format, formatRelative } from 'date-fns';
import { formatDate } from './formatDate';

/**
 * Formats date to a human friendly sentence
 */
export const formatFriendlyDate = (dateInput: Date) => {
  const today = Date.now();
  if (differenceInDays(today, dateInput) === 0) {
    return formatRelative(dateInput, today);
  }
  if (
    differenceInDays(today, dateInput) > 0 &&
    differenceInDays(today, dateInput) < 6
  ) {
    return `${format(dateInput, 'EEEE')} at ${format(dateInput, 'p')}`;
  }
  return formatDate(dateInput);
};
