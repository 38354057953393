const segment = () => {
  const isImpersonation = localStorage.getItem('impersonation') === 'true';

  if (
    process.env.REACT_APP_API_BASE !== 'prod' ||
    window?.location?.origin?.includes('localhost') ||
    isImpersonation
  ) {
    return;
  }

  const analytics: any = window.analytics || {};

  if (!analytics.initialize)
    if (analytics.invoked) console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory =
        t =>
        (...args) => {
          const e = Array.prototype.slice.call(args);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      for (let t = 0; t < analytics.methods.length; t += 1) {
        const e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = (t, e) => {
        const n = document.createElement('script');
        n.type = 'text/javascript';
        n.async = !0;
        n.src = `https://cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;
        const a = document.getElementsByTagName('script')[0];
        a.parentNode?.insertBefore(n, a);
        // eslint-disable-next-line no-underscore-dangle
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.1.0';

      analytics.load('H7c6rU93p7mcLKqStYosomDdgqVTJG5w');
    }
};

export default segment;
