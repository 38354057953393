import styled from 'styled-components';
import { Heading, HeadingInfographic } from '@ui/components/Typography';
import Button from '@ui/components/Button';
export var Container = styled.div.withConfig({
  displayName: "PhoneNumberstyled__Container",
  componentId: "sc-g3ntrj-0"
})(["width:100%;display:grid;grid-gap:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var StyledHeading = styled(Heading).withConfig({
  displayName: "PhoneNumberstyled__StyledHeading",
  componentId: "sc-g3ntrj-1"
})(["display:block;padding-top:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var Label = styled(HeadingInfographic).withConfig({
  displayName: "PhoneNumberstyled__Label",
  componentId: "sc-g3ntrj-2"
})(["float:right;color:", ";"], function (props) {
  return props.verified ? props.theme.success : props.theme.warning;
});
export var ModalContainer = styled.div.withConfig({
  displayName: "PhoneNumberstyled__ModalContainer",
  componentId: "sc-g3ntrj-3"
})(["", ";"], function (props) {
  return props.padding ? "padding: ".concat(props.theme.largeSpacer, "px;") : '';
});
export var InputContainer = styled.div.withConfig({
  displayName: "PhoneNumberstyled__InputContainer",
  componentId: "sc-g3ntrj-4"
})(["display:flex;margin-top:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var PhoneNumberContainer = styled.div.withConfig({
  displayName: "PhoneNumberstyled__PhoneNumberContainer",
  componentId: "sc-g3ntrj-5"
})(["", " display:grid;"], function (props) {
  return !props.isMobile && "min-width: 350px;";
});
export var StyledButton = styled(Button).withConfig({
  displayName: "PhoneNumberstyled__StyledButton",
  componentId: "sc-g3ntrj-6"
})(["margin-left:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var SkipButton = styled(Button).withConfig({
  displayName: "PhoneNumberstyled__SkipButton",
  componentId: "sc-g3ntrj-7"
})(["margin-left:auto;"]);
export var RightAlignedButton = styled(Button).withConfig({
  displayName: "PhoneNumberstyled__RightAlignedButton",
  componentId: "sc-g3ntrj-8"
})(["margin-left:auto;margin-top:", "px;"], function (props) {
  return props.theme.largeSpacer;
});