import styled from 'styled-components';
import ReactCodeInput from 'react-code-input';
import Icon from '@ui/components/Icon';
import { BodyText, HeadingLabel, SmallText } from '@ui/components/Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "InputCodestyled__Wrapper",
  componentId: "sc-16jtra6-0"
})(["width:100%;position:relative;display:flex;flex-direction:column;min-width:50px;"]);
export var Label = styled(HeadingLabel).withConfig({
  displayName: "InputCodestyled__Label",
  componentId: "sc-16jtra6-1"
})(["", " ", ""], function (props) {
  return !props.hasTooltip && "margin-bottom: ".concat(props.theme.spacer, "px;");
}, function (props) {
  return props.hasTooltip && 'align-self: center;';
});
export var Message = styled(BodyText).withConfig({
  displayName: "InputCodestyled__Message",
  componentId: "sc-16jtra6-2"
})(["margin-top:", "px;color:", ";"], function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.error ? props.theme.warning : props.theme.tertiary;
});
export var WithTooltip = styled.div.withConfig({
  displayName: "InputCodestyled__WithTooltip",
  componentId: "sc-16jtra6-3"
})(["display:grid;grid-template-columns:auto 1fr;grid-gap:6px;margin-bottom:", "px;"], function (p) {
  return p.theme.spacer;
});
export var HintWrapper = styled.div.withConfig({
  displayName: "InputCodestyled__HintWrapper",
  componentId: "sc-16jtra6-4"
})(["margin-top:", "px;display:grid;grid-template-columns:auto 1fr;grid-gap:", "px;"], function (p) {
  return p.theme.spacer / 2;
}, function (p) {
  return p.theme.spacer / 2;
});
export var CustomIcon = styled(Icon).withConfig({
  displayName: "InputCodestyled__CustomIcon",
  componentId: "sc-16jtra6-5"
})(["align-self:center;color:", ";"], function (props) {
  return props.theme[props.color || 'grey'];
});
export var HintMessage = styled(SmallText).withConfig({
  displayName: "InputCodestyled__HintMessage",
  componentId: "sc-16jtra6-6"
})(["color:", ";"], function (props) {
  return props.theme[props.color || 'grey'];
});
export var CodeInput = styled(ReactCodeInput).withConfig({
  displayName: "InputCodestyled__CodeInput",
  componentId: "sc-16jtra6-7"
})(["display:grid !important;grid-gap:", "px;grid-template-columns:repeat(6,1fr);input{text-align:center;color:", ";font-family:", ";font-size:", "px;line-height:", "px;padding:", "px;outline:none;border:none;background-color:", ";box-sizing:border-box;margin:0;&::-webkit-outer-spin-button,&::-webkit-inner-spin-button{-webkit-appearance:none;margin:0;}&[type='number']{-moz-appearance:textfield;}border-radius:", "px;&:active,&:focus{outline:1px solid ", ";}", ";", ";}"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.headingFont;
}, function (props) {
  return props.theme.headingFontSize;
}, function (props) {
  return props.theme.headingLineHeight;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.vdgrey;
}, function (props) {
  return props.theme.inputBorderRadius;
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.isCorrect && "outline: 1px solid ".concat(props.theme.primary, ";");
}, function (props) {
  return props.isIncorrect && "outline: 1px solid ".concat(props.theme.warning, ";");
});