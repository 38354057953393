import React from 'react';
import Avatar from '@ui/components/Avatar';
import { HeadingSmall } from '@ui/components/Typography';
import Icon from '@ui/components/Icon';
import { Feature } from '@ui/types/feature';
import useActiveArtist from '@hooks/useActiveArtist';
import useDevice from '@hooks/useDevice';
import useUser from '@hooks/useUser';
import { truncateText } from '@helpers/truncateText';
import {
  AccountToggleMain,
  UserWrapper,
  UserInnerWrapper,
  IconWrapper,
} from './AccountToggle.styled';

interface Props {
  onToggle: () => void;
  activeFeature?: Feature;
}

const AccountToggle = (props: Props) => {
  const { actId, appProfileImage, actName } = useActiveArtist();
  const { isMobile } = useDevice();
  const { email } = useUser();
  const displayNameOrEmail =
    actName && actName !== '' && !props.activeFeature?.isPro ? actName : email;
  return (
    <AccountToggleMain
      isMobile={isMobile}
      onClick={props.onToggle}
      isBlocked={!actId}
      data-testid="account_toggle"
    >
      <Avatar source={appProfileImage} size={32} />
      {!isMobile && (
        <UserWrapper>
          <UserInnerWrapper>
            <HeadingSmall>{truncateText(displayNameOrEmail, 25)}</HeadingSmall>
          </UserInnerWrapper>
          <IconWrapper>
            <Icon name="caret-down" />
          </IconWrapper>
        </UserWrapper>
      )}
    </AccountToggleMain>
  );
};

export default AccountToggle;
