import React from 'react';

interface Props {
  symbol: string;
  label?: string;
}

/**
 * Displays a cross-browser emoji given a name
 */
const Emoji = (props: Props) => (
  <span
    role="img"
    aria-label={props.label ? props.label : ''}
    aria-hidden={props.label ? 'false' : 'true'}
    {...props}
  >
    {props.symbol}
  </span>
);

export default Emoji;
