const key =
  process.env.REACT_APP_API_BASE === 'prod'
    ? 'beatchain'
    : `${process.env.REACT_APP_API_BASE}-beatchain`;

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.error('ERROR SAVING STATE', err);
  }
};
