import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import {
  getFacebookTokenAlert,
  getTwitterTokenAlert,
} from '@helpers/getSocialTokenAlert';
import { find } from 'lodash';
import { features } from 'config/features';
import { useLocation } from '@hooks/useRouting';
import AppHeaderBar from 'components/app/AppHeaderBar';
import ScreenFade from 'components/app/ScreenFade';
import TopNav from 'components/app/TopNav';
import AccountMenu from 'components/account/AccountMenu';
import useTheme from '@hooks/useTheme';

/**
 * Composes a thumbnails, heading and sub-header with option for filters or other header controls
 */
const Header = React.memo(() => {
  const theme = useTheme();
  const [accountMenuOpen, setMenuOpen] = useState(false);
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);

  const activeFeature = find(features, feature => feature.slug === activeItem);

  const collapseNavs = (clearActiveItem?: true) => {
    setMenuOpen(false);
    setNavMenuOpen(false);
    if (clearActiveItem) setActiveItem(location.pathname);
  };

  const toastId = 'TokenAlert';
  const cookie = Cookies.get(toastId);

  const showToast = (text: string, onClick: () => void) => {
    toast.error(text, {
      onClick,
      closeButton: false,
      onClose: () => Cookies.set(toastId, true, { expires: 1 / 12 }),
    });
  };

  useEffect(() => {
    const twitterTokenAlert = getTwitterTokenAlert(theme?.title);
    if (twitterTokenAlert && !cookie) {
      showToast(twitterTokenAlert.text, twitterTokenAlert.clickHandler);
    }

    const facebookTokenAlert = getFacebookTokenAlert(theme?.title);
    if (facebookTokenAlert && !cookie) {
      showToast(facebookTokenAlert.text, facebookTokenAlert.clickHandler);
    }
  }, []);

  return (
    <>
      <AppHeaderBar
        onToggle={() => setMenuOpen(true)}
        toggleNavMenu={setNavMenuOpen}
        navMenuOpen={navMenuOpen}
        collapseNavs={() => collapseNavs(true)}
        activeFeature={activeFeature}
      />

      <ScreenFade
        isActive={navMenuOpen || accountMenuOpen}
        isExtraZIndex={accountMenuOpen}
        onToggle={collapseNavs}
      />

      <TopNav
        isExpanded={navMenuOpen}
        collapseNavs={collapseNavs}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />

      <AccountMenu
        isExpanded={accountMenuOpen}
        onToggle={collapseNavs}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />
    </>
  );
});

export default Header;
