import React from 'react';
import Icon from '@ui/components/Icon';
import useLogOut from '@hooks/useLogOut';
import { IconWrapper } from './LogOutButton.styled';

interface Props {
  isSmall?: boolean;
}

/**
 * Button styled and configured for logging out
 */
const BackButton = (props: Props) => {
  const logOut = useLogOut();
  return (
    <IconWrapper onClick={() => logOut()} isSmall={props.isSmall}>
      <Icon name="cross" />
    </IconWrapper>
  );
};
export default BackButton;
