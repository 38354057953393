import numeral from 'numbro';

/**
 * Formats numbers into a diplay friendly number e.g 4.1m
 */
export const formatStreamingNumbers = (value: number) =>
  value > 9999
    ? numeral(value).format({
        thousandSeparated: true,
        mantissa: 1,
        trimMantissa: true,
        average: true,
        lowPrecision: false,
      })
    : numeral(value).format({
        thousandSeparated: true,
        mantissa: 1,
        trimMantissa: true,
        average: false,
      });

/**
 * Formats number into one with no decimal places
 */
export const formatNumbersNoDecimalPlaces = (value: number) =>
  numeral(value).format({ thousandSeparated: true, mantissa: 0 });

/**
 * Formats number into one with two decimal places
 */
export const formatNumbersTwoDecimalPlaces = (value: number) =>
  numeral(value).format({
    mantissa: 2,
    roundingFunction: Math.floor,
  });

/**
 * Formats number into one with a unit at the end e.g k, m
 */
export const formatNumbersWithUnit = (value: number) =>
  numeral(value).format({
    mantissa: 1,
    trimMantissa: true,
    average: value > 999,
  });
