import React, { useState } from 'react';
import {
  MediaLibraryItem,
  MediaLibraryProps,
  MediaSectionSortMethod,
  ModalInjectedProps,
} from '@ui/types';
import useDevice from '@hooks/useDevice';
import guid from '@helpers/guid';
import Button from '@ui/components/Button';
import Tabs from '@ui/components/Tabs';
import Scrollbars from 'components/app/Scrollbars';
import { ModalFooter } from 'components/common/ModalFooter/ModalFooter.styled';
import Dropzone from 'components/common/Dropzone';
import MediaMenu from '../MediaMenu';
import MediaSection from '../MediaSection';
import useFileLoader from '../useFileLoader';
import UploadProgress from '../UploadProgress';
import {
  Wrapper,
  EmptyState,
  MenuWrapper,
  MediaSectionWrapper,
} from './MediaLibrary.styled';
import MediaModal from '../MediaModal/MediaModal';

interface Props {
  mediaItems: MediaLibraryItem[];
  tags: string[];
  collections: string[];
  updateSortValue: (value: 'fileType' | 'collection') => void;
  updateSearchValue: (value: string) => void;
  goToMediaItem: (item: MediaLibraryItem) => void;
  currentMediaItem: MediaLibraryItem | null;
  setCurrentMediaItem: (item: MediaLibraryItem | null) => void;
  updateTags: (tags: string[]) => void;
  updateCollections: (collections: string[]) => void;
  addItem: (item: MediaLibraryItem) => void;
  handleInsert?: () => void;
  selectedItems: string[];
  emptySelectedItems: () => void;
  setSelectedItems: (ids: string[]) => void;
  addToSelectedItems: (id: string) => void;
  removeFromSelectedItems: (id: string) => void;
  deleteSelectedItem: () => void;
  loading: boolean;
  setAddMediaRequested: (value: boolean) => void;
}

const MediaLibrary = (
  props: Props & MediaLibraryProps & ModalInjectedProps
) => {
  const { isMobile } = useDevice();

  const [selectedTab, setSelectedTab] = useState(
    (props.accept && props.accept[0].split('/').shift()) || 'all'
  );
  const [filterOption, setFilterOption] =
    useState<MediaSectionSortMethod>('date_newest');

  function cb(file, fileData) {
    const mediaId = guid();

    const item = {
      media_id: mediaId,
      url: fileData.s3url,
      created_date: file.lastModifiedDate,
      collections: [],
      tags: [],
      used: false,
      updated_date: '',
      name: file.name?.replace(/\.[^.]+$/, ''),
      file_name: file.name,
      meta: {
        ...file.meta,
        fileSize: file.size,
        fileType: file.type.split('/')[0],
        fileExtension: file.type.split('/')[1],
      },
      loaded: file.size,
    };

    props.validateItem?.(item);
    props.addItem(item);
    props.setAddMediaRequested(true);
    if (props.isMultiSelect) {
      props.addToSelectedItems(mediaId);
    } else {
      props.setSelectedItems([mediaId]);
    }
  }

  const [uploadingFiles, setUploadingFiles, resetUploadingFiles] =
    useFileLoader(cb);

  // temporary fix for typescript erros caused by no typing in useFileLoader
  const files: any[] = typeof uploadingFiles === 'object' ? uploadingFiles : [];
  const setFiles: (newItems: any[]) => void =
    typeof setUploadingFiles === 'object' ? () => {} : setUploadingFiles;
  const reset: () => void =
    typeof resetUploadingFiles === 'object' ? () => {} : resetUploadingFiles;

  const handleSelect = filterSelectOption => {
    setFilterOption(filterSelectOption.value);
  };
  // hasNoCurrentItem={!!props.currentMediaItem} // todo add to new layout

  const onInsertButtonClick = () => {
    if (props.currentMediaItem) {
      props.addToSelectedItems(props.currentMediaItem.media_id);
      props.handleInsert!();
    }
  };
  return (
    <Wrapper isMobile={isMobile} hasNoCurrentItem={!!props.currentMediaItem}>
      <Dropzone onDrop={setFiles} accept={props.accept} multiple>
        <MenuWrapper isMobile={isMobile}>
          <Tabs
            tabs={[
              { slug: 'all', label: 'All' },
              { slug: 'image', label: 'Images' },
              { slug: 'video', label: 'Video' },
              { slug: 'audio', label: 'Audio' },
            ]}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <MediaMenu
            handleSelect={handleSelect}
            updateSearchValue={props.updateSearchValue}
            onFileDrop={setFiles}
            sortBy={filterOption}
            accept={props.accept}
            dropzoneText={props.dropzoneText}
          />
        </MenuWrapper>
        <MediaSectionWrapper hasNoCurrentItem={!!props.currentMediaItem}>
          <Scrollbars>
            <MediaSection
              {...props}
              data={
                selectedTab === 'all'
                  ? props.mediaItems
                  : props.mediaItems.filter(
                      item => item.meta.fileType === selectedTab
                    )
              }
              sortMethod={filterOption}
            />
          </Scrollbars>
          <Scrollbars>
            {props.currentMediaItem && (
              <MediaModal
                isSelectMode={props.isSelectMode}
                handleInsert={props.handleInsert}
                collections={props.collections}
                addToSelectedItems={props.addToSelectedItems}
                currentMediaItem={props.currentMediaItem}
                deleteSelectedItem={props.deleteSelectedItem}
                setCurrentMediaItem={props.setCurrentMediaItem}
                tags={props.tags}
                updateCollections={props.updateCollections}
                updateTags={props.updateTags}
                openAlert={props.openAlert}
                loading={props.loading}
              />
            )}
          </Scrollbars>
        </MediaSectionWrapper>
        {props.mediaItems.length === 0 && (
          <EmptyState>No media found</EmptyState>
        )}
        {files?.length > 0 && <UploadProgress files={files} reset={reset} />}
      </Dropzone>
      {props.currentMediaItem && props.isSelectMode && (
        <ModalFooter isLarge>
          <Button
            text="Insert"
            loading={props.loading}
            onPress={onInsertButtonClick}
            dataTestId="insert-media-button"
          />
        </ModalFooter>
      )}
    </Wrapper>
  );
};

export default MediaLibrary;
