import React, { useState } from 'react';
import { useSelector, useDispatch } from '@hooks/useStore';
import { login } from 'store/auth';
import useWebOtpApi from '@hooks/useWebOtpApi';
import Button from '@ui/components/Button';
import { BodyText, Heading } from '@ui/components/Typography';
import FormErrorMessage from 'components/common/FormErrorMessage';
import OtpInput from 'components/account/OtpInput';

import { Wrapper, Header } from './MfaForm.styled';

const MfaForm = () => {
  const [code, setCode] = useState('');
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const mfaType = auth.signInAttemptPayload?.challengeName;
  const number =
    auth.signInAttemptPayload?.challengeParam?.CODE_DELIVERY_DESTINATION || '';

  const sendCode = async mfaCode => dispatch(login({ mfaType, mfaCode }));

  useWebOtpApi(otp => {
    setCode(otp.code);
    sendCode(otp.code);
  });

  const helpText =
    mfaType === 'SMS_MFA'
      ? `To continue, please enter the 6-digit verification code sent to your phone ending in ${number.substring(
          number.length - 4,
          number.length
        )}.`
      : 'To continue please enter the code generated from your temporary code generator app.';

  const error = auth.LOGIN?.error?.message;

  return (
    <Wrapper>
      <Header>
        <Heading>Two-step authentication</Heading>
        <BodyText>{helpText}</BodyText>
      </Header>
      <OtpInput
        label="Verification Code"
        onChange={setCode}
        value={code}
        disabled={auth.LOGIN?.fetching}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      <Button
        text="Continue"
        loading={auth.LOGIN?.fetching}
        onPress={() => sendCode(code)}
        disabled={!code}
      />
    </Wrapper>
  );
};

export default MfaForm;
