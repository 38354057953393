import React from 'react';
import NotificationsIndicator from '@ui/components/NotificationsIndicator/NotificationsIndicator';
import { PLATFORM, COLOURS } from '../../consts';

import icons from './icons';

import { Tile, ClickWrapper } from './SocialIcon.styled';

export interface SocialIconProps {
  platform?: string;
  variant?: 'circle' | 'square' | 'colour' | 'outline';
  size?: number;
  color?: string;
  onPress?: () => void;
  notificationsIndicatorText?: string;
  className?: string;
}

/**
 * Maps and styles icons for platforms referenced
 */
const SocialIcon = ({
  platform = 'beatchain',
  variant = 'outline',
  size = 24,
  onPress,
  className,
  ...props
}: SocialIconProps) => {
  const SVG = icons[platform] || icons[PLATFORM.BEATCHAIN];

  const tileSize =
    platform === PLATFORM.LINKEDIN ||
    (variant === 'circle' &&
      (platform === PLATFORM.INSTAGRAM ||
        platform === PLATFORM.BANDSINTOWN ||
        platform === PLATFORM.PANDORA ||
        platform === PLATFORM.ITUNES_STORE))
      ? '60%'
      : '66%';

  return (
    <ClickWrapper
      isClickable={!!onPress}
      onClick={e => {
        e.stopPropagation();
        if (onPress) onPress();
      }}
      className={className}
    >
      {variant === 'square' || variant === 'circle' ? (
        <Tile
          size={size}
          color={platform === PLATFORM.BEATCHAIN ? 'black' : COLOURS[platform]}
          shape={variant}
          {...props}
        >
          <SVG
            width={platform === PLATFORM.BEATCHAIN ? '100%' : tileSize}
            height={platform === PLATFORM.BEATCHAIN ? '100%' : tileSize}
            tiled={
              platform === PLATFORM.AMAZON_MUSIC && size >= 40 ? '' : 'true'
            }
            color={
              platform === PLATFORM.BEATCHAIN
                ? COLOURS[PLATFORM.BEATCHAIN]
                : 'white'
            }
          />
        </Tile>
      ) : (
        <SVG
          isColour={variant === 'colour'}
          platform={platform}
          width={size}
          height={size}
          tiled={platform === PLATFORM.AMAZON_MUSIC && size < 24 ? 'true' : ''}
          {...props}
        />
      )}
      {props.notificationsIndicatorText && (
        <NotificationsIndicator text={props.notificationsIndicatorText} />
      )}
    </ClickWrapper>
  );
};

export default SocialIcon;
