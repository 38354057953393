import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    <path
      fill={isColour ? '#EF0176' : 'currentColor'}
      fillRule="evenodd"
      d="M256 0c-69.525 0-78.243.295-105.548 1.54-27.249 1.243-45.858 5.571-62.142 11.9-16.834 6.541-31.11 15.295-45.343 29.527C28.735 57.2 19.98 71.476 13.44 88.31c-6.329 16.284-10.657 34.893-11.9 62.141C.296 177.757 0 186.475 0 256s.295 78.243 1.54 105.548c1.243 27.249 5.571 45.858 11.9 62.142 6.541 16.834 15.295 31.11 29.527 45.343 14.233 14.232 28.509 22.985 45.343 29.528 16.284 6.328 34.893 10.656 62.142 11.898C177.757 511.705 186.475 512 256 512s78.243-.295 105.548-1.541c27.249-1.242 45.858-5.57 62.142-11.898 16.834-6.543 31.11-15.296 45.343-29.528 14.232-14.233 22.986-28.509 29.528-45.343 6.328-16.284 10.656-34.893 11.898-62.142C511.705 334.243 512 325.525 512 256s-.295-78.243-1.541-105.549c-1.242-27.248-5.57-45.857-11.898-62.14-6.542-16.835-15.296-31.112-29.528-45.344C454.8 28.735 440.524 19.98 423.69 13.44c-16.284-6.329-34.893-10.657-62.142-11.9C334.243.296 325.525 0 256 0zm0 46.126c68.355 0 76.452.261 103.446 1.493 24.96 1.138 38.515 5.308 47.536 8.814 11.949 4.644 20.477 10.192 29.435 19.15 8.958 8.958 14.506 17.486 19.15 29.436 3.506 9.02 7.676 22.575 8.814 47.535 1.232 26.994 1.493 35.091 1.493 103.446 0 68.355-.261 76.452-1.493 103.446-1.138 24.96-5.308 38.515-8.814 47.535-4.644 11.95-10.192 20.478-19.15 29.435-8.958 8.959-17.486 14.507-29.435 19.151-9.021 3.505-22.576 7.676-47.536 8.814-26.99 1.232-35.086 1.493-103.446 1.493s-76.455-.261-103.446-1.493c-24.96-1.138-38.515-5.309-47.535-8.814-11.95-4.644-20.478-10.192-29.436-19.151-8.957-8.957-14.506-17.485-19.15-29.435-3.506-9.02-7.676-22.575-8.814-47.535-1.232-26.994-1.493-35.091-1.493-103.446 0-68.355.261-76.452 1.493-103.446 1.138-24.96 5.308-38.515 8.814-47.535 4.644-11.95 10.192-20.478 19.15-29.436 8.958-8.958 17.486-14.506 29.436-19.15 9.02-3.506 22.575-7.676 47.535-8.814 26.994-1.232 35.091-1.493 103.446-1.493z"
      clipRule="evenodd"
    />
    <path
      fill={isColour ? '#EF0176' : 'currentColor'}
      fillRule="evenodd"
      d="M256.082 341.034c-47.007 0-85.114-38.107-85.114-85.113 0-47.008 38.107-85.114 85.114-85.114 47.007 0 85.113 38.106 85.113 85.114 0 47.006-38.106 85.113-85.113 85.113zm0-216.234c-72.417 0-131.121 58.704-131.121 131.121 0 72.416 58.704 131.12 131.121 131.12 72.416 0 131.12-58.704 131.12-131.12 0-72.417-58.704-131.121-131.12-131.121zM424.162 118.638c0 17.01-13.79 30.799-30.801 30.799-17.009 0-30.799-13.789-30.799-30.799 0-17.011 13.79-30.8 30.799-30.8 17.011 0 30.801 13.789 30.801 30.8z"
      clipRule="evenodd"
    />
    <path
      fill="transparent"
      fillRule="evenodd"
      d="M256 46.126c68.355 0 76.452.261 103.446 1.493 24.96 1.138 38.515 5.308 47.536 8.814 11.949 4.644 20.477 10.192 29.435 19.15 8.958 8.958 14.506 17.486 19.15 29.436 3.506 9.02 7.676 22.575 8.814 47.535 1.232 26.994 1.493 35.091 1.493 103.446 0 68.355-.261 76.452-1.493 103.446-1.138 24.96-5.308 38.515-8.814 47.535-4.644 11.95-10.192 20.478-19.15 29.435-8.958 8.959-17.486 14.507-29.435 19.151-9.021 3.505-22.576 7.676-47.536 8.814-26.99 1.232-35.086 1.493-103.446 1.493s-76.455-.261-103.446-1.493c-24.96-1.138-38.515-5.309-47.535-8.814-11.95-4.644-20.478-10.192-29.436-19.151-8.957-8.957-14.506-17.485-19.15-29.435-3.506-9.02-7.676-22.575-8.814-47.535-1.232-26.994-1.493-35.091-1.493-103.446 0-68.355.261-76.452 1.493-103.446 1.138-24.96 5.308-38.515 8.814-47.535 4.644-11.95 10.192-20.478 19.15-29.436 8.958-8.958 17.486-14.506 29.436-19.15 9.02-3.506 22.575-7.676 47.535-8.814 26.994-1.232 35.091-1.493 103.446-1.493zM124.961 255.921c0-72.417 58.704-131.121 131.121-131.121 72.416 0 131.12 58.704 131.12 131.121 0 72.416-58.704 131.12-131.12 131.12-72.417 0-131.121-58.704-131.121-131.12zm268.4-106.484c17.011 0 30.801-13.789 30.801-30.799 0-17.011-13.79-30.8-30.801-30.8-17.009 0-30.799 13.789-30.799 30.8 0 17.01 13.79 30.799 30.799 30.799z"
      clipRule="evenodd"
    />
    <path
      fill="transparent"
      d="M256.082 341.034c-47.007 0-85.114-38.107-85.114-85.113 0-47.008 38.107-85.114 85.114-85.114 47.007 0 85.113 38.106 85.113 85.114 0 47.006-38.106 85.113-85.113 85.113z"
    />
  </svg>
);
