import React from 'react';
import { Auth } from '@aws-amplify/auth';
import { toast } from 'react-toastify';

import { fetchUserMetadata } from 'store/user';
import { useDispatch } from '@hooks/useStore';
import { useNavigate, useSearchParams } from '@hooks/useRouting';
import ajax from '@helpers/ajax';
import ConfirmationComponent from 'components/app/Confirmation';

const Confirmation = () => {
  const [res, setRes] = React.useState<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search] = useSearchParams();
  const code = search.get('code');
  const email = search.get('email');
  const redirect = search.get('redirect_to');

  const redirectTo =
    redirect ||
    (typeof window !== 'undefined'
      ? window.localStorage.getItem('beatchain_verify_email_redirect')
      : '');

  React.useEffect(() => {
    const confirm = async () => {
      try {
        if (!code) {
          throw new Error('Missing code');
        }
        const data = await ajax(
          'signup/account',
          { email, code },
          'POST',
          false
        );
        if (data.result === 'success') {
          if (!data.alreadyConfirmed) {
            try {
              // If the user is not logged in currentAuthenticatedUser will throw an error
              await Auth.currentAuthenticatedUser();
              dispatch(fetchUserMetadata({}));
            } catch (e) {
              console.error(e);
            }
            toast.success('Email address confirmed', { hideProgressBar: true });
          }
          if (redirectTo) {
            window.localStorage.removeItem('beatchain_verify_email_redirect');
          }
          setTimeout(() => navigate(redirectTo || '/welcome'), 2000);
        } else {
          throw new Error(`Couldn't confirm. Error: ${data.error}`);
        }
        setRes(data);
      } catch (e) {
        console.error(e);
        setRes({ result: 'error' });
        navigate('/invite-code');
      }
    };
    confirm();
  }, [code, email, redirectTo]);

  return (
    <ConfirmationComponent
      confirming={!res}
      success={res?.result === 'success'}
      alreadyConfirmed={res?.alreadyConfirmed}
      error={res?.result === 'error'}
    />
  );
};

export default Confirmation;
