import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { readableColor } from 'polished';
export var StyledToastContainer = styled(ToastContainer).withConfig({
  displayName: "Toaststyled__StyledToastContainer",
  componentId: "sc-1mhkqzp-0"
})(["padding:0;top:", "px;width:unset;display:flex;flex-direction:column;align-items:flex-end;", ";", ";.Toastify__toast{display:flex;align-items:center;min-height:unset;margin-bottom:", "px;padding:", "px;border-radius:", "px;font-family:", ";font-size:", "px;line-height:", "px;&--default{background:", ";color:", ";}&--dark{background:", ";color:", ";}&--info{background:", ";color:", ";}&--success{background:", ";color:", ";}&--warning{background:", ";color:", ";}&--error{background:", ";color:", ";}&-body{margin:0 ", "px;}}"], function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.isMobile && "\n      right: 0;\n      max-width: 94%;\n      margin: 0 auto;\n  ";
}, function (props) {
  return !props.isMobile && "\n      max-width: 320px;\n      right: ".concat(props.theme.xlargeSpacer, "px;\n    ");
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.bodyTextFont;
}, function (props) {
  return props.theme.bodyTextFontSize;
}, function (props) {
  return props.theme.bodyTextLineHeight;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.vdgrey;
}, function (props) {
  return readableColor(props.theme.vdgrey);
}, function (props) {
  return props.theme.info;
}, function (props) {
  return readableColor(props.theme.info);
}, function (props) {
  return props.theme.success;
}, function (props) {
  return readableColor(props.theme.success);
}, function (props) {
  return props.theme.tertiary;
}, function (props) {
  return readableColor(props.theme.tertiary);
}, function (props) {
  return props.theme.warning;
}, function (props) {
  return readableColor(props.theme.warning);
}, function (props) {
  return props.theme.spacer;
});