import React from 'react';
import { Formik } from 'formik';

import validateForm from '@helpers/validateForm';
import { useSelector, useDispatch } from '@hooks/useStore';
import { useSearchParams } from '@hooks/useRouting';
import { signup } from 'store/auth';
import Field from '@ui/components/Field';
import ButtonWrapper from '@ui/components/ButtonWrapper';
import Button from '@ui/components/Button';
import { FormWrapper } from 'components/common/FormWrapper';
import FormErrorMessage from 'components/common/FormErrorMessage';
import { PasswordFieldset, Strength } from './SignUpForm.styled';

const SignUpForm = () => {
  const [search, setSearch] = useSearchParams();

  const signupState = useSelector(({ auth }) => auth.SIGNUP);
  const dispatch = useDispatch();

  const validationSchema = {
    email: {
      type: String,
      match: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/i,
      required: true,
      message: {
        match: 'Email must be valid',
        required: 'Email is required',
      },
    },
    password: {
      type: String,
      match: /(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])/,
      required: true,
      length: {
        min: 8,
      },
      message: {
        match: 'Must contain a capital, lowercase, and number',
        length: 'Password must have a minimum length of 8',
        required: 'Password is required',
      },
    },
  };

  const strengthIndicator = (value: string) => {
    const hasNumbers = /[0-9]/.test(value);
    const hasSpecial = /[!@#$^&*)(+=._-]/.test(value);
    const hasMixed = /[a-z]/.test(value) && /[A-Z]/.test(value);
    let count = 0;
    if (value.length > 5) count += 1;
    if (value.length >= 8) count += 1;
    if (hasNumbers) count += 1;
    if (hasMixed) count += 1;
    if (hasSpecial) count += 1;

    if (count > 5) return { label: 'Very Strong', color: '#009933' };
    if (count > 4) return { label: 'Strong', color: '#80cc33' };
    if (count > 3) return { label: 'Weak', color: '#ff4827' };
    return { label: '', color: '' };
  };

  const customErrorMessage = (error: string) =>
    error.indexOf(
      `Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128`
    ) > -1
      ? 'Email too long'
      : error;

  return (
    <div data-testid="signup-form">
      <Formik
        initialValues={{
          email: search.get('email') || '',
          password: '',
          termsAgreed: false,
        }}
        validate={values => validateForm(validationSchema, values)}
        onSubmit={values => {
          setSearch({});
          dispatch(
            signup({
              ...values,
              redirectTo: search.get('redirect_to') || undefined,
              trialCode: search.get('trial_code') || undefined,
              cohortId: search.get('cohort_id') || undefined,
            })
          );
        }}
      >
        {formikProps => (
          <FormWrapper method="post">
            <Field
              dataTestId="email"
              label="email"
              name="email"
              type="email"
              autoComplete="username"
              disabled={
                signupState?.fetching || search.get('lock_email') === 'true'
              }
              large
            />

            <PasswordFieldset>
              <Field
                dataTestId="password"
                label="password"
                name="password"
                type="password"
                autoComplete="new-password"
                disabled={signupState?.fetching}
                large
              />
              <Strength
                color={strengthIndicator(formikProps.values.password).color}
              >
                {strengthIndicator(formikProps.values.password).label}
              </Strength>
            </PasswordFieldset>

            {signupState?.error?.message && (
              <FormErrorMessage isCentered>
                {customErrorMessage(signupState?.error?.message)}
              </FormErrorMessage>
            )}

            <ButtonWrapper>
              <Button
                type="submit"
                text="Next"
                icon="right"
                loading={signupState?.fetching}
                data-test-id="signup-submit"
              />
            </ButtonWrapper>
          </FormWrapper>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForm;
