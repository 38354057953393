import styled from 'styled-components';
export var FormPageWrapper = styled.div.withConfig({
  displayName: "FormPagestyles__FormPageWrapper",
  componentId: "sc-kxyust-0"
})(["display:flex;flex-direction:column;align-items:stretch;"]);
export var FormPageMain = styled.div.withConfig({
  displayName: "FormPagestyles__FormPageMain",
  componentId: "sc-kxyust-1"
})(["display:grid;width:100%;", " align-self:center;padding:", "px;grid-template-columns:", ";", " grid-gap:", "px;box-sizing:border-box;"], function (props) {
  return !props.hideSocialProofPanel && "max-width: 1200px;";
}, function (props) {
  return props.isMobile ? props.theme.largeSpacer : props.theme.xlargeSpacer;
}, function (props) {
  return props.isMobile ? '1fr' : '350px 1fr';
}, function (props) {
  return props.hideSocialProofPanel && "\n    grid-template-columns: ".concat(props.isMobile ? '1fr' : "".concat(props.theme.centeredDesktopColumnWidth, "px"), ";\n    justify-content: center;\n  ");
}, function (props) {
  return props.isMobile ? props.theme.largeSpacer : props.theme.xxlargeSpacer;
});
export var ContentWrapper = styled.div.withConfig({
  displayName: "FormPagestyles__ContentWrapper",
  componentId: "sc-kxyust-2"
})(["margin:0 auto;max-width:514px;min-height:100vh;padding-top:", ";padding-bottom:50px;a,a:hover,a:active,a:visited{color:", ";}"], function (props) {
  return props.isMobile ? 0 : '200px';
}, function (props) {
  return props.theme.linkColour;
});