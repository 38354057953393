import styled from 'styled-components';
import { HeadingTitle, BodyStrongText } from '@ui/components/Typography';

var mediaQuery = function mediaQuery(string, delim) {
  return "@media (".concat(delim, "-width: 600px) { ").concat(string, " }");
};

export var Wrapper = styled.div.withConfig({
  displayName: "Affiliatestyled__Wrapper",
  componentId: "sc-xtfnpe-0"
})(["background-color:black;height:", ";padding-bottom:100px;"], function (props) {
  return props.height || '100vh';
});
export var Banner = styled.div.withConfig({
  displayName: "Affiliatestyled__Banner",
  componentId: "sc-xtfnpe-1"
})(["padding:10px;background-color:", ";font-family:", ";text-align:center;position:absolute;top:0;left:0;z-index:200;a{color:black;margin-left:10px;text-decoration:underline;cursor:pointer;}", ";"], function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.headingFont;
}, mediaQuery('width: 100vw;', 'min'));
export var AffiliateHero = styled.div.withConfig({
  displayName: "Affiliatestyled__AffiliateHero",
  componentId: "sc-xtfnpe-2"
})(["overflow:hidden;position:relative;"]);
export var AffiliateBackground = styled.div.withConfig({
  displayName: "Affiliatestyled__AffiliateBackground",
  componentId: "sc-xtfnpe-3"
})(["width:100vw;height:100vh;position:fixed;left:0;top:0;background-color:black;background-image:url('https://static.beatchain.com/butter/ILXjKEXSrGdNDGfLQQhdHeroes_Images_MDW_SPO_07.jpg');filter:grayscale(1) contrast(0.8) brightness(0.3);"]);
export var AffiliateContent = styled.div.withConfig({
  displayName: "Affiliatestyled__AffiliateContent",
  componentId: "sc-xtfnpe-4"
})(["z-index:100;padding-top:10vh;position:relative;", ";"], mediaQuery('padding-top: 14vh;', 'max'));
export var LogoContainer = styled.div.withConfig({
  displayName: "Affiliatestyled__LogoContainer",
  componentId: "sc-xtfnpe-5"
})(["width:fit-content;display:grid;height:200px;grid-template-columns:1fr auto 1fr;margin:auto;color:white;", ";", ";"], mediaQuery('grid-template-columns: 1fr;', 'max'), mediaQuery('grid-template-rows: 1fr auto 1fr;', 'max'));
export var PartnerLogo = styled.div.withConfig({
  displayName: "Affiliatestyled__PartnerLogo",
  componentId: "sc-xtfnpe-6"
})(["display:flex;flex-direction:column;align-items:center;justify-content:center;background-image:", ";background-repeat:no-repeat;background-size:contain;min-height:200px;background-position:center right;", ";", ";"], function (props) {
  return "url(".concat(props.imageUrl, ")");
}, mediaQuery('background-position: center bottom;', 'max'), mediaQuery('min-height: 100px;', 'max'));
export var LogoVerticalCenter = styled.div.withConfig({
  displayName: "Affiliatestyled__LogoVerticalCenter",
  componentId: "sc-xtfnpe-7"
})(["display:flex;flex-direction:column;align-items:center;justify-content:center;"]);
export var X = styled.div.withConfig({
  displayName: "Affiliatestyled__X",
  componentId: "sc-xtfnpe-8"
})(["color:white;margin-left:40px;margin-right:40px;font-size:30px;display:flex;flex-direction:column;align-items:center;justify-content:center;", ";"], mediaQuery('margin: 20px 40px;', 'max'));
export var CtaText = styled.div.withConfig({
  displayName: "Affiliatestyled__CtaText",
  componentId: "sc-xtfnpe-9"
})(["color:white;padding:20px;display:grid;max-width:690px;margin:0 auto;"]);
export var CtaHeader = styled(HeadingTitle).withConfig({
  displayName: "Affiliatestyled__CtaHeader",
  componentId: "sc-xtfnpe-10"
})(["margin-bottom:35px;margin-top:20px;text-align:center;", ";"], mediaQuery('font-size: 24px; line-height: 27px;', 'max'));
export var CtaSubHeader = styled(BodyStrongText).withConfig({
  displayName: "Affiliatestyled__CtaSubHeader",
  componentId: "sc-xtfnpe-11"
})(["text-align:center;"]);
export var ButtonWrapper = styled.div.withConfig({
  displayName: "Affiliatestyled__ButtonWrapper",
  componentId: "sc-xtfnpe-12"
})(["display:flex;align-items:center;justify-content:center;"]);