import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    {isColour ? (
      <>
        <path
          fill="#254AA5"
          d="M102 170.998V1h219.172c8.532 0 15.885 5.03 19.259 12.286l68.073 149.108a21.192 21.192 0 011.812 8.604H102z"
        />
        <path
          fill="url(#7digital0_linear)"
          d="M257.204 511l-77.603-170.002 77.603-170h153.112c0 3.076-.648 6.002-1.83 8.647L257.204 511z"
        />
      </>
    ) : (
      <path
        fill="currentColor"
        d="M102 170.997V1h219.172c8.532 0 15.885 5.03 19.259 12.286l68.073 149.108a21.19 21.19 0 011.812 8.603c0 3.076-.648 6.003-1.83 8.648L257.204 511l-77.603-170.003 77.603-169.999L102 170.997z"
      />
    )}
    <defs>
      <linearGradient
        id="7digital0_linear"
        x1="179"
        x2="348.5"
        y1="477.5"
        y2="171"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61B5AE" />
        <stop offset=".009" stopColor="#61B5AE" />
        <stop offset="1" stopColor="#485DB4" />
      </linearGradient>
    </defs>
  </svg>
);
