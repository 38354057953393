import React from 'react';
import ExternalTextLink from '@ui/components/ExternalTextLink';
import {
  Wrapper,
  Title,
  Subtitle,
  StyledLargeCheckbox,
  PostTitle,
  StyledButton,
} from './ChecklistItem.styled';

export interface Item {
  title: string;
  name: string;
  subtitle: string;
  postTitle: string;
  postUrl: string;
  isChecked?: boolean;
  buttonText?: string;
  buttonHandleClick?: () => void;
}

interface Props extends Item {
  setIsChecked: (itemName: string, isChecked: boolean) => void;
}

const ChecklistItem = (props: Props) => (
  <Wrapper>
    <Title>{props.title}</Title>
    <StyledLargeCheckbox
      isChecked={props.isChecked}
      handleChange={props.setIsChecked}
      name={props.name}
    />
    <Subtitle>{props.subtitle}</Subtitle>
    <PostTitle>
      <ExternalTextLink text={props.postTitle} href={props.postUrl} />
    </PostTitle>
    {props.buttonText && (
      <StyledButton text={props.buttonText} onPress={props.buttonHandleClick} />
    )}
  </Wrapper>
);

export default ChecklistItem;
