import styled from 'styled-components';
import { darken } from 'polished';
import { COLOURS } from '../../consts';
import { ButtonText, HeadingSmall } from '../Typography';
import TextBase from '../Text';
export var Wrapper = styled.button.attrs({
  dataTestId: 'social-button'
}).withConfig({
  displayName: "SocialButtonstyled__Wrapper",
  componentId: "sc-rc4u4o-0"
})(["max-width:100%;height:", "px;", " ", " ", " cursor:pointer;", ";", " background-color:", ";", ";color:", ";padding:0 ", "px;", " border:0;display:flex;align-items:center;text-align:center;", " ", " ", " &[disabled]{pointer-events:none;}&:hover,&:focus{outline:none;background-color:", ";", " transition:background-color 250ms;}"], function (props) {
  return props.small ? props.theme.buttonHeightSmall : props.theme.buttonHeight;
}, function (props) {
  return props.large && "height: ".concat(props.theme.buttonHeightLarge, "px;");
}, function (props) {
  return !props.squishy && "min-width: ".concat(props.theme.buttonMinWidth, "px;");
}, function (props) {
  return props.small && !props.squishy && "min-width: ".concat(props.theme.buttonMinWidthSmall, "px;");
}, function (props) {
  return props.visuallyDisabled && "opacity: 0.4;\n     cursor: default;";
}, function (props) {
  return props.shouldNotStretch && "width: fit-content;";
}, function (props) {
  return props.light ? props.theme.white : COLOURS[props.platform];
}, function (props) {
  return props.dark && "background-color: ".concat(props.theme.vdgrey);
}, function (props) {
  return props.light ? props.theme.black : props.theme.white;
}, function (props) {
  return props.theme[props.small ? 'smallSpacer' : 'spacer'];
}, function (props) {
  return props.large && "padding: 0 ".concat(props.theme.largeSpacer, "px;");
}, function (props) {
  return props.darkShadow && "box-shadow: ".concat(props.theme.solidShadow, ";");
}, function (props) {
  return props.lightShadow && "box-shadow: ".concat(props.theme.solidShadowLight, ";");
}, function (props) {
  return props.official && "padding: 0 18px;";
}, function (props) {
  return darken(0.1, props.light ? props.theme.white : COLOURS[props.platform] || props.theme.white);
}, function (props) {
  return props.dark && "\n    &:hover,\n    &:focus {\n      outline: none;\n      background-color: ".concat(props.theme.black, ";\n  ");
});
export var Content = styled.div.withConfig({
  displayName: "SocialButtonstyled__Content",
  componentId: "sc-rc4u4o-1"
})(["position:relative;display:flex;justify-content:center;align-items:center;margin:0 ", "px;flex-grow:1;", ""], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.official && "margin: 0;\n    margin-left: 24px;";
});
export var Text = styled(HeadingSmall).withConfig({
  displayName: "SocialButtonstyled__Text",
  componentId: "sc-rc4u4o-2"
})(["", " ", ""], function (props) {
  return props.hidden && 'opacity: 0;';
}, function (props) {
  return props.small && 'font-size: 12px;';
});
export var TextAllCaps = styled(ButtonText).withConfig({
  displayName: "SocialButtonstyled__TextAllCaps",
  componentId: "sc-rc4u4o-3"
})(["", " ", ""], function (props) {
  return props.hidden && 'opacity: 0;';
}, function (props) {
  return props.small && 'font-size: 10px;';
});
export var TextOfficial = styled(TextBase).withConfig({
  displayName: "SocialButtonstyled__TextOfficial",
  componentId: "sc-rc4u4o-4"
})(["font-size:15px;", " ", ""], function (props) {
  return props.hidden && 'opacity: 0;';
}, function (props) {
  return props.small && 'font-size: 10px;';
});
export var LoaderWrapper = styled.div.withConfig({
  displayName: "SocialButtonstyled__LoaderWrapper",
  componentId: "sc-rc4u4o-5"
})(["position:absolute;left:0;top:0;right:0;bottom:0;display:flex;justify-content:center;align-items:center;"]);