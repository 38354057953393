import React from 'react';
import { PLATFORM, COLOURS } from '@ui/consts';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    viewBox="0 0 223 60"
    fill={isColour ? COLOURS[PLATFORM.FUGA] : 'currentColor'}
    {...props}
  >
    <path d="M9.92 53h-2.5V19.5A13.27 13.27 0 0120.67 6.25h33.47v2.5H20.67A10.77 10.77 0 009.92 19.5V53zM94.64 53H73.93a13.261 13.261 0 01-13.25-13.29V6.26h2.5v33.45a10.76 10.76 0 0010.75 10.75h20.71a10.759 10.759 0 0010.75-10.75V6.26h2.5v33.45A13.26 13.26 0 0194.64 53zM161.13 53h-33.5a13.267 13.267 0 01-13.25-13.25V19.52a13.268 13.268 0 0113.25-13.25h33.49v2.5h-33.49a10.77 10.77 0 00-10.75 10.75v20.21a10.768 10.768 0 0010.75 10.75h33.5V53zM214.77 53h-2.5V19.5a10.772 10.772 0 00-10.75-10.75H180.8a10.772 10.772 0 00-10.75 10.75V53h-2.5V19.5A13.268 13.268 0 01180.8 6.25h20.72a13.267 13.267 0 0113.25 13.25V53z" />
    <path d="M88.62 16.61H80a6.92 6.92 0 01-6.92-6.92V6.26h2.5v3.43A4.42 4.42 0 0080 14.11h8.63A4.43 4.43 0 0093 9.69V6.26h2.5v3.43a6.93 6.93 0 01-6.88 6.92zM26.71 25.09h8.63A6.94 6.94 0 0142.26 32v3.43h-2.5V32a4.42 4.42 0 00-4.42-4.42h-8.63A4.42 4.42 0 0022.29 32v3.43h-2.5V32a6.93 6.93 0 016.92-6.91zM145.59 25.09h8.63a6.941 6.941 0 016.92 6.91v3.43h-2.5V32a4.43 4.43 0 00-4.42-4.42h-8.63a4.418 4.418 0 00-4.42 4.42v3.43h-2.5V32a6.928 6.928 0 016.92-6.91zM186.85 42.62h8.63a6.93 6.93 0 016.92 6.92V53h-2.5v-3.46a4.43 4.43 0 00-4.42-4.42h-8.63a4.43 4.43 0 00-4.41 4.42V53h-2.5v-3.46a6.931 6.931 0 016.91-6.92z" />
  </svg>
);
