import React from 'react';
import { Formik } from 'formik';
import validateForm from '@helpers/validateForm';
import { useSelector } from '@hooks/useStore';
import Field from '@ui/components/Field';
import Button from '@ui/components/Button';
import ButtonWrapper from '@ui/components/ButtonWrapper';
import { FormWrapper } from 'components/common/FormWrapper';
import FormErrorMessage from 'components/common/FormErrorMessage';

interface Props {
  onSubmit: (newPassword: string) => void;
}

const ResetPasswordForm = (props: Props) => {
  const authResponse = useSelector(state => state.auth.RESET_PASSWORD);
  const loading = authResponse.fetching || authResponse.result === 'success';

  const validationSchema = {
    newPassword: {
      type: String,
      match: /(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])/,
      required: true,
      length: {
        min: 8,
      },
      message: {
        match: 'Must contain a capital, lowercase, and number',
        required: 'Password is required',
      },
    },
  };

  return (
    <>
      <Formik
        initialValues={{
          newPassword: '',
        }}
        validate={values => validateForm(validationSchema, values)}
        onSubmit={values => props.onSubmit(values.newPassword)}
      >
        <FormWrapper>
          <Field
            label="New Password"
            name="newPassword"
            type="password"
            large
            dataTestId="forgot-enter-password"
          />
          <ButtonWrapper>
            <Button
              dataTestId="submit"
              text="Set new password"
              type="submit"
              loading={loading}
              data-testid="set-new-password-button"
            />
          </ButtonWrapper>
          {authResponse.result === 'success' && (
            <FormErrorMessage>
              Password successfully changed, redirecting...
            </FormErrorMessage>
          )}
          {authResponse.error && authResponse.error.message && (
            <FormErrorMessage>{authResponse.error.message}</FormErrorMessage>
          )}
        </FormWrapper>
      </Formik>
    </>
  );
};

export default ResetPasswordForm;
