import React from 'react';
import guid from '@helpers/guid';
import { HeadingSmall } from '@ui/components/Typography';
import { FeedCardCategory } from '@ui/types';
import NewsFeedCard from '../NewsFeedCard';

const NewsFeedCardCategory = (props: FeedCardCategory) => (
  <>
    <HeadingSmall>{props.title}</HeadingSmall>
    {props.cards.map(card => (
      <NewsFeedCard key={card.news_feed_id || guid()} {...card} />
    ))}
  </>
);

export default NewsFeedCardCategory;
