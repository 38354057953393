import styled from 'styled-components';
export var ContentWithFooterMain = styled.div.withConfig({
  displayName: "ContentWithFooterstyles__ContentWithFooterMain",
  componentId: "sc-1o31odx-0"
})(["display:grid;grid-gap:", "px;grid-template-rows:1fr ", "px;padding:", ";", ";", " width:100%;height:100%;box-sizing:border-box;", ";"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.buttonHeight;
}, function (props) {
  return props.isMobile ? "".concat(props.theme.largeSpacer, "px") : "".concat(props.theme.xlargeSpacer, "px");
}, function (props) {
  return props.hasNoPadding && "padding: 0;";
}, function (props) {
  return props.smallPadding && "padding: ".concat(props.theme.spacer, "px;");
}, function (props) {
  return !props.isMobile && "\n    > :last-child {\n      justify-self: flex-end;\n    }\n    ";
});