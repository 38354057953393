import React from 'react';
import { map } from 'lodash';
import { useLocation, useNavigate } from '@hooks/useRouting';
import useDevice from '@hooks/useDevice';
import { PLATFORM_TITLES } from '@ui/consts';
import Link from '@ui/components/Link';
import { features } from 'config/features';
import Hamburger from 'components/app/Hamburger';
import BackButton from 'components/common/BackButton';

import { Wrapper, Item, Label } from './Navigation.styled';

const featureArray = map(features, feature => feature);

interface Props {
  toggleNavMenu: (isExpanded: boolean) => void;
  navMenuOpen: boolean;
}

const Navigation = (props: Props) => {
  const { isMobile } = useDevice();
  const navigate = useNavigate();
  const location = useLocation();

  const [, slug, ...params] = location.pathname.split('/');
  const hasSubList = ['fan-builder', 'hypelinks'].includes(slug);

  const goBackTo =
    hasSubList && params.length > 1 && !params.includes('linkInBio')
      ? `/${slug}/${params[0]}`
      : `/${slug}`;

  const hasBackButton = () => {
    if (params.includes('linkInBio') && !isMobile) return false;
    if (slug === 'insights' || slug === 'tracks' || isMobile)
      return params.length > 0;
    return hasSubList && params.length > 1;
  };

  const feature = featureArray.find(x => x.slug?.replace('/', '') === slug);

  return (
    <Wrapper>
      {hasBackButton() ? (
        <BackButton onClick={() => navigate(goBackTo)} />
      ) : (
        <Hamburger
          isSmall={isMobile}
          handleToggle={props.toggleNavMenu}
          isExpanded={props.navMenuOpen}
        />
      )}

      <Item>
        <Link to={feature?.path || ''}>
          <Label>
            {feature?.name === 'insights' &&
            params.length > 0 &&
            PLATFORM_TITLES[params[0]]
              ? `${PLATFORM_TITLES[params[0]]} Insights`
              : feature?.longLabel}
          </Label>
        </Link>
      </Item>
    </Wrapper>
  );
};

export default Navigation;
