import React, { useState, useEffect, useCallback } from 'react';
import ajax from '@helpers/ajax';
import Loader from '@ui/components/Loader';
import { ModalPadding } from 'components/common/ModalPadding/ModalPadding.styles';
import useActiveArtist from '@hooks/useActiveArtist';
import {
  LoaderWrapper,
  IframeWrapper,
  Iframe,
} from './PaymentRailsWidget.styled';

const PaymentRailsWidget = () => {
  const [widgetUrl, setWidgetUrl] = useState();

  const { actId } = useActiveArtist();

  const fetchWidgetUrl = useCallback(async () => {
    const res = await ajax(`payments/payment-rails/${actId}/widget`);
    setWidgetUrl(res.url);
  }, [actId]);

  useEffect(() => {
    fetchWidgetUrl();
  }, [fetchWidgetUrl]);

  if (!widgetUrl)
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );

  return (
    <ModalPadding>
      <IframeWrapper>
        {!!widgetUrl && <Iframe title="Payouts" src={widgetUrl} />}
      </IframeWrapper>
    </ModalPadding>
  );
};

export default PaymentRailsWidget;
