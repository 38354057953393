import React from 'react';
import TitleBlock from '@ui/components/TitleBlock';
import Button from '@ui/components/Button';
import { ModalInjectedProps } from '@ui/types';
import { Wrapper, ButtonWrapper } from './TitleBlockDialogue.styled';

interface Props {
  title: string;
  subtitle: string;
  closeButtonText: string;
}

/**
 * Messaging to users in an alert but with brand style used in marketing
 */
const TitleBlockDialogue = (props: Props & ModalInjectedProps) => (
  <Wrapper>
    <TitleBlock title={props.title} subtitle={props.subtitle} />
    <ButtonWrapper>
      <Button
        dataTestId="TitleBlockdialogue-cancel-button"
        text={props.closeButtonText}
        onPress={props.closeClickHandler}
      />
    </ButtonWrapper>
  </Wrapper>
);

export default TitleBlockDialogue;
