import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#460856' : 'currentColor'}
      d="M256 323.919c37.51 0 67.918-30.408 67.918-67.919 0-37.51-30.408-67.918-67.918-67.918-37.51 0-67.918 30.408-67.918 67.918 0 37.511 30.408 67.919 67.918 67.919zM256 0v83.592c-95.086 0-172.408 77.322-172.408 172.408H0C0 114.416 114.416 0 256 0zm0 512v-83.592c95.086 0 172.408-77.322 172.408-172.408H512c0 141.584-114.416 256-256 256z"
    />
  </svg>
);
