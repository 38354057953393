import styled from 'styled-components';
import { transparentize } from 'polished';
export var Tile = styled.div.withConfig({
  displayName: "SocialIconstyled__Tile",
  componentId: "sc-1qdns0u-0"
})(["width:", "px;height:", "px;background:", ";", ";display:flex;justify-content:center;align-items:center;flex-basis:auto;flex-shrink:0;"], function (props) {
  return props.size;
}, function (props) {
  return props.size;
}, function (props) {
  return props.color;
}, function (props) {
  return props.shape === 'circle' && "border-radius: ".concat(props.size / 2, "px;");
});
export var StoryRow = styled.div.withConfig({
  displayName: "SocialIconstyled__StoryRow",
  componentId: "sc-1qdns0u-1"
})(["display:flex;flex-direction:row;"]);
export var ClickWrapper = styled.span.withConfig({
  displayName: "SocialIconstyled__ClickWrapper",
  componentId: "sc-1qdns0u-2"
})(["cursor:", ";display:flex;justify-content:center;align-items:center;width:max-content;transition:transform 0.2s;position:relative;", ""], function (props) {
  return props.isClickable ? 'pointer' : 'default';
}, function (props) {
  return props.isClickable && "\n    &:hover {\n      transform: scale(1.1);\n      background-color: ".concat(transparentize(0.95, props.theme.vdgrey), ";\n      box-shadow: 0 0 0 3px ").concat(transparentize(0.95, props.theme.vdgrey), ";\n    }\n  ");
});