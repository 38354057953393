import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    {isColour ? (
      <>
        <path
          fill="#D13BF2"
          d="M401.813 512H110.187C49.837 512 0 462.552 0 401.813V110.576C0 49.837 49.837 0 110.187 0h291.237c60.739 0 110.187 49.837 110.187 110.187v291.237C512 462.552 462.552 512 401.813 512z"
        />
        <path
          fill="#fff"
          d="M149.122 426.732l293.962-213.366c7.008-5.062 3.504-16.353-5.062-16.353H74.366c-8.566 0-12.07 10.902-5.061 16.353l293.962 213.366c7.008 5.061 16.352-1.947 13.627-10.123L264.371 71.252c-2.726-8.176-14.406-8.176-17.132 0L135.105 416.609c-2.336 8.565 7.009 15.184 14.017 10.123z"
          opacity=".85"
        />
        <path
          fill="#fff"
          d="M175.988 290.847l80.207 58.014 79.818-58.014-30.759-93.834h-98.507l-30.759 93.834z"
        />
      </>
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M110.187 512h291.626c60.739 0 110.187-49.448 109.798-110.576V110.187C511.611 49.837 462.163 0 401.424 0H110.187C49.837 0 0 49.837 0 110.576v291.237C0 462.552 49.837 512 110.187 512zm38.935-85.268l107.072-77.716 107.073 77.716c7.008 5.061 16.352-1.947 13.627-10.123l-40.896-125.517 107.086-77.726c7.008-5.062 3.504-16.353-5.062-16.353H305.346L264.371 71.252c-2.726-8.176-14.406-8.176-17.132 0l-40.833 125.761H74.366c-8.566 0-12.07 10.902-5.061 16.353L175.96 290.78l-40.856 125.829c-2.336 8.565 7.009 15.184 14.017 10.123z"
        clipRule="evenodd"
      />
    )}
  </svg>
);
