import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    viewBox="0 0 160 50"
    fill={isColour ? '#0F1114' : 'currentColor'}
    {...props}
  >
    <path d="M19.31 34.65a2.92 2.92 0 01-2.98 2.85c-1.8 0-2.92-1.07-2.92-2.8 0-1.9 1.4-4.03 5.3-4.32l.6-.04v4.3zm-2.15-22.17c-9.45 0-14.4 3.94-14.4 7.83 0 3.26 2.3 5.14 6.27 5.14 3.56 0 5.86-1.24 5.86-3.14 0-.29-.07-.54-.14-.81-.08-.3-.16-.62-.16-1.01 0-.45.17-1.92 2.33-1.92 2.4 0 2.4 2.01 2.4 3.21v5.49l-.56.01C6.31 27.61 0 30.64 0 36.3c0 4.62 4.15 7.6 10.56 7.6 3.6 0 7.58-1.86 9.06-4.25l.03-.04 12.78-17.59c-.12-4.62-4.91-9.53-15.27-9.53zM83.94 34.65a2.92 2.92 0 01-2.98 2.85c-1.8 0-2.92-1.07-2.92-2.8 0-1.9 1.39-4.03 5.3-4.32l.6-.04v4.3zm75.49-14.06v-7.9h-11.56V5h-6.69c-2.43 5.34-7.03 7.7-13.32 7.7h-5.73v4.32l-4.33-4.32h-3.69c-7.77 0-13.62 4.17-13.62 9.71 0 7.85 8.07 9.38 13.97 10.5 3.43.65 5.7 1.08 5.7 2.66 0 1.04-.54 2.27-3.1 2.27-4.7 0-8.84-1.84-11.13-4.93h-5.44v3.2c0 1.2-.65 1.92-1.75 1.92-.8 0-1.68-.5-1.68-1.91V22.19c0-4.68-4.78-9.71-15.27-9.71-9.45 0-14.4 3.94-14.4 7.83 0 3.27 2.29 5.14 6.27 5.14 3.56 0 5.85-1.24 5.85-3.14 0-.29-.06-.54-.13-.81-.08-.3-.16-.62-.16-1.01 0-.45.17-1.92 2.33-1.92 2.4 0 2.4 2.01 2.4 3.21v5.49l-.56.01c-9.47.25-15.37 2.07-17.67 5.4H61.9c-1.24 3.01-4.14 5-7.34 5-3.38 0-6.45-3.18-6.45-9.44s3.07-9.43 6.45-9.43c2.34 0 3.39 1.67 3.39 2.85 0 .8-.38 1.35-.7 1.84-.37.53-.71 1.04-.71 1.92 0 1.3 1.01 3.38 3.73 3.38 3.44 0 5.5-2.25 5.5-6.02 0-4.18-5.3-10.25-13.62-10.25-8.18 0-16.87 5.51-16.87 15.71 0 10.17 8.69 15.65 16.87 15.65 5.53 0 10-2.37 12.58-6.45.64 3.96 4.6 6.45 10.46 6.45 3.6 0 7.58-1.86 9.06-4.25l.52-.84.46.87c1.3 2.45 5.16 4.22 9.16 4.22 1.96 0 3.74-.38 5.28-1.14l.82-.4v.88h7.64v-2.8l.95.9c1.77 1.66 5.4 2.73 9.27 2.73 8.12 0 13.57-4.05 13.57-10.07 0-8.03-7.82-9.37-13.53-10.35-3.53-.6-6.31-1.07-6.31-3.05 0-.95.51-2.1 2.98-2.1 2.85 0 5.35.75 7.08 2.1h12.61v12.7c0 6.64 4.14 10.77 10.8 10.77 6.66 0 10.8-4.13 10.8-10.78v-3.72h-4.58v5.9c0 1.22-.85 2.15-1.98 2.15-1.08 0-1.92-.95-1.92-2.15V20.59h11.56z" />
  </svg>
);
