import React from 'react';

import { IconProps } from './index';

const IconRhapsody = ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill={isColour ? '#f78f11' : 'currentColor'}
        d="M9.316 9.373c0-1.258.892-1.772 1.983-1.143l5.394 3.11c1.09.63 1.09 1.659 0 2.288l-5.394 3.11c-1.09.629-1.983.115-1.983-1.142V9.373Z"
      />
      <path
        fill={isColour ? '#003255' : 'currentColor'}
        d="M8.49 2.536C-.89 6.086-1.96 12.632 4.231 19.12c4.027 4.222 13.722 10.474 18.434-5.099.203-1.294-.819-1.004-1.636-.16-.774.755-1.22 1.183-1.993 1.715-.68.59-1.522 1.217-2.553 1.898-9.25 6.116-11.4-2.062-11.124-5.858.41-5.639 3.745-7.752 9.38-4.881.307.156.603.31.89.461.432.173.881.387 1.352.653 1.89 1.067 3.104 1.97 4.083 2.857.765.672 2.012.684 2.229-.828 1.62-11.34-3.572-11.595-14.805-7.342Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default IconRhapsody;
