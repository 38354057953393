import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "Logostyled__Wrapper",
  componentId: "sc-3ynamd-0"
})(["box-sizing:border-box;display:flex;height:", "px;width:", "px;", ""], function (props) {
  return props.size;
}, function (props) {
  return props.type === 'full' ? props.size * 4.3 : props.size;
}, function (props) {
  return props.type === 'tile' && "background-color: ".concat(props.theme[props.color], ";\n     padding: ").concat(props.size * (props.tileShape === 'circle' ? 0.15 : 0.12), "px;\n     ").concat(props.tileShape === 'circle' ? 'border-radius: 50%;' : '', "\n  ");
});