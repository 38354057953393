import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#F9061B' : 'currentColor'}
      d="M254.697 6.643a249.094 249.094 0 10251.668 249.082l-.028-3.431a249.095 249.095 0 00-251.64-245.65zm.807 120.842A129.122 129.122 0 01385.936 254.82l.024 1.779A129.123 129.123 0 11255.5 127.485h.004zm3.574 10.929a117.94 117.94 0 00-84.517 200.612 117.938 117.938 0 00166.494 1.72 117.935 117.935 0 0035.544-82.769l.02-1.624a117.942 117.942 0 00-117.533-117.939h-.008zm-47.003 55.284l112.378 63.203-113.295 61.678.917-124.881z"
    />
  </svg>
);
