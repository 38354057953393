import styled from 'styled-components';
import { Box } from 'components/common/Box/Box.styled';
import { Heading } from '@ui/components/Typography';
export var HeadingGroup = styled.div.withConfig({
  displayName: "DashboardRowstyles__HeadingGroup",
  componentId: "sc-1333pbn-0"
})(["display:grid;grid-auto-flow:row;grid-gap:", "px;"], function (props) {
  return props.theme.spacer;
});
export var StyledBox = styled(Box).withConfig({
  displayName: "DashboardRowstyles__StyledBox",
  componentId: "sc-1333pbn-1"
})(["height:min-content;align-content:flex-start;div[role='button']{max-width:fit-content;}"]);
export var HeadingLabelContainer = styled.div.withConfig({
  displayName: "DashboardRowstyles__HeadingLabelContainer",
  componentId: "sc-1333pbn-2"
})(["display:flex;gap:", "px;"], function (props) {
  return props.theme.spacer;
});
export var HeadingCustom = styled(Heading).withConfig({
  displayName: "DashboardRowstyles__HeadingCustom",
  componentId: "sc-1333pbn-3"
})(["display:grid;grid-gap:", "px;grid-auto-flow:column;justify-content:flex-start;align-items:center;"], function (props) {
  return props.theme.spacer;
});