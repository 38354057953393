import React from 'react';
import { filter, isArray, find } from 'lodash';
import { DashboardItemLayout, DashboardItemLayouts } from './types';
import {
  MasonryDashboard,
  FixedLayoutDashboard,
  FixedLayoutGridItem,
} from './Dashboard.styles';

interface Props {
  widthReductionInPx?: number;
  children?: any;
  isFixedLayout?: boolean;
  layouts?: DashboardItemLayouts;
  hasLargeGap?: boolean;
  isMaxTwoCol?: boolean;
}

/**
 * Dashboard layout which takes a layout config object,
 * describing how it respond to different device sizes
 */
const Dashboard = (props: Props) => {
  const masonryBreakpoints = props.widthReductionInPx
    ? {
        default: 3,
        [1600 + props.widthReductionInPx]: 3,
        [1350 + props.widthReductionInPx]: 2,
        [850 + props.widthReductionInPx]: 1,
      }
    : {
        default: 3,
        1350: 2,
        850: 1,
      };

  const fixedLayoutBreakpoints = props.widthReductionInPx
    ? {
        threeCol: 1350 + props.widthReductionInPx,
        twoCol: 850 + props.widthReductionInPx,
      }
    : {
        threeCol: 1350,
        twoCol: 850,
      };

  const removeEmptyChildren = children => filter(children);

  const fixedLayoutChildren = isArray(props.children)
    ? filter(removeEmptyChildren(props.children), child => !!child.key)
    : [props.children];

  const fixedLayoutDefaultItemLayout = {
    key: 'default-item-layout',
    colSpans: {
      threeCol: 1,
      twoCol: 1,
    },
    rowSpans: {
      threeCol: 1,
      twoCol: 1,
    },
  };

  return props.isFixedLayout ? (
    <FixedLayoutDashboard
      breakpoints={fixedLayoutBreakpoints}
      hasLargeGap={props.hasLargeGap}
      isMaxTwoCol={props.isMaxTwoCol}
    >
      {fixedLayoutChildren.map(gridItem => {
        const itemLayout: DashboardItemLayout =
          find(props.layouts, item => item.key === gridItem.key) ||
          fixedLayoutDefaultItemLayout;
        return (
          <FixedLayoutGridItem
            isMaxTwoCol={props.isMaxTwoCol}
            key={gridItem.key}
            breakpoints={fixedLayoutBreakpoints}
            colSpans={itemLayout.colSpans}
            rowSpans={itemLayout.rowSpans}
          >
            {gridItem}
          </FixedLayoutGridItem>
        );
      })}
    </FixedLayoutDashboard>
  ) : (
    <MasonryDashboard
      breakpointCols={masonryBreakpoints}
      className="masonry-grid"
      columnClassName="masonry-grid_column"
    >
      {isArray(props.children)
        ? removeEmptyChildren(props.children)
        : props.children}
    </MasonryDashboard>
  );
};

export default Dashboard;
