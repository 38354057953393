import styled from 'styled-components';
import { HeadingSmall } from '@ui/components/Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "MediaLibrarystyled__Wrapper",
  componentId: "sc-1svjyfq-0"
})(["display:flex;flex-direction:column;width:100%;height:100%;box-sizing:border-box;padding:", "px ", "px ", "px;", ""], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.isMobile && "padding: ".concat(props.theme.largeSpacer, "px;");
});
export var MenuWrapper = styled.div.withConfig({
  displayName: "MediaLibrarystyled__MenuWrapper",
  componentId: "sc-1svjyfq-1"
})(["width:100%;display:grid;grid-template-columns:", ";", " align-items:center;padding-bottom:", "px;"], function (props) {
  return props.isMobile ? "repeat(2, 1fr)" : "repeat(3,1fr)";
}, function (props) {
  return props.isMobile && "grid-gap: ".concat(props.theme.spacer, "px;");
}, function (props) {
  return props.theme.largeSpacer;
});
export var SelectionWrapper = styled.div.withConfig({
  displayName: "MediaLibrarystyled__SelectionWrapper",
  componentId: "sc-1svjyfq-2"
})(["display:flex;"]);
export var Main = styled.div.withConfig({
  displayName: "MediaLibrarystyled__Main",
  componentId: "sc-1svjyfq-3"
})(["display:grid;grid-template-columns:", ";grid-gap:", "px;height:100%;width:100%;", " ", ""], function (props) {
  return props.hasNoCurrentItem ? '288px 1fr 280px' : '288px 1fr';
}, function (props) {
  return props.theme.xxlargeSpacer;
}, function (props) {
  return props.hasMargin && "margin-bottom: ".concat(props.theme.xlargeSpacer, "px;");
}, function (props) {
  return props.isMobile && "grid-template-columns: 1fr;\n    grid-template-rows: auto 1fr;\n    grid-gap: ".concat(props.theme.largeSpacer, "px;\n    height: calc(100% - ").concat(props.theme.buttonHeight + props.theme.largeSpacer, "px);");
});
export var EmptyState = styled(HeadingSmall).withConfig({
  displayName: "MediaLibrarystyled__EmptyState",
  componentId: "sc-1svjyfq-4"
})(["color:", ";display:flex;justify-content:center;align-items:center;height:100%;"], function (props) {
  return props.theme.lgrey;
});
export var ChooseItemModeWrapper = styled.div.withConfig({
  displayName: "MediaLibrarystyled__ChooseItemModeWrapper",
  componentId: "sc-1svjyfq-5"
})(["position:absolute;bottom:0;right:", "px;margin-bottom:", "px;", ";"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.isMobile && "width: calc(50% - ".concat(props.theme.largeSpacer, "px);\n    margin-left: auto;");
});
export var MediaSectionWrapper = styled.div.withConfig({
  displayName: "MediaLibrarystyled__MediaSectionWrapper",
  componentId: "sc-1svjyfq-6"
})(["width:100%;height:100%;", ""], function (props) {
  return props.hasNoCurrentItem && "\n  display: grid;\n  grid-template-columns: 1fr 280px;\n  grid-gap: ".concat(props.theme.xxlargeSpacer, "px;\n  ");
});