import React from 'react';
import { LinkWrapper, LinkText, LinkTag, LinkLabel } from './NavbarLink.styled';

interface Props {
  name: string;
  description?: string;
  featureName?: string;
  onClick: (path?: string) => void;
  isHighlighted?: boolean;
  isBlocked?: boolean;
  isInverted?: boolean;
  isTertiary?: boolean;
  activeItem?: string;
  path?: string;
  collapseNav?: () => void;
}

const NavbarLink = (props: Props) => {
  const isActive = props.activeItem && props.activeItem === props.path;

  function clickHandler(e) {
    if (e.ctrlKey || e.shiftKey || e.metaKey || e.altKey) return;
    e.preventDefault();

    if (!props.isBlocked) {
      props.onClick(props.path);
      if (props.collapseNav) props.collapseNav();
    }
  }

  return (
    <LinkTag href={props.path}>
      <LinkWrapper
        data-testid={props.featureName || props.path}
        isBlocked={props.isBlocked}
        isActive={isActive}
        isHighlighted={props.isHighlighted}
        onClick={clickHandler}
        isInverted={props.isInverted}
        isTertiary={props.isTertiary}
        hasDescription={!!props.description}
      >
        <LinkText isInverted={props.isInverted} isTertiary={props.isTertiary}>
          {props.name}
          {props.description && <LinkLabel>{props.description}</LinkLabel>}
        </LinkText>
      </LinkWrapper>
    </LinkTag>
  );
};

export default NavbarLink;
