import React from 'react';
import { map, filter } from 'lodash';
import { featureCategories, features } from 'config/features';
import { useNavigate } from '@hooks/useRouting';
import useDevice from '@hooks/useDevice';
import useModal from '@hooks/useModal';
import useUser from '@hooks/useUser';
import useActiveArtist from '@hooks/useActiveArtist';
import openExternalWebpage from '@helpers/openExternalWebpage';
import { Feature } from '@ui/types/feature';
import TitleBlockDialogue from 'components/common/TitleBlockDialogue';
import Navbar from 'components/app/Navbar';
import NavbarBox from 'components/app/NavbarBox';
import NavbarLink from 'components/app/NavbarLink';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  setActiveItem: (arg0: any) => void;
  collapseNavs: () => void;
  isExpanded: boolean | undefined;
  activeItem: string | undefined;
}

const TopNav = (props: Props) => {
  const { onboarded, confirmed, authorizer, subscription, hasFeature } =
    useUser();
  const { hasWebsiteBuilderAccess } = useActiveArtist();
  const { discographyVisibilityOps } = useFlags() as Record<string, boolean>;
  const { isWebView, isMobile } = useDevice();
  const navigate = useNavigate();
  const openModal = useModal();

  const copyFeatureCategories = { ...featureCategories };
  const copyFeatures = { ...features };

  if (isWebView) {
    delete copyFeatures.blog;
  }

  const handleItemClick = (feature: Feature) => {
    if (feature.external) {
      openExternalWebpage(feature.external);
    } else {
      navigate(feature.path);
      props.setActiveItem(feature.path);
      props.collapseNavs();
    }
  };

  const openOnboardingMessage = () =>
    openModal({
      Component: TitleBlockDialogue,
      modalSize: 'alert',
      modalTitle: 'Hold up!',
      onClose: () => navigate('/welcome'),
      componentProps: {
        title: "Let's get set up first",
        subtitle: 'We need a bit more info to provide these powerful tools 🚀',
        closeButtonText: 'Okay 👍',
      },
    });

  const navMenuItems = map(copyFeatureCategories, category => ({
    label: category.categoryLabel,
    links: map(
      filter(
        copyFeatures,
        feature =>
          feature.category === category.categoryName &&
          (feature.requiredFeatureId
            ? hasFeature(feature.requiredFeatureId)
            : true) &&
          (feature.name === features.websiteBuilder.name
            ? !!hasWebsiteBuilderAccess
            : true) &&
          (feature.name === 'discography' ? discographyVisibilityOps : true)
      ),
      feature => ({
        name: feature.longLabel,
        featureName: feature.name,
        description: feature.description,
        path: feature.path,
        onClick: () => {
          if (!confirmed && authorizer === 'cognito') {
            return;
          }
          if (onboarded || subscription.product_name === 'Basic') {
            handleItemClick(feature);
          } else openOnboardingMessage();
        },
      })
    ),
  }));

  return (
    <Navbar
      isExpanded={props.isExpanded}
      slideDirection={!isMobile ? 'down' : 'right'}
      hasBeatchainLogo
    >
      {navMenuItems.map(item => (
        <NavbarBox
          key={item.label}
          linkComponent={NavbarLink}
          activeItem={props.activeItem}
          {...item}
        />
      ))}
    </Navbar>
  );
};

export default React.memo(TopNav);
