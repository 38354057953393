import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg viewBox="0 0 160 50" fill="none" {...props}>
    <path
      fill={isColour ? '#254AA5' : 'currentColor'}
      d="M1 16V0h21.483a2.08 2.08 0 011.888 1.204l6.673 13.966c.114.258.177.53.177.83H1zM46.038 35.527c-3.497 0-6.342-2.906-6.342-6.479 0-3.571 2.845-6.477 6.342-6.477 3.388 0 6.25 2.966 6.25 6.477 0 3.512-2.862 6.479-6.25 6.479zm6.068-16.485c-1.58-1.437-3.873-2.26-6.432-2.26-7.365 0-12.312 6.343-12.312 12.266 0 6.03 4.52 12.267 12.084 12.267 2.536 0 4.957-.878 6.66-2.359v1.659h6.062V9.365h-6.062v9.678zM117.786 34.889l2.715 1.55-2.319 5.08-.561-.27c-4.544-2.164-7.522-3.974-7.522-9.198v-9.838h-2.685V16.66h2.685V9.362h6.061v7.298h3.776v5.553h-3.776v8.973c0 2.332.548 3.077 1.626 3.703zM159.852 36.44l-2.318 5.079-.561-.27c-4.544-2.164-7.523-3.974-7.523-9.198V9.365h6.062v21.821c0 2.376.479 3.037 1.625 3.703l2.715 1.55zM134.212 35.527c-3.497 0-6.342-2.907-6.342-6.479 0-3.571 2.845-6.478 6.342-6.478 3.388 0 6.251 2.967 6.251 6.478 0 3.512-2.863 6.479-6.251 6.479zm6.069-16.485c-1.581-1.437-3.874-2.26-6.433-2.26-7.365 0-12.313 6.343-12.313 12.266 0 6.03 4.521 12.267 12.085 12.267 2.536 0 4.957-.878 6.661-2.359v1.658h6.061V16.66h-6.061v2.382zM65.09 7.405a3.701 3.701 0 100 7.404 3.702 3.702 0 100-7.404zm-3.031 9.255h6.062v23.955h-6.062V16.66zM101.368 7.405a3.702 3.702 0 100 7.404 3.702 3.702 0 000-7.404zm-3.031 9.255h6.062v23.955h-6.062V16.66zM83.36 34.935c-3.465 0-5.979-2.61-5.979-6.205 0-3.396 2.682-6.16 5.978-6.16 3.246 0 5.887 2.784 5.887 6.206 0 3.511-2.53 6.16-5.887 6.16zm5.891-15.913h-.005c-1.63-1.47-3.777-2.24-6.296-2.24-5.916 0-11.903 4.135-11.903 12.04 0 3.487 1.18 6.496 3.41 8.701 2.088 2.064 4.91 3.201 7.946 3.201 2.726 0 4.891-.775 6.841-2.48h.007v.442c-.005.561-.774 3.126-3.017 4.55-2.241 1.424-5.293 1.268-9.072-.462l-2.409 5.28S78.635 50 82.73 50c2.904 0 5.108-.892 6.668-1.892 4.124-2.649 5.662-7.185 5.662-9.421V16.66H89.25v2.362z"
    />
    <path
      fill={isColour ? 'url(#7digital__paint0_linear)' : 'currentColor'}
      d="M16.212 49.327L8.605 32.663 16.212 16H31.22c0 .302-.063.588-.18.848l-14.828 32.48z"
    />
    <defs>
      <linearGradient
        id="7digital__paint0_linear"
        x1={9.83}
        y1={47.024}
        x2={25.275}
        y2={13.901}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61B5AE" />
        <stop offset={0.009} stopColor="#61B5AE" />
        <stop offset={1} stopColor="#485DB4" />
      </linearGradient>
    </defs>
  </svg>
);
