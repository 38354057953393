import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill={isColour ? '#1DA1F2' : 'currentColor'}
    {...props}
  >
    <path d="M7.54752 21.7526C16.6042 21.7526 21.5578 14.2492 21.5578 7.74235C21.5578 7.52923 21.5578 7.31707 21.5434 7.10587C22.507 6.40883 23.3389 5.54576 24 4.55707C23.1013 4.95528 22.148 5.21643 21.1718 5.33179C22.1998 4.71641 22.9691 3.74853 23.3366 2.60827C22.3701 3.18183 21.3126 3.58604 20.2099 3.80347C19.4675 3.01405 18.4856 2.49131 17.4162 2.31616C16.3468 2.141 15.2494 2.32319 14.294 2.83452C13.3385 3.34586 12.5782 4.15784 12.1307 5.14482C11.6833 6.13179 11.5735 7.23874 11.8186 8.29435C9.86088 8.19621 7.94572 7.68747 6.19741 6.80116C4.4491 5.91485 2.90672 4.67078 1.6704 3.14971C1.04073 4.23371 0.847872 5.51694 1.1311 6.73813C1.41433 7.95933 2.15234 9.02666 3.19488 9.72283C2.41127 9.69961 1.64475 9.48822 0.96 9.10651C0.96 9.12667 0.96 9.14779 0.96 9.16891C0.960311 10.3058 1.35385 11.4075 2.07387 12.2873C2.79389 13.167 3.79606 13.7707 4.9104 13.9958C4.18548 14.1935 3.42488 14.2224 2.68704 14.0803C3.00169 15.0587 3.61427 15.9143 4.43911 16.5274C5.26395 17.1405 6.25979 17.4805 7.28736 17.4998C5.54375 18.8701 3.38982 19.614 1.17216 19.6118C0.780387 19.611 0.388996 19.5873 0 19.5407C2.25181 20.9858 4.87192 21.7523 7.54752 21.7487" />
  </svg>
);
