import styled from 'styled-components';
import { readableColor } from 'polished';
export var NewsFeedToggleMain = styled.div.withConfig({
  displayName: "NewsFeedTogglestyled__NewsFeedToggleMain",
  componentId: "sc-1lpgkqc-0"
})(["border-radius:50%;background:", ";color:", ";font-size:14px;display:flex;justify-content:center;align-items:center;width:", "px;height:", "px;cursor:pointer;z-index:1;flex-shrink:0;"], function (props) {
  return props.theme.info;
}, function (props) {
  return readableColor(props.theme.info);
}, function (props) {
  return props.isSmall ? 24 : props.theme.xlargeSpacer;
}, function (props) {
  return props.isSmall ? 24 : props.theme.xlargeSpacer;
});