import React from 'react';
import { ScreenFadeMain } from './ScreenFade.styles';

interface Props {
  isActive?: boolean;
  isExtraZIndex?: boolean;
  onToggle: () => void;
}

const ScreenFade = (props: Props) => (
  <ScreenFadeMain
    isExtraZIndex={props.isExtraZIndex}
    isActive={props.isActive}
    onClick={props.onToggle}
  />
);

export default React.memo(ScreenFade);
