import React, { useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { toast } from 'react-toastify';

import ajax from '@helpers/ajax';
import { useDispatch, useSelector } from '@hooks/useStore';
import useModal from '@hooks/useModal';
import useWebOtpApi from '@hooks/useWebOtpApi';
import { updateUserState } from 'store/user';
import { ModalInjectedProps } from '@ui/types';
import { BodyText } from '@ui/components/Typography';
import OtpInput from 'components/account/OtpInput';
import {
  ModalContainer,
  InputContainer,
  StyledButton,
  SkipButton,
} from '../PhoneNumber.styled';
import NewPhone from '../NewPhone/NewPhone';

interface Props {
  setSmsMfa?: () => void;
}

const VerifyPhone = (props: Props & ModalInjectedProps) => {
  const [code, setCode] = useState('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [canResend, setCanResend] = useState(true);

  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    let timer;
    if (!canResend) {
      timer = setTimeout(() => {
        setCanResend(true);
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [canResend, setCanResend]);

  const inModal = !!props.closeClickHandler;
  const openModal = useModal();

  const updatePhoneNumber = () =>
    openModal({
      Component: NewPhone,
      modalSize: 'dynamic',
      modalTitle: 'Update Phone Number',
      componentProps: {
        setSmsMfa: props.setSmsMfa,
      },
      showOverflow: true,
    });

  const verifyPhoneNumber = async otpCode => {
    setVerifyLoading(true);

    try {
      const result = await Auth.verifyCurrentUserAttributeSubmit(
        'phone_number',
        otpCode
      );
      if (result !== 'SUCCESS') {
        throw new Error();
      }

      toast.success('Phone number verified');

      if (props.setSmsMfa) {
        await props.setSmsMfa();
      }

      const data = await ajax('user/signin-settings', {}, 'PUT');
      dispatch(updateUserState(data));

      setVerifyLoading(false);
      if (inModal) {
        props.closeClickHandler();
      }
    } catch (e: any) {
      toast.error(e?.message || 'Failed to verify phone number');
      setVerifyLoading(false);
    }
  };

  useWebOtpApi(otp => {
    setCode(otp.code);
    verifyPhoneNumber(otp.code);
  });

  const resendCode = async () => {
    setResendLoading(true);

    try {
      await Auth.verifyCurrentUserAttribute('phone_number');
      setCanResend(false);
      toast.success('Verification code sent');
    } catch (e: any) {
      toast.error(e?.message || 'Failed to send verification code');
    }

    setResendLoading(false);
  };

  return (
    <ModalContainer padding={inModal}>
      <BodyText>
        Enter the 6 digit code we sent to {user.phoneNumber} to verify your
        phone number.
      </BodyText>
      <InputContainer>
        <OtpInput
          placeholder="verification code"
          onChange={setCode}
          value={code}
          disabled={verifyLoading || resendLoading}
        />
        <StyledButton
          text="verify"
          onPress={() => verifyPhoneNumber(code)}
          loading={verifyLoading}
        />
      </InputContainer>
      <br /> <br />
      <BodyText>
        Having trouble? You can request another code or change the phone number
      </BodyText>
      <InputContainer>
        <StyledButton
          text="resend code"
          small
          onPress={resendCode}
          loading={resendLoading}
          disabled={!canResend}
        />
        <StyledButton text="update number" onPress={updatePhoneNumber} small />
        {inModal && (
          <SkipButton
            text="skip"
            onPress={props.closeClickHandler}
            small
            secondary
          />
        )}
      </InputContainer>
    </ModalContainer>
  );
};

export default VerifyPhone;
