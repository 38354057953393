import styled from 'styled-components';
import { BodyText, DescriptionText } from '../Typography';
export var ExternalTextLinkMain = styled.a.withConfig({
  displayName: "ExternalTextLinkstyles__ExternalTextLinkMain",
  componentId: "sc-rdunqa-0"
})([""]);
export var ExternalTextLinkBodyText = styled(BodyText).withConfig({
  displayName: "ExternalTextLinkstyles__ExternalTextLinkBodyText",
  componentId: "sc-rdunqa-1"
})(["text-decoration:underline;color:", ";cursor:pointer;"], function (props) {
  return props.colour ? props.colour : props.theme.linkColour;
});
export var ExternalTextLinkDescriptionText = styled(DescriptionText).withConfig({
  displayName: "ExternalTextLinkstyles__ExternalTextLinkDescriptionText",
  componentId: "sc-rdunqa-2"
})(["text-decoration:underline;color:", ";cursor:pointer;"], function (props) {
  return props.colour ? props.colour : props.theme.linkColour;
});