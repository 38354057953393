/* eslint-disable no-bitwise */
/**
 * Generates a new guid
 */
const generate = () => {
  let d = new Date().getTime();

  const GUID_TEMPLATE = 'xxxxxxxx';

  return GUID_TEMPLATE.replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export default generate;
