import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import LargeCheckbox from '@ui/components/LargeCheckbox';
import Button from '@ui/components/Button';
import TextArea from '@ui/components/TextArea';
import { Heading, BodyText } from '@ui/components/Typography';
import { EquallySpacedRow } from 'components/common/EquallySpacedRow';

import { Wrapper } from './LinkTile.styled';

export interface Props {
  name: string;
  title: string;
  subtitle: string;
  buttonText?: string;
  isChecked?: boolean;
  setIsChecked?: (name: string, isChecked: boolean) => void;
  buttonHandleClick?: () => void;
  feedback?: { message: string };
  setFeedback?: (feedback: { name: any; message: string }) => void;
}

/**
 * Large checkbox UI with links
 */
const LinkTile = (props: Props) => (
  <Wrapper>
    <EquallySpacedRow>
      <Heading>{props.title}</Heading>
      {props.setIsChecked && (
        <LargeCheckbox
          isChecked={props.isChecked}
          handleChange={props.setIsChecked}
          name={props.name}
        />
      )}
    </EquallySpacedRow>
    <BodyText>{ReactHtmlParser(props.subtitle)}</BodyText>
    {props.buttonText && (
      <Button text={props.buttonText} onPress={props.buttonHandleClick} />
    )}
    {props.isChecked && props.feedback && (
      <TextArea
        value={props.feedback.message}
        onChange={value =>
          props.setFeedback?.({
            name: props.name,
            message: value,
          })
        }
        placeholder="Enter feedback"
        dark
        isInBox
      />
    )}
  </Wrapper>
);

export default LinkTile;
