import React from 'react';
import ReactTooltip from 'react-tooltip';
import { get } from 'lodash';
import config from 'config/tooltipText.config.json';
import Icon from '../Icon';

import { Wrapper, DataTip, PopupText } from './Tooltip.styled';

type Place = 'top' | 'right' | 'bottom' | 'left';
type Type = 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
type Effect = 'float' | 'solid';

export interface TooltipProps {
  id: string;
  text?: string;
  type?: Type;
  place?: Place;
  effect?: Effect;
  iconProps?: { name?: string; color?: string };
  inReactWebsitePreview?: boolean;
  children?: React.ReactNode;
}

/**
 * Styling and configuration of third-party tool tip module 'react-tooltip'
 */
const Tooltip = ({
  id,
  text,
  type,
  place,
  effect,
  inReactWebsitePreview,
  children,
  iconProps = {},
  ...props
}: TooltipProps) => (
  <Wrapper>
    <DataTip data-tip data-for={`${id}-${place}`}>
      {children || (
        <Icon
          name={iconProps.name || 'info-circle'}
          color={iconProps.color || 'grey'}
          {...iconProps}
        />
      )}
    </DataTip>
    <ReactTooltip
      aria-haspopup="true"
      multiline
      id={`${id}-${place}`}
      place={place}
      type={type}
      effect={effect}
      disable={!(text || get(config, id)) || inReactWebsitePreview}
      {...props}
    >
      <PopupText
        numberOfLines={(text || get(config, id))?.length < 20 ? 1 : undefined}
      >
        {text || get(config, id)}
      </PopupText>
    </ReactTooltip>
  </Wrapper>
);

export default Tooltip;
