import React, { useEffect } from 'react';
import { useSelector, useDispatch } from '@hooks/useStore';
import { resetStore, logout } from 'store/auth';
import Button from '@ui/components/Button';
import StaticBackground from 'components/common/StaticBackground';
import { EquallySpacedColumn } from 'components/common/EquallySpacedColumn';
import { EquallySpacedRow } from 'components/common/EquallySpacedRow';
import WithPullToRefresh from 'components/app/WithPullToRefresh';
import AppUtilities from 'AppUtilities';
import {
  BodyText,
  HeadingTitle,
  HeadingLabel,
} from '@ui/components/Typography';
import { ErrorPageMain, InnerContainer } from './AppErrorPage.styled';

const AppErrorPage = () => {
  const error = useSelector(state => state.error);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window?.location.hostname === 'localhost') return;
    console.warn(JSON.stringify(error));
    window?.Raven?.captureException(JSON.stringify(error, null, 4));
  }, []);

  return (
    <AppUtilities>
      <WithPullToRefresh>
        <ErrorPageMain>
          <StaticBackground
            src="https://static.beatchain.com/images/beatchain-artists-login-background.jpg"
            brightness="30%"
          />

          <InnerContainer>
            <EquallySpacedColumn isSmallGap>
              <HeadingTitle>Oops, an error has occurred.</HeadingTitle>
              <EquallySpacedRow isSmallGap isFitMaxContent alignItems="center">
                <HeadingLabel>Contact</HeadingLabel>
                <BodyText>
                  <a
                    href="mailto:support@beatchain.com?Subject=App Error"
                    target="_top"
                  >
                    support@beatchain.com
                  </a>
                </BodyText>
              </EquallySpacedRow>
            </EquallySpacedColumn>

            <EquallySpacedRow isFitMaxContent alignItems="center">
              <Button
                text="Refresh"
                onPress={() => {
                  dispatch(resetStore());
                  window.location.reload();
                }}
              />
              <BodyText>-or-</BodyText>
              <Button
                text="Logout"
                squishy
                grey
                darkShadow={false}
                onPress={() => dispatch(logout())}
              />
            </EquallySpacedRow>
          </InnerContainer>
        </ErrorPageMain>
      </WithPullToRefresh>
    </AppUtilities>
  );
};

export default AppErrorPage;
