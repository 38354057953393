import React from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import Button from '@ui/components/Button';
import ProgressBar from '@ui/components/ProgressBar';
import { Heading } from '@ui/components/Typography';
import { Wrapper, DropzoneOverlay } from './Dropzone.styled';

interface Props {
  text?: string;
  isDisabled?: boolean;
  accept?: string[];
  onDrop: (files: FileWithPath[]) => void;
  progress?: number;
  className?: string;
  multiple?: boolean;
  showButton?: boolean;
  children?: React.ReactNode;
}

/**
 * Styling and configuration of third-party drop zone module 'react-dropzone'
 */
const Dropzone: React.FC<Props> = props => {
  const isInProgress =
    !!props.progress && props.progress > 0 && props.progress < 100;

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length) {
        props.onDrop(acceptedFiles);
      }
    },
    noClick: false,
    noKeyboard: true,
    accept: props.accept || ['image/*', 'video/*', 'audio/*', '.pdf'],
    multiple: props.multiple,
  });

  return (
    <Wrapper
      {...getRootProps()}
      className={props.className}
      showButton={props.showButton}
    >
      {isInProgress ? (
        <ProgressBar percentage={props.progress} message="Loading..." />
      ) : (
        <>
          {props.showButton && (
            <Button
              text="Upload"
              icon="upload"
              iconFirst
              onPress={open}
              disabled={props.isDisabled}
              dataTestId="upload-button"
              squishy
            />
          )}
          <input
            {...getInputProps({
              disabled:
                props.isDisabled ||
                isInProgress ||
                (!isDragActive && !props.showButton),
            })}
            data-testid="dropzone-input"
          />
          {props.children}
          {isDragActive && !props.showButton && (
            <DropzoneOverlay>
              <Heading>Drop your files here...</Heading>
            </DropzoneOverlay>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default React.memo<Props>(Dropzone);
