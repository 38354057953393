import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#fff' : 'transparent'}
      d="M255.564 111.871c35.451 0 67.15 9.688 97.004 26.645 18.661-15.023 42.906-26.166 67.15-32.465l22.382-6.299v189.44c0 103.201-83.941 186.044-186.543 186.044-102.601 0-186.536-82.371-186.536-185.559V99.752l22.382 5.814c24.244 6.784 48.495 17.927 67.15 32.95 27.997-16.957 61.566-26.645 97.011-26.645z"
    />
    <path
      fill={isColour ? '#62BA46' : 'currentColor'}
      d="M307.793 289.678c27.991-3.388 76.493-2.903 76.493-2.903-1.863 8.717 0 33.422-27.978 48.93-3.733 2.418-14.927 6.292-26.115 6.784-26.114.963-63.423 1.455-63.423 1.455 7.448-29.561 16.772-51.84 41.023-54.266zM203.326 289.678c22.394 2.426 33.588 24.705 41.042 54.259 0 0-37.309-.485-63.423-1.455-11.194-.485-22.382-4.365-26.121-6.784-27.979-15.508-26.109-40.212-27.979-48.93 0 0 48.516-.478 76.481 2.91z"
    />
    <path
      fill={isColour ? '#005CAB' : 'currentColor'}
      d="M307.792 366.223c-14.914 14.053-33.575 24.717-52.229 31.501-20.518-6.784-37.308-17.442-52.236-31.501h104.465z"
    />
    <path
      fill={isColour ? '#005CAB' : 'currentColor'}
      d="M97.005 84.737C136.177 41.129 192.139 14 255.563 14c63.429 0 119.385 27.136 158.557 70.737 16.791-4.359 33.588-7.27 52.236-7.748v71.222c26.108 16.957 44.769 47.475 44.769 81.88 0 34.404-18.661 64.437-44.769 81.4l-1.87 1.928c-11.187 104.65-100.725 185.074-208.923 185.074-110.056 0-199.594-81.879-210.788-187.008C18.661 294.522 0 264.004 0 230.084c0-34.398 18.654-64.916 44.775-81.394V76.983c18.654.485 35.439 3.395 52.23 7.754zm61.559 27.136c29.848-15.023 61.554-23.747 96.999-23.747 35.451 0 67.15 8.724 97.004 23.747 5.597-4.366 13.051-8.246 20.512-12.113-29.842-28.585-72.748-46.026-117.516-46.026-44.769 0-87.675 17.441-117.529 46.517 5.603 2.891 14.927 7.256 20.53 11.622zm0 48.444c-20.524-17.441-44.775-30.518-72.76-37.308v166.669c0 93.023 76.494 167.639 169.759 167.639 93.271 0 167.894-74.61 167.894-168.124V123.009c-26.115 7.275-50.378 19.867-70.883 37.308-27.985-19.381-61.56-30.518-97.005-30.518-35.451 0-69.02 11.137-97.005 30.518z"
    />
    <path
      fill={isColour ? '#005CAB' : 'currentColor'}
      d="M304.07 422.913c-13.057 8.724-29.854 13.568-48.508 13.568-18.648 0-35.445-4.844-50.366-13.568 0 0 1.863-5.329 3.727-9.694 3.733-5.329 7.46-6.778 7.46-6.778 11.194 3.383 26.108 5.323 39.172 5.323 13.051 0 26.115-1.94 39.172-5.323 0 0 3.734 1.934 7.467 7.748 1.876 4.857 1.876 8.724 1.876 8.724z"
    />
  </svg>
);
