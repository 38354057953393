import styled from 'styled-components';
export var Main = styled.div.withConfig({
  displayName: "Avatarstyled__Main",
  componentId: "sc-vvhn8d-0"
})(["", " overflow:hidden;box-sizing:border-box;", " flex-shrink:0;", ";"], function (props) {
  var size = props.noScale ? props.size : props.size * props.theme.scale;
  return "\n      width: ".concat(size, "px;\n      height: ").concat(size, "px;\n      background: ").concat(props.theme.grey, ";\n      border-radius: ").concat(props.size / 2, "px;\n    ");
}, function (props) {
  return props.outlined && "border: ".concat(props.theme.xSmallSpacer, "px solid ").concat(props.color, ";");
}, function (props) {
  return props.isClickable && 'cursor: pointer';
});
export var Image = styled.img.withConfig({
  displayName: "Avatarstyled__Image",
  componentId: "sc-vvhn8d-1"
})(["width:100%;height:100%;object-fit:cover;"]);
export var ItemList = styled.div.withConfig({
  displayName: "Avatarstyled__ItemList",
  componentId: "sc-vvhn8d-2"
})(["margin-right:", "px;display:flex;"], function (props) {
  return props.theme.largeSpacer;
});
export var Item = styled.div.withConfig({
  displayName: "Avatarstyled__Item",
  componentId: "sc-vvhn8d-3"
})(["margin-right:-", "px;"], function (props) {
  return props.theme.largeSpacer;
});