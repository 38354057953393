import React from 'react';
import { Formik } from 'formik';

import { login } from 'store/auth';
import { useSelector, useDispatch } from '@hooks/useStore';
import { useSearchParams } from '@hooks/useRouting';
import validateForm from '@helpers/validateForm';
import Link from '@ui/components/Link';
import { BodyText } from '@ui/components/Typography';
import Field from '@ui/components/Field';
import ButtonWrapper from '@ui/components/ButtonWrapper';
import Button from '@ui/components/Button';
import FormErrorMessage from 'components/common/FormErrorMessage';
import { FormWrapper } from 'components/common/FormWrapper';

const LogInForm = () => {
  const [search] = useSearchParams();
  const loginState = useSelector(({ auth }) => auth.LOGIN);
  const isSubmitting = loginState?.fetching;
  const apiError = loginState?.error?.message;
  const dispatch = useDispatch();

  const validationSchema = {
    email: {
      type: String,
      required: true,
      message: 'Email is required',
    },
    password: {
      type: String,
      match: /(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])/,
      required: true,
      length: {
        min: 8,
      },
      message: {
        match: 'Must contain a capital, lowercase, and number',
        required: 'Password is required',
      },
    },
  };

  return (
    <>
      <Formik
        initialValues={{
          email: search.get('email') || '',
          password: '',
        }}
        validate={values => validateForm(validationSchema, values)}
        onSubmit={values => {
          dispatch(login(values));
        }}
      >
        <FormWrapper method="post">
          <Field
            dataTestId="email"
            label="email"
            autoComplete="username"
            name="email"
            type="email"
            disabled={isSubmitting}
            large
          />
          <Field
            dataTestId="password"
            label="password"
            autoComplete="current-password"
            name="password"
            type="password"
            disabled={isSubmitting}
            large
          />
          {apiError && (
            <FormErrorMessage isCentered>{apiError}</FormErrorMessage>
          )}
          <ButtonWrapper>
            <Button
              text="Sign in"
              icon="right"
              data-testid="signIn-email-auth-button"
              type="submit"
              loading={isSubmitting}
            />
          </ButtonWrapper>
          <BodyText>
            <Link to="/forgot" data-testid="forgot-password-link">
              Forgot your password?
            </Link>
          </BodyText>
        </FormWrapper>
      </Formik>
    </>
  );
};

export default LogInForm;
