import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreditsAction, StripeProduct } from '@ui/types/credits';
import { asyncActionState } from 'store/asyncActionState';
import {
  CreditsStateType,
  FetchActionsSuccessAction,
  FetchProductsSuccessAction,
} from './types';

const initialState: CreditsStateType = {
  actions: [],
  products: [],
  FETCH_ACTIONS: { ...asyncActionState },
  FETCH_PRODUCTS: { ...asyncActionState },
};

export const getActions = (state): CreditsAction[] => state.credits.actions;
export const getProducts = (state): StripeProduct[] => state.credits.products;

const creditsSlice = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    fetchActions: state => ({
      ...state,
      FETCH_ACTIONS: {
        fetching: true,
        error: null,
      },
    }),
    fetchActionsSuccess: (state, { payload }: FetchActionsSuccessAction) => ({
      ...state,
      actions: payload.actions,
      FETCH_ACTIONS: {
        fetching: false,
        error: null,
      },
    }),
    fetchActionsError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      FETCH_ACTIONS: {
        fetching: false,
        error: payload,
      },
    }),
    fetchProducts: state => ({
      ...state,
      FETCH_PRODUCTS: {
        fetching: true,
        error: null,
      },
    }),
    fetchProductsSuccess: (state, { payload }: FetchProductsSuccessAction) => ({
      ...state,
      products: payload.products,
      FETCH_PRODUCTS: {
        fetching: false,
        error: null,
      },
    }),
    fetchProductsError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      FETCH_PRODUCTS: {
        fetching: false,
        error: payload,
      },
    }),
  },
});

export const { actions, reducer } = creditsSlice;
