import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import {
  logoutSuccess,
  loginSuccess,
  signupSuccess,
  resetStore,
} from '../auth/reducer'; // undefined if imported from '../auth'

interface ErrorState {
  errorCode?: number;
  errorMessage?: string;
  errorData?: {
    response?: string;
    request: any;
  };
}

const initialState: ErrorState = {};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showErrorPage: (state, action: PayloadAction<ErrorState>) => ({
      ...state,
      ...action.payload,
      errorData: action.payload.errorData,
    }),
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(logoutSuccess, loginSuccess, signupSuccess, resetStore),
        () => initialState
      )
      .addDefaultCase(state => state);
  },
});

export const { actions, reducer } = errorSlice;

export const { showErrorPage } = actions;
