import React from 'react';

import { IconProps } from './index';

const IconSoundtrack = ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill={isColour ? '#F23440' : 'currentColor'}
      fillRule="evenodd"
      d="M5.75 24a5.75 5.75 0 1 0 0-11.5 5.75 5.75 0 0 0 0 11.5Zm18.24-11.5c-.256 6.23-5.26 11.234-11.49 11.49V12.5h11.49Zm-5.74-1a5.75 5.75 0 1 0 0-11.5 5.75 5.75 0 0 0 0 11.5Zm-18.24 0C.266 5.27 5.27.266 11.5.01V11.5H.01Z"
      clipRule="evenodd"
    />
  </svg>
);

export default IconSoundtrack;
