import { useDispatch } from '@hooks/useStore';
import { logout } from 'store/auth';
import useModal from '@hooks/useModal';
import Dialogue from '@ui/components/Dialogue';

/**
 * Logs out user
 */
const useLogOut = () => {
  const dispatch = useDispatch();
  const openModal = useModal();
  return () => {
    openModal({
      Component: Dialogue,
      modalSize: 'dialogue',
      modalTitle: 'Log Out?',
      modalText: 'Are you sure you want to log out?',
      componentProps: {
        actionButton: {
          text: 'Log out',
          clickHandler: () => {
            dispatch(logout());
          },
        },
      },
    });
  };
};

export default useLogOut;
