import React from 'react';
import { PLATFORM } from '@ui/consts';

import { IconProps } from './index';

const IconSpotify = ({ isColour, platform, ...props }: IconProps) => {
  const color =
    platform === PLATFORM.SPOTIFY_FOR_ARTISTS ? '#4100f5' : '#1ED760';
  return (
    <svg width="100%" height="100%" viewBox="0 0 512 512" {...props}>
      <path
        fill={isColour ? color : 'currentColor'}
        d="M255.998 0C114.617 0 0 114.617 0 256.002 0 397.392 114.617 512 255.998 512 397.395 512 512 397.392 512 256.002 512 114.626 397.395.012 255.995.012l.003-.012zm117.4 369.228c-4.586 7.52-14.429 9.904-21.949 5.288-60.106-36.715-135.772-45.03-224.882-24.67-8.587 1.957-17.146-3.424-19.103-12.014-1.965-8.59 3.393-17.15 12.002-19.106 97.517-22.289 181.164-12.687 248.643 28.552 7.52 4.616 9.905 14.429 5.289 21.95zm31.333-69.716c-5.777 9.401-18.066 12.366-27.451 6.588-68.812-42.306-173.705-54.556-255.097-29.849-10.555 3.189-21.704-2.76-24.908-13.298-3.179-10.556 2.773-21.683 13.31-24.893 92.971-28.21 208.552-14.545 287.574 34.015 9.385 5.778 12.35 18.067 6.572 27.44v-.003zm2.691-72.586c-82.508-49.007-218.634-53.513-297.408-29.604-12.65 3.836-26.027-3.305-29.86-15.955-3.834-12.656 3.3-26.024 15.96-29.87 90.427-27.452 240.753-22.148 335.745 34.245 11.402 6.753 15.132 21.448 8.376 32.811-6.725 11.378-21.46 15.129-32.801 8.373h-.012z"
      />
      <path
        fill={isColour && platform === PLATFORM.SPOTIFY ? '#000' : 'none'}
        d="M351.449 374.516c7.52 4.616 17.363 2.232 21.949-5.288 4.616-7.521 2.231-17.334-5.289-21.95-67.479-41.239-151.126-50.841-248.643-28.552-8.609 1.956-13.967 10.516-12.002 19.106 1.957 8.59 10.516 13.971 19.103 12.014 89.11-20.36 164.776-12.045 224.882 24.67zM377.28 306.1c9.385 5.778 21.674 2.813 27.451-6.588v.003c5.778-9.373 2.813-21.662-6.572-27.44-79.022-48.56-194.603-62.225-287.574-34.015-10.537 3.21-16.489 14.337-13.31 24.893 3.204 10.538 14.353 16.487 24.908 13.298 81.392-24.707 186.285-12.457 255.097 29.849zM110.014 197.322c78.774-23.909 214.9-19.403 297.408 29.604h.012c11.341 6.756 26.076 3.005 32.801-8.373 6.756-11.363 3.026-26.058-8.376-32.811-94.992-56.393-245.318-61.697-335.745-34.245-12.66 3.846-19.794 17.214-15.96 29.87 3.833 12.65 17.21 19.792 29.86 15.955z"
      />
    </svg>
  );
};

export default IconSpotify;
