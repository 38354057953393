import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#050606' : 'currentColor'}
      d="M377.286 196.959c0 17.426.626 34.747-.209 52.173-.834 19.826 5.218 36.834 17.739 51.859 1.46 1.774 2.818 3.548 4.069 5.427 4.487 6.783 3.652 11.477-2.504 16.695-13.149 11.061-26.191 22.226-39.338 33.287-7.826 6.573-12.731 6.47-20.661 0-10.643-8.661-18.991-19.304-26.399-30.678-1.774-2.713-2.713-2.609-4.904-.522-13.149 12.834-27.026 24.73-44.66 30.678-27.235 9.287-54.886 10.748-82.225 1.043-26.295-9.391-42.051-28.8-47.895-55.825-6.469-30.156-3.13-58.851 15.443-84.416 14.087-19.304 33.599-30.677 56.033-37.668 23.165-7.096 47.06-9.809 71.059-12.209 6.783-.73 13.461-1.356 20.243-1.982 1.67-.105 3.131-.313 3.026-2.505-.208-11.06.939-22.33-1.669-33.182-3.339-13.877-12.418-22.017-26.295-25.043-9.913-2.191-19.721-1.565-29.321 1.67-14.608 5.008-23.687 15.13-27.339 30.155-1.982 8.244-5.843 11.166-13.878 10.33a89764.43 89764.43 0 01-51.233-5.321c-6.992-.731-10.643-6.053-9.183-12.836 8.869-43.303 37.252-67.616 78.155-78.78 31.409-8.557 63.234-9.079 94.746-.21 25.356 7.096 46.643 19.618 58.538 44.452 6.783 14.087 8.348 29.321 8.557 44.556.209 19.514 0 39.235.105 58.852-.105 0 0 0 0 0zm-81.182 32.556c0-5.217-.208-10.434.105-15.652.208-3.235-.94-4.383-4.174-4.07-7.931.73-15.965.835-23.895 1.878-13.878 1.774-27.444 5.218-38.713 13.982-16.383 12.835-19.826 30.365-17.113 50.086 3.235 23.164 23.895 35.999 46.017 28.278 15.025-5.218 24.207-16.277 30.573-30.156 6.678-14.085 7.2-29.216 7.2-44.346z"
    />
    <path
      fill={isColour ? '#F6A61F' : 'currentColor'}
      d="M257.496 465.023c-79.615-.522-151.093-20.973-215.683-64.069-13.148-8.765-25.67-18.365-37.564-28.904-1.043-.939-2.087-1.877-2.922-3.026-1.148-1.565-1.983-3.443-.626-5.322 1.357-2.087 3.653-2.296 5.635-1.356 4.59 2.191 8.974 4.591 13.46 6.886 41.113 21.704 84.416 37.356 129.807 47.269 41.112 8.974 82.641 13.149 124.797 12 60.208-1.669 118.224-13.774 174.049-36.209 2.714-1.147 5.531-2.295 8.348-2.922 3.966-.731 7.931-.209 9.705 4.07 1.669 3.965-.522 7.095-3.444 9.6-2.087 1.773-4.487 3.234-6.886 4.799-36 23.374-75.443 38.4-117.181 47.687-27.548 6.263-55.512 9.287-81.495 9.497z"
    />
    <path
      fill={isColour ? '#F6A61F' : 'currentColor'}
      d="M458.363 372.053c-12-.105-23.895 1.46-35.79 2.712-2.296.209-4.904.835-5.948-1.669-1.251-2.818 1.148-4.487 3.131-5.844 14.817-9.807 31.408-13.668 48.833-14.608 12-.729 23.895-.207 35.582 3.339 5.321 1.566 7.93 4.696 7.826 10.122-.626 29.008-10.331 54.26-31.303 74.817-.731.73-1.565 1.356-2.4 1.982-1.878 1.358-4.174 2.608-6.157.939-1.878-1.565-.834-3.861 0-5.739 6.157-15.338 12.418-30.572 15.443-46.955.418-2.087.522-4.174.626-6.157.104-6.783-2.295-9.704-8.973-11.373-6.992-1.88-13.983-1.566-20.87-1.566z"
    />
  </svg>
);
