import styled from 'styled-components';
/**
 * Wrapper for modal content providing padding
 */

export var ModalPadding = styled.div.withConfig({
  displayName: "ModalPaddingstyles__ModalPadding",
  componentId: "sc-15e3fwq-0"
})(["padding:", "px;width:100%;height:100%;box-sizing:border-box;"], function (props) {
  return props.theme.largeSpacer;
});