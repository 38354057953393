import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "MfaFormstyled__Wrapper",
  componentId: "sc-1xnu1ow-0"
})(["display:grid;grid-gap:", "px;color:", ";"], function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.white;
});
export var Header = styled.div.withConfig({
  displayName: "MfaFormstyled__Header",
  componentId: "sc-1xnu1ow-1"
})(["display:grid;grid-gap:", "px;"], function (props) {
  return props.theme.spacer;
});