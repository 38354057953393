import styled from 'styled-components';
import { SmallText } from '@ui/components/Typography';
export var PasswordFieldset = styled.div.withConfig({
  displayName: "SignUpFormstyled__PasswordFieldset",
  componentId: "sc-myef4j-0"
})(["position:relative;"]);
export var Strength = styled(SmallText).withConfig({
  displayName: "SignUpFormstyled__Strength",
  componentId: "sc-myef4j-1"
})(["color:", ";position:absolute;top:", "px;right:24px;"], function (props) {
  return props.color;
}, function (props) {
  return props.theme.xlargeSpacer;
});