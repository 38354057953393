import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import useUser from '@hooks/useUser';
import * as pages from 'pages';
import Confirmation from 'pages/confirmation';
import DistributionPreview from 'pages/releases';
import MusicVideos from 'pages/music-videos';
import Mastering from 'pages/mastering';
import PublishingRoyalties from 'pages/publishing-royalties';
import NewsFeed from 'pages/welcome';
import CancelSubscription from 'pages/cancel';
import Unsubscribe from 'pages/unsubscribe';
import ScrollToTop from 'components/app/ScrollToTop';
import Blog from 'pages/blog';
import Blockers from 'AuthenticatedApp/Blockers';
import ProSubscription from 'pages/pro-subscription';

const AuthenticatedRoutes = () => {
  const { hasFeature, defaultRedirect } = useUser();

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/distribution/*" element={<pages.Distribution />} />
        <Route path="/dev/*" element={<pages.DevSandbox />} />
        <Route path="/insights/tracks" element={<pages.TopTracks />} />
        <Route path="/insights/posts" element={<pages.Posts />} />
        <Route path="/insights/posts/:id" element={<pages.Post />} />
        <Route path="/insights/playlists" element={<pages.Playlists />} />
        <Route path="/insights/playlists/:id" element={<pages.Playlist />} />
        <Route
          path="/insights/potential-playlists"
          element={<pages.PotentialPlaylists />}
        />
        <Route
          path="/insights/related-artists"
          element={<pages.RelatedArtists />}
        />
        <Route path="/insights/map" element={<pages.Map />} />
        <Route path="/insights/artists/:id" element={<pages.Artist />} />
        <Route path="/insights/facebook" element={<pages.Facebook />} />
        <Route path="/insights/instagram" element={<pages.Instagram />} />
        <Route path="/insights/spotify" element={<pages.Spotify />} />
        <Route path="/insights/twitter" element={<pages.Twitter />} />
        <Route path="/insights" element={<pages.Insights />} />
        <Route path="/launchpad/:launchPadId/*" element={<pages.LaunchPad />} />
        <Route path="/launchpad" element={<pages.LaunchPad />} />
        <Route path="/oauth/:provider" element={<pages.Oauth />} />
        <Route path="/royalties/*" element={<pages.Royalties />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/tracks" element={<pages.DiscographyContainer />} />
        <Route path="/tracks/:id" element={<pages.TrackInsights />} />
        <Route path="/music-videos" element={<MusicVideos />} />
        <Route path="/mastering" element={<Mastering />} />
        <Route path="/publishing-royalties" element={<PublishingRoyalties />} />
        <Route path="/fan-builder/*" element={<pages.FanBuilder />} />
        <Route path="/hypelinks/*" element={<pages.HypeLinks />} />
        <Route path="/website-builder/*" element={<pages.WebsiteBuilder />} />
        <Route path="/partners/:partner" element={<pages.Partners />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route
          path="/releases/:userId/:releaseId"
          element={<DistributionPreview />}
        />
        <Route path="/invite-code" element={<pages.InviteCode />} />
        <Route path="/pro-subscription" element={<ProSubscription />} />
        <Route path="/welcome" element={<NewsFeed />} />
        <Route path="/onboarding/*" element={<pages.Onboarding />} />
        <Route path="/splits/*" element={<pages.ContributorSplits />} />
        <Route path="/social-profiles" element={<pages.SocialProfiles />} />
        <Route path="/artist-profile/*" element={<pages.ArtistProfile />} />
        <Route path="/user-profile/*" element={<pages.UserProfile />} />
        <Route path="/team-members/*" element={<pages.TeamMembers />} />
        <Route path="/cancel" element={<CancelSubscription />} />
        {hasFeature(22) && (
          <Route path="/smart-dashboards" element={<pages.SmartDashboards />} />
        )}
        <Route path="*" element={<Navigate to={defaultRedirect} replace />} />
      </Routes>
      <Blockers />
    </>
  );
};

export default React.memo(AuthenticatedRoutes);
