import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "MediaSectionstyled__Wrapper",
  componentId: "sc-qu9gde-0"
})(["margin-bottom:", "px;box-sizing:border-box;padding-right:", "px;"], function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.smallSpacer;
});
export var Header = styled.div.withConfig({
  displayName: "MediaSectionstyled__Header",
  componentId: "sc-qu9gde-1"
})(["display:flex;justify-content:space-between;"]);
export var Action = styled.div.withConfig({
  displayName: "MediaSectionstyled__Action",
  componentId: "sc-qu9gde-2"
})(["display:flex;> button{margin-left:", "px;}"], function (props) {
  return props.theme.spacer;
});
export var Items = styled.div.withConfig({
  displayName: "MediaSectionstyled__Items",
  componentId: "sc-qu9gde-3"
})(["padding:", "px 0;display:grid;grid-template-columns:repeat(auto-fill,minmax(144px,1fr));grid-gap:", "px;grid-auto-flow:dense;", " ", ""], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.isMobile && "grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));\n    grid-gap: ".concat(props.theme.largeSpacer, "px;\n    ");
}, function (props) {
  return props.isList && "grid-template-columns: 1fr;";
});