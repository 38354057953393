import React, { useEffect, useState } from 'react';
import { Label } from '@ui/components/Input/Input.styled';
import PhoneInputComponent from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ajax from '@helpers/ajax';
import { Wrapper } from './PhoneInput.styled';

interface Props {
  value?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const PhoneInput = (props: Props) => {
  const [country, setCountry] = useState('');
  useEffect(() => {
    const fetchLocation = async () => {
      const res = await ajax('public/location', {}, 'GET', false).catch(e =>
        console.error('Failed to grab location', e)
      );

      setCountry(res?.country?.toLowerCase?.() || 'us');
    };
    fetchLocation();
  }, []);

  return (
    <Wrapper>
      {props.label && <Label>{props.label}</Label>}
      <PhoneInputComponent
        value={props.value}
        disabled={props.disabled}
        onChange={(_, countryData, __, phone) => {
          props.onChange?.(
            phone.replace(`+${(countryData as any).dialCode}`, '') ? phone : ''
          );
        }}
        country={country}
        countryCodeEditable={false}
        autoFormat={false}
      />
    </Wrapper>
  );
};

export default PhoneInput;
