import filesize from 'filesize';

/**
 * Formats number to a byte unit
 */
export const formatNumberToContainBytes = (fileSize: number): string => {
  if (!fileSize) {
    return '-';
  }
  return filesize(fileSize, { standard: 'jedec' });
};
