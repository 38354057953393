import styled from 'styled-components';
export var Wrapper = styled.label.withConfig({
  displayName: "LargeCheckboxstyled__Wrapper",
  componentId: "sc-1drbkwc-0"
})(["display:flex;align-items:center;position:relative;cursor:pointer;user-select:none;color:", ";height:", "px;width:", "px;"], function (props) {
  return props.theme.white;
}, function (props) {
  return props.isSmaller ? 25 : props.theme.xlargeSpacer;
}, function (props) {
  return props.isSmaller ? 25 : props.theme.xlargeSpacer;
});
export var Input = styled.input.withConfig({
  displayName: "LargeCheckboxstyled__Input",
  componentId: "sc-1drbkwc-1"
})(["position:absolute;opacity:0;height:0;width:0;"]);
export var Check = styled.span.withConfig({
  displayName: "LargeCheckboxstyled__Check",
  componentId: "sc-1drbkwc-2"
})(["position:absolute;left:0;top:0;height:", "px;width:", "px;border:solid ", " ", "px;box-sizing:border-box;&:after{content:'';position:absolute;opacity:", ";left:", "px;top:0;width:", "px;height:", "px;border:solid ", ";border-width:0 ", "px ", "px 0;transform:rotate(45deg);}"], function (props) {
  return props.isSmaller ? 25 : props.theme.xlargeSpacer;
}, function (props) {
  return props.isSmaller ? 25 : props.theme.xlargeSpacer;
}, function (props) {
  return props.isChecked ? props.theme.primary : props.theme.white;
}, function (props) {
  return props.theme.xSmallSpacer;
}, function (props) {
  return props.isChecked ? 1 : 0;
}, function (props) {
  return props.isSmaller ? 6 : 9;
}, function (props) {
  return props.isSmaller ? 7 : 8;
}, function (props) {
  return props.isSmaller ? 15 : 20;
}, function (props) {
  return props.theme.success;
}, function (props) {
  return props.theme.xSmallSpacer;
}, function (props) {
  return props.theme.xSmallSpacer;
});