import React from 'react';
import styled from 'styled-components';
import PullToRefresh from 'react-simple-pull-to-refresh';
import useDevice from '@hooks/useDevice';
import { HeadingSmall } from '@ui/components/Typography';

const PullDownWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.largeSpacer}px;
`;

interface Props {
  children: JSX.Element;
}

/**
 * Adds for functionality to refresh the page when a user pulls down on a mobile device
 */
const WithPullToRefresh = (props: Props) => {
  const { isWebView } = useDevice();
  // This allows the user to pull down to refresh the whole WebView
  const onRefresh = () =>
    new Promise<void>(resolve => {
      setTimeout(() => {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            method: 'refresh',
          })
        );
        resolve();
      }, 1000);
    });

  const pullDownToRefresh = (
    <PullDownWrapper>
      <HeadingSmall style={{ color: 'white' }}>
        Pull down to refresh
      </HeadingSmall>
    </PullDownWrapper>
  );

  if (isWebView) {
    return (
      <PullToRefresh onRefresh={onRefresh} pullingContent={pullDownToRefresh}>
        {props.children}
      </PullToRefresh>
    );
  }
  return props.children;
};

export default WithPullToRefresh;
