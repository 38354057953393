import React, { useState } from 'react';
import openExternalWebpage from '@helpers/openExternalWebpage';
import trackEvent from '@helpers/tracking';
import { useNavigate } from '@hooks/useRouting';
import useActiveArtist from '@hooks/useActiveArtist';
import { useDispatch } from '@hooks/useStore';
import IconNotification from '@ui/components/IconNotification';
import Avatar from '@ui/components/Avatar/Avatar';
import Markdown from '@ui/components/Markdown';
import { dismissFeedItem } from 'store/user';
import { FeedCard } from '@ui/types';
// import TrendChart from 'components/infographics/TrendChart';
import DashboardRow from 'components/infographics/DashboardRow';
import Button from '@ui/components/Button';
import { HeadingLargeClean } from '@ui/components/Typography';
import { formatFriendlyDate } from '@helpers/formatFriendlyDate';
import {
  SubWrapper,
  NewsFeedCardMain,
  CtaRow,
  Content,
  NewsCardSubText,
  ContentWrapper,
} from './NewsFeedCard.styled';

const NewsFeedCard = (props: FeedCard) => {
  const [showCross, setShowCross] = useState(false);
  const { actId } = useActiveArtist();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const link = props.cta_link;
  const { isFeatured } = props;
  // const featuredImageURL = props;
  // const featuredComponentName = props;
  // const featuredComponentData = props;
  const mockData = {
    featuredImageURL:
      'https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?cs=srgb&dl=pexels-lumn-167699.jpg&fm=jpg',
    featuredComponentData: {
      metric: 'Monthly Listeners',
      trend: [{ day: '2021-11-25', value: 2850 }],
    },
  };

  const feedCardHasId: boolean = !!link && actId !== props.act_id;
  const feedCardLink =
    feedCardHasId && props.act_id
      ? `${link}${link?.includes('?') ? '&' : '?'}set_act_id=${props.act_id}`
      : link;

  const handleCtaClick = () => {
    trackEvent('Activity Feed CTA Click', {
      type: props.type,
      cta_link: props.cta_link,
      act_id: props.act_id,
      act_name: props.act_name,
      cta_copy: props.cta_copy,
    });
    if (link?.startsWith('http')) {
      openExternalWebpage(link);
    } else {
      navigate(feedCardLink!);
    }
  };

  return (
    <SubWrapper
      isFeatured={isFeatured}
      onMouseEnter={() => setShowCross(true)}
      onMouseLeave={() => setShowCross(false)}
      onClick={() => handleCtaClick()}
    >
      {!!props.news_feed_id && (
        <IconNotification
          showNotification={!!showCross}
          iconName="cross"
          backgroundColour="tertiary"
          isTopRightOfParent
          size={44}
          clickHandler={() =>
            dispatch(
              dismissFeedItem({
                act_id: props.act_id,
                news_feed_id: props.news_feed_id,
              })
            )
          }
        />
      )}
      <NewsFeedCardMain>
        {!isFeatured ? (
          <Avatar source={props.profile_image} size={32} />
        ) : (
          <Avatar source={props.profile_image} size={48} />
        )}
        <Content>
          <HeadingLargeClean>
            <Markdown>{props.copy}</Markdown>
          </HeadingLargeClean>
          <CtaRow>
            <NewsCardSubText>
              {formatFriendlyDate(new Date(props.date_ts))}
            </NewsCardSubText>
          </CtaRow>
        </Content>
      </NewsFeedCardMain>
      {isFeatured && (
        <ContentWrapper>
          {mockData.featuredComponentData !== null ? (
            <DashboardRow heading="Spotify Streams This Week">
              {/* <TrendChart data={mockData.featuredComponentData.trend} /> */}
            </DashboardRow>
          ) : (
            <img
              alt="feature media"
              src={mockData.featuredImageURL}
              style={{ width: '100%' }}
            />
          )}
          <Button
            dark
            text="View Spotify Insights"
            onPress={() => handleCtaClick()}
          />
        </ContentWrapper>
      )}
    </SubWrapper>
  );
};

export default NewsFeedCard;
