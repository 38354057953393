import { useEffect } from 'react';
import { useDispatch } from '@hooks/useStore';
import { setActiveArtist } from 'store/user';
import { useSearchParams } from '@hooks/useRouting';

/**
 * Sets active artist from query param
 */
const useSetActiveArtistFromUrl = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams();
  const targetActId = search.get('set_act_id');

  useEffect(() => {
    if (targetActId) {
      dispatch(setActiveArtist({ actId: targetActId }));
      search.delete('set_act_id');
      setSearch(search);
    }
  }, [targetActId]);
};

export default useSetActiveArtistFromUrl;
