import { useContext } from 'react';
import GlobalState from 'context/GlobalState';

/**
 * Returns data about users device
 */
const useDevice = () => {
  const globalState = useContext(GlobalState);
  const { isMobile, isTablet, isWebView } = globalState;
  const isSmallScreen = !!globalState.width && globalState.width < 840;

  return {
    isMobile: isMobile || isSmallScreen,
    isTablet,
    isWebView,
    isDesktop: !isMobile && !isTablet,
    width: globalState.width,
  };
};

export default useDevice;
