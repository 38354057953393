import Schema from 'validate';

/**
 * Validates a form
 */
const validateForm = (schema, values) => {
  const validationSchema = new Schema(schema);
  const errors = validationSchema.validate(values, { strip: false });
  const errorObject = {};
  errors.forEach(error => {
    errorObject[error.path] = error.message;
  });

  return errorObject;
};

export default validateForm;
