import React from 'react';
import { PLATFORM, COLOURS } from '@ui/consts';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    viewBox="0 0 84 30"
    fill={isColour ? COLOURS[PLATFORM.DRIP] : 'currentColor'}
    {...props}
  >
    <g id="logomark">
      <path
        id="logo-mouth"
        d="M18 14.5L15.3 14.5C15.3 14.6 15.3 14.7 15.3 14.8 15.3 18.8 12 21.2 9 21.2 6 21.2 2.7 18.8 2.7 14.8 2.7 14.7 2.7 14.6 2.7 14.5L0 14.5C0 14.6 0 14.7 0 14.8 0 20.3 4.5 23.9 9 23.9 13.5 23.9 18 20.3 18 14.8 18 14.7 18 14.6 18 14.5Z"
      />
      <path
        id="logo-hat"
        d="M9 3.4L10.9 6 14.3 6C12.7 3.9 11 1.8 9 0 7 1.8 5.3 3.9 3.7 6L7.1 6 9 3.4Z"
      />
      <g id="logo-eyes">
        <ellipse id="logo-eye-left" cx="3.6" cy="10.3" rx="1.8" ry="1.8" />
        <ellipse id="logo-eye-center" cx="9" cy="10.3" rx="1.8" ry="1.8" />
        <ellipse id="logo-eye-right" cx="14.4" cy="10.3" rx="1.8" ry="1.8" />
      </g>
    </g>
    <g id="wordmark">
      <path
        id="wordmark-d"
        d="M40.8 23.5L37.6 23.5 37.6 20.9C35.9 23 33.6 24 30.9 24 28.2 24 25.8 23.1 24.2 21.5 22.6 19.9 21.6 17.6 21.6 14.9 21.6 12.1 22.5 9.9 24.2 8.3 25.9 6.7 28.2 5.8 30.9 5.8 33.8 5.8 35.8 6.7 37.6 8.7L37.6 0 40.8 0 40.8 23.5ZM31.2 8.6C27.3 8.6 24.9 10.9 24.9 14.9 24.9 18.8 27.3 21.1 31.2 21.1 35.2 21.1 37.6 18.8 37.6 14.9 37.6 11 35.2 8.6 31.2 8.6Z"
      />
      <polygon
        id="wordmark-r"
        points="55.2 8.9 47.5 8.9 47.5 24 44.4 24 44.4 6 55.2 6"
      />
      <path
        id="wordmark-i"
        d="M57.6 1.9C57.6 1.2 57.9 0.6 58.5 0.3 59.1-0.1 59.7-0.1 60.3 0.3 60.9 0.6 61.2 1.2 61.2 1.9 61.2 2.6 60.9 3.3 60.3 3.6 59.7 4 59.1 4 58.5 3.6 57.9 3.3 57.6 2.6 57.6 1.9ZM57.9 6.2L60.9 6.2 60.9 24 57.9 24 57.9 6.2Z"
      />
      <path
        id="wordmark-p"
        d="M64.8 6.4L68 6.4 68 9C69.6 7.1 71.7 6 74.7 6 77.4 6 79.7 6.9 81.4 8.5 83.1 10.1 84 12.4 84 15.1 84 17.9 83.1 20.1 81.4 21.7 79.7 23.3 77.4 24.2 74.6 24.2 71.8 24.2 69.8 23.3 68 21.3L68 30 64.8 30 64.8 6.4ZM74.3 21.4C78.3 21.4 80.7 19.1 80.7 15.1 80.7 11.2 78.3 8.9 74.3 8.9 70.4 8.9 68 11.2 68 15.1 68 19 70.4 21.4 74.3 21.4Z"
      />
    </g>
  </svg>
);
