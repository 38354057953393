import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    fill={isColour ? '#A8E00F' : 'currentColor'}
    {...props}
  >
    <path d="M441.83 283.12C441.83 179.54 358.29 96 255.82 96 153.35 96 69.8 179.54 69.8 283.12c0 47.9 17.82 93.57 50.12 128.1 4.46 4.45 11.14 4.45 16.7 0l20.06-18.94c4.45-4.46 4.45-11.14 0-16.7-51.24-54.59-47.9-141.46 6.68-192.7 54.58-51.24 140.34-47.9 190.47 6.68 49 52.35 49 133.66 0 186.01a11.21 11.21 0 001.11 16.71L375 411.22c4.45 4.45 12.25 4.45 16.7 0 32.3-34.53 50.13-81.32 50.13-128.1z" />
    <path d="M512 282.01c0-25.61-3.34-50.12-11.14-74.62-2.23-5.57-8.9-8.91-14.48-7.8l-26.73 7.8c-5.57 2.23-8.91 7.8-7.8 14.48 16.71 54.58 10.03 112.5-18.93 161.5-3.34 5.57-1.12 12.26 4.45 15.6l23.4 14.48c5.56 3.34 12.25 1.11 15.59-4.46C499.75 371.12 512 326.57 512 282.01zM78.72 383.37c-28.96-49.01-35.64-108.04-18.94-161.5 2.23-5.58-1.11-12.26-7.8-14.49l-26.73-7.8c-5.56-2.22-12.25 1.12-14.47 7.8-20.05 67.95-12.26 141.46 24.5 202.72 3.34 5.57 10.02 6.68 15.6 4.46l23.38-14.48c5.57-3.34 6.69-11.14 4.46-16.71z" />
  </svg>
);
