import React from 'react';
import openExternalWebpage from '@helpers/openExternalWebpage';
import trackEvent from '@helpers/tracking';
import ExpandContentPanel from 'components/common/ExpandContentPanel';
import OptionTiles from 'components/common/OptionTiles';
import useTheme from '@hooks/useTheme';
import { NoArtistNameOptionsMain } from './NoArtistNameOptions.styled';

const NoArtistNameOptions = () => {
  const theme = useTheme();
  const options = [
    {
      name: 'setupSocials',
      title: "I haven't chosen a name yet",
      subtitle: 'See how to grow a music career from scratch',
      buttonText: 'Get started',
      externalUrl:
        'http://help.beatchain.com/en/articles/3766041-choosing-your-name-and-building-a-presence',
    },
    {
      name: 'buildFollowing',
      title: "I'm not an artist",
      subtitle: `See what ${theme.title} has to offer managers, labels and promoters`,
      buttonText: 'Industry tools',
      externalUrl:
        'http://help.beatchain.com/en/articles/3766483-i-m-not-an-artist',
    },
    {
      name: 'ratherNot',
      title: 'Privacy concerns?',
      subtitle: `See what ${theme.title} does with artist data`,
      buttonText: 'How it works',
      externalUrl:
        'http://help.beatchain.com/en/articles/3771968-what-happens-when-i-enter-my-artist-details',
    },
  ];
  return (
    <NoArtistNameOptionsMain>
      <ExpandContentPanel heading="More options">
        <OptionTiles
          isRow
          options={options?.map(item => ({
            key: item.name,
            name: item.name,
            title: item.title,
            subtitle: item.subtitle,
            buttonText: item.buttonText,
            buttonHandleClick: () => {
              openExternalWebpage(item.externalUrl);
              trackEvent('Onboarding No Artist Name', {
                Title: item.title,
                Subtitle: item.subtitle,
                CTA: item.buttonText,
                Article: item.externalUrl,
              });
            },
          }))}
        />
      </ExpandContentPanel>
    </NoArtistNameOptionsMain>
  );
};

export default NoArtistNameOptions;
