import React, { useEffect, useState } from 'react';
import { find, filter, orderBy, reject } from 'lodash';
import {
  MediaLibraryProps,
  MediaLibraryItem,
  ModalInjectedProps,
  WithDataInjectedProps,
} from '@ui/types';
import Loader from '@ui/components/Loader';
import { withData } from '@api/withData';
import entities from '@api/entities';
import useDevice from '@hooks/useDevice';
import { toast } from 'react-toastify';
import MediaModal from '../MediaModal';
import MediaLibrary from './MediaLibrary';

const defaultCollections = [
  'Images - Professional',
  'Images - informal',
  'Images - Throwback',
  'Images - Selfies',
  'Images - Live Performances',
  'Images - Other Interests',
  'Promotions - Merchandise',
  'Promotions - Spotify',
  'Promotions - Gigs',
  'Video - Instagram Stories',
  'Video - Cover Songs',
  'Video - Original Tracks',
  'Video - Professional',
];

const MediaLibraryContainer = ({
  isSelectMode = true,
  ...props
}: MediaLibraryProps & WithDataInjectedProps & ModalInjectedProps) => {
  const { isMobile, isWebView } = useDevice();
  const media: MediaLibraryItem[] = props.data || [];

  const [sortBy, setSortBy] = useState(props.sortBy || 'fileType');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [addMediaRequested, setAddMediaRequested] = useState(false);

  const [currentMediaItem, setCurrentMediaItem] =
    useState<MediaLibraryItem | null>(null);

  const tags = Array.from(new Set(media.map(el => el.tags).flat()));
  const collections = Array.from(
    new Set(
      media
        .map(el => el.collections)
        .flat()
        .concat(defaultCollections)
    )
  );

  const preSelectedItem = find(media, {
    url: props.selectedItemUrl,
  });

  const [selectedItems, setSelectedItems] = useState(
    preSelectedItem ? [preSelectedItem.media_id] : []
  );

  const searchItems = filter(
    media,
    item =>
      item.name?.toLowerCase().includes(search.toLowerCase()) ||
      filter(item.tags, tag =>
        tag?.toLowerCase().includes(search.toLowerCase())
      ).length >= 1
  );

  const orderedByUpdateDate = orderBy(
    searchItems,
    item => new Date(item.updated_date),
    ['desc']
  );

  const mediaItems = orderBy(
    orderedByUpdateDate,
    item => (item.url === props.selectedItemUrl ? -1 : 1),
    'asc'
  );

  function emptySelectedItems() {
    setSelectedItems([]);
  }

  function addToSelectedItems(id) {
    setSelectedItems([...selectedItems, id]);
  }

  function removeFromSelectedItems(id) {
    setSelectedItems(reject(selectedItems, item => item === id));
  }

  function deleteSelectedItem() {
    if (currentMediaItem) {
      props.deleteItem(currentMediaItem.media_id);
      setCurrentMediaItem(null);
    }
  }

  function updateTags(value: string[]) {
    if (currentMediaItem) {
      setCurrentMediaItem({ ...currentMediaItem, tags: value });
    }
  }

  function updateCollections(value: string[]) {
    if (currentMediaItem) {
      setCurrentMediaItem({ ...currentMediaItem, collections: value });
    }
  }

  const handleResult = (res: any) => {
    setLoading(false);

    if (res?.error) {
      toast.error(res.reason);
    } else {
      props.closeClickHandler();
    }
  };

  const tryInsert = async (selectedItem: any) => {
    const errObj = { error: true, reason: 'Failed inserting a media file.' };

    if (props.onInsert) {
      const result = await props
        .onInsert(selectedItem)
        .catch(err => (err?.error ? err : errObj));
      return result;
    }

    return {};
  };

  const tryInsertMulti = async () => {
    const errObj = {
      error: true,
      reason: 'Failed inserting the selected media files.',
    };

    if (props.onInsertMulti) {
      const mediaItemsArr = filter(
        media,
        item => item.media_id === currentMediaItem?.media_id
      );

      const result = await props
        .onInsertMulti(mediaItemsArr)
        .catch(err => (err?.error ? err : errObj));
      return result;
    }
    return {};
  };

  async function handleInsert() {
    setLoading(true);
    if (currentMediaItem) {
      if (props.onInsert) {
        const selectedItem = find(media, {
          media_id: currentMediaItem.media_id,
        });
        if (selectedItem) {
          const result = await tryInsert(selectedItem);
          handleResult(result);
        }
      }
      if (props.onInsertMulti) {
        const result = await tryInsertMulti();
        handleResult(result);
      }
    } else {
      props.closeClickHandler();
    }
  }

  function goToMediaItem(item) {
    setCurrentMediaItem(item);
    if (isMobile) {
      props.updateModalContent({
        modalTitle: 'Media Item',
        hasGoBackButton: true,
        onGoBack: () => {
          setCurrentMediaItem(null);
          props.updateModalContent({
            modalTitle: 'Media Library',
          });
        },
      });
    }
  }

  useEffect(() => {
    if (addMediaRequested && !isWebView) {
      setCurrentMediaItem(media[0]);
    }
  }, [props.data]);

  if (mediaItems.length === 0 && props.isLoading) return <Loader />;

  const mediaModalProps = {
    ...props,
    isSelectMode,
    collections,
    tags,
    setCurrentMediaItem,
    updateMediaItem: item => props.updateItem(item.media_id, item),
    uploadMediaItem: props.addItem,
    deleteMediaItem: props.deleteItem,
    updateTags,
    updateCollections,
    addToSelectedItems,
  };

  if (currentMediaItem && isMobile) {
    return (
      <MediaModal
        currentMediaItem={currentMediaItem}
        handleInsert={handleInsert}
        deleteSelectedItem={deleteSelectedItem}
        loading={loading}
        {...mediaModalProps}
      />
    );
  }

  return (
    <MediaLibrary
      {...props}
      {...mediaModalProps}
      isSelectMode={isSelectMode}
      mediaItems={mediaItems}
      handleInsert={handleInsert}
      sortBy={sortBy}
      updateSortValue={setSortBy}
      updateSearchValue={setSearch}
      tags={tags}
      collections={collections}
      goToMediaItem={goToMediaItem}
      selectedItems={selectedItems}
      emptySelectedItems={emptySelectedItems}
      setSelectedItems={setSelectedItems}
      currentMediaItem={currentMediaItem}
      setCurrentMediaItem={setCurrentMediaItem}
      addToSelectedItems={addToSelectedItems}
      removeFromSelectedItems={removeFromSelectedItems}
      deleteSelectedItem={deleteSelectedItem}
      addItem={props.addItem}
      updateTags={updateTags}
      updateCollections={updateCollections}
      openAlert={props.openAlert}
      loading={loading}
      setAddMediaRequested={setAddMediaRequested}
    />
  );
};

export default withData({
  component: MediaLibraryContainer,
  primaryEntity: entities.mediaLibrary,
  dataKey: 'media_id',
});
