import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "MediaLibraryTogglestyled__Wrapper",
  componentId: "sc-1vm31t1-0"
})(["border-radius:50%;background:", ";color:", ";display:flex;justify-content:center;align-items:center;width:", "px;height:", "px;cursor:pointer;z-index:1;flex-shrink:0;", ";"], function (props) {
  return props.theme.tertiary;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.theme.xlargeSpacer;
}, function (props) {
  return props.isBlocked && "cursor: not-allowed;\n    opacity: 0.4;";
});