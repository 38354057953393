import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    viewBox="0 0 160 50"
    fill={isColour ? '#00DE75' : 'currentColor'}
    {...props}
  >
    <path d="M92.43.01A25.08 25.08 0 0078.26 5.4l-.33.27.17.2a32.91 32.91 0 013.45 5.4 33.21 33.21 0 012.18 5.63 13.79 13.79 0 012.6-2.46 12.7 12.7 0 0116.88 1.76 13.02 13.02 0 01.95 16.44 12.8 12.8 0 01-9.97 5.34c-2.92.1-5.73-.8-8.1-2.58-.75-.57-1.62-1.4-2.16-2.06-.08-.1-.16-.17-.17-.16-.02.02-.14.4-.28.85a31.24 31.24 0 01-5.18 10c-.23.29-.27.36-.22.42a24.83 24.83 0 0018.25 5.43 24.93 24.93 0 0016.53-8.72 24.97 24.97 0 00-6.34-37.67A24.9 24.9 0 0096.18.1 45.8 45.8 0 0092.43 0z" />
    <path d="M52.14.08a25 25 0 00-2.23 49.65c1.42.23 2.07.27 3.9.27 1.75 0 2.16-.02 3.48-.2 3.7-.51 7.31-1.9 10.39-3.97.37-.25.6-.37.67-.36.06 0 1.7.3 3.66.67l3.55.65-.66-3.66-.67-3.65.44-.67A25 25 0 0052.14.08zm3.02 12.06c2.98.31 5.7 1.62 7.81 3.74a12.9 12.9 0 013.3 12.74 12.99 12.99 0 01-14.4 9.23 12.99 12.99 0 01-10.92-14.27c.34-3 1.65-5.66 3.82-7.79a12.78 12.78 0 0110.39-3.65z" />
    <path d="M53.52 21.42c-.58.06-1.31.31-1.8.64a3.62 3.62 0 105.41 4.42c.22-.48.3-.95.28-1.59a3.55 3.55 0 00-3.9-3.47zM10.82 17.73c0 11.67-.02 16.48-.05 16.65a2.68 2.68 0 01-1.87 1.98c-.23.07-.54.08-5.07.1H-1v12l13.36-.03.62-.12a11.9 11.9 0 009.76-9.93l.1-.55.02-36.5H10.82v16.4zM115.53 1.43l7.56 11.76c4.16 6.45 7.56 11.76 7.55 11.8L123.1 36.7c-4.14 6.41-7.52 11.68-7.52 11.71 0 .02 2.68.04 7.12.04h7.12l4-6.2c2.19-3.4 4-6.18 4.02-6.16l4 6.18 3.96 6.16 7.15.01c5.71.01 7.14 0 7.12-.04-.01-.03-3.41-5.33-7.56-11.76l-7.54-11.7.15-.23a88679.33 88679.33 0 0114.96-23.27c0-.02-3.07-.04-7.12-.04h-7.13l-4 6.2-4 6.2c-.02 0-1.83-2.79-4.02-6.2l-3.99-6.2h-7.14c-3.93 0-7.15.01-7.15.02z" />
  </svg>
);
