import React from 'react';

import Emoji from '@ui/components/Emoji';
import { HeadingLarge, HeadingLabel } from '@ui/components/Typography';

import {
  Wrapper,
  WhiteBox,
  TextWrapper,
  BoldHeadingLabel,
} from './Unsubscribe.styled';

const Unsubscribe = ({ email }: { email: string }) => (
  <Wrapper>
    <WhiteBox>
      <TextWrapper>
        <HeadingLarge>
          <Emoji symbol="😫" />
        </HeadingLarge>
      </TextWrapper>
      <TextWrapper>
        <HeadingLarge> Sorry to see you go...</HeadingLarge>
      </TextWrapper>
      <TextWrapper>
        <HeadingLabel>
          Email <BoldHeadingLabel>{email}</BoldHeadingLabel> is now unsubscribed
          from Beatchain&apos;s marketing communications
        </HeadingLabel>
      </TextWrapper>
    </WhiteBox>
  </Wrapper>
);

export default Unsubscribe;
