import { useDispatch } from '@hooks/useStore';
import { ModalContent } from '@ui/types';
import { capitalizeFirstLetter } from '@helpers/textFormatting';
import * as uiStore from 'store/ui';

/**
 * Opens a modal
 */
const useModal = () => {
  const dispatch = useDispatch();

  return (modalContent: ModalContent) =>
    dispatch(
      uiStore.setModalContent({
        isVisible: true,
        ...modalContent,
        modalTitle: capitalizeFirstLetter(modalContent?.modalTitle || ''),
      })
    );
};

export default useModal;
