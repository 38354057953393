import styled from 'styled-components';

/**
 * Layout utility for spacing elements with consistent padding and alignment cross browser
 */
export var EquallySpacedRow = styled.div.withConfig({
  displayName: "EquallySpacedRowstyles__EquallySpacedRow",
  componentId: "sc-1armeov-0"
})(["display:grid;grid-auto-flow:column;grid-gap:", "px;", " ", " ", " ", " ", " ", " ", ""], function (props) {
  if (props.isSmallGap) return props.theme.spacer;
  if (props.isXlargeGap) return props.theme.xlargeSpacer;
  return props.theme.largeSpacer;
}, function (props) {
  return props.isEqualColumns && 'grid-auto-columns: 1fr;';
}, function (props) {
  return props.isFitMinContent && 'grid-auto-columns: min-content;';
}, function (props) {
  return props.isFitMaxContent && 'grid-auto-columns: max-content;';
}, function (props) {
  return props.isMobile && 'grid-auto-flow: row;';
}, function (props) {
  return !!props.gridTemplateColumns && "grid-auto-flow: unset;\n    grid-template-columns: ".concat(props.gridTemplateColumns, ";\n  ");
}, function (props) {
  return !!props.justifyContent && "justify-content: ".concat(props.justifyContent, ";");
}, function (props) {
  return !!props.alignItems && "align-items: ".concat(props.alignItems, ";");
});