import { configureStore } from '@reduxjs/toolkit';
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import reduxCatch from 'redux-catch';
import { loadState } from '../localStorage';

import * as user from './user';
import * as ui from './ui';
import * as auth from './auth';
import * as error from './error';
import * as credits from './credits';

export function* rootSaga() {
  yield all([
    auth.loginSaga(),
    auth.requestResetPasswordSaga(),
    auth.resetPasswordSaga(),
    auth.forceChangePasswordSaga(),
    auth.logoutSaga(),
    auth.signupSaga(),
    auth.loginSuccessSaga(),
    auth.forceChangePasswordSuccessSaga(),
    user.fetchUserMetadataSaga(),
    user.setActiveArtistSaga(),
    user.fetchMetadataErrorSaga(),
    user.fetchUserFeedSaga(),
    user.dismissFeedItemSaga(),
    user.markFeedItemAsDismissedSaga(),
    credits.fetchActionsSaga(),
    credits.fetchProductsSaga(),
  ]);
}

export const sagaMiddleware = createSagaMiddleware();

const errorHandlingMiddleware = reduxCatch(
  (err, getState, lastAction, dispatch) => {
    console.error(err);
    dispatch(
      error.showErrorPage({
        errorCode: 500,
        errorMessage: `Redux error: ${err.toString()}`,
      })
    );
  }
);

const preloadedState = loadState();

export const store = configureStore({
  reducer: {
    auth: auth.reducer,
    user: user.reducer,
    ui: ui.reducer,
    error: error.reducer,
    credits: credits.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat([
      sagaMiddleware,
      errorHandlingMiddleware,
    ]),
  preloadedState,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
