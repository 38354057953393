import React from 'react';
import Input from '@ui/components/Input';

const OtpInput = props => (
  <Input
    placeholder="Verification Code"
    autoComplete="one-time-code"
    isLight
    {...props}
  />
);

export default OtpInput;
