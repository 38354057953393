const triggerEvent = (key: string, data: string) => {
  window.localStorage.setItem(key, data);
  window.localStorage.removeItem(key);
};

export const providers = ['twitter', 'stripe'];

/**
 * Triggers events for twitter and stripe oauth
 */
export const methods = {
  twitter: (search: string) => {
    triggerEvent('connect_twitter', search);
  },
  stripe: async (search: string) => {
    triggerEvent('connect_stripe', search);
  },
};

// Using local storage to communicate with the oauth login tab.
// There is a better API called BroadcastChannel but it's not supported in some
// important browsers atm (https://caniuse.com/#feat=broadcastchannel).
/**
 * Adds event listenet for oauth
 */
export const addEventListener = (
  key: string,
  callback: (search: string) => void
) => {
  window.addEventListener('storage', event => {
    if (event.key !== key || !event.newValue) return; // ignore other keys

    callback(event.newValue);
  });
};
