import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Props as SelectProps } from 'react-select';
import { Option } from '@ui/types';
import { useTheme } from 'styled-components';
import MetaItem from '@ui/components/MetaItem';
import { getReactSelectStyles } from '@ui/theme/moduleStyles/reactSelectStyles';
import {
  SelectWrapper,
  SelectLabel,
  LockedValue,
} from './SelectCreatable.styled';

interface Props extends SelectProps<Option, true> {
  label?: string;
  value?: Option[];
  defaultValue?: Option[];
  isSmall?: boolean;
  isLight?: boolean;
  isInBox?: boolean;
  isLocked?: boolean;
}

/**
 * Wraps, configures and styles third-party select box 'react-select' with creatable options
 */
const SelectCreatable = (props: Props) => {
  const theme = useTheme();
  const styles = getReactSelectStyles(theme, props);

  const [inputValue, setInputValue] = useState('');

  return (
    <SelectWrapper isSmall={props.isSmall}>
      {props.label && <SelectLabel>{props.label}</SelectLabel>}
      {props.isLocked ? (
        <LockedValue>
          {props.defaultValue &&
            props.defaultValue.map((item, index) => (
              <MetaItem key={`${item?.label}-${index}`} text={item?.label} />
            ))}
          {props.value &&
            props.value.map((item, index) => (
              <MetaItem key={`${item?.label}-${index}`} text={item?.label} />
            ))}
        </LockedValue>
      ) : (
        <>
          <CreatableSelect
            {...props}
            styles={styles}
            defaultValue={props.defaultValue}
            value={props.value}
            options={props.options}
            onChange={props.onChange}
            placeholder={
              props.placeholder || 'Type something and press enter...'
            }
            isMulti
            isDisabled={props.isDisabled}
            components={{
              DropdownIndicator: null,
            }}
            inputValue={inputValue}
            menuIsOpen={props.options ? undefined : !!inputValue}
            onInputChange={inputVal => {
              setInputValue(inputVal);
            }}
            formatCreateLabel={userInput => `Add "${userInput}"`}
          />
        </>
      )}
    </SelectWrapper>
  );
};

export default SelectCreatable;
