import React, { useState } from 'react';
import Button from '@ui/components/Button';
import { InfoPair } from 'components/common/InfoPair/InfoPair.styled';
import FormPage from 'components/app/FormPage/FormPage';
import Autosuggest from 'components/common/Autosuggest';
import {
  HeadingLarge,
  Heading,
  DescriptionText,
} from '@ui/components/Typography/Typography';
import { BoxInBox } from 'components/common/Box/Box.styled';
import NoArtistNameOptions from '../NoArtistNameOptions';
import { Error } from './ArtistName.styled';

interface MusicBrainzResult {
  artist_name: string;
  musicbrainz_genre: string;
}

interface Props {
  onSelect: (value: string) => void;
  search: (artistNameToSearch: string) => Promise<any>;
  error?: string;
  isLightMode?: boolean;
  showMoreOptions?: boolean;
}

const ArtistNameAutosuggest = (props: Props) => {
  const [selectedArtist, setSelectedArtist] = useState<MusicBrainzResult>();

  return (
    <FormPage
      hasNoHeader
      hideSocialProofPanel
      hasScrollbars
      isLightMode={props.isLightMode}
    >
      <HeadingLarge>What is your artist or band name?</HeadingLarge>
      {props.error && <Error>{props.error}</Error>}
      {!!selectedArtist && (
        <>
          <InfoPair>
            <BoxInBox isLightMode={props.isLightMode}>
              <Heading>{selectedArtist.artist_name}</Heading>
            </BoxInBox>
            <DescriptionText>
              This will appear as your artist name on streaming platforms if you
              choose to distribute with us
            </DescriptionText>
          </InfoPair>
          <Button
            text="Correct"
            shouldNotStretch
            icon="right"
            onPress={() => props.onSelect(selectedArtist?.artist_name)}
            disabled={!selectedArtist}
            alignRight
            dataTestId="confirm-new-artist-button"
          />
        </>
      )}
      <Autosuggest
        onAddClick={setSelectedArtist}
        search={props.search}
        inputLabel={
          selectedArtist ? 'Not right? Search again...' : 'Artist Name'
        }
        suggestionTextPropName="artist_name"
        allowNotFound
        isLightMode={props.isLightMode}
        isInBox={props.isLightMode}
      />
      {props.showMoreOptions && <NoArtistNameOptions />}
    </FormPage>
  );
};

export default ArtistNameAutosuggest;
