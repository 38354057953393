import { toast } from 'react-toastify';

const toastId = 'connection_issues_toast';

// ** Capturing when browser goes offline and when it comes back online **
// Can be a React component instead of string
const OfflineMessage = 'Internet connectivity issues...';
const showOfflineToast = () =>
  toast.error(OfflineMessage, {
    autoClose: false,
    toastId,
  });

/**
 * Attatches event listeners to see if user is online or offline
 */
export const attachStatusListener = () => {
  if (window && window.navigator) {
    window.addEventListener('offline', showOfflineToast);
    window.addEventListener('online', () => toast.dismiss(toastId));
  }
};

// ** Capturing when api calls take too long **
// Can be a React component instead of string
const SlowConnectionMessage = 'Internet connectivity issues...';
const showSlowConnectionToast = () =>
  toast.error(SlowConnectionMessage, {
    autoClose: false,
    toastId,
  });

const slowAPIs = ['artist/search/public', 'user/me', 'analytics/multi'];
const noShows = ['warehouse/analytics/', 'facebook/', 'splits/'];

const slowTimeout = (requestUrl: string) => {
  if (slowAPIs.some(slowAPI => requestUrl.includes(slowAPI))) return 25000;
  return 15000;
};

/**
 * Sends a request with a timeout if it takes too long
 */
export const timedRequest = async (endpoint: string, options) => {
  let timeoutId;
  if (!noShows.some(noShow => endpoint.includes(noShow))) {
    timeoutId = setTimeout(showSlowConnectionToast, slowTimeout(endpoint));
  }

  try {
    const data = await fetch(endpoint, options);
    clearTimeout(timeoutId);
    return data;
  } catch (e) {
    clearTimeout(timeoutId);
    throw e;
  }
};
