import React from 'react';
import useAlertModal from '@hooks/useAlertModal';
import trackEvent from '@helpers/tracking';
import openExternalWebpage from '@helpers/openExternalWebpage';
import TitleBlock from '@ui/components/TitleBlock';
import AppLandingPage from 'components/app/AppLandingPage';
import ChecklistLanding from 'components/checklist/ChecklistLanding';

const PublishingRoyalties = () => {
  const openAlertModal = useAlertModal();

  const openDiscountCodeAlert = () => {
    trackEvent('Publishing Royalties / Claim SongTrust Discount');
    openAlertModal({
      modalTitle: `Claim your 20% discount`,
      modalText:
        'Enter "beatchain" as the discount code when you register for SongTrust (click "Have discount code?" at checkout)',
      componentProps: {
        buttons: [
          {
            text: 'Register for SongTrust',
            onPress: () => {
              openExternalWebpage('https://app.songtrust.com/signup');
              trackEvent('Publishing Royalties / Register for SongTrust');
            },
          },
        ],
      },
    });
  };

  return (
    <AppLandingPage backgroundImageUrl="https://static.beatchain.com/butter/Bj8iHV1RZS73aHWT6Rem5f9aed26afa194b33e1f5dcc_Distribution doesn’t have to be difficult -main.jpg">
      <TitleBlock
        title="Global Publishing Royalty Collection"
        subtitle="Beatchain collects your Master Recording Royalties for you, but who collects your Global Publishing Royalties?"
      />
      <ChecklistLanding
        data={[
          {
            title: 'Royalty Collection Checklist',
            items: [
              {
                name: 'masteringRoyalties',
                title:
                  "First, distribute your music with Beatchain - we'll collect your recording royalties for you",
                subtitle:
                  "It's free to distribute with Beatchain and we provide the best analytics in the industry",
                postTitle: 'More about your distribution options',
                postUrl:
                  'https://beatchain.com/blog/distribution-doesnt-have-to-be-difficult',
              },
              {
                name: 'collectionAgency',
                title: 'Then join a collection society',
                subtitle:
                  'Choose a collection society that operates in your country, such as ASCAP or BMI in the US, PRS in the UK, IMRO in Ireland or SOCAN in Canada. You will then be identified as a writer in the publishing industry.',
                postTitle: 'More about royalties',
                postUrl:
                  'https://beatchain.com/blog/royalties-everything-you-need-to-know-to-get-started',
              },
              {
                name: 'publishingAdmin',
                title:
                  'Also consider SongTrust who can collect your publishing royalties globally',
                subtitle:
                  "The best way to ensure you don't miss out on any publishing royalties is to let SongTrust register your compositions with global performance and mechanical societies and collect ALL your revenue",
                postTitle: 'Is SongTrust right for you?',
                postUrl: 'https://www.songtrust.com/',
                buttonText: 'Get 20% off SongTrust registration',
                buttonHandleClick: openDiscountCodeAlert,
              },
            ],
          },
        ]}
      />
    </AppLandingPage>
  );
};

export default PublishingRoyalties;
