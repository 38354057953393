import styled from 'styled-components';
import { HeadingLabel } from '../Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "Selectstyled__Wrapper",
  componentId: "sc-yzyyn8-0"
})(["display:flex;flex-direction:column;position:relative;max-width:600px;"]);
export var Label = styled(HeadingLabel).withConfig({
  displayName: "Selectstyled__Label",
  componentId: "sc-yzyyn8-1"
})(["margin-bottom:", "px;@media screen and (max-width:400px){font-size:", "px;line-height:", "px;}"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.headingLabelFontSize - 2;
}, function (props) {
  return props.theme.headingLabelLineHeight - 2;
});