import React, { useState, useEffect } from 'react';
import { useSearchParams } from '@hooks/useRouting';
import { useDispatch } from '@hooks/useStore';
import { setLoginRedirect } from '@helpers/loginRedirect';
import { loginClearStatus } from 'store/auth';
import { BodyText } from '@ui/components/Typography';
import TitleBlock from '@ui/components/TitleBlock';
import Link from '@ui/components/Link';
import SignUpForm from 'components/account/SignUpForm';
import SignUpOptions from 'components/account/SignUpOptions';
import FormPage from 'components/app/FormPage/FormPage';
import Captcha from 'components/app/Captcha';
import useTheme from '@hooks/useTheme';

const Signup = () => {
  const theme = useTheme();
  const [search] = useSearchParams();

  const dispatch = useDispatch();
  const [useEmail, setUseEmail] = useState(search.has('email'));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loginClearStatus());
    setLoginRedirect(search.get('redirect_to') || undefined);
  }, []);

  const isInvitedContributor = search.has('splits_release_id');
  const title = isInvitedContributor ? 'Get Paid' : `Join ${theme.title}`;

  return (
    <FormPage
      handleBackButtonClick={useEmail ? () => setUseEmail(false) : undefined}
    >
      <TitleBlock title={title} subtitle="Create Account" hasNoBox />
      {useEmail ? (
        <SignUpForm />
      ) : (
        <SignUpOptions
          setUseEmail={setUseEmail}
          useEmailButtonText="Join with email"
          isSignUp
        />
      )}
      <BodyText>
        {'Already a member? '}
        <Link to="/signin">Sign in</Link>
      </BodyText>

      <BodyText>
        {'By signing up you are agreeing to the '}
        <Link to="/privacy-policy" target="_blank">
          Privacy Policy
        </Link>
        {' and '}
        <Link to="/terms-and-conditions" target="_blank">
          Terms of Service
        </Link>
      </BodyText>
      <Captcha />
    </FormPage>
  );
};

export default Signup;
