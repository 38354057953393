import React, { useState } from 'react';
import { addDays } from 'date-fns';
import { featureIds } from 'config/features';
import ajax from '@helpers/ajax';
import openExternalWebpage from '@helpers/openExternalWebpage';
import useActiveArtist from '@hooks/useActiveArtist';
import useUser from '@hooks/useUser';
import Button from '@ui/components/Button';
import { BodyText } from '@ui/components/Typography';
import SubscriptionBtn from 'components/account/SubscriptionBtn';
import DatePicker from 'components/common/DatePicker';
import { Wrapper } from './ExportCsv.styled';

const ExportCsv = () => {
  const [startDate, setStartDate] = useState(addDays(new Date(), -1));
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const { actId } = useActiveArtist();
  const { hasFeature } = useUser();

  if (!hasFeature(featureIds.csvExports)) {
    return (
      <Wrapper>
        <BodyText>
          You have discovered a Pro feature. In order to export your data as a
          CSV you need a Pro subscription.
        </BodyText>
        <SubscriptionBtn buttonText="Upgrade" product="Premium" />
      </Wrapper>
    );
  }

  const exportToCsv = async () => {
    setLoading(true);
    const response = await ajax(
      `artist/${actId}/insights/csvFileCreate`,
      { dateStart: startDate?.getTime(), dateEnd: endDate?.getTime() },
      'POST'
    );
    setLoading(false);
    openExternalWebpage(response.downloadLink);
  };

  return (
    <Wrapper>
      <DatePicker
        label="Start date"
        selected={startDate}
        onChange={setStartDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        minDate={new Date(0)}
        maxDate={new Date()}
      />
      <DatePicker
        label="End date"
        selected={endDate}
        onChange={setEndDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={new Date()}
      />
      <Button
        style={{ marginLeft: 'auto' }}
        text="Export"
        onPress={exportToCsv}
        loading={loading}
      />
    </Wrapper>
  );
};

export default ExportCsv;
