import styled from 'styled-components';
export var StoryRow = styled.div.withConfig({
  displayName: "PlatformLogostyled__StoryRow",
  componentId: "sc-8wdmal-0"
})(["display:grid;grid-template-columns:repeat(auto-fill,minmax(200px,1fr));grid-gap:", "px;margin-bottom:", "px;"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.xlargeSpacer;
});
export var TextVersion = styled.div.withConfig({
  displayName: "PlatformLogostyled__TextVersion",
  componentId: "sc-8wdmal-1"
})(["display:flex;justify-content:flex-start;align-items:center;font-family:", ",sans-serif;font-size:50px;"], function (props) {
  return props.theme.bodyBoldFont;
});
export var FontSize = styled.div.withConfig({
  displayName: "PlatformLogostyled__FontSize",
  componentId: "sc-8wdmal-2"
})(["display:flex;align-items:center;"]);