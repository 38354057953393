import React from 'react';
import { signRequest, uploadToS3 } from '@helpers/uploadFile';
import { getAudioDetails } from '@helpers/getAudioDetails';
import guid from '@helpers/guid';

// DO NOT USE TYPESCRIPT HERE YET

const useFileLoader = cb => {
  const [fileState, setFiles] = React.useState([]);

  // need to hold this as a ref so that the s3 call back can access current value
  const files = React.useRef(fileState);
  files.current = fileState;

  const updateFileState = (uploadId, item) => {
    const newFiles = files.current.map(_file => {
      if (_file.upload_id === uploadId) {
        return Object.assign(_file, item);
      }
      return _file;
    });

    setFiles(newFiles);
  };

  const setProgress = (uploadId, file, uploadProgress) => {
    updateFileState(uploadId, {
      progress:
        uploadProgress.total &&
        (uploadProgress.loaded / uploadProgress.total) * 100,
    });
  };

  const processFile = async file => {
    const uploadId = guid();

    const newFile = file;
    newFile.upload_id = uploadId;

    const newFiles = [...files.current, newFile];

    setFiles(newFiles);

    const { signedRequest, url, s3url } = await signRequest(
      file.name,
      file.type
    );

    const fileData = { url, s3url, done: true };

    await uploadToS3(file, signedRequest, (uploadedFile, uploadProgress) => {
      setProgress(uploadId, uploadedFile, uploadProgress);
    });

    updateFileState(uploadId, { complete: true });

    if (file.type === 'audio/wav' || file.type === 'audio/x-wav') {
      const audioDetails = await getAudioDetails(fileData.s3url);
      updateFileState(uploadId, { meta: audioDetails });
    }

    cb(file, fileData);
  };

  const queueForUpload = newFiles =>
    new Promise(resolve => {
      Promise.all(newFiles.map(file => processFile(file))).then(values =>
        resolve(values)
      );
    });

  function reset() {
    setFiles([]);
  }

  return [files.current, queueForUpload, reset];
};

export default useFileLoader;
