import styled from 'styled-components';
import { HeadingTitle, HeadingSubtitle, ButtonText } from '../Typography';
var goLargeBreakpoint = 1445;
var goSmallBreakpoint = 450;
export var Verb = styled(ButtonText).withConfig({
  displayName: "TitleBlockstyled__Verb",
  componentId: "sc-xtcxws-0"
})(["display:block;@media (min-width:", "px){font-size:", "px;line-height:", "px;}@media (max-width:", "px){font-size:", ";line-height:", ";}"], goLargeBreakpoint, function (props) {
  return props.theme.buttonTextFontSize;
}, function (props) {
  return props.theme.buttonTextLineHeight;
}, goSmallBreakpoint, function (props) {
  return props.shouldGoSmall ? "".concat(props.theme.buttonTextFontSize * 0.75, "px") : "".concat(props.theme.buttonTextFontSize, "px");
}, function (props) {
  return props.shouldGoSmall ? "".concat(props.theme.buttonTextLineHeight * 0.75, "px") : "".concat(props.theme.buttonTextLineHeight, "px");
});
export var Title = styled(HeadingTitle).withConfig({
  displayName: "TitleBlockstyled__Title",
  componentId: "sc-xtcxws-1"
})(["@media (min-width:", "px){font-size:", "px;line-height:", "px;}@media (max-width:", "px){font-size:", ";line-height:", ";}"], goLargeBreakpoint, function (props) {
  return props.theme.headingTitleFontSize;
}, function (props) {
  return props.theme.headingTitleLineHeight;
}, goSmallBreakpoint, function (props) {
  return props.shouldGoSmall ? "".concat(props.theme.headingTitleFontSize * 0.75, "px") : "".concat(props.theme.headingTitleFontSize, "px");
}, function (props) {
  return props.shouldGoSmall ? "".concat(props.theme.headingTitleLineHeight * 0.75, "px") : "".concat(props.theme.headingTitleLineHeight, "px");
});
export var Subtitle = styled(HeadingSubtitle).withConfig({
  displayName: "TitleBlockstyled__Subtitle",
  componentId: "sc-xtcxws-2"
})(["@media (min-width:", "px){font-size:", "px;line-height:", "px;}@media (max-width:", "px){font-size:", ";line-height:", ";}"], goLargeBreakpoint, function (props) {
  return props.theme.headingSubtitleFontSize;
}, function (props) {
  return props.theme.headingSubtitleLineHeight;
}, goSmallBreakpoint, function (props) {
  return props.shouldGoSmall ? "".concat(props.theme.headingSubtitleFontSize * 0.75, "px") : "".concat(props.theme.headingSubtitleFontSize, "px");
}, function (props) {
  return props.shouldGoSmall ? "".concat(props.theme.headingSubtitleLineHeight * 0.75, "px") : "".concat(props.theme.headingSubtitleLineHeight, "px");
});
export var TitleBlockMain = styled.div.withConfig({
  displayName: "TitleBlockstyled__TitleBlockMain",
  componentId: "sc-xtcxws-3"
})(["padding:", ";background-color:", ";color:", ";max-width:500px;position:relative;"], function (props) {
  return props.hasNoBox ? 'none' : "".concat(props.theme.largeSpacer, "px\n    ").concat(props.theme.largeSpacer + props.theme.spacer, "px");
}, function (props) {
  return props.hasNoBox ? 'none' : props.theme.white;
}, function (props) {
  return props.hasNoBox ? props.theme.white : props.theme.black;
});