import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#FFF' : 'transparent'}
      d="M41.56 366.46c26.15 26.94 58.47 34.2 98.07 34.2 54.49 0 99.87-28.75 99.87-81.42 0-24.01-5.43-43.25-19.23-56.7-10.9-10.9-27.6-18.16-50.87-21.42l-31.22-4.36c-9.09-1.1-14.53-4.36-17.79-7.63a17 17 0 0 1-5.1-12c0-11.24 9.09-23.96 31.24-23.96h2c11.65-.07 31.6-.17 47.03 15.26l39.96-39.96c-22.15-22.15-50.12-30.88-87.17-30.88-58.85 0-94.43 34.52-94.43 81.74 0 22.15 5.8 39.58 17.8 51.93 11.62 11.98 29.04 19.62 51.93 22.9l31.24 4.34c8.35 1.08 13.8 3.64 17.07 6.9 3.64 4.02 5.07 9.11 5.07 15.3 0 14.89-11.97 23.24-37.04 23.24-20.7 0-44.3-4.73-57.73-18.17l-40.7 40.7z M321.81 5.02c-19.7-3.3-40.8-4.98-63.56-4.98v397.84h63.56V291.33s35.6 55.92 46.43 73.16c17.43 26.64 40.67 33.67 79.24 33.67l18.58-.04v-60.03c-3.58 0-5.26-.01-6.84-.02l-5.25-.01c-17.85 0-29.63-5.24-41.96-24.48-12.03-19.12-32.18-51.3-32.18-51.3l87.61-122.06h-76.07l-69.56 96.84V5.03z"
    />
    <path
      fill={isColour ? '#F80046' : 'currentColor'}
      d="M139.62 400.66c-39.6 0-71.92-7.27-98.06-34.2l40.7-40.7c13.42 13.44 37.03 18.17 57.72 18.17 25.07 0 37.05-8.35 37.05-23.24 0-6.18-1.44-11.28-5.08-15.3-3.26-3.26-8.71-5.81-17.07-6.9l-31.23-4.34c-22.9-3.28-40.31-10.91-51.94-22.9-12-12.34-17.8-29.78-17.8-51.92 0-47.22 35.59-81.75 94.43-81.75 37.05 0 65.02 8.73 87.17 30.89l-39.95 39.95c-16.35-16.35-37.78-15.26-49.03-15.26-22.16 0-31.24 12.72-31.24 23.97 0 3.28 1.1 8 5.1 12 3.26 3.27 8.7 6.53 17.79 7.62l31.22 4.36c23.26 3.26 39.96 10.52 50.87 21.43 13.8 13.44 19.22 32.68 19.22 56.7 0 52.66-45.38 81.42-99.87 81.42zM443.87 56.57C409.1 30.54 370.5 13.07 321.8 5.05v232l69.56-96.84h76.06l-87.6 122.06s20.15 32.19 32.18 51.3c12.32 19.25 24.1 24.48 41.96 24.48 5.33 0 5.33.03 12.09.03v60.03l-18.59.05c-38.57 0-61.8-7.04-79.23-33.68-10.83-17.24-46.43-73.16-46.43-73.16v106.55h-63.57V.04c-.8 0-1.55-.04-2.35-.04-82.17 0-138.84 19.8-187.94 56.57C-2.9 109.6-.07 180.3-.07 255.94c0 75.68-2.83 146.48 68.02 199.5C117.05 492.23 173.72 512 255.89 512s138.86-19.78 187.98-56.56c70.83-53.02 67.99-123.82 67.99-199.5 0-75.64 2.84-146.35-68-199.37z"
    />
  </svg>
);
