import { useState, useContext } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import ajax from '@helpers/ajax';
import useUser from '@hooks/useUser';
import GlobalState from 'context/GlobalState';

export interface StripeSubscriptionProps {
  product?: 'Premium' | 'Essentials' | 'PRO';
  billingPeriod?: 'month' | 'year';
  redirectTo?: string;
  // Prefilling coupon (user won't be able to change), needs to
  // be a valid coupon. Don't send user added coupon through here...
  coupon?: string;
  // Only applicable for 'PRO'
  quantities?: {
    artists: number;
    teamMembers: number;
  };
  // Reactivate a subscription that has failed payments
  reactivate?: boolean;
}

/**
 * For dealing with stripe payments
 */
const useStripeSubscription = (): {
  loading: boolean;
  subscription: any;
  disabled: boolean;
  onClick: (props: StripeSubscriptionProps) => Promise<boolean>;
} => {
  const stripe = useStripe();
  const user = useUser();
  const isSubscribed = user?.subscription?.product_name !== 'Basic';
  const [loading, setLoading] = useState(false);
  const globalState = useContext(GlobalState);

  const createPortalSession = async (
    props: StripeSubscriptionProps
  ): Promise<boolean> => {
    if (!stripe || loading) return false;
    setLoading(true);

    try {
      const session = await ajax(
        'payments/stripe/create-customer-portal-session',
        {
          redirectTo:
            props.redirectTo ||
            `${window.location.origin}${window.location.pathname}`,
        },
        'POST',
        true,
        false
      );

      window.location.assign(session.url);
      return true;
    } catch (e) {
      setLoading(false);
      console.error(e);
      toast.error(
        "There's been an issue, please contact the support team for more information"
      );
      return false;
    }
  };

  const createCheckoutSession = async (
    props: StripeSubscriptionProps
  ): Promise<boolean> => {
    if (!stripe || loading) return false;
    setLoading(true);

    try {
      // If the user already has a currency set in stripe, it can't be changed....
      const currency = user.currency || globalState.currency || 'USD';
      const session = await ajax(
        'payments/stripe/create-checkout-session',
        {
          currency,
          product: props.product || 'Essentials',
          billingPeriod: props.billingPeriod || 'month',
          redirectTo: props.redirectTo || window.location.href,
          account: user.getUserStripeAccountType(currency),
          coupon: props.coupon,
          quantities: props.quantities,
          reactivate: props.reactivate,
        },
        'POST',
        true,
        false
      );
      const res = await stripe.redirectToCheckout({ sessionId: session.id });
      if (res.error) {
        toast.error(res.error.message);
      }
      return !!res.error;
    } catch (e) {
      setLoading(false);
      console.error(e);
      toast.error(
        "There's been an issue, please contact the support team for more information"
      );
      return false;
    }
  };

  return {
    loading,
    subscription: user?.subscription,
    disabled: !stripe,
    onClick: isSubscribed ? createPortalSession : createCheckoutSession,
  };
};

export default useStripeSubscription;
