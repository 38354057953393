import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    {props.tiled ? (
      <path
        fill="#fff"
        d="M503.5 259.66a54.1 54.1 0 00-54.17-54.17 54.53 54.53 0 00-37.74 15.1c-37.3-26.64-88.35-43.96-145.19-46.18L291.27 58.1l80.8 17.31a38.65 38.65 0 0077.25-1.77A38.66 38.66 0 00410.71 35a38.6 38.6 0 00-34.63 21.76l-90.13-19.1c-2.66-.44-5.32 0-7.1 1.34a8.63 8.63 0 00-4 6.21l-27.53 129.65c-57.72 1.77-109.66 19.1-147.4 46.17a54.54 54.54 0 00-37.74-15.1A54.1 54.1 0 008 260.12c0 22.2 13.32 40.84 31.97 49.28a98.4 98.4 0 00-1.33 16.43c0 83.47 97.23 151.4 217.11 151.4 119.88 0 217.11-67.49 217.11-151.4 0-5.33-.44-11.1-1.33-16.43a54.7 54.7 0 0031.97-49.73zm-372.07 38.63a38.66 38.66 0 0138.63-38.63 38.65 38.65 0 0138.62 38.63 38.66 38.66 0 01-38.62 38.62c-21.32.45-38.63-17.31-38.63-38.63zm216.23 102.56c-26.64 26.64-77.26 28.41-91.91 28.41-15.1 0-65.72-2.21-91.91-28.41-4-4-4-10.21 0-14.2 4-4 10.21-4 14.2 0 16.88 16.86 52.4 22.64 77.7 22.64 25.31 0 61.28-5.78 77.7-22.65 4-4 10.22-4 14.22 0a10.8 10.8 0 010 14.21zm-7.11-63.5a38.65 38.65 0 01-38.63-38.62 38.66 38.66 0 0138.63-38.63 38.65 38.65 0 0138.63 38.63c0 20.87-17.32 38.63-38.63 38.63z"
      />
    ) : (
      <>
        <path
          fill={isColour ? '#FF4500' : 'currentColor'}
          d="M197.34 256a26.6 26.6 0 00-26.57 26.58c0 14.66 11.91 26.88 26.57 26.57a26.6 26.6 0 0026.58-26.57A26.6 26.6 0 00197.35 256zM256.3 372.7c10.08 0 44.91-1.22 63.24-19.55a7.43 7.43 0 000-9.78 6.83 6.83 0 00-9.78 0c-11.3 11.6-36.04 15.58-53.46 15.58-17.41 0-41.85-3.97-53.46-15.58a6.83 6.83 0 00-9.78 0 6.83 6.83 0 000 9.78c18.03 18.02 52.86 19.55 63.24 19.55zM288.07 282.88a26.6 26.6 0 0026.58 26.58c14.67 0 26.58-12.22 26.58-26.58a26.6 26.6 0 00-26.58-26.57 26.6 26.6 0 00-26.57 26.57z"
        />
        <path
          fill={isColour ? '#FF4500' : 'currentColor'}
          fillRule="evenodd"
          d="M512 256c0 141.38-114.62 256-256 256S0 397.38 0 256 114.61 0 256 0c141.38 0 256 114.61 256 256zm-122.5-37.27A37.22 37.22 0 01426.77 256c0 15.27-9.17 28.41-22 34.22.61 3.66.92 7.63.92 11.3 0 57.74-66.9 104.17-149.38 104.17-82.49 0-149.39-46.74-149.39-104.17 0-3.97.3-7.64.92-11.3a37.22 37.22 0 0115.27-71.18 37.53 37.53 0 0125.97 10.39c25.96-18.64 61.7-30.55 101.42-31.78l18.94-89.2a5.94 5.94 0 012.75-4.28c1.22-.91 3.05-1.22 4.89-.91l62.01 13.13a26.56 26.56 0 0123.83-14.97A26.6 26.6 0 01389.5 128a26.6 26.6 0 01-53.16 1.22l-55.6-11.91-17.1 80.04c39.1 1.52 74.23 13.44 99.9 31.77a37.52 37.52 0 0125.96-10.39z"
        />
        <path
          fill={isColour ? '#fff' : 'transparent'}
          d="M426.77 256a37.22 37.22 0 00-37.27-37.27 37.52 37.52 0 00-25.97 10.39c-25.66-18.33-60.8-30.25-99.9-31.77l17.11-80.04 55.6 11.91A26.6 26.6 0 00389.5 128a26.6 26.6 0 00-26.57-26.58 26.57 26.57 0 00-23.83 14.97l-62.01-13.13c-1.84-.3-3.67 0-4.9.91a5.94 5.94 0 00-2.74 4.28l-18.94 89.2c-39.71 1.23-75.46 13.14-101.42 31.78a37.53 37.53 0 00-25.97-10.4 37.22 37.22 0 00-15.27 71.19 67.73 67.73 0 00-.92 11.3c0 57.43 66.9 104.17 149.39 104.17 82.48 0 149.38-46.43 149.38-104.17 0-3.67-.3-7.64-.92-11.3a37.63 37.63 0 0022-34.22zm-256 26.58A26.6 26.6 0 01197.34 256a26.6 26.6 0 0126.58 26.58 26.6 26.6 0 01-26.57 26.57c-14.67.31-26.58-11.91-26.58-26.57zm148.77 70.57c-18.33 18.33-53.16 19.55-63.24 19.55-10.38 0-45.21-1.53-63.24-19.55a6.83 6.83 0 010-9.78 6.83 6.83 0 019.78 0c11.61 11.6 36.05 15.58 53.46 15.58 17.42 0 42.16-3.97 53.46-15.58a6.83 6.83 0 019.78 0 7.43 7.43 0 010 9.78zm-4.89-43.69a26.6 26.6 0 01-26.57-26.58 26.6 26.6 0 0126.57-26.57 26.6 26.6 0 0126.58 26.57c0 14.36-11.91 26.58-26.58 26.58z"
        />
      </>
    )}
  </svg>
);
