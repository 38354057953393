import React from 'react';
import { useLocation } from '@hooks/useRouting';
import useUser from '@hooks/useUser';
import AuthenticatedRoutes from 'routing/AuthenticatedRoutes';
import PageLoader from '@ui/components/PageLoader';
import Toast from 'components/common/Toast';
import Header from 'components/app/Header';
import useAppLoadHook from '@hooks/useAppLoadHook';
import useWebSockets from '@hooks/useWebSockets';
import useSetActiveArtistFromUrl from '@hooks/useSetActiveArtistFromUrl';
import ModalWrapper from 'components/app/ModalWrapper';
import AppUtilities from 'AppUtilities';
import StripeElementsProvider from 'components/app/StripeElementsProvider';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { launchDarklyClientSideID } from 'config/backend.config';
import { AppWrapper } from './AuthenticatedApp.styled';

const whiteListedPaths = ['/oauth', '/confirmation'];
const hiddenHeaderPaths = ['/invite-code'];

const AuthenticatedApp = () => {
  const { loggedIn, userId, name, email } = useUser();
  const { loadingUser, waitForWebhooks } = useAppLoadHook();
  const location = useLocation();

  useWebSockets();
  useSetActiveArtistFromUrl();

  if (waitForWebhooks) {
    return <PageLoader isFullScreen message="Updating subscription" />;
  }

  const pathStartsWith = (el: string) =>
    location.pathname.toLowerCase().startsWith(el);

  const shouldShowHeader = !hiddenHeaderPaths.some(pathStartsWith);

  if (loadingUser && !whiteListedPaths.some(pathStartsWith)) {
    return <PageLoader isFullScreen message="Loading your account" />;
  }

  const ldClient = useLDClient();

  if (loggedIn && userId) {
    const previousUser = ldClient?.getUser();
    const newUser = {
      key: userId.toString(),
      name,
      email,
    };
    if (previousUser) {
      ldClient?.alias(newUser, previousUser);
    }
    ldClient?.identify(newUser);
  }

  return (
    <AppUtilities>
      <AppWrapper>
        <StripeElementsProvider>
          {shouldShowHeader && <Header />}
          <AuthenticatedRoutes />
          <ModalWrapper />
          <Toast />
        </StripeElementsProvider>
      </AppWrapper>
    </AppUtilities>
  );
};

export default withLDProvider({
  clientSideID: launchDarklyClientSideID,
})(AuthenticatedApp);
