import React, { useState, useEffect, TextareaHTMLAttributes } from 'react';
import { debounce } from 'lodash';
import {
  Wrapper,
  Label,
  Input,
  AutoSizeInput,
  BodyTextWithLineBreaks,
  HeadingSmallWithLineBreaks,
} from './TextArea.styled';

export interface TextAreaProps
  extends Omit<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    'onChange' | 'style'
  > {
  isLocked?: boolean;
  isTextSolid?: boolean;
  label?: string;
  isResizable?: boolean;
  numLines?: number;
  minWidth?: number;
  value?: string;
  onChange?: (value: string) => void;
  isAutoGrow?: boolean;
  minRows?: number;
  maxRows?: number;
  onHeightChange?: (height: number) => void;
  dark?: boolean;
  isInBox?: boolean;
}

/**
 * Styles a native TextArea and adds additional functionality such as auto grow
 */
const TextArea = ({
  isLocked,
  label,
  numLines = 3,
  className,
  minWidth,
  isResizable,
  placeholder,
  onChange,
  value,
  isAutoGrow,
  minRows,
  maxRows,
  onHeightChange,
  isTextSolid,
  dark,
  ...props
}: TextAreaProps) => {
  const [localValue, setLocalValue] = useState(value || '');
  const handleChange = e => setLocalValue(e.target.value);

  const propagateChange = debounce(() => {
    if (onChange) {
      onChange(localValue);
    }
  }, 250);

  useEffect(() => {
    propagateChange();
  }, [localValue]);

  return (
    <Wrapper className={className}>
      {label && <Label>{label}</Label>}
      {isLocked ? (
        <>
          {isTextSolid ? (
            <HeadingSmallWithLineBreaks>{value}</HeadingSmallWithLineBreaks>
          ) : (
            <BodyTextWithLineBreaks>{value}</BodyTextWithLineBreaks>
          )}
        </>
      ) : (
        <>
          {isAutoGrow ? (
            <AutoSizeInput
              minRows={minRows}
              maxRows={maxRows}
              minWidth={minWidth}
              isResizable={isResizable}
              onChange={handleChange}
              onHeightChange={onHeightChange}
              isLight={!dark}
              {...props}
              value={localValue}
              placeholder={placeholder}
            />
          ) : (
            <Input
              numLines={numLines}
              minWidth={minWidth}
              isResizable={isResizable}
              onChange={handleChange}
              isLight={!dark}
              {...props}
              value={localValue}
              placeholder={placeholder}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default TextArea;
