import React from 'react';
import { map } from 'lodash';
import { toast } from 'react-toastify';
import useDevice from '@hooks/useDevice';
import { MediaLibraryItem, MediaLibraryProps, OpenAlertFn } from '@ui/types';
import trackEvent from '@helpers/tracking';
import Button from '@ui/components/Button';
import Loader from '@ui/components/Loader';
import VideoPlayer from '@ui/components/VideoPlayer';
import SelectCreatable from '@ui/components/SelectCreatable';
import Scrollbars from 'components/app/Scrollbars';
import { EquallySpacedColumn } from 'components/common/EquallySpacedColumn';
import Dialogue from '@ui/components/Dialogue';
import { getUserFileName } from '@helpers/getUserFileNameUrl';
import { getMediaItemThumbnail } from '@helpers/getMediaItemThumbnail';
import {
  CircularProgress,
  Image,
  Audio,
  ButtonsWrapper,
  ImageBackground,
  ImagePreview,
  MediaPreviewWrapper,
  WrapperSection,
} from './MediaModal.styled';
import MediaItemMetaInformation from '../MediaItemMeta/MediaItemMeta';

interface Props {
  new?: boolean;
  currentMediaItem: MediaLibraryItem;
  setCurrentMediaItem: (item: MediaLibraryItem | null) => void;
  handleInsert?: () => void;
  deleteSelectedItem: (id: string) => void;
  collections: string[];
  tags: string[];
  updateTags: (tags: string[]) => void;
  updateCollections: (collections: string[]) => void;
  addToSelectedItems: (id: string) => void;
  openAlert: OpenAlertFn;
  loading: boolean;
}

const MediaModal = (props: Props & MediaLibraryProps) => {
  const { isMobile } = useDevice();

  const uploaded = !!props.currentMediaItem.url;
  const { fileType, fileSize } = props.currentMediaItem.meta;
  const { loaded } = props.currentMediaItem;
  const progress = fileSize && loaded ? (loaded / fileSize) * 100 : 1;

  // const [isEditorOpen, setIsEditorOpen] = useState(false);

  const handleTagChange = tags => {
    const tagsArray = map(tags, tag => tag.value);
    props.updateTags(tagsArray);
  };

  const handleCollectionChange = collections => {
    const collectionsArray = map(collections, collection => collection.value);
    props.updateCollections(collectionsArray);
  };

  /*  const openMediaLibraryWithItemSelected = () => {
    props.setCurrentMediaItem(null);
    props.addToSelectedItems(props.currentMediaItem.media_id);
  }; */

  /* const onCompleteEdit = () =>
    props.isSelectMode
      ? openMediaLibraryWithItemSelected()
      : props.closeClickHandler(); */

  const deleteMediaItem = () => {
    props.deleteSelectedItem(props.currentMediaItem.media_id);
    toast.success('Media deleted 👋');
    trackEvent('Media Library', {
      event: 'item deleted',
      id: props.currentMediaItem.media_id,
    });
    props.setCurrentMediaItem(null);
  };

  const onDeleteButtonClick = () => {
    props.openAlert(
      'Delete',
      'Are you sure you want to delete this media item?',
      Dialogue,
      {
        actionButton: {
          text: 'Delete',
          dataTestId: 'media-item-delete',
          clickHandler: deleteMediaItem,
        },
        noPadding: true,
      }
    );
  };

  const onInsertButtonClick = () => {
    props.addToSelectedItems(props.currentMediaItem.media_id);
    props.handleInsert!();
  };

  /* const onSaveButtonClick = () => {
    if (props.new) props.uploadMediaItem(props.currentMediaItem);
    else props.updateMediaItem(props.currentMediaItem);
    toast.success('Changes saved 👏');

    trackEvent('Media Library', {
      ...props.currentMediaItem,
      event: `item ${props.new ? 'uploaded' : 'updated'}`,
    });
    if (props.onSave) props.onSave(props.currentMediaItem);

    if (!props.isPreview) onCompleteEdit();
  }; */

  /* const shareLink = () => {
    openExternalWebpage(props.currentMediaItem.url);
  }; */

  /* const updateFile = (file, fileData, mediaData?) => {
    const updatedMediaItem = {
      ...props.currentMediaItem,
      ...mediaData,
      ...file,
      loaded: fileData.done
        ? props.currentMediaItem.meta?.fileSize
        : fileData.loaded,
      url: fileData.s3url,
    };
    props.setCurrentMediaItem(updatedMediaItem);
    props.onSave?.(updatedMediaItem);
  }; */

  /* const onImageEdit = async file => {
    props.setCurrentMediaItem({ ...props.currentMediaItem, url: '' });
    const { signedRequest, url, s3url } = await signRequest(
      `${props.currentMediaItem.name} copy`,
      file.type
    );

    const fileData = { url, s3url, done: true };
    const mediaData = {
      meta: {
        fileSize: file.size,
        fileType: 'image',
        fileExtension: 'jpeg',
      },
    };
    await uploadToS3(file, signedRequest, updateFile);
    updateFile(file, fileData, mediaData);
  }; */

  // until Image Editor is fixed
  // const handleEditButtonClick = () => {
  //   setIsEditorOpen(true);
  //   trackEvent('Image editor open');
  // };

  /* const updateFileName = value =>
    props.setCurrentMediaItem({ ...props.currentMediaItem, name: value }); */
  return (
    <>
      <Scrollbars>
        <WrapperSection>
          <MediaPreviewWrapper isMobile={isMobile}>
            {!uploaded && props.new && <CircularProgress value={progress} />}

            {!uploaded && !props.new && <Loader />}
            <ImageBackground>
              {fileType === 'image' && (
                <ImagePreview isImage={fileType === 'image'}>
                  <Image
                    src={
                      getMediaItemThumbnail(
                        props.currentMediaItem as MediaLibraryItem,
                        'medium'
                      ) || props.currentMediaItem.url
                    }
                    alt={
                      props.currentMediaItem.meta.fileType === 'image'
                        ? props.currentMediaItem.name
                        : ''
                    }
                  />
                </ImagePreview>
              )}
              {fileType === 'audio' && (
                <Audio src={props.currentMediaItem.url} controls>
                  Your browser does not support the
                  <code> audio </code>
                  element.
                </Audio>
              )}

              {fileType === 'video' && (
                <VideoPlayer
                  url={props.currentMediaItem.url}
                  thumbnailUrl={props.currentMediaItem.thumbnails?.medium}
                />
              )}
            </ImageBackground>
            <ButtonsWrapper>
              {props.isSelectMode && isMobile && (
                <Button
                  text="Insert"
                  onPress={onInsertButtonClick}
                  loading={props.loading}
                />
              )}
              <Button
                icon="delete"
                iconOnly
                dark
                small
                onPress={onDeleteButtonClick}
                dataTestId="open-delete-modal"
              />
            </ButtonsWrapper>
            <EquallySpacedColumn>
              <MediaItemMetaInformation
                name={
                  props.currentMediaItem.file_name
                    ? props.currentMediaItem.file_name
                    : getUserFileName(props.currentMediaItem.url)
                }
                currentMediaItemMeta={props.currentMediaItem.meta}
                url={props.currentMediaItem.url}
                isScrapped={props.currentMediaItem.isScrapped}
              />
              <SelectCreatable
                label="Tags"
                defaultValue={map(props.currentMediaItem.tags, item => ({
                  value: item,
                  label: item,
                }))}
                options={map(props.tags, item => ({
                  value: item,
                  label: item,
                }))}
                onChange={handleTagChange}
                placeholder="Add a tag"
                menuPlacement="top"
                isLight
              />
              <SelectCreatable
                label="Collections"
                defaultValue={map(props.currentMediaItem.collections, item => ({
                  value: item,
                  label: item,
                }))}
                options={map(props.collections, item => ({
                  value: item,
                  label: item,
                }))}
                onChange={handleCollectionChange}
                placeholder="Add to a collection"
                menuPlacement="top"
                isLight
              />
            </EquallySpacedColumn>
          </MediaPreviewWrapper>
        </WrapperSection>
      </Scrollbars>
    </>
  );
};

export default MediaModal;
