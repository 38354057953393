import React from 'react';
import PageLoader from '@ui/components/PageLoader';
import universal from 'react-universal-component';
import OAuthPage from 'pages/oauth';

// Sometimes happens right after an upgrade...
const importError = e => {
  console.warn({ e, message: e.message });
  window.location.reload();
};

export const Oauth = OAuthPage;

export const TopTracks = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/top-tracks').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Posts = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/posts').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Post = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/posts/[id]').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Playlists = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/playlists').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Playlist = universal(
  () =>
    import(
      /* webpackChunkName: 'dashboard' */ './insights/playlists/[id]'
    ).catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const PotentialPlaylists = universal(
  () =>
    import(
      /* webpackChunkName: 'dashboard' */ './insights/potential-playlists'
    ).catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const RelatedArtists = universal(
  () =>
    import(
      /* webpackChunkName: 'dashboard' */ './insights/related-artists'
    ).catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Artist = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/artists/[id]').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Map = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/map').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Facebook = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/facebook').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Instagram = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/instagram').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Spotify = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/spotify').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Twitter = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/twitter').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const Insights = universal(
  () => import('./insights').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);

export const SocialProfiles = universal(
  () => import('./social-profiles').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Social Profiles" />,
  }
);

export const ArtistProfile = universal(
  () => import('./artist-profile').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Profile" />,
  }
);

export const UserProfile = universal(
  () => import('./user-profile').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Member Profile" />,
  }
);

export const TeamMembers = universal(
  () => import('./team-members').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Team Members" />,
  }
);

export const LaunchPad = universal(
  () => import('./launchpad').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Launchpad" />,
  }
);

export const DevSandbox = universal(() => import('./dev').catch(importError), {
  loading: <PageLoader isFullScreen />,
});

export const Distribution = universal(
  () => import('./distribution').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Distribution" />,
  }
);

export const Royalties = universal(
  () => import('./royalties').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Streaming Royalties" />,
  }
);

export const Partners = universal(
  () => import('./partners').catch(importError),
  {
    loading: <PageLoader isFullScreen />,
  }
);

export const InviteCode = universal(
  () => import('./invite-code').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Sending Invite Code" />,
  }
);

export const FanBuilder = universal(
  () => import('./fan-builder').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Fan Builder" />,
  }
);

export const WebsiteBuilder = universal(
  () => import('./website-builder').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading Website Builder" />,
  }
);

export const HypeLinks = universal(
  () => import('./hypelinks').catch(importError),
  {
    loading: <PageLoader isFullScreen message="Loading HypeLinks" />,
  }
);

export const ContributorSplits = universal(
  () => import('./splits').catch(importError),
  {
    loading: <PageLoader isFullScreen />,
  }
);

export const Onboarding = universal(
  () => import('./onboarding').catch(importError),
  {
    loading: <PageLoader isFullScreen />,
  }
);

export const SmartDashboards = universal(
  () => import('./smart-dashboards').catch(importError),
  {
    loading: <PageLoader isFullScreen />,
  }
);

export const DiscographyContainer = universal(
  () => import('./discography').catch(importError),
  {
    loading: <PageLoader isFullScreen />,
  }
);

export const TrackInsights = universal(
  () =>
    import(/* webpackChunkName: 'dashboard' */ './insights/tracks/[id]').catch(
      importError
    ),
  {
    loading: <PageLoader isFullScreen message="Loading Artist Insights" />,
  }
);
