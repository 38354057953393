import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#00B4B3' : 'currentColor'}
      d="M388.64 21.33h102.03V256H388.64V21.33zm-122.43 97.78h102.02V256H266.2V119.11zm-122.46 0h102.02V256h-102V119.11h-.02zm346.92 371.56H21.33V21.33h102.03V392.9h265.28v-19.56H143.75v-97.77h346.92v215.1z"
    />
  </svg>
);
