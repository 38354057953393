import React, { useState } from 'react';
import Dashboard from 'components/infographics/Dashboard';
import { HeadingLabel } from '@ui/components/Typography';
import DashboardRow from 'components/infographics/DashboardRow';
import { Box } from 'components/common/Box/Box.styled';
import ChecklistItem, { Item } from '../ChecklistItem';

interface Props {
  data: {
    title: string;
    items: Item[];
  }[];
}

const ChecklistLanding = (props: Props) => {
  const [data, setData] = useState(props.data || []);

  const setIsChecked = (itemName: string, isChecked: boolean) => {
    const newData = data.map(section => ({
      title: section.title,
      items: section.items.map(item =>
        item.name === itemName ? { ...item, ...{ isChecked } } : item
      ),
    }));
    setData(newData);
  };

  return (
    <>
      {data.map((section, sectionIndex) => (
        <Box background="darkShade" key={`${section.title}-${sectionIndex}`}>
          <HeadingLabel>{section.title}</HeadingLabel>
          <Dashboard>
            {section?.items?.map((item, i) => (
              <DashboardRow key={`${item.title}-${i}`} background="black">
                <ChecklistItem {...item} setIsChecked={setIsChecked} />
              </DashboardRow>
            ))}
          </Dashboard>
        </Box>
      ))}
    </>
  );
};

export default ChecklistLanding;
