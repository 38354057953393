import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy, orderBy } from 'lodash';
import { Artist } from '@ui/types';
import { cookieExists } from '@helpers/checkAndSetCookie';
import { isWindowExists } from '@helpers/isWindowExists';
import { getMediaItemThumbnail } from '@helpers/getMediaItemThumbnail';
import { getCDNUrl } from '@helpers/getCDNUrl';
import { asyncActionState } from '../asyncActionState';
import { logoutSuccess, resetStore } from '../auth';
import {
  FeedAction,
  FeedDataAction,
  FetchUserMetadataPayload,
  UpdateArtistAction,
  UpdateUserAction,
  UserState,
} from './types';

const initialState: UserState = {
  trialOfferDismissed: cookieExists('trial_offer_dismissed'),
  userId: 0,
  email: '',
  name: '',
  artists: [],
  activeArtistId: null,
  activeArtist: null,
  FETCH_USER_METADATA: { ...asyncActionState },
  currency: isWindowExists ? window.beatchain_currency : undefined,
  feed: null,
};

// Selectors
export const getUser = state => state.user;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserMetadata: {
      reducer: state => ({
        ...state,
        FETCH_USER_METADATA: {
          fetching: true,
          error: null,
        },
      }),
      prepare: (payload?: FetchUserMetadataPayload) => ({ payload }),
    },
    fetchUserMetadataError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      FETCH_USER_METADATA: {
        fetching: false,
        error: payload,
      },
    }),
    fetchUserMetadataSuccess: (state, { payload }) => ({
      ...state,
      ...payload,
      FETCH_USER_METADATA: {
        fetching: false,
        error: null,
      },
    }),
    fetchUserFeed: (state, { payload }: PayloadAction<{ type: string }>) =>
      payload.type === 'initial'
        ? {
            ...state,
            feed: null,
          }
        : state,
    dismissFeedItem: (state, { payload }: FeedDataAction) => ({
      ...state,
      feed: {
        ...state.feed,
        data: state.feed?.data?.filter(
          el =>
            !(
              el.act_id === payload.act_id &&
              el.news_feed_id === payload.news_feed_id
            )
        ),
      },
    }),
    markFeedItemAsSeen: (state, { payload }: FeedDataAction) => ({
      ...state,
      feed: {
        ...state.feed,
        data: state.feed?.data?.map(el =>
          el.act_id === payload.act_id &&
          el.news_feed_id === payload.news_feed_id
            ? {
                ...el,
                seen: true,
              }
            : el
        ),
      },
    }),
    liveUserFeed: (state, { payload }: FeedAction) => {
      let items = (state.feed?.data || []).concat(payload.data || []);
      items = uniqBy(items, entry =>
        entry.category_id === 'welcome' ? entry.copy : entry.news_feed_id
      );
      items = orderBy(items, 'date_ts', 'desc').map(item => ({
        ...item,
        act_name:
          item.act_name ||
          state.artists?.find(el => el.actId === item.act_id)?.actName,
        profile_image:
          getMediaItemThumbnail(
            state.artists?.find(el => el.actId === item.act_id)?.profile_image,
            'square'
          ) ||
          getCDNUrl(item.profile_image) ||
          getCDNUrl(
            state.artists?.find(el => el.actId === item.act_id)?.appProfileImage
          ),
      }));

      const feed = {
        data: items,
        has_more: payload.has_more ?? state.feed?.has_more,
      };
      return {
        ...state,
        feed,
      };
    },
    setActiveArtist: (state, { payload }: PayloadAction<{ actId: string }>) =>
      state.artists
        ? {
            ...state,
            activeArtist: state.artists.find(
              artist => artist.actId === payload.actId
            ) as Artist,
            activeArtistId: payload.actId,
          }
        : state,
    setTrialOfferDismissed: state => ({
      ...state,
      trialOfferDismissed: true,
    }),
    updateUserState: (state, { payload }: UpdateUserAction) => ({
      ...state,
      ...payload,
    }),
    updateArtistState: (state, { payload }: UpdateArtistAction) => ({
      ...state,
      artists: state.artists.map(artist =>
        artist.actId === payload.actId ? { ...artist, ...payload.data } : artist
      ),
      activeArtist:
        state.activeArtist?.actId === payload.actId
          ? { ...state.activeArtist, ...payload.data }
          : state.activeArtist,
    }),
  },
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(logoutSuccess, resetStore), state => ({
        ...initialState,
        activeArtistId: state.activeArtistId,
      }))
      .addDefaultCase(state => state);
  },
});

export const { actions, reducer } = userSlice;

export const {
  fetchUserMetadata,
  fetchUserMetadataError,
  fetchUserMetadataSuccess,
  fetchUserFeed,
  dismissFeedItem,
  markFeedItemAsSeen,
  liveUserFeed,
  setActiveArtist,
  setTrialOfferDismissed,
  updateUserState,
  updateArtistState,
} = actions;
