import { formatAccountBalanceToNumber } from './formatAccountBalance';

/**
 * Gets a message to show to a user when they try to get a payout
 */
export const getPayoutsMessage = (
  balance: number,
  totalPending: number,
  withdrawAmount: string,
  hasPayoutMethod: boolean
) => {
  const hasPendingPayout = totalPending > 0;

  const withdrawAmountAsNumber = formatAccountBalanceToNumber(withdrawAmount);
  const currencyRegex =
    /[$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]/;

  const insufficientFunds = balance < 25;
  const overDrawn = !insufficientFunds && withdrawAmountAsNumber > balance;
  const cantWithdraw = !insufficientFunds && withdrawAmountAsNumber < 25;
  const invalidInputSymbol = currencyRegex.test(withdrawAmount);

  const withdrawMessage =
    (insufficientFunds &&
      'Insufficient funds - minimum withdrawal balance is €25.') ||
    (overDrawn &&
      hasPendingPayout &&
      cantWithdraw &&
      'Insufficient funds after pending payouts - minimum withdrawal amount is €25.') ||
    (overDrawn &&
      hasPendingPayout &&
      'Insufficient funds after pending payouts.') ||
    (cantWithdraw && 'Minimum withdrawal amount is €25.') ||
    (overDrawn && !hasPendingPayout && 'Amount exceeds your balance.') ||
    (!hasPayoutMethod && 'Please add a payout method.') ||
    (invalidInputSymbol && 'Please use only numbers.') ||
    '';

  return withdrawMessage;
};
