import styled from 'styled-components';
import { transparentize } from 'polished';
import { Heading, BodyText } from '../Typography';
export var ClickWrapper = styled.span.withConfig({
  displayName: "Iconstyled__ClickWrapper",
  componentId: "sc-14l6p6k-0"
})(["cursor:pointer;display:flex;justify-content:center;align-items:center;width:max-content;", " &:hover{background-color:", ";box-shadow:0 0 0 3px ", ";}"], function (props) {
  return props.disabled && "opacity: 0.5;\n    pointer-events: none;";
}, function (props) {
  return transparentize(0.95, props.theme.vdgrey);
}, function (props) {
  return transparentize(0.95, props.theme.vdgrey);
});
export var StoryRow = styled.div.withConfig({
  displayName: "Iconstyled__StoryRow",
  componentId: "sc-14l6p6k-1"
})(["display:flex;flex-direction:row;justify-content:flex-start;flex-wrap:wrap;margin-bottom:", "px;"], function (props) {
  return props.theme.spacer;
});
export var StoryTitle = styled(Heading).withConfig({
  displayName: "Iconstyled__StoryTitle",
  componentId: "sc-14l6p6k-2"
})(["margin-bottom:", "px;"], function (props) {
  return props.theme.spacer;
});
export var StoryItem = styled.div.withConfig({
  displayName: "Iconstyled__StoryItem",
  componentId: "sc-14l6p6k-3"
})(["display:flex;flex-direction:row;align-items:center;width:25%;margin-bottom:", "px;"], function (props) {
  return props.theme.smallSpacer;
});
export var StoryItemName = styled(BodyText).withConfig({
  displayName: "Iconstyled__StoryItemName",
  componentId: "sc-14l6p6k-4"
})(["margin-left:", "px;"], function (props) {
  return props.theme.spacer;
});