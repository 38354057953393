import { createSlice } from '@reduxjs/toolkit';
import { browserHistory } from 'browserHistory';
import { asyncActionState } from '../asyncActionState';
import {
  AuthState,
  LoginPayload,
  LoginSuccessAction,
  SessionAction,
  SignupPayload,
} from './types';

const initialState: AuthState = {
  session: null,
  mustChangePassword: false,
  signInAttemptPayload: null,
  mfaChallenge: false,
  LOGIN: { ...asyncActionState },
  FORCE_CHANGE_PASSWORD: { ...asyncActionState },
  REQUEST_RESET_PASSWORD: { ...asyncActionState },
  RESET_PASSWORD: { ...asyncActionState, result: null },
  SIGNUP: { ...asyncActionState, result: null },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: {
      reducer: state => ({
        ...state,
        LOGIN: { result: null, error: null, fetching: true },
      }),
      prepare: (payload: LoginPayload) => ({ payload }),
    },
    loginFetching: state => ({
      ...state,
      LOGIN: { result: null, error: null, fetching: true },
    }),
    loginSuccess: (state, { payload }: LoginSuccessAction) => ({
      ...state,
      email:
        payload.signInUserSession &&
        payload.signInUserSession.getIdToken &&
        payload.signInUserSession.getIdToken().payload.email,
      session: payload.signInUserSession,
      LOGIN: { fetching: false, error: null },
    }),
    refreshCognitoUser: (state, { payload }: LoginSuccessAction) => ({
      ...state,
      session: payload.signInUserSession,
    }),
    mustChangePassword: (state, { payload }) => ({
      ...state,
      LOGIN: { fetching: false, error: null },
      signInAttemptPayload: payload,
      mustChangePassword: true,
    }),
    mfaChallenge: (state, { payload }) => ({
      ...state,
      LOGIN: { fetching: false, error: null },
      signInAttemptPayload: payload,
      mfaChallenge: true,
    }),
    loginError: (state, { payload }) => ({
      ...state,
      LOGIN: { fetching: false, error: payload },
    }),
    loginClearStatus: state => {
      const { doNotClear, ...error } = state.SIGNUP?.error || {};
      const SIGNUP = doNotClear
        ? { ...initialState.SIGNUP, ...state.SIGNUP, error }
        : { ...initialState.SIGNUP };
      return {
        ...state,
        mustChangePassword: false,
        mfaChallenge: false,
        LOGIN: { ...initialState.LOGIN },
        FORCE_CHANGE_PASSWORD: { ...initialState.FORCE_CHANGE_PASSWORD },
        RESET_PASSWORD: { ...initialState.RESET_PASSWORD },
        REQUEST_RESET_PASSWORD: { ...initialState.REQUEST_RESET_PASSWORD },
        SIGNUP,
      };
    },
    forceChangePassword: {
      reducer: state => ({
        ...state,
        FORCE_CHANGE_PASSWORD: { fetching: true },
      }),
      prepare: payload => ({ payload }),
    },
    forceChangePasswordSuccess: (state, { payload }: SessionAction) => ({
      ...state,
      email: payload.getIdToken().payload.email,
      session: payload,
      FORCE_CHANGE_PASSWORD: { fetching: false },
    }),
    forceChangePasswordError: state => ({
      ...state,
      FORCE_CHANGE_PASSWORD: { fetching: false },
    }),
    requestResetPassword: {
      reducer: state => ({
        ...state,
        REQUEST_RESET_PASSWORD: { error: null, fetching: true },
      }),
      prepare: payload => ({ payload }),
    },
    requestResetPasswordSuccess: (state, { payload }) => ({
      ...state,
      email: payload.email,
      REQUEST_RESET_PASSWORD: { error: null, fetching: false },
    }),
    requestResetPasswordError: (state, { payload }) => ({
      ...state,
      REQUEST_RESET_PASSWORD: {
        error: payload,
        fetching: false,
      },
    }),
    resetPassword: {
      reducer: state => ({
        ...state,
        RESET_PASSWORD: { error: null, fetching: true, result: null },
      }),
      prepare: payload => ({ payload }),
    },
    resetPasswordSuccess: {
      reducer: state => {
        setTimeout(() => {
          browserHistory.push('/signin');
        }, 2500);
        return {
          ...state,
          RESET_PASSWORD: { error: null, fetching: false, result: 'success' },
        };
      },
      prepare: payload => ({ payload }),
    },
    resetPasswordError: (state, { payload }) => ({
      ...state,
      RESET_PASSWORD: { error: payload, fetching: false, result: 'error' },
    }),
    signup: {
      reducer: state => ({
        ...state,
        SIGNUP: { error: null, fetching: true },
      }),
      prepare: (payload: SignupPayload) => ({ payload }),
    },
    signupError: (state, { payload }) => ({
      ...state,
      SIGNUP: { error: payload, fetching: false },
    }),
    signupSuccess: state => ({
      ...state,
      SIGNUP: { error: null, fetching: false },
    }),
    logout: state => ({
      ...state,
      loggingOut: true,
    }),
    logoutSuccess: () => initialState,
    resetStore: state => ({
      ...initialState,
      session: state.session,
      email: state.session?.getIdToken().payload.email,
    }),
  },
  extraReducers: builder => {
    builder.addDefaultCase(state => state);
  },
});

export const { actions, reducer } = authSlice;

export const {
  login,
  loginFetching,
  loginError,
  loginSuccess,
  refreshCognitoUser,
  mustChangePassword,
  mfaChallenge,
  loginClearStatus,
  forceChangePassword,
  forceChangePasswordSuccess,
  forceChangePasswordError,
  requestResetPassword,
  requestResetPasswordSuccess,
  requestResetPasswordError,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordError,
  signup,
  signupError,
  signupSuccess,
  logout,
  logoutSuccess,
  resetStore,
} = actions;
