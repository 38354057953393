import React from 'react';
import { Auth } from '@aws-amplify/auth';
import { logoutSuccess } from 'store/auth';
import { saveState } from 'localStorage';
import { useDispatch } from '@hooks/useStore';
import { useSearchParams } from '@hooks/useRouting';
import { isWindowExists } from '@helpers/isWindowExists';

type DataType = { type: string; data: Record<string, string> };

const EventListeners = React.memo(() => {
  const dispatch = useDispatch();
  const [search] = useSearchParams();

  // To allow user impersonations from admin.beatchain.com
  // TODO: THIS IS VERY ERROR PRONE AND COMPLICATED
  // DO THE CUSTOM AUTH CHALLENGE DIRECTLY ON THE UI
  // https://aws.amazon.com/blogs/mobile/implementing-passwordless-email-authentication-with-amazon-cognito/
  async function adminMessageListener(event: MessageEvent<DataType>) {
    if (event.data.type !== 'impersonation') return;

    if (
      event.origin !== 'https://admin.beatchain.com' &&
      event.origin !== 'https://dev.admin.beatchain.com' &&
      process.env.REACT_APP_IMPERSONATION !== 'true'
    ) {
      return;
    }

    await Auth.signOut();

    dispatch(logoutSuccess());

    Object.entries(event.data.data).forEach(([k, v]) =>
      localStorage.setItem(k, v)
    );

    localStorage.setItem('impersonation', 'true');

    const session = await Auth.currentSession();
    saveState({ auth: { session } });

    window.location.reload();
  }

  if (isWindowExists) {
    window.addEventListener('message', adminMessageListener, false);
    const referralCode =
      search.get('referral_code') || search.get('promo_code');
    if (referralCode) {
      window.localStorage.setItem('referral_code', referralCode as string);
    }
  }

  return <div id="event-listeners" />;
});

export default EventListeners;
