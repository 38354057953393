import React, { FC } from 'react';
import { use100vh } from 'react-div-100vh';
import useDevice from '@hooks/useDevice';
import { FeaturePageMain, FeaturePageContent } from './FeaturePage.styled';

interface Props {
  isScrollable?: boolean;
}

/**
 * Layout component for feature pages
 */
const FeaturePage: FC<Props> = props => {
  const { isMobile } = useDevice();
  const height = use100vh();

  return (
    <FeaturePageMain
      isMobile={isMobile}
      height={height}
      isScrollable={props.isScrollable}
    >
      <FeaturePageContent isMobile={isMobile}>
        {props.children}
      </FeaturePageContent>
    </FeaturePageMain>
  );
};

export default FeaturePage;
