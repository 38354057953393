import api from '@helpers/api';
import { apiBase } from 'config/backend.config';
import { Method } from '@ui/types';

/**
 * Function to make ajax request
 */
const ajax = async (
  endpoint: string,
  params?: { [key: string]: any },
  method?: Method,
  auth = true,
  displayError?: boolean
) => {
  const result = await api({
    endpoint: `${apiBase}/${endpoint}`,
    params,
    method,
    auth,
    displayError,
  });

  if (!result) return [];
  return result;
};

export default ajax;
