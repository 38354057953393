export interface AsyncActionType {
  fetching: boolean;
  error?: any;
  result?: any;
}

export const asyncActionState: AsyncActionType = {
  fetching: false,
  error: null,
};
