import React from 'react';
import useDevice from '@hooks/useDevice';
import { formatDate } from '@helpers/formatDate';
import { BodyText } from '@ui/components/Typography';
import { Payout } from '../types';
import { Wrapper, Table, TH, TRHeader } from './AccountHistory.styled';

interface Props {
  payouts: Payout[];
}

const AccountHistory = (props: Props) => {
  const { isMobile } = useDevice();

  return (
    <Wrapper>
      {props.payouts?.length > 0 ? (
        <Table>
          <thead>
            <TRHeader>
              <TH>Status</TH>
              <TH>Amt.</TH>
              <TH>Currency</TH>
              <TH>Requested on</TH>
              {!isMobile && <TH>Updated on</TH>}
            </TRHeader>
          </thead>
          <tbody>
            {props.payouts.map((el, i) => (
              <tr key={i}>
                <td>
                  {`${el.status.charAt(0).toUpperCase() + el.status.slice(1)}`}
                </td>
                <td>{el.amount}</td>
                <td>{el.currency}</td>
                <td>{formatDate(el.requested_at)}</td>
                {!isMobile && <td>{formatDate(el.updated_at)}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <BodyText>None</BodyText>
      )}
    </Wrapper>
  );
};

export default AccountHistory;
