import styled from 'styled-components';
export var SocialProofPanelMain = styled.div.withConfig({
  displayName: "SocialProofPanelstyled__SocialProofPanelMain",
  componentId: "sc-92m3f0-0"
})(["background-image:", ";background-size:cover;background-position:center center;min-height:600px;padding:", "px;display:flex;justify-content:flex-end;align-items:flex-end;color:", ";"], function (props) {
  return "url('".concat(props.backgroundUrl, "')");
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme[props.textColour];
});
export var LabelWrapper = styled.div.withConfig({
  displayName: "SocialProofPanelstyled__LabelWrapper",
  componentId: "sc-92m3f0-1"
})(["display:grid;grid-auto-flow:row;grid-gap:", "px;"], function (props) {
  return props.theme.spacer;
});