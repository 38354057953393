import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#000' : 'currentColor'}
      d="M86.013 85.999h1.056c8.053 9.237 17.156 17.418 25.734 26.258l58.33 58.323v1.056c-28.238 28.105-56.35 56.475-84.592 84.58-28.373-28.237-56.614-56.607-84.988-84.844 1.455-2.771 4.223-4.619 6.335-6.862 25.998-26.258 52.392-52.121 78.125-78.511zm170.24 0h1.056c.795 1.188 1.584 2.243 2.639 3.299 27.318 27.05 54.242 54.496 81.821 81.414-.66 1.583-2.111 2.639-3.299 3.826-27.315 27.182-54.503 54.496-81.821 81.678-28.505-28.105-56.482-56.739-85.12-84.712 27.714-28.898 56.879-56.74 84.724-85.505zm170.24 0h1.056c5.279 6.334 11.481 11.744 17.156 17.682 21.379 21.376 42.628 42.62 64.005 63.996 1.058 1.188 2.774 2.243 3.167 3.959-28.637 27.841-56.351 56.739-85.12 84.58l-81.161-81.15c-1.319-1.451-3.035-2.507-3.695-4.354 14.385-13.987 28.373-28.37 42.626-42.489 13.989-14.118 28.241-27.841 41.966-42.224zM172.717 340.006c27.845-27.842 55.823-55.684 83.668-83.658l1.185.528c27.978 28.106 55.955 56.212 84.197 84.053-.922 2.111-2.901 3.299-4.355 5.014-20.321 20.189-40.647 40.641-60.97 60.83-6.598 6.334-12.933 13.459-19.661 19.397-9.634-8.709-18.475-18.473-27.848-27.446-19.001-19.001-37.872-38.002-57.008-56.871.264-.528.528-1.32.792-1.847z"
    />
  </svg>
);
