import React from 'react';
import openExternalWebpage from '@helpers/openExternalWebpage';
import {
  ExternalTextLinkMain,
  ExternalTextLinkBodyText,
  ExternalTextLinkDescriptionText,
} from './ExternalTextLink.styles';

interface Props {
  href: string;
  text: string;
  isDescription?: boolean;
  colour?: string;
}

/**
 * Handles external text links
 */
const ExternalTextLink = (props: Props) => (
  <ExternalTextLinkMain
    onClick={() => openExternalWebpage(props.href)}
    rel="noopener noreferrer"
  >
    {props.isDescription ? (
      <ExternalTextLinkDescriptionText colour={props.colour}>
        {props.text}
      </ExternalTextLinkDescriptionText>
    ) : (
      <ExternalTextLinkBodyText colour={props.colour}>
        {props.text}
      </ExternalTextLinkBodyText>
    )}
  </ExternalTextLinkMain>
);

export default ExternalTextLink;
