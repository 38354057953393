import { Campaign } from 'components/FanBuilder/types';

interface Args {
  actId?: string;
  actName: string;
  limit?: number;
}

const insights = ({ actId }: Args) => `fanInsights/${actId}/fanInsights`;

const distribution = ({ actId }: Args) =>
  `distribution/${actId}/releases?fetch_dsps=true&fetch_hypelinks=true`;

const feed = ({ actId }: Args) => `feed/${actId}/cards`;

const landingPages = ({ actId }: Args) => `hypelink/${actId}/landingpages`;

const landingPageTemplates = ({ actId }: Args) =>
  `hypelink/${actId}/landingpages/base_templates`;

const releaseSplits = ({ actId, releaseId }: Args & { releaseId: string }) =>
  `splits/${actId}/${releaseId}`;

const receivingSplits = () => 'splits/as_recipient';

const playlists = ({ actId, limit }: Args) => ({
  path: `insights/${actId}/playlists`,
  params: { limit },
});

const potentialPlaylists = ({ actId, limit }: Args) => ({
  path: `insights/${actId}/potentialPlaylists`,
  params: { limit },
  swallowErrors: true,
});

const relatedArtists = ({ actId, limit }: Args) => ({
  path: `insights/${actId}/relatedArtists`,
  params: { limit },
  swallowErrors: true,
});

const payment = ({ actId }: Args) =>
  actId
    ? `payments/payment-rails/${actId}/recipient`
    : `payments/payment-rails/splits/recipient`;

const topPosts = ({ actId, filter, limit }: Args & { filter: string }) => ({
  path: `insights/${actId}/posts`,
  params: { filter, limit },
  swallowErrors: true,
});

const fugaStats = ({ actId }: Args) => `warehouse/analytics/${actId}/fuga`;

const mediaLibrary = ({ actId }: Args) => `file/media/${actId}`;

const adAccounts = ({ actId }: Args) =>
  `facebook/${actId}/advertisementAccounts`;

const socialEngagement = ({
  actId,
  platforms,
}: Args & { platforms?: string[] }) => ({
  path: `insights/${actId}/engagementMap`,
  params: { platforms },
});

const socials = ({ actId }: Args) => `artist/${actId}/socials`;

const platforms = ({ actName }: Args) => `artist/search/public/${actName}`;

const twitterDrillData = ({ actId }: Args) =>
  `analytics/multi/${actId}?analytics=twitter_insights`;

const instagramDrillData = ({ actId }: Args) =>
  `analytics/multi/${actId}?analytics=instagram_engagement`;

const facebookDrillData = ({ actId }: Args) =>
  `analytics/multi/${actId}?analytics=facebook_engagement`;

const spotifyDrillData = ({ actId }: Args) => `insights/${actId}/spotify`;

const launchpad = ({ actId }: Args) => `launchpad/${actId}`;

const campaignsConfig = ({ actId }: Args) =>
  `facebook/${actId}/fanBuilderCampaignsConfig`;

const adAccountData = ({ actId }: Args) =>
  `facebook/${actId}/advertisementAccount`;

const adCampaigns = ({ actId }: Args) =>
  `facebook/${actId}/fanBuilderCampaigns`;

const mailingList = ({ actId }: Args) => `artistEmail/mailing_list/${actId}`;

const existingPosts = (args: Args & { selectedCampaign?: Campaign }) => {
  const igEligible =
    args.selectedCampaign?.campaign_config?.selected_platforms?.includes(
      'instagram'
    )
      ? 'true'
      : 'false';
  if (args.selectedCampaign?.campaign_type === 'fan_finder')
    return {
      path: `facebook/${args.actId}/getFanFinderPosts`,
      params: { ig_eligible: igEligible },
    };
  if (args.selectedCampaign?.campaign_type === 'build_facebook_followers')
    return `facebook/${args.actId}/getFacebookPosts`;
  if (args.selectedCampaign?.campaign_type === 'build_instagram_followers')
    return `facebook/${args.actId}/getInstagramPosts`;
  if (args.selectedCampaign?.campaign_type === 'web_traffic')
    return `facebook/${args.actId}/getFacebookPosts`;
  return '';
};

const entities = {
  insights,
  distribution,
  landingPages,
  playlists,
  potentialPlaylists,
  relatedArtists,
  topPosts,
  socialEngagement,
  adAccounts,
  socials,
  platforms,
  fugaStats,
  payment,
  twitterDrillData,
  spotifyDrillData,
  facebookDrillData,
  instagramDrillData,
  landingPageTemplates,
  mediaLibrary,
  feed,
  launchpad,
  campaignsConfig,
  adAccountData,
  adCampaigns,
  existingPosts,
  mailingList,
  releaseSplits,
  receivingSplits,
};

export default entities;
