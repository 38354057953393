import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    viewBox="0 0 160 50"
    fill={isColour ? '#F05537' : 'currentColor'}
    {...props}
  >
    <path d="M149.02 25.03a5.33 5.33 0 015.53 2.08L145 29.28c.32-2 1.92-3.77 4-4.25zm5.6 7.93a5.37 5.37 0 01-8.74.08l9.55-2.16 1.52-.32 2.97-.64c0-.64-.08-1.28-.24-1.93a9.76 9.76 0 00-11.7-7.13 9.53 9.53 0 00-7.3 11.46c1.2 5.13 6.5 8.34 11.7 7.22a9.75 9.75 0 006.74-5.45l-4.5-1.13zm-22.52 6.18V25.5h-2.96v-4.65h2.96v-6.41h4.97v6.41h3.77v4.65h-3.77v13.63h-4.97zm-9.53 0h4.8V20.86h-4.8v18.28zm-.65-24.77a2.98 2.98 0 013.05-2.97 2.98 2.98 0 013.05 2.97 2.93 2.93 0 01-3.05 2.96c-1.76 0-3.05-1.2-3.05-2.96zm-1.04 6.49c-2.48.16-3.93.48-5.45 1.6v-1.6h-4.8v18.28h4.8v-8.98c0-2.8.72-4.41 5.45-4.57v-4.73zm-26.93 9.38a5.33 5.33 0 005.29 4.89 5.25 5.25 0 005.29-5.13 5.3 5.3 0 00-5.3-5.13 5.22 5.22 0 00-5.28 4.89v.48zm-4.73 8.9V12.12l4.8-1.12v11.22a8.12 8.12 0 015.22-1.68c5.53 0 9.94 4.25 9.94 9.54 0 5.29-4.41 9.62-9.94 9.62A8.12 8.12 0 0194.03 38v1.2l-4.81-.07zm-10.34 0v-13.8H75.9v-4.56h2.97v-6.41l4.97-1.13v7.54h3.77v4.57h-3.77v13.79h-4.97zm-8.82 0v-9.78c0-3.53-1.92-4.33-3.85-4.33-1.92 0-3.85.72-3.85 4.25v9.86h-4.8V20.86h4.8v1.36a7.15 7.15 0 014.9-1.68c4.08 0 7.61 2.32 7.61 8.1v10.58h-4.81v-.08zM45.29 25.03c2.16-.48 4.33.4 5.53 2.08l-9.54 2.17c.32-2 1.85-3.77 4.01-4.25zm5.53 7.93a5.28 5.28 0 01-3.2 2.17 5.33 5.33 0 01-5.54-2.09l9.54-2.16 1.53-.32 2.96-.64a7.9 7.9 0 00-.24-1.93 9.76 9.76 0 00-11.7-7.13 9.53 9.53 0 00-7.3 11.46c1.2 5.13 6.5 8.34 11.7 7.22a9.75 9.75 0 006.74-5.45c.08-.08-4.49-1.13-4.49-1.13zm-25.25 6.18l-7.53-18.28h5.29l4.73 12.42 4.8-12.42h5.3l-7.54 18.28h-5.05zm-17-14.11a5.33 5.33 0 015.54 2.08l-9.54 2.17a5.12 5.12 0 014-4.25zm5.54 7.93a5.37 5.37 0 01-8.74.08l9.54-2.16 1.52-.32 2.97-.64a7.9 7.9 0 00-.24-1.93 9.76 9.76 0 00-11.7-7.13A9.4 9.4 0 00.23 32.24a9.76 9.76 0 0011.7 7.14 9.75 9.75 0 006.74-5.45l-4.57-.97z" />
  </svg>
);
