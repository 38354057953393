import React from 'react';
import Icon from '@ui/components/Icon';
import { Chevron } from './BackButton.styles';

interface Props {
  isSmall?: boolean;
  onClick: () => void;
}

/**
 * Button styled and configured for use as a back button
 */
const BackButton = (props: Props) => (
  <Chevron onClick={props.onClick} isSmall={props.isSmall}>
    <Icon name="left" />
  </Chevron>
);
export default BackButton;
