import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#FBBB00' : 'currentColor'}
      d="M113.47 309.406l-17.822 66.532-65.139 1.378C11.042 341.209 0 299.898 0 255.998c0-42.451 10.324-82.483 28.624-117.732h.014l57.992 10.632 25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
    />
    <path
      fill={isColour ? '#518EF8' : 'currentColor'}
      d="M507.527 208.177c2.94 15.486 4.473 31.479 4.473 47.824 0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.177h245.899z"
    />
    <path
      fill={isColour ? '#28B446' : 'currentColor'}
      d="M416.253 455.626l.014.014c-43.871 35.263-99.601 56.362-160.267 56.362-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
    />
    <path
      fill={isColour ? '#F14336' : 'currentColor'}
      d="M419.404 58.936l-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
    />
  </svg>
);
