import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#00A0EE' : 'currentColor'}
      d="M496.75 107.68c-7.84-18.4-18.4-36.8-31.52-49.92-18.4-18.4-42.08-31.52-65.6-42.08C373.39 5.12 344.43 0 315.63 0H81.07v512h134.72c13.12 0 23.68-7.84 23.68-23.68v-91.84c0-5.28 0-5.28 5.28-5.28h49.92a257.7 257.7 0 0 0 63.04-7.84c31.52-7.84 60.32-20.96 86.72-42.08 23.68-18.4 42.08-44.64 52.48-73.44 7.84-20.96 13.12-44.64 13.12-68.32 2.4-31.52-2.72-63.04-13.28-91.84z"
    />
  </svg>
);
