import React from 'react';
import { HeadingTitle, HeadingLabel } from '@ui/components/Typography';
import { getCDNUrl } from '@helpers/getCDNUrl';
import { SocialProofPanelMain, LabelWrapper } from './SocialProofPanel.styled';

interface ArtistOption {
  artistName: string;
  imageUrl: string;
  textColour: 'white' | 'black';
}

const imageBaseUrl: string =
  'https://s3.eu-west-2.amazonaws.com/static.beatchain.com/socialProofArtistImages/';
const artistOptions: ArtistOption[] = [
  {
    artistName: 'Lil Wayne',
    imageUrl: `${imageBaseUrl}LilWayne.jpg`,
    textColour: 'white',
  },
  {
    artistName: 'Nicki Minaj',
    imageUrl: `${imageBaseUrl}NickiMinaj.jpg`,
    textColour: 'white',
  },
  {
    artistName: 'Christina Milian',
    imageUrl: `${imageBaseUrl}ChristinaMilian.jpg`,
    textColour: 'white',
  },
];

/**
 * Marketing panel displayed at point of sale UIs to build social proof via artist
 * or company testimonial slide show
 */
const SocialProofPanel = () => {
  const artist =
    artistOptions[Math.floor(Math.random() * artistOptions.length)];

  return (
    <SocialProofPanelMain
      backgroundUrl={getCDNUrl(artist.imageUrl) as string}
      textColour={artist.textColour || 'white'}
    >
      <LabelWrapper>
        <HeadingTitle>{artist.artistName}</HeadingTitle>
        <HeadingLabel>/ Young Money Artist</HeadingLabel>
      </LabelWrapper>
    </SocialProofPanelMain>
  );
};

export default SocialProofPanel;
