import React from 'react';

import { IconProps } from './index';

const IconBeatchin = ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" {...props}>
    <path
      fill={isColour ? '#FF5BCB' : 'currentColor'}
      d="M12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0zm7.536 13.176l-7.296 2.04-.336-1.224 7.296-2.04.336 1.224zM16.968 8.64l-7.296 2.04-.336-1.224 7.296-2.016.336 1.2zm-.696-2.424l-7.296 2.04-.336-1.224 7.296-2.04.336 1.224zM5.52 5.28l7.296-2.04.336 1.224-7.296 2.04L5.52 5.28zm2.04 7.32l7.296-2.04.336 1.224-7.296 2.04L7.56 12.6zm2.4 8.496l-.336-1.224 7.296-2.04.336 1.224-7.296 2.04zm2.976-3.456l-.336-1.224 7.296-2.04.336 1.224-7.296 2.04z"
    />
  </svg>
);

export default IconBeatchin;
