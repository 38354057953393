import styled from 'styled-components';

/**
 * Static background image
 */
var StaticBackground = styled.div.withConfig({
  displayName: "StaticBackgroundstyled__StaticBackground",
  componentId: "sc-y5nqtc-0"
})(["background-image:url('", "');filter:brightness(", ");height:100%;width:100%;z-index:-100;position:absolute;background-repeat:no-repeat;background-position:center center;background-size:cover;background-attachment:fixed;"], function (props) {
  return props.src;
}, function (props) {
  return props.brightness || '100%';
});
export default StaticBackground;