import React from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from '@hooks/useStore';
import trackEvent from '@helpers/tracking';
import ajax from '@helpers/ajax';
import * as userStore from 'store/user';
import PageLoader from '@ui/components/PageLoader';
import ArtistName from './ArtistName';

interface Props {
  showToast?: boolean;
  closeClickHandler?: () => void;
  isLightMode?: boolean;
  showMoreOptions?: boolean;
}

const ArtistNameContainer = (props: Props) => {
  const dispatch = useDispatch();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  async function assignUserAct(artistName) {
    trackEvent("let's go! confirm artist name", artistName);
    setLoading(true);

    const { success, actId, errorMessage } = await ajax(
      `user/me/act`,
      { actName: artistName },
      'POST'
    );

    if (success) {
      await ajax(`user/me`, { onboarded: true }, 'PATCH');
      if (props.showToast) {
        toast.success(`Congratulations, you have claimed ${artistName}`);
      }

      if (props.closeClickHandler) {
        props.closeClickHandler();
      }

      dispatch(userStore.fetchUserMetadata({ actId }));
    } else {
      setError(errorMessage);
      toast.error(errorMessage);
    }
    setLoading(false);
  }

  const search = artistNameToSearch =>
    ajax(`artist/musicbrainz?artist=${artistNameToSearch}`, {}, 'GET');

  return loading ? (
    <PageLoader message="Saving your artist name" />
  ) : (
    <ArtistName
      onSelect={assignUserAct}
      error={error}
      search={search}
      isLightMode={props.isLightMode}
      showMoreOptions={props.showMoreOptions}
    />
  );
};

export default ArtistNameContainer;
