import { library } from '@fortawesome/fontawesome-svg-core';

import { faAd } from '@fortawesome/free-solid-svg-icons/faAd';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBold } from '@fortawesome/free-solid-svg-icons/faBold';
import { faBullseye } from '@fortawesome/free-solid-svg-icons/faBullseye';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons/faCalendarDay';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons/faCaretLeft';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons/faChevronCircleUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faCompactDisc } from '@fortawesome/free-solid-svg-icons/faCompactDisc';
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons/faCompressArrowsAlt';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons/faExpandArrowsAlt';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faFileImport } from '@fortawesome/free-solid-svg-icons/faFileImport';
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons/faGlobeAfrica';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons/faGripVertical';
import { faHeadphones } from '@fortawesome/free-solid-svg-icons/faHeadphones';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faImages } from '@fortawesome/free-solid-svg-icons/faImages';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faItalic } from '@fortawesome/free-solid-svg-icons/faItalic';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons/faMailBulk';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons/faMoneyBillWave';
import { faMusic } from '@fortawesome/free-solid-svg-icons/faMusic';
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faRocket } from '@fortawesome/free-solid-svg-icons/faRocket';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faTh } from '@fortawesome/free-solid-svg-icons/faTh';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons/faTicketAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUnderline } from '@fortawesome/free-solid-svg-icons/faUnderline';
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons/faUndoAlt';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { faUnlink } from '@fortawesome/free-solid-svg-icons/faUnlink';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faWallet } from '@fortawesome/free-solid-svg-icons/faWallet';
import { faCoins } from '@fortawesome/free-solid-svg-icons/faCoins';

import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex';
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import { faCcStripe } from '@fortawesome/free-brands-svg-icons/faCcStripe';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa';
import { faChrome } from '@fortawesome/free-brands-svg-icons/faChrome';
import { faSafari } from '@fortawesome/free-brands-svg-icons/faSafari';

import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleDown';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleLeft';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleRight';
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleUp';
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faClock as farClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faFile as farFile } from '@fortawesome/free-regular-svg-icons/faFile';
import { faImage as farImage } from '@fortawesome/free-regular-svg-icons/faImage';
import { faImages as farImages } from '@fortawesome/free-regular-svg-icons/faImages';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons/faLightbulb';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons/faPaperPlane';
import { faSmile } from '@fortawesome/free-regular-svg-icons/faSmile';
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';

const initLibrary = () => {
  library.add(
    // solid
    faAd,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBars,
    faBell,
    faBold,
    faBullseye,
    faCalendarDay,
    faCamera,
    faCaretDown,
    faCaretRight,
    faCaretLeft,
    faChartBar,
    faCheck,
    faCheckCircle,
    faChevronCircleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faClipboardList,
    faClipboardCheck,
    faClock,
    faCloudUploadAlt,
    faCog,
    faCoins,
    faCompactDisc,
    faCompressArrowsAlt,
    faCopy,
    faCreditCard,
    faDownload,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExpandArrowsAlt,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFileImport,
    faGlobeAfrica,
    faGripVertical,
    faHeadphones,
    faHeart,
    faHome,
    faImage,
    faImages,
    faInfo,
    faInfoCircle,
    faItalic,
    faLink,
    faList,
    faLock,
    faMailBulk,
    faMapMarkerAlt,
    faMinus,
    faMoneyBillWave,
    faMusic,
    faPause,
    faPen,
    faPlay,
    faPlayCircle,
    faPlus,
    faQuestion,
    faRocket,
    faSave,
    faSearch,
    faSignOutAlt,
    faShare,
    faSort,
    faSpinner,
    faStar,
    faSync,
    faTh,
    faTicketAlt,
    faTimes,
    faTimesCircle,
    faTrash,
    faUnderline,
    faUndoAlt,
    faUniversity,
    faUnlink,
    faUpload,
    faUser,
    faUsers,
    faUserPlus,
    faVideo,
    faWallet,
    // brands
    faCcAmex,
    faCcDiscover,
    faCcMastercard,
    faCcStripe,
    faCcVisa,
    faChrome,
    faSafari,
    // regular
    faArrowAltCircleDown,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowAltCircleUp,
    farCircle,
    farClock,
    farFile,
    farImage,
    farImages,
    faLightbulb,
    faPaperPlane,
    faSmile,
    faSquare
  );
};

export default initLibrary;
