import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    <path
      fill={isColour ? '#40AB5D' : 'currentColor'}
      d="M512 89.09H401.29v64.77h110.7V89.1z"
    />
    <path
      fill={isColour ? 'url(#deezer0_linear)' : 'currentColor'}
      d="M512 178.9H401.29v64.77h110.7V178.9z"
    />
    <path
      fill={isColour ? 'url(#deezer1_linear)' : 'currentColor'}
      d="M512 268.7H401.29v64.78h110.7V268.7z"
    />
    <path
      fill={isColour ? 'url(#deezer2_linear)' : 'currentColor'}
      d="M110.71 358.51H0v64.77h110.71v-64.77z"
    />
    <path
      fill={isColour ? 'url(#deezer3_linear)' : 'currentColor'}
      d="M244.39 358.51H133.68v64.77h110.7v-64.77z"
    />
    <path
      fill={isColour ? 'url(#deezer4_linear)' : 'currentColor'}
      d="M378.32 358.51h-110.7v64.77h110.7v-64.77z"
    />
    <path
      fill={isColour ? 'url(#deezer5_linear)' : 'currentColor'}
      d="M512 358.51H401.29v64.77h110.7v-64.77z"
    />
    <path
      fill={isColour ? 'url(#deezer6_linear)' : 'currentColor'}
      d="M378.32 268.7h-110.7v64.78h110.7V268.7z"
    />
    <path
      fill={isColour ? 'url(#deezer7_linear)' : 'currentColor'}
      d="M244.39 268.7H133.68v64.78h110.7V268.7z"
    />
    <path
      fill={isColour ? 'url(#deezer8_linear)' : 'currentColor'}
      d="M244.39 178.9H133.68v64.77h110.7V178.9z"
    />
    <defs>
      <linearGradient
        id="deezer0_linear"
        x1="457.19"
        x2="456.14"
        y1="244.23"
        y2="177.94"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#358C7B" />
        <stop offset=".53" stopColor="#33A65E" />
      </linearGradient>
      <linearGradient
        id="deezer1_linear"
        x1="399.66"
        x2="513.68"
        y1="330.15"
        y2="272.06"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222B90" />
        <stop offset="1" stopColor="#367B99" />
      </linearGradient>
      <linearGradient
        id="deezer2_linear"
        x1=".02"
        x2="110.64"
        y1="390.92"
        y2="390.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F90" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="deezer3_linear"
        x1="133.8"
        x2="244.42"
        y1="390.92"
        y2="390.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8000" />
        <stop offset="1" stopColor="#CC1953" />
      </linearGradient>
      <linearGradient
        id="deezer4_linear"
        x1="267.58"
        x2="378.2"
        y1="390.92"
        y2="390.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CC1953" />
        <stop offset="1" stopColor="#241284" />
      </linearGradient>
      <linearGradient
        id="deezer5_linear"
        x1="401.23"
        x2="511.85"
        y1="390.92"
        y2="390.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222B90" />
        <stop offset="1" stopColor="#3559A6" />
      </linearGradient>
      <linearGradient
        id="deezer6_linear"
        x1="263.21"
        x2="382.56"
        y1="318.56"
        y2="283.65"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CC1953" />
        <stop offset="1" stopColor="#241284" />
      </linearGradient>
      <linearGradient
        id="deezer7_linear"
        x1="129.86"
        x2="248.35"
        y1="279.54"
        y2="322.67"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FC0" />
        <stop offset="1" stopColor="#CE1938" />
      </linearGradient>
      <linearGradient
        id="deezer8_linear"
        x1="143.1"
        x2="235.12"
        y1="168.83"
        y2="253.74"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FFD100" />
        <stop offset="1" stopColor="#FD5A22" />
      </linearGradient>
    </defs>
  </svg>
);
