import { createGlobalStyle } from 'styled-components';
import { rgba } from 'polished';
export var GlobalStyle = createGlobalStyle(["html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font:inherit;font-size:100%;vertical-align:baseline;}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}ol,ul{list-style:none;}blockquote,q{quotes:none;}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}table{border-collapse:collapse;border-spacing:0;}input{outline:none;border-radius:", "px;}@-webkit-keyframes autofill{0%,100%{color:", ";background:transparent;}}input:-webkit-autofill{animation-delay:1s;animation-name:autofill;animation-fill-mode:both;}*{-webkit-overflow-scrolling:touch;}body{height:100vh;overflow:auto;position:relative;overscroll-behavior:contain;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-family:", ",sans-serif !important;font-size:14px !important;line-height:20px !important;font-weight:normal !important;background-color:white;margin-top:0;}.Scrollbars{overscroll-behavior:contain !important;-webkit-overflow-scrolling:auto !important;}a{color:", ";}code{font-family:source-code-pro,Menlo,Monaco,Consolas,'Courier New',monospace;}::-webkit-scrollbar{width:", "px;height:", "px;}::-webkit-scrollbar-track{background:", "}::-webkit-scrollbar-corner{background:transparent;}::-webkit-scrollbar-thumb{background:", ";&:hover{background:", ";}}#root{height:100%;}"], function (props) {
  return props.theme.inputBorderRadius;
}, function (props) {
  return props.theme.grey;
}, function (props) {
  return props.theme.bodyTextFont;
}, function (props) {
  return props.theme.linkColour;
}, function (props) {
  return props.theme.scrollbarWidth;
}, function (props) {
  return props.theme.scrollbarWidth;
}, function (props) {
  return rgba(props.theme.dgrey, 0.5);
}, function (props) {
  return props.theme.dgrey;
}, function (props) {
  return rgba(props.theme.tertiary, 0.5);
});