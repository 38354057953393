import React from 'react';
import { LogoProps } from './index';

const BankIcon = ({ isColour, ...props }: LogoProps) => (
  <svg fill="none" viewBox="0 0 160 50" {...props}>
    <path
      fill={isColour ? '#253B80' : 'currentColor'}
      d="M59.827 13.738h-8.854a1.23 1.23 0 0 0-1.216 1.038l-3.58 22.704a.737.737 0 0 0 .73.852h4.227a1.23 1.23 0 0 0 1.215-1.04l.966-6.123c.093-.6.61-1.04 1.214-1.04h2.803c5.833 0 9.199-2.822 10.078-8.415.396-2.447.017-4.37-1.13-5.716-1.258-1.478-3.49-2.26-6.453-2.26Zm1.022 8.292c-.485 3.177-2.912 3.177-5.26 3.177h-1.335l.937-5.934a.738.738 0 0 1 .729-.622h.612c1.599 0 3.107 0 3.887.911.464.544.607 1.352.43 2.468ZM86.293 21.927h-4.24a.739.739 0 0 0-.729.623l-.187 1.186-.297-.43c-.918-1.332-2.965-1.777-5.008-1.777-4.685 0-8.686 3.548-9.466 8.526-.405 2.483.171 4.858 1.58 6.513 1.292 1.523 3.14 2.157 5.34 2.157 3.775 0 5.869-2.427 5.869-2.427l-.19 1.178a.738.738 0 0 0 .728.854h3.82c.607 0 1.12-.44 1.215-1.04l2.292-14.51a.735.735 0 0 0-.727-.853Zm-5.91 8.252c-.409 2.423-2.331 4.049-4.783 4.049-1.232 0-2.216-.395-2.847-1.143-.627-.744-.865-1.801-.666-2.98.382-2.401 2.337-4.08 4.751-4.08 1.205 0 2.183.4 2.828 1.155.646.762.902 1.826.717 3ZM108.874 21.927h-4.261c-.406 0-.788.202-1.018.54l-5.877 8.656-2.49-8.318a1.234 1.234 0 0 0-1.181-.878H89.86a.738.738 0 0 0-.7.977l4.692 13.772-4.412 6.228a.738.738 0 0 0 .602 1.166h4.256c.404 0 .782-.197 1.01-.529l14.172-20.455a.738.738 0 0 0-.606-1.159Z"
    />
    <path
      fill={isColour ? '#179BD7' : 'currentColor'}
      d="M122.981 13.738h-8.856a1.23 1.23 0 0 0-1.214 1.038L109.33 37.48a.737.737 0 0 0 .728.852h4.544a.86.86 0 0 0 .849-.728l1.016-6.435c.094-.6.61-1.04 1.215-1.04h2.801c5.834 0 9.199-2.822 10.079-8.415.398-2.447.016-4.37-1.13-5.716-1.257-1.478-3.488-2.26-6.451-2.26Zm1.021 8.292c-.483 3.177-2.91 3.177-5.259 3.177h-1.334l.938-5.934a.736.736 0 0 1 .728-.622h.612c1.598 0 3.107 0 3.887.911.465.544.606 1.352.428 2.468ZM149.446 21.927h-4.237a.734.734 0 0 0-.728.623l-.188 1.186-.298-.43c-.917-1.332-2.963-1.777-5.006-1.777-4.685 0-8.686 3.548-9.465 8.526-.404 2.483.17 4.858 1.578 6.513 1.295 1.523 3.141 2.157 5.341 2.157 3.775 0 5.868-2.427 5.868-2.427l-.189 1.178a.738.738 0 0 0 .73.854h3.818c.605 0 1.12-.44 1.215-1.04l2.292-14.51a.74.74 0 0 0-.731-.853Zm-5.91 8.252c-.407 2.423-2.332 4.049-4.784 4.049-1.228 0-2.215-.395-2.847-1.143-.626-.744-.862-1.801-.665-2.98.384-2.401 2.337-4.08 4.751-4.08 1.204 0 2.183.4 2.828 1.155.648.762.905 1.826.717 3ZM154.445 14.36l-3.634 23.12a.736.736 0 0 0 .727.852h3.654c.607 0 1.122-.44 1.215-1.04l3.584-22.703a.738.738 0 0 0-.728-.853h-4.091a.739.739 0 0 0-.727.624ZM29.839 14.926a21.41 21.41 0 0 1-.124.712c-1.602 8.222-7.08 11.063-14.078 11.063h-3.563a1.73 1.73 0 0 0-1.71 1.465l-1.825 11.57-.516 3.279a.912.912 0 0 0 .9 1.053h6.319a1.52 1.52 0 0 0 1.502-1.281l.062-.321 1.19-7.55.076-.415a1.52 1.52 0 0 1 1.502-1.284h.945c6.122 0 10.915-2.486 12.316-9.68.585-3.004.282-5.513-1.266-7.278a6.04 6.04 0 0 0-1.73-1.333Z"
    />
    <path
      fill={isColour ? '#253B80' : 'currentColor'}
      d="m10.364 28.166-1.825 11.57H1.042c-.64 0-1.129-.573-1.03-1.207l5.06-32.062A1.736 1.736 0 0 1 6.786 5H18.93c4.183 0 7.452.883 9.263 2.945 1.643 1.874 2.124 3.942 1.647 6.981-.036.232-.077.469-.125.712-1.602 8.22-7.08 11.063-14.078 11.063h-3.563a1.73 1.73 0 0 0-1.71 1.465Z"
    />
    <path
      fill={isColour ? '#222D65' : 'currentColor'}
      d="M28.164 14.258a12.58 12.58 0 0 0-1.558-.346c-.96-.155-2.013-.229-3.14-.229h-9.519a1.517 1.517 0 0 0-1.5 1.284l-2.025 12.825-.058.374a1.73 1.73 0 0 1 1.71-1.465h3.563c6.997 0 12.476-2.842 14.078-11.063.048-.243.088-.48.124-.712a8.54 8.54 0 0 0-1.317-.556 11.66 11.66 0 0 0-.358-.112Z"
    />
  </svg>
);

export default BankIcon;
