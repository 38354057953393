import { store } from 'store';
import { UserState } from 'store/user/types';
import { AuthState } from 'store/auth/types';
import { featureIds } from 'config/features';
import { Currency } from '@ui/types';

const getUser = () => store.getState().user;

/**
 * Transforms and returns user data
 */
const transformUserData = (user: UserState = getUser(), auth?: AuthState) => {
  const { roles = [], subscription } = user;

  const loggedIn = !!auth?.session;

  const isBeta = !!roles?.find(x => x.app_role_id === 5);

  const hasNonBasicRole = !!roles?.find(
    x => x.app_role_id !== 1 && x.app_role_id !== 6
  );

  const hasSplitsRole = !!roles?.find(x => x.app_role_id === 6);

  const subscriptionType = (subscription || {}).product_name || 'Basic';

  const defaultRedirect =
    !hasNonBasicRole && subscriptionType === 'Basic' && hasSplitsRole
      ? '/splits'
      : '/welcome';

  const hasReadOnlyDistro =
    subscriptionType === 'Basic' && !hasNonBasicRole && !!user.hasReleases;

  const isPro = !!roles?.find(x => x.app_role_id === 2);

  function hasFeature(featureId: number) {
    return !!user?.features?.find(x => x.app_feature_id === featureId);
  }

  function getReferralCode() {
    let code = '';
    if (typeof window !== 'undefined') {
      code = window.localStorage.getItem('referral_code') || '';
    }
    return code || user?.referralCode || '';
  }

  function canAddArtist() {
    const max =
      user?.features?.find(el => el.app_feature_id === featureIds.multiArtist)
        ?.quantity || 1;
    const count = user?.artists?.filter(el => !el.federated)?.length || 1;

    return count < max;
  }

  function getUserStripeAccountType(currency: Currency) {
    const type = user?.accountType;
    if (type) return type;

    switch (currency?.toLowerCase()) {
      case 'gbp':
      case 'eur':
        return 'ORIGINAL';
      case 'usd':
      default:
        return 'US';
    }
  }

  function isSubscribedTo(product: string) {
    return user?.subscription?.product_name === product;
  }

  const canCancel =
    user.hasFailedPayments ||
    (['Premium', 'Essentials'].includes(subscription?.product_name as string) &&
      subscription?.status !== 'cancelled_expiring');

  return {
    ...user,
    roles,
    subscription: subscription!,
    subscriptionType,
    hasFeature,
    getReferralCode,
    canAddArtist,
    getUserStripeAccountType,
    isSubscribedTo,
    isBeta,
    hasNonBasicRole,
    canCancel,
    hasReadOnlyDistro,
    loggedIn,
    isPro,
    hasSplitsRole,
    defaultRedirect,
  };
};

export default transformUserData;
