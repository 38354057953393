import React, { FC, useContext } from 'react';
import useUser from '@hooks/useUser';
import GlobalState from 'context/GlobalState';
import Elements from '@ui/components/Elements';

const StripeElementsProvider: FC = props => {
  const { getUserStripeAccountType } = useUser();
  const globalState = useContext(GlobalState);
  const currency = globalState.currency || 'USD';
  const accountType = getUserStripeAccountType(currency);

  return <Elements accountType={accountType}>{props.children}</Elements>;
};

export default StripeElementsProvider;
