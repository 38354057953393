import React from 'react';
import Page from 'components/app/Page';
import FeaturePage from 'components/app/FeaturePage';
import FeedComponent from 'components/feed';

const NewsFeed = () => (
  <Page hasScroll>
    <FeaturePage>
      <FeedComponent />
    </FeaturePage>
  </Page>
);

export default NewsFeed;
