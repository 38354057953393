import styled from 'styled-components';
import { HeadingSmall } from '@ui/components/Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "AccountHistorystyled__Wrapper",
  componentId: "sc-r1cz2j-0"
})(["width:100%;max-height:200px;overflow:auto;td{text-align:center;}"]);
export var Heading = styled(HeadingSmall).withConfig({
  displayName: "AccountHistorystyled__Heading",
  componentId: "sc-r1cz2j-1"
})([""]);
export var Table = styled.table.withConfig({
  displayName: "AccountHistorystyled__Table",
  componentId: "sc-r1cz2j-2"
})(["width:100%;max-height:280px;overflow-y:auto;font-size:11px;margin-top:", "px;"], function (props) {
  return props.theme.spacer;
});
export var TRHeader = styled.tr.withConfig({
  displayName: "AccountHistorystyled__TRHeader",
  componentId: "sc-r1cz2j-3"
})(["background-color:", ";"], function (props) {
  return props.theme.primary;
});
export var TH = styled.th.withConfig({
  displayName: "AccountHistorystyled__TH",
  componentId: "sc-r1cz2j-4"
})(["font-weight:bold;padding:", "px 0;"], function (props) {
  return props.theme.smallSpacer;
});