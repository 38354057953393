const bases = {
  local: `http://localhost:3000/${process.env.REACT_APP_LOCAL_STAGE}`,
  dev: 'https://dev-api-v2.beatchain.com/core',
  qa: 'https://qa-api-v2.beatchain.com/core',
  prod: 'https://api-v2.beatchain.com/core',
};

const websocketBases = {
  'local-wb': 'ws://localhost:3001',
  local: 'wss://4tqm2mts05.execute-api.eu-west-1.amazonaws.com/dev',
  dev: 'wss://4tqm2mts05.execute-api.eu-west-1.amazonaws.com/dev',
  qa: 'https://qa-api-v2.beatchain.com/core',
  prod: 'wss://jqmrct6g45.execute-api.eu-west-1.amazonaws.com/prod',
};

const origins = {
  local: 'http://localhost:3883',
  dev: 'https://dev.beatchain.com',
  qa: 'https://qa.beatchain.com',
  prod: 'https://beatchain.com',
};

const oauthDomains = {
  local: 'idp-dev',
  dev: 'idp-dev',
  qa: 'idp-dev',
  prod: 'idp',
};

const amplifyConfig = {
  local: {
    userPoolId: 'eu-west-1_i80N4IIDL',
    userPoolWebClientId: '6671sh9o9irq5au48hoearj18m',
  },
  dev: {
    userPoolId: 'eu-west-1_i80N4IIDL',
    userPoolWebClientId: '6671sh9o9irq5au48hoearj18m',
  },
  qa: {
    userPoolId: 'eu-west-1_i80N4IIDL',
    userPoolWebClientId: '6671sh9o9irq5au48hoearj18m',
  },
  prod: {
    userPoolId: 'eu-west-1_R6iRUgXku',
    userPoolWebClientId: '1udch7qs0q2692oacqsusf1621',
  },
};

export const env = process.env.REACT_APP_API_BASE || 'dev';

if (!env) {
  throw new Error('REACT_APP_API_BASE not defined');
}

const websiteUrls = {
  local: 'https://dev.beatcha.in',
  dev: 'https://dev.beatcha.in',
  prod: 'https://beatcha.in',
  qa: 'https://dev.beatcha.in',
};

export const websiteUrl = websiteUrls[env];

export const apiBase =
  bases[
    process.env.REACT_APP_IMPERSONATION_LOCAL_API === 'true' ? 'local' : env
  ];

export const websocketBase =
  websocketBases[
    process.env.REACT_APP_IMPERSONATION_LOCAL_API === 'true' ? 'local-wb' : env
  ];

export const { userPoolId } = amplifyConfig[env] || {};

export const { userPoolWebClientId } = amplifyConfig[env] || {};

export const stripeClientId =
  env === 'prod'
    ? 'ca_F5JuqvzSeGsQVuDNOstllxJ1sy0xxPgs'
    : 'ca_F5JuQXRazfrSxVXGtFgvX3E4WgnVbq4U';

export const webOrigin =
  process.env.REACT_APP_DOMAIN === 'YOUNG_MONEY'
    ? 'ymd-test.net'
    : origins[env];

export const oauthDomain = `${oauthDomains[env]}.beatchain.com`;

export const launchDarklyClientSideID =
  env === 'prod' ? '61d45509c5712213fbd5bd15' : '61d45509c5712213fbd5bd14';
