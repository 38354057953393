import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Heading } from '@ui/components/Typography';
import Icon from '@ui/components/Icon';
export var Wrapper = styled.div.withConfig({
  displayName: "UploadProgressstyled__Wrapper",
  componentId: "sc-k0wdsl-0"
})(["width:362px;position:absolute;z-index:1;bottom:", "px;left:", "px;background:", ";box-shadow:", ";padding-bottom:", "px;", ""], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.darkShadow;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.isMobile && "width: calc(100% - ".concat(props.theme.largeSpacer, "px);\n    bottom: ").concat(props.theme.spacer, "px;\n    right: ").concat(props.theme.spacer, "px;");
});
export var Header = styled.div.withConfig({
  displayName: "UploadProgressstyled__Header",
  componentId: "sc-k0wdsl-1"
})(["display:grid;grid-template-columns:1fr ", "px;grid-gap:", "px;align-items:center;padding:", "px;background:", ";color:", ";height:45px;box-sizing:border-box;"], function (props) {
  return props.theme.iconCanvasSize;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.black;
}, function (props) {
  return props.theme.white;
});
export var LoaderWrapper = styled.div.withConfig({
  displayName: "UploadProgressstyled__LoaderWrapper",
  componentId: "sc-k0wdsl-2"
})(["display:grid;grid-template-columns:50px auto;"]);
export var Title = styled(Heading).withConfig({
  displayName: "UploadProgressstyled__Title",
  componentId: "sc-k0wdsl-3"
})(["padding:", "px;font-size:15px;line-height:13px;"], function (props) {
  return props.theme.spacer;
});
export var Close = styled(Icon).attrs({
  name: 'cross'
}).withConfig({
  displayName: "UploadProgressstyled__Close",
  componentId: "sc-k0wdsl-4"
})(["font-size:", "px;justify-self:center;"], function (props) {
  return props.theme.largeSpacer;
});
export var Files = styled.div.withConfig({
  displayName: "UploadProgressstyled__Files",
  componentId: "sc-k0wdsl-5"
})(["display:grid;max-height:300px;overflow:auto;"]);
export var File = styled.div.withConfig({
  displayName: "UploadProgressstyled__File",
  componentId: "sc-k0wdsl-6"
})(["display:grid;grid-template-columns:auto 1fr ", "px;align-items:center;grid-gap:", "px;padding:", "px;"], function (props) {
  return props.theme.iconCanvasSize;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.largeSpacer;
});
export var FileTypeIcon = styled(Icon).withConfig({
  displayName: "UploadProgressstyled__FileTypeIcon",
  componentId: "sc-k0wdsl-7"
})(["font-size:", "px;color:", ";"], function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return props.theme.tertiary;
});
export var CircularProgress = styled(CircularProgressbar).withConfig({
  displayName: "UploadProgressstyled__CircularProgress",
  componentId: "sc-k0wdsl-8"
})(["height:", "px;.CircularProgressbar-path{stroke:", " !important;stroke-linecap:unset !important;}.CircularProgressbar-trail{stroke:", ";}"], function (props) {
  return props.theme.iconCanvasSize;
}, function (props) {
  return props.theme.primary;
}, function (props) {
  return props.theme.vlgrey;
});
export var TickIcon = styled(Icon).attrs({
  name: 'tick-circle'
}).withConfig({
  displayName: "UploadProgressstyled__TickIcon",
  componentId: "sc-k0wdsl-9"
})(["font-size:", "px;color:", ";"], function (props) {
  return props.theme.iconCanvasSize;
}, function (props) {
  return props.theme.primary;
});