import { store } from 'store';
import { differenceInDays } from 'date-fns';
import openInternalPage from '@helpers/openInternalPage';
import { PLATFORM } from '@ui/consts';

const getActiveArtist = () => store.getState().user?.activeArtist;

const isTokenValid = getActiveArtist()?.facebookProfile?.tokenData?.is_valid;
const hasFacebook = getActiveArtist()?.facebookProfile;
const expiryInDaysFromNow = differenceInDays(
  new Date(
    (getActiveArtist()?.facebookProfile?.tokenData
      ?.data_access_expires_at as number) * 1000
  ),
  Date.now()
);
const isTokenOutOfDate = expiryInDaysFromNow <= 0;
const isTokenWithinOneWeekOfExpiry =
  isTokenValid && expiryInDaysFromNow < 8 && expiryInDaysFromNow > 0;

/**
 * Alerts user if facebook token is expired/expiring
 */
export const getFacebookTokenAlert = (themeTitle = 'Beatchain') => {
  if (hasFacebook && isTokenWithinOneWeekOfExpiry)
    return {
      platform: PLATFORM.FACEBOOK,
      text: `${themeTitle}'s will lose access to your Facebook data in ${differenceInDays(
        new Date(
          (getActiveArtist()?.facebookProfile?.tokenData
            ?.data_access_expires_at as number) * 1000
        ),
        Date.now()
      )} days. Click to reauthorise and keep your insights up-to-date.`,
      clickHandler: () =>
        openInternalPage('social-profiles?modalPlatform=facebook'),
    };

  if (hasFacebook && (isTokenOutOfDate || !isTokenValid))
    return {
      platform: PLATFORM.FACEBOOK,
      text: `${themeTitle}'s access to your Facebook data has expired. Click to reauthorise and keep your insights up-to-date.`,
      clickHandler: () =>
        openInternalPage('social-profiles?modalPlatform=facebook'),
    };

  return undefined;
};

/**
 * Alerts user if twitter token is expired
 */
export const getTwitterTokenAlert = (themeTitle = 'Beatchain') => {
  const hasTwitter = getActiveArtist()?.twitterProfile;
  if (hasTwitter && getActiveArtist()?.twitterProfile?.valid_token === false)
    return {
      platform: PLATFORM.TWITTER,
      text: `${themeTitle}'s access to your Twitter data has expired. Click to reauthorise and keep your insights up-to-date.`,
      clickHandler: () =>
        openInternalPage('social-profiles?modalPlatform=twitter'),
    };

  return undefined;
};
