import React, { useState, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { throttle } from 'lodash';
import {
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';

import initSegment from '@helpers/segment';
import { isWindowExists } from '@helpers/isWindowExists';
import initIconLibrary from '@ui/components/Icon/iconLibrary';
import GlobalStateProvider from '@ui/components/GlobalStateProvider';
import { store, sagaMiddleware, rootSaga } from './store';
import { saveState } from './localStorage';
import {
  userPoolId,
  userPoolWebClientId,
  oauthDomain,
  webOrigin,
} from './config/backend.config';
import {
  loginClearStatus,
  login,
  loginSuccess,
  refreshCognitoUser,
  logout,
  signupError,
} from './store/auth';
import App from './App';
import { browserHistory as history } from './browserHistory';

initIconLibrary();
initSegment();

// CONFIGURE AMPLIFY FOR COGNITO AUTH
const amplifyConfig = {
  Auth: {
    region: 'eu-west-1',
    userPoolId,
    userPoolWebClientId,
  },
  oauth: {
    domain: oauthDomain,
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${webOrigin}/signup`,
    redirectSignOut: webOrigin,
    responseType: 'code',
  },
};

if (isWindowExists) Auth.configure(amplifyConfig);

// Federated Logins (google/facebook)
Hub.listen('auth', ({ payload: { event, data } }) => {
  if (
    event === 'signIn' &&
    (data.username?.startsWith('Facebook') ||
      data.username?.startsWith('Google') ||
      data.username?.startsWith('SignInWithApple'))
  ) {
    Auth.currentSession().then(signInUserSession => {
      if (isWindowExists) {
        window.localStorage.setItem(
          'needs_registration_tracking',
          signInUserSession.idToken.payload.email
        );
      }
      const splitted = data.username.split('_');
      store.dispatch(
        loginSuccess({
          signInUserSession,
          mode: splitted.length > 1 ? splitted[0] : 'user_password',
        })
      );
    });
  } else if (event === 'signIn_failure') {
    const message = data?.message
      ?.replace(/\+/g, ' ')
      ?.replace(/^.+failed with error /, '');

    store.dispatch(
      signupError({
        code: 'SignInExeception',
        name: 'SignInExeception',
        message:
          message ||
          'There was an error signing in, please try again later or contact Beatchain',
        doNotClear: true,
      })
    );
  }
});

const isClientSide = isWindowExists;

// For react native app views sessions
if (isClientSide) {
  window.RemoveCognitoSession = () => {
    store.dispatch(logout());
  };

  window.RefreshCognitoSession = session => {
    const localSession = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: session.idToken.jwtToken }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: session.refreshToken,
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: session.accessToken.jwtToken,
      }),
    });

    Auth.currentCredentials = async () => localSession;

    store.dispatch(
      refreshCognitoUser({
        signInUserSession: session,
      })
    );

    return true;
  };

  window.SetCognitoSession = (session, redirectTo) => {
    try {
      let callback;
      if (window.ReactNativeWebView) {
        callback = () =>
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              method: 'reactNativeLogin',
            })
          );
      }

      const localSession = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: session.idToken.jwtToken }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: session.refreshToken,
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: session.accessToken.jwtToken,
        }),
      });

      const localUser = new CognitoUser({
        Username: session.accessToken.payload.username,
        Pool: Auth.userPool,
        Storage: Auth.userPool.storage,
      });

      localUser.setSignInUserSession(localSession);

      Auth.currentCredentials = async () => localSession;

      store.dispatch(
        loginSuccess({
          redirectTo,
          callback,
          signInUserSession: session,
        })
      );

      return true;
    } catch (e) {
      console.warn('SetCognitoSession failed', e.toString());
      return false;
    }
  };
}

// Used for react-native app WebView Sign in
if (isClientSide) {
  window.AuthSignIn = payload => {
    store.dispatch(login(payload));
  };
}

if (isClientSide) {
  sagaMiddleware.run(rootSaga);

  store.subscribe(
    throttle(() => {
      const { auth, user } = store.getState();
      const smallAuth = { ...auth };
      smallAuth.signInAttemptPayload = null;
      saveState({
        auth: smallAuth,
        user,
      });
    }, 1000)
  );

  if (window.location.search.toLowerCase().includes('webview=true')) {
    window.localStorage.setItem('beatchain-webview', 'true');
  }

  store.dispatch(loginClearStatus());
}

const CustomRouter = ({ children }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
};

const BeatchainReactApp = () => (
  <React.StrictMode>
    <GlobalStateProvider>
      <CustomRouter>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </CustomRouter>
    </GlobalStateProvider>
  </React.StrictMode>
);

if (typeof document !== 'undefined') {
  document.getElementsByTagName('html')[0].classList.add('authenticated');
}

if (isClientSide && document.getElementById('root')) {
  const rootElement = document.getElementById('root');
  ReactDOM.render(<BeatchainReactApp />, rootElement);
}
