import React from 'react';
import Loader from '../Loader';
import SocialIcon from '../SocialIcon';

import {
  Wrapper,
  Content,
  Text,
  TextAllCaps,
  TextOfficial,
  LoaderWrapper,
} from './SocialButton.styled';

export interface SocialButtonProps {
  platform: string;
  text: string;
  light?: boolean;
  darkShadow?: boolean;
  lightShadow?: boolean;
  small?: boolean;
  dark?: boolean;
  large?: boolean;
  loading?: boolean;
  disabled?: boolean;
  allCaps?: boolean;
  squishy?: boolean;
  shouldNotStretch?: boolean;
  noIcon?: boolean;
  official?: boolean;
  onPress: () => void;
  dataTestId?: string;
}

/**
 * Button with social media or other platform branding
 */
const SocialButton = ({
  platform,
  text,
  light,
  dark,
  large,
  darkShadow = true,
  lightShadow,
  small,
  loading,
  allCaps,
  disabled,
  noIcon,
  squishy,
  shouldNotStretch,
  official,
  onPress,
  dataTestId,
  ...props
}: SocialButtonProps) => (
  <Wrapper
    small={small}
    large={large}
    dark={dark}
    squishy={squishy}
    shouldNotStretch={shouldNotStretch}
    disabled={disabled || loading}
    visuallyDisabled={disabled}
    platform={platform}
    darkShadow={darkShadow && !official}
    light={light}
    lightShadow={lightShadow}
    official={official}
    onClick={onPress}
    data-testid={dataTestId}
    {...props}
  >
    {!noIcon && (
      <SocialIcon
        platform={platform}
        size={small ? 16 : 24}
        variant={light || dark ? 'colour' : 'outline'}
      />
    )}
    <Content official={official}>
      {loading && (
        <LoaderWrapper>
          <Loader
            small={small}
            color={light ? 'black' : 'white'}
            hasMessage={false}
          />
        </LoaderWrapper>
      )}
      {allCaps && !official && (
        <TextAllCaps hidden={loading} small={small}>
          {text}
        </TextAllCaps>
      )}
      {official && (
        <TextOfficial hidden={loading} small={small}>
          {text}
        </TextOfficial>
      )}
      {!allCaps && !official && (
        <Text hidden={loading} small={small}>
          {text}
        </Text>
      )}
    </Content>
  </Wrapper>
);

export default SocialButton;
