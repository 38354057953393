import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from '@hooks/useStore';
import { useSearchParams } from '@hooks/useRouting';
import { setLoginRedirect } from '@helpers/loginRedirect';
import { loginClearStatus, forceChangePassword } from 'store/auth';
import { HeadingLarge } from '@ui/components/Typography/Typography';
import { BodyText } from '@ui/components/Typography';
import Link from '@ui/components/Link';
import LogInForm from 'components/account/LogInForm';
import NewPasswordForm from 'components/account/NewPasswordForm';
import FormPage from 'components/app/FormPage/FormPage';
import SignUpOptions from 'components/account/SignUpOptions';
import MfaForm from 'components/account/MfaForm';
import useTheme from '@hooks/useTheme';

const Signin = () => {
  const theme = useTheme();
  const [useEmail, setUseEmail] = useState(false);
  const auth = useSelector(state => state.auth || {});
  const dispatch = useDispatch();
  const [search] = useSearchParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loginClearStatus());
    setLoginRedirect(search.get('redirect_to') || undefined);
  }, []);

  const handleNewPasswordSubmit = ({ password }) => {
    dispatch(
      forceChangePassword({
        password,
        signInAttemptPayload: auth.signInAttemptPayload,
      })
    );
  };

  return (
    <FormPage
      handleBackButtonClick={useEmail ? () => setUseEmail(false) : undefined}
    >
      <HeadingLarge>Members&apos; Sign In</HeadingLarge>
      {auth.mustChangePassword && (
        <NewPasswordForm
          isSubmitting={auth.FORCE_CHANGE_PASSWORD?.fetching}
          invalidatedPassword={auth.signInAttemptPayload?.invalidatedPassword}
          onSubmit={handleNewPasswordSubmit}
        />
      )}
      {auth.mfaChallenge && <MfaForm />}
      {!auth.mustChangePassword && !auth.mfaChallenge && useEmail && (
        <LogInForm />
      )}
      {!auth.mustChangePassword && !auth.mfaChallenge && !useEmail && (
        <SignUpOptions
          setUseEmail={setUseEmail}
          useEmailButtonText="Sign in with email"
        />
      )}
      <BodyText>
        {`Not a member yet? `}
        <Link to="/signup">{`Join ${theme.title}`}</Link>
      </BodyText>
    </FormPage>
  );
};

export default Signin;
