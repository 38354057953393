import React from 'react';
import { LogoProps } from './index';

export default ({ isColour, ...props }: LogoProps) => (
  <svg
    viewBox="0 0 160 50"
    fill={isColour ? '#009CDE' : 'currentColor'}
    {...props}
  >
    <path d="M100.11 33.78c2.98 0 3.9-2.06 4.53-4.53h-1.4c-1.94 0-5.15.33-5.15 2.92 0 1.07 1.01 1.6 2.02 1.6zm3.9.2h-.06c-1.37 1.73-2.73 2.71-4.94 2.71-2.76 0-4.73-1.54-4.73-4.37 0-5.06 5.66-5.45 9.4-5.45h1.4c.12-.42.12-.86.12-1.3 0-1.56-1.66-2-3.06-2-1.76 0-3.46.44-5.03 1.19l.56-3.19c1.7-.59 3.43-.92 5.21-.92 3.07 0 5.95 1.13 5.95 4.73 0 2.06-1.51 8.07-1.87 10.96h-3.36l.41-2.36zM.62 21h2.9l.68-3.16 4.28-1.4-.98 4.55h3.57l-.62 2.92H6.87l-1.45 6.7c-.1.35-.18.89-.18 1.49 0 .92.62 1.48 1.52 1.48.71 0 1.42-.15 1.93-.35l-.68 3.1c-.69.14-1.46.35-2.15.35-2.7 0-4.61-1.07-4.61-4.02 0-1.19.24-2.35.5-3.57l1.14-5.18H0L.62 21zM14.17 14.39h3.99l-.84 3.8h-3.98l.83-3.8zm-1.46 6.6h3.99l-3.28 15.33H9.44L12.7 21zM28.74 24.5a5.4 5.4 0 00-2.92-.77c-3.18 0-4.97 2.98-4.97 5.87 0 1.99.66 3.98 3.4 3.98.98 0 2.2-.26 3.24-.77l-.45 3.15c-1.22.6-2.53.72-3.83.72-3.96 0-6.52-2.65-6.52-6.6 0-5.37 3.54-9.44 9.04-9.44 1.5 0 2.92.27 4 .6l-.99 3.27zM33.12 14h3.98l-2.65 12.56h.06l5.57-5.57h5.18l-7.35 7 4.61 8.33h-4.5l-3.95-8.12H34l-1.73 8.12h-3.9L33.12 14zM59.63 21h2.9l.68-3.16 4.28-1.4-.98 4.55h3.57l-.62 2.92h-3.58l-1.45 6.7c-.1.35-.18.89-.18 1.49 0 .92.62 1.48 1.52 1.48.71 0 1.42-.15 1.93-.35l-.68 3.1c-.69.14-1.46.35-2.15.35-2.7 0-4.61-1.07-4.61-4.02 0-1.19.24-2.35.5-3.57l1.14-5.18H59l.63-2.92z" />
    <path d="M58.3 26.47c0-3.9-2.5-5.83-6.28-5.83-5.03 0-8.72 4.58-8.72 9.34 0 4.56 2.98 6.7 7.38 6.7a17 17 0 004.88-.86l.5-3.16c-1.48.69-3.06 1.1-4.7 1.1-2.55 0-4.04-.9-4.23-3.31l-.02-.43v-.08-.05c.02-1.05.25-2.08.65-3.04.77-1.95 1.85-3.3 4.2-3.3 1.67 0 2.53.93 2.53 2.56 0 .36-.03.69-.09 1.04h-5.46a9.4 9.4 0 00-.42 2.74h9.4c.23-1.13.38-2.26.38-3.42zM71.8 22.7l.33-1.7h3.72l-.36 2.05h.06a5.59 5.59 0 014.7-2.41c1.82 0 3.7 1.07 3.9 2.91h.06c1.01-1.99 3.25-2.91 5.39-2.91 2.29 0 4.25 1.87 4.25 4.2 0 1.36-.35 2.91-.68 4.16l-1.6 7.32h-4l1.61-7.47c.21-.92.5-2.35.5-3.27a1.8 1.8 0 00-1.87-1.85c-2.76 0-3.45 3.22-3.96 5.3l-1.57 7.3h-3.99l1.6-7.48c.22-.92.51-2.35.51-3.27a1.8 1.8 0 00-1.87-1.85c-2.71 0-3.45 3.25-3.96 5.3l-1.58 7.3H69l2.8-13.64zM120.68 24.12a6.48 6.48 0 00-2.66-.57c-1.19 0-2.68.33-2.68 1.55 0 1.94 5.18 2.65 5.18 6.4 0 3.99-3.48 5.18-6.9 5.18-1.61 0-3.22-.24-4.68-.92l.87-3.19a7.5 7.5 0 003.8 1.2c1.34 0 2.75-.45 2.75-1.7 0-2.47-5.18-2.71-5.18-6.55 0-3.6 3.48-4.88 6.63-4.88 1.46 0 2.95.27 3.73.47l-.86 3zM123.23 21h2.88l.69-3.16 4.29-1.4-.99 4.55h3.58l-.63 2.92h-3.57l-1.46 6.7c-.09.35-.18.89-.18 1.49 0 .92.63 1.48 1.52 1.48.71 0 1.43-.15 1.93-.35l-.68 3.1c-.69.14-1.46.35-2.14.35-2.71 0-4.62-1.07-4.62-4.02 0-1.19.24-2.35.5-3.57l1.14-5.18h-2.89l.63-2.92zM150.27 23.05c.12-.66.24-1.34.35-2.06h3.81l-.53 2.9h.06c.71-1.68 2.68-3.25 4.52-3.25.5 0 1.05.03 1.52.15l-.83 3.9a5.04 5.04 0 00-1.52-.24c-2.83 0-4.2 2.53-4.7 5l-1.43 6.87h-3.99l2.74-13.27zM147.5 26.47c0-3.9-2.5-5.83-6.28-5.83-5.03 0-8.72 4.58-8.72 9.34 0 4.56 2.97 6.7 7.38 6.7 1.64 0 3.33-.39 4.88-.86l.5-3.16c-1.48.69-3.06 1.1-4.7 1.1-2.55 0-4.04-.9-4.23-3.31l-.02-.43v-.08-.05c.02-1.05.25-2.08.65-3.04.77-1.95 1.85-3.3 4.2-3.3 1.67 0 2.53.93 2.53 2.56 0 .36-.03.69-.09 1.04h-5.46a9.34 9.34 0 00-.42 2.74h9.4c.23-1.13.38-2.26.38-3.42z" />
  </svg>
);
