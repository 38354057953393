import { isWindowExists } from './isWindowExists';

/**
 * Reloads the page
 */
export const reloadPage = () => {
  if (isWindowExists) {
    window.location.reload();
  }
};
