import styled from 'styled-components';
export var Wrapper = styled.span.withConfig({
  displayName: "IconNotificationstyled__Wrapper",
  componentId: "sc-wnhl6x-0"
})(["font-size:", "px;", " ", ""], function (props) {
  return props.size || 24;
}, function (props) {
  return props.isTopRightOfParent && "\n    position: absolute;\n    top:0;\n    right:0;\n  ";
}, function (props) {
  return props.isClickable && "\n    cursor: pointer;\n  ";
});
export var Counter = styled.span.withConfig({
  displayName: "IconNotificationstyled__Counter",
  componentId: "sc-wnhl6x-1"
})(["background:", ";transform:scale(1);top:-14px;right:-14px;"], function (props) {
  return props.theme.warning;
});