var _excluded = ["theme"];

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import styled from 'styled-components';
import { darken, lighten, readableColor, getLuminance } from 'polished';
import { ButtonText } from '../Typography';

var getBackgroundColour = function getBackgroundColour(_ref) {
  var theme = _ref.theme,
      props = _objectWithoutProperties(_ref, _excluded);

  if (props.clean) return 'transparent';
  if (props.colour) return props.colour;
  if (props.light) return theme.white;
  if (props.secondary) return theme.secondary;
  if (props.tertiary) return theme.tertiary;
  if (props.grey) return theme.vdgrey;
  if (props.warning) return theme.warning;
  if (props.dark) return theme.black;
  return theme.primary;
};

var getColour = function getColour(props) {
  return props.clean && props.dark ? props.theme.black : readableColor(getBackgroundColour(props));
};

var getHoverColour = function getHoverColour(props) {
  var colour = getBackgroundColour(props);

  if (props.clean) {
    colour = props.dark ? props.theme.white : props.theme.black;
  }

  return getLuminance(colour) > 0.1 ? darken(0.2, colour) : lighten(0.2, colour);
};

export var Wrapper = styled.button.withConfig({
  displayName: "Buttonstyled__Wrapper",
  componentId: "sc-1jp6d3p-0"
})(["height:", ";min-height:", "px;", " ", " ", " cursor:pointer;", " ", " ", " padding:", "px ", "px;border:0;display:flex;align-items:center;justify-content:center;text-align:center;font-size:unset;background:", ";color:", ";", " ", " ", " ", " ", " ", " ", " ", " ", " &[disabled]{pointer-events:none;}&:focus{outline:none;}&:hover{@media (min-width:700px){outline:none;background:", ";transition:background-color 250ms;}}"], function (props) {
  return props.small ? 'min-content' : "".concat(props.theme.buttonHeight, "px");
}, function (props) {
  return props.small ? props.theme.buttonHeightSmall : props.theme.buttonHeight;
}, function (props) {
  return !props.squishy && "min-width: ".concat(props.theme.buttonMinWidth, "px;");
}, function (props) {
  return props.small && !props.squishy && "min-width: ".concat(props.theme.buttonMinWidthSmall, "px;");
}, function (props) {
  return props.shouldNotStretch && "width: fit-content;";
}, function (props) {
  return props.visuallyDisabled && "opacity: 0.4;\n     cursor: default;";
}, function (props) {
  return props.iconOnly && "min-width: ".concat(props.small ? props.theme.buttonHeightSmall : props.theme.buttonHeight, "px;");
}, function (props) {
  return props.fullWidth && 'width: 100%;';
}, function (props) {
  return props.theme.smallSpacer;
}, function (props) {
  return props.theme.largeSpacer;
}, function (props) {
  return getBackgroundColour(props);
}, function (props) {
  return getColour(props);
}, function (props) {
  return props.darkShadow && "box-shadow: ".concat(props.theme.solidShadow, ";");
}, function (props) {
  return props.lightShadow && "box-shadow: ".concat(props.theme.solidShadowLight, ";");
}, function (props) {
  return props.attentionShadow && "box-shadow: ".concat(props.theme.solidShadowAttention, ";");
}, function (props) {
  return props.clean && "box-shadow: none;";
}, function (props) {
  return props.small && "padding: 0 ".concat(props.theme.spacer, "px;");
}, function (props) {
  return props.iconOnly && 'padding: 0;';
}, function (props) {
  return props.alignRight && 'justify-self: right;';
}, function (props) {
  return props.outline && "border: 0.5px ".concat(getColour(props), " solid;");
}, function (props) {
  return props.roundedCorners && "border-radius: ".concat(props.theme.inputBorderRadius, "px;");
}, function (props) {
  return getHoverColour(props);
});
export var Content = styled.div.withConfig({
  displayName: "Buttonstyled__Content",
  componentId: "sc-1jp6d3p-1"
})(["position:relative;display:flex;justify-content:center;align-items:center;flex-grow:1;", " ", ""], function (props) {
  return props.iconLast && "margin-right: ".concat(props.small ? props.theme.spacer : props.theme.largeSpacer, "px;");
}, function (props) {
  return props.iconFirst && "order: 1;\n    margin-left: ".concat(props.small ? props.theme.spacer : props.theme.largeSpacer, "px;");
});
export var Text = styled(ButtonText).withConfig({
  displayName: "Buttonstyled__Text",
  componentId: "sc-1jp6d3p-2"
})(["", " ", " word-break:normal;"], function (props) {
  return props.hidden && 'opacity: 0;';
}, function (props) {
  return props.small && 'font-size: 11px;';
});
export var LoaderWrapper = styled.div.withConfig({
  displayName: "Buttonstyled__LoaderWrapper",
  componentId: "sc-1jp6d3p-3"
})(["position:absolute;left:0;top:0;right:0;bottom:0;display:flex;justify-content:center;align-items:center;"]);
export var StoryRow = styled.div.withConfig({
  displayName: "Buttonstyled__StoryRow",
  componentId: "sc-1jp6d3p-4"
})(["display:flex;flex-direction:row;justify-content:center;padding:", "px;& > *:not(:last-child){margin-right:", "px;}"], function (props) {
  return props.theme.spacer;
}, function (props) {
  return props.theme.largeSpacer;
});