import { isWindowExists } from '@helpers/isWindowExists';

/**
 * Opens website in a new tab
 */
const openExternalWebpage = (url?: string) => {
  if (window?.ReactNativeWebView?.postMessage) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        method: 'externalUrl',
        url,
      })
    );
  } else if (isWindowExists) {
    // eslint-disable-next-line no-restricted-syntax
    window.open(url, '_blank');
  }
};

export default openExternalWebpage;
