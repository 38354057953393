import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { toast } from 'react-toastify';

import { useDispatch } from '@hooks/useStore';
import useModal from '@hooks/useModal';
import ajax from '@helpers/ajax';
import { updateUserState } from 'store/user';
import PhoneInput from 'components/account/PhoneInput';
import useDevice from '@hooks/useDevice';
import VerifyPhone from '../VerifyPhone/VerifyPhone';
import {
  ModalContainer,
  PhoneNumberContainer,
  RightAlignedButton,
} from '../PhoneNumber.styled';

interface Props {
  setSmsMfa?: () => void;
  closeClickHandler?: () => void;
}

const NewPhone = (props: Props) => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();

  const [number, setNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const openModal = useModal();

  function verifyPhoneNumber() {
    openModal({
      Component: VerifyPhone,
      modalSize: 'dynamic',
      modalTitle: 'Verify Your Phone Number',
      componentProps: {
        setSmsMfa: props.setSmsMfa,
      },
    });
  }

  const inModal = !!props.closeClickHandler;

  const updatePhoneNumber = async () => {
    setLoading(true);
    const authUser = await Auth.currentAuthenticatedUser();
    try {
      const { error, phoneNumber } = await ajax(
        'public/phone-number/verify',
        // Passing a country code would help with validation...
        { phoneNumber: number, countryCode: undefined },
        'POST'
      );

      if (error) {
        throw new Error(error);
      }

      const result = await Auth.updateUserAttributes(authUser, {
        phone_number: phoneNumber,
      });
      if (result !== 'SUCCESS') {
        throw new Error('Invalid phone number format.');
      }

      const data = await ajax('user/signin-settings', {}, 'PUT');
      dispatch(updateUserState(data));
      setLoading(false);

      if (!data.phoneNumberVerified) {
        verifyPhoneNumber();
      } else if (props.closeClickHandler) {
        props.closeClickHandler();
      }
      toast.success('Phone number updated');
    } catch (e: any) {
      toast.error(e?.message || 'Failed to update phone number');
      setLoading(false);
    }
  };

  return (
    <ModalContainer padding={inModal}>
      <PhoneNumberContainer isMobile={isMobile}>
        <PhoneInput
          label="Phone Number"
          onChange={setNumber}
          value={number}
          disabled={loading}
        />
        <RightAlignedButton
          text="save"
          squishy
          onPress={updatePhoneNumber}
          loading={loading}
          disabled={!number}
        />
      </PhoneNumberContainer>
    </ModalContainer>
  );
};

export default NewPhone;
