import { ModalContent } from '@ui/types';
import Dialogue from '@ui/components/Dialogue/Dialogue';
import useModal from '@hooks/useModal';

/**
 * Opens an alert modal
 */
const useAlertModal = () => {
  const openModal = useModal();
  return (
    modalProps: Pick<
      ModalContent,
      'modalTitle' | 'modalText' | 'componentProps'
    >
  ) => {
    openModal({
      modalSize: 'alert',
      isVisible: true,
      ...modalProps,
      Component: Dialogue,
    });
  };
};

export default useAlertModal;
