import React from 'react';

import { IconProps } from './index';

export default ({ isColour, ...props }: IconProps) => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="none" {...props}>
    {isColour ? (
      <>
        <path
          fill="#FF004F"
          d="M364.797 184.822c33.057 23.618 73.559 37.516 117.297 37.516V138.21a118.55 118.55 0 01-24.632-2.577v66.221c-43.736 0-84.231-13.897-117.299-37.516v171.679c0 85.882-69.656 155.5-155.578 155.5-32.058 0-61.857-9.688-86.611-26.3C126.226 494.089 165.624 512 209.212 512 295.14 512 364.8 442.384 364.8 356.497V184.822h-.003zm30.388-84.874C378.29 81.5 367.199 57.658 364.797 31.3V20.48h-23.344c5.875 33.499 25.917 62.119 53.732 79.467zM152.318 399.313a70.813 70.813 0 01-14.517-43.061c0-39.281 31.862-71.127 71.169-71.127a71.298 71.298 0 0121.589 3.332V202.45a157.072 157.072 0 00-24.625-1.417v66.945a71.357 71.357 0 00-21.598-3.334c-39.308 0-71.167 31.847-71.167 71.132.002 27.775 15.927 51.824 39.149 63.538z"
        />
        <path
          fill="#000"
          d="M340.164 164.339c33.067 23.618 73.562 37.516 117.298 37.516v-66.222c-24.414-5.195-46.027-17.948-62.277-35.686C367.37 82.6 347.328 53.98 341.453 20.48h-61.317v336.015c-.139 39.172-31.946 70.889-71.168 70.889-23.115 0-43.649-11.011-56.653-28.069-23.223-11.714-39.147-35.764-39.147-63.54 0-39.284 31.858-71.13 71.166-71.13a71.355 71.355 0 0121.598 3.332v-66.944c-84.412 1.743-152.301 70.68-152.301 155.464 0 42.322 16.907 80.69 44.343 108.723 24.754 16.612 54.552 26.3 86.611 26.3 85.921 0 155.578-69.621 155.578-155.5V164.339h.001z"
        />
        <path
          fill="#00F2EA"
          d="M457.461 135.633v-17.907a117.325 117.325 0 01-62.277-17.782 117.654 117.654 0 0062.277 35.689zM341.453 20.48a119.357 119.357 0 01-1.29-9.66V0h-84.662v336.017c-.136 39.168-31.941 70.885-71.167 70.885-11.518 0-22.389-2.731-32.019-7.588 13.003 17.058 33.538 28.069 56.652 28.069 39.22 0 71.03-31.717 71.168-70.889V20.48h61.318zM205.934 201.033V181.97a156.96 156.96 0 00-21.346-1.447C98.656 180.522 29 250.143 29 336.017c0 53.84 27.376 101.288 68.977 129.199-27.437-28.031-44.343-66.399-44.343-108.722 0-84.78 67.885-153.718 152.3-155.461z"
        />
      </>
    ) : (
      <path
        fill="currentColor"
        d="M357.128 0h-88.19v350.017c-.141 40.8-33.272 73.839-74.132 73.839-11.998 0-23.322-2.845-33.353-7.904v.001c-24.191-12.203-40.778-37.254-40.778-66.188 0-40.92 33.186-74.094 74.131-74.094a74.323 74.323 0 0122.498 3.471l.003-89.59a163.44 163.44 0 00-22.236-1.507C105.558 188.044 33 260.565 33 350.017c0 56.076 28.509 105.495 71.832 134.571l.015.015C130.633 501.908 161.673 512 195.067 512c89.502 0 162.061-72.522 162.061-161.979V171.186c34.446 24.603 76.627 39.079 122.186 39.079l-.001-87.634a122.218 122.218 0 01-64.872-18.522 135.599 135.599 0 00.001.003c-28.973-18.07-49.85-47.884-55.97-82.779a123.867 123.867 0 01-1.344-10.062V0z"
      />
    )}
  </svg>
);
