import styled from 'styled-components';
export var ScreenFadeMain = styled.div.withConfig({
  displayName: "ScreenFadestyles__ScreenFadeMain",
  componentId: "sc-1jsjbqt-0"
})(["transition:background-color 0.8s;position:absolute;z-index:", ";width:100vw;height:100vh;pointer-events:", ";cursor:", ";background-color:", ";"], function (props) {
  return props.isExtraZIndex ? 176 : props.theme.zIndexFadeScreen;
}, function (props) {
  return props.isActive ? 'auto' : 'none';
}, function (props) {
  return props.isActive ? 'pointer' : 'default';
}, function (props) {
  return props.isActive ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0)';
});