import styled from 'styled-components';
/**
 * Lays out label and value pairs for meta data display and details
 */

export var InfoPair = styled.div.withConfig({
  displayName: "InfoPairstyled__InfoPair",
  componentId: "sc-1sk9tjx-0"
})(["display:grid;grid-auto-flow:row;grid-gap:", "px;"], function (props) {
  return props.theme.smallSpacer;
});