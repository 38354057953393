import styled from 'styled-components';
import Button from '@ui/components/Button';
import { BodyText, InfographicNumber, InfographicNumberSmall } from '@ui/components/Typography';
export var Wrapper = styled.div.withConfig({
  displayName: "AccountInfostyled__Wrapper",
  componentId: "sc-y09xsp-0"
})(["display:grid;grid-gap:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var Balance = styled(InfographicNumberSmall).withConfig({
  displayName: "AccountInfostyled__Balance",
  componentId: "sc-y09xsp-1"
})(["text-align:right;"]);
export var BalanceLarge = styled(InfographicNumber).withConfig({
  displayName: "AccountInfostyled__BalanceLarge",
  componentId: "sc-y09xsp-2"
})(["white-space:nowrap;"]);
export var BalancesBox = styled.div.withConfig({
  displayName: "AccountInfostyled__BalancesBox",
  componentId: "sc-y09xsp-3"
})(["display:grid;grid-template-columns:auto 1fr;grid-gap:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var BalancesWrapper = styled.div.withConfig({
  displayName: "AccountInfostyled__BalancesWrapper",
  componentId: "sc-y09xsp-4"
})(["display:grid;grid-gap:", "px;"], function (props) {
  return props.theme.largeSpacer;
});
export var BalanceBox = styled.div.withConfig({
  displayName: "AccountInfostyled__BalanceBox",
  componentId: "sc-y09xsp-5"
})(["display:grid;grid-gap:", "px;justify-content:end;align-content:start;"], function (props) {
  return props.theme.spacer;
});
export var PendingText = styled(BodyText).withConfig({
  displayName: "AccountInfostyled__PendingText",
  componentId: "sc-y09xsp-6"
})(["text-align:end;"]);
export var PaymentMethod = styled.div.withConfig({
  displayName: "AccountInfostyled__PaymentMethod",
  componentId: "sc-y09xsp-7"
})(["display:grid;grid-template-columns:auto 1fr;grid-gap:", "px;align-items:center;"], function (props) {
  return props.theme.largeSpacer;
});
export var NoPaymentMethod = styled.div.withConfig({
  displayName: "AccountInfostyled__NoPaymentMethod",
  componentId: "sc-y09xsp-8"
})(["display:grid;grid-gap:", "px;"], function (props) {
  return props.theme.spacer;
});
export var WarningText = styled(BodyText).withConfig({
  displayName: "AccountInfostyled__WarningText",
  componentId: "sc-y09xsp-9"
})(["color:", ";"], function (props) {
  return props.theme.warning;
});
export var CustomButton = styled(Button).attrs({
  small: true,
  icon: 'cog'
}).withConfig({
  displayName: "AccountInfostyled__CustomButton",
  componentId: "sc-y09xsp-10"
})(["max-width:250px;margin:0 auto;"]);