import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "AppLandingPagestyled__Wrapper",
  componentId: "sc-1qo0ey6-0"
})(["height:", ";width:100vw;display:flex;flex-direction:column;background:black url('", "');filter:contrast(0.8);"], function (props) {
  return "calc(".concat(props.height, "px - ").concat(props.isMobile ? props.theme.topbarHeightMobile : props.theme.topbarHeightDesktop, "px)");
}, function (props) {
  return props.backgroundImageUrl;
});
export var Content = styled.div.withConfig({
  displayName: "AppLandingPagestyled__Content",
  componentId: "sc-1qo0ey6-1"
})(["padding:", "px;display:grid;grid-auto-flow:row;grid-gap:", "px;justify-items:start;"], function (props) {
  return props.isMobile ? props.theme.largeSpacer : props.theme.xxlargeSpacer;
}, function (props) {
  return props.theme.xlargeSpacer;
});